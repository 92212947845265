export default {
  computed: {
    isCH() {
      return this.countryCode == this.chCountryCode
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
  },
}
