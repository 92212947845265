<template>
  <div class="fund_card" v-on="$listeners">
    <div class="content">
      <el-image class="image">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <p class="title">
        <template v-if="!$slots.title">{{ title }}</template>
        <slot v-else name="title" />
      </p>
    </div>
    <div class="footer">
      <div class="fee" v-if="feeVisible">
        <template v-if="!$slots.fee">{{ fee }}{{ $t('deposit.default.fee') }}</template>
        <slot v-else name="fee" />
      </div>
      <div v-else><!-- 占位元素 --></div>
      <div class="time" v-if="timeVisible">
        <template v-if="!$slots.time">{{ time }}</template>
        <slot v-else name="time" />
      </div>
      <div v-else><!-- 占位元素 --></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FundCard',
  props: {
    title: {
      type: String,
    },
    image: {
      type: String,
    },
    feeVisible: {
      type: Boolean,
      default: true,
    },
    fee: {
      type: [String, Number],
    },
    timeVisible: {
      type: Boolean,
      default: true,
    },
    time: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.fund_card {
  cursor: pointer;
  border-radius: 8px;
  background-color: $background-color-base;
  border: 1px solid $background-color-base;
  padding: 4px 4px 14px 4px;
  &:hover {
    border: 1px solid $primary;
  }

  .content {
    text-align: center;
    border-radius: 8px;
    background-color: $white;
    padding: 16px 8px 8px;

    .image {
      height: 32px;
    }

    .title {
      font-size: 14px;
      line-height: 22px;
      margin-top: 8px;
      color: $text-primary;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    color: $text-secondary;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    padding: 0 24px;
  }
}
</style>
