import {
  DEPOSIT_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_PATHNAME,
  REFER_A_FRIEND_PATHNAME,
  TRADING_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
} from '@/constants/route'
import { countryCodeEnum as COUNTRYCODEENUM } from '@/constants/country'

export const PROMOTION_TYPE = {
  NO_DEPOSIT_BONUS: 'NO_DEPOSIT_BONUS_1',
  REFER_A_FRIEND: 'REFER_A_FRIEND_3',
  DEPOSIT_BONUS: 'DEPOSIT_BONUS_2',
  TRADING_BONUS: 'TRADING_BONUS',
  CASHBACK: 'CASHBACK',
  XMASMAKEAWISH: 'LUCKY_DRAW',
  MILLION_DOLLAR_CASH_POOL: 'MILLION_DOLLAR_CASH_POOL',
  FIRST_TIME_DEPOSIT: 'FIRST_TIME_DEPOSIT'
}

export const PROMOTION_TYPE_PATH_MAP = {
  [PROMOTION_TYPE.NO_DEPOSIT_BONUS]: NO_DEPOSIT_BONUS_PATHNAME,
  [PROMOTION_TYPE.REFER_A_FRIEND]: REFER_A_FRIEND_PATHNAME,
  [PROMOTION_TYPE.TRADING_BONUS]: TRADING_BONUS_PATHNAME,
  [PROMOTION_TYPE.CASHBACK]: CASHBACK_PATHNAME,
  [PROMOTION_TYPE.XMASMAKEAWISH]: XMAS_MAKE_A_WISH_PATHNAME,
  [PROMOTION_TYPE.MILLION_DOLLAR_CASH_POOL]: MILLION_DOLLAR_CASH_POOL_PATHNAME,
  [PROMOTION_TYPE.FIRST_TIME_DEPOSIT]: FIRST_TIME_DEPOSIT_PATHNAME
}

export const SAME_PROMOTION_TYPE = {
  [PROMOTION_TYPE.DEPOSIT_BONUS]: {
    6: DEPOSIT_BONUS_PATHNAME, //Welcome5020
    32: DEPOSIT_20_BONUS_PATHNAME, //VN20
  },
}

export const FE_ONLY_CAMPAIGN = {
  // millionDollarCashPool: {
  //   id: 1000,
  //   campaignType: 'MILLION_DOLLAR_CASH_POOL',
  //   excludeCountry: ['CHINA'],
  // },
}

export const addFEOnlyCampaign = (campaignArray = [], countryCode) => {
  if (!countryCode) return [...campaignArray]

  const res = [...campaignArray]
  Object.keys(FE_ONLY_CAMPAIGN).forEach(campaign => {
    const campaignItem = FE_ONLY_CAMPAIGN[campaign]
    const excludeCountry =
      campaignItem.excludeCountry && campaignItem.excludeCountry.length ? [...campaignItem.excludeCountry] : []
    const excludeCountryCodeArray = excludeCountry.length
      ? excludeCountry.reduce((acc, item) => {
          if (COUNTRYCODEENUM[item]) {
            acc.push(COUNTRYCODEENUM[item])
            return acc
          }
        }, [])
      : []

    if (!excludeCountryCodeArray.includes(Number(countryCode))) {
      res.push(campaignItem)
    }
  })
  return [...res]
}
