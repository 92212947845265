import { render, staticRenderFns } from "./Cima.vue?vue&type=template&id=709c8f80&scoped=true&"
import script from "./Cima.vue?vue&type=script&lang=js&"
export * from "./Cima.vue?vue&type=script&lang=js&"
import style0 from "./Cima.vue?vue&type=style&index=0&id=709c8f80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709c8f80",
  null
  
)

export default component.exports