// 進行同步執行
import Vue from 'vue'

export default {
  setPaymentList(state, value) {
    state.paymentList = value
  },
  setRestrictedDeposit(state, value) {
    state.restrictedDeposit = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = []
    })
  },
  SET_ISSHOWFIRSTPROMOTIONPOPUP(state, value) {
    state.isShowFirstPromotionPopup = value;
  }
}
