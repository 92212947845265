export default {
  en: 'Bahasa Inggris',
  zh: '中文',
  fr: 'Bahasa Perancis',
  common: {
    liveChat: { desc: 'Butuh bantuan ? Berbicara Langsung' },
    country: { international: 'Internasional', australia: 'Australia' },
    verification: { toSlide: 'Geser untuk melengkapi teka-teki' },
    field: {
      nameOnCard: 'Nama pada kartu',
      cardholderName: `Nama pemegang kartu`,
      first4Digits: '4 digit pertama pada kartu',
      last3Digits: '3 digit pertama pada kartu',
      first6Digits: '6 digit pertama pada kartu',
      last4Digits: '4 digit terakhir pada kartu',
      bankName: 'Nama Bank',
      bankAddress: 'Alamat Bank',
      branchAddress: 'Alamat Kantor Cabang',
      bankBranch: 'Kantor Cabang Bank',
      bankCity: 'Kota Bank',
      bankProvince: 'Provinsi Bank',
      bankBeneficiaryName: 'Nama Bank Penerima',
      bankBeneAccName: 'Nama Akun Bank Penerima',
      bankBeneAccNum: 'Nomor Akun Bank Penerima',
      bankBeneAddress: 'Alamat Penerima',
      bankAccNum: 'Nomor Akun Bank',
      bankAccName: 'Nama Akun Bank  ',
      bankHolderAddress: 'Alamat Pemegang Akun',
      address: 'Alamat',
      accName: 'Nama Akun',
      accNum: 'Nomor Akun',
      accNumTo: 'Nomor Akun',
      bsbNum: 'Nomor BSB',
      swift: 'Kode SWIFT ',
      ifscCode: 'Kode IFSC',
      swiftNonAUD: 'Kode SWIFT (Akun Non AUD)',
      amt: 'Jumlah',
      amtUSD: 'Jumlah (USD)',
      availableAmount: 'Jumlah yang Tersedia',
      transitNumber: 'Nomor Transit',
      institutionNumber: 'Nomor Institusi',
      imptNotes: 'Catatan penting',
      yes: 'Ya',
      no: 'Tidak',
      payMethod: 'Metode Pembayaran:',
      expDate: 'Tanggal kedaluwarsa',
      bsb: 'BSB',
      abaSortCode: 'Kode ABA/Sort:',
      country_region: 'Negara/Wilayah',
      currLeverage: 'Leverage saat ini',
      accEquity: 'Jumlah Ekuitas',
      newLeverage: 'Leverage yang diinginkan',
      currPw: 'Password saat ini',
      newPw: 'Password baru',
      confirmPw: 'Konfirmasi Password Baru',
      loginEmail: 'Masuk dengan Email',
      email: 'Email',
      pw: 'Password',
      accType: 'Tipe Akun',
      name: 'Nama',
      emailAdd: 'Alamat Email',
      phone: 'Nomor Telepon',
      dob: 'Tanggal Lahir',
      nat: 'Kewarganegaraan',
      country: 'Negara tempat tinggal',
      street: 'Nomor Rumah/Nama Jalan',
      suburbOrCity: 'Kabupaten/Kota',
      provinceOrState: 'Provinsi',
      postcode: 'Kode Pos',
      empStat: 'Status Pekerjaan',
      annIncome: 'Pendapatan Tahunan',
      save: 'Investasi',
      source: 'Sumber Pendapatan',
      industry: 'Industri',
      statusDef: 'DEFINISI STATUS',
      month: 'Bulan',
      year: 'Tahun',
      select: 'Pilih',
      completed: 'Lengkap',
      incomplete: 'Tidak Lengkap',
      submitted: 'Dikirim',
      successful: 'Berhasil',
      processing: 'Sedang Diproses',
      cancelled: 'Dibatalkan',
      failed: 'Gagal',
      rejected: 'Ditolak',
      upload: 'Unggah',
      pending: `tertunda`,
      ccNum: 'Nomor Kartu Kredit',
      ccExpireDate: 'Tanggal kedaluwarsa',
      bankCardNumber: 'Nomor Kartu Bank',
      cardHolderName: 'Nama Pemegang Kartu',
      reservedMobileNumber: 'Nomor Telepon Seluler untuk Bank',
      nationalId: 'Kartu Tanda Penduduk',
      cardPhoto: 'Foto Kartu (Depan)',
      notes: 'Catatan',
      bankAccOrIBAN: 'Nomor Akun Bank/IBAN',
      selectWithdrawCard: 'Pilih Kartu Penarikan Anda',
      updateCardPhoto: 'Silahkan unggah foro kartu anda (Depan)',
      actualAmount: 'Jumlah Aktual',
      resetBalTo: 'Atur Ulang Saldo ke',
      ipChangeWarning: 'Dapatkan email peringatan ketika ada yang masuk ke akun Anda dari lokasi yang berbeda',
      enterVerificationCode: 'Masukkan Kode Verifikasi',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'TANGGAL',
      type: 'TIPE  ',
      server: 'SERVER',
      status: 'STATUS',
      comment: 'KOMENTAR',
      accNum: 'NOMOR AKUN',
      currency: 'MATA UANG',
      equity: 'EKUITAS',
      leverage: 'LEVERAGE',
      resetMT4PW: 'SETEL ULANG PASSWORD MT4',
      resetMT5PW: 'SETEL ULANG PASSWORD MT5',
      tradingAcc: 'AKUN TRADING',
      method: 'METODE',
      amt: 'JUMLAH',
      procNote: 'NOTA PROSES',
      number: 'NOMOR KARTU',
      PLATFORM: 'PLATFORM',
      resetBal: 'ATUR ULANG SALDO',
    },
    key: { from: 'DARI', to: 'KE' },
    fund: {
      must: 'HARUS',
      infoHover: 'Nama pada kartu anda {must} sama dengan nama pada trading akun anda',
      ccUploadReminder:
        'Mohon unggah foto tampak depan dari kartu anda untuk verifikasi. (Silahkan tutup 6 digit angka tengah)',
    },
    button: {
      bkToHm: 'Kembali ke Beranda',
      smsLogin: 'LOGIN DENGAN TELEPON SELULER',
      goToIB: 'Ganti ke Portal IB',
      logout: 'KELUAR',
      submit: 'Kirim',
      selectFile: 'Pilih File',
      depositFunds: 'SETOR DANA',
      withdrawFunds: 'TARIK DANA',
      ibPortal: 'PORTAL IB',
      download: 'UNDUH',
      changeLeverage: 'GANTI LEVERAGE',
      cancel: 'Batal',
      changePw: 'GANTI PASSWORD',
      forgetPw: 'LUPA PASSWORD',
      confirm: 'Konfirmasi',
      login: 'LOG IN',
      forgotPw: 'Lupa Password?',
      completeQuestionnaire: 'LENGKAPI PERTANYAAN',
      identityProof: 'LENGKAPI PERMOHONAN',
      upload: 'Unggah',
      export: 'EKSPOR',
      ok: 'OK',
      hide: 'TUTUPI',
      showAll: 'TAMPILKAN SEMUA',
      createLiveAccount: `Buat Akun Live`,
      redeem: `Tukarkan`,
      sendCode: 'Kirim Kode',
      resendCode: 'Kirim Ulang Kode',
    },
    formValidation: {
      accReq: 'Nomor Akun diperlukan',
      amtReq: 'Jumlah diperlukan',
      ccReq: 'Nomor Kartu Kredit diperlukan',
      cardReq: 'Nomor Kartu diperlukan',
      cardOnNameReq: 'Nama pada kartu diperlukan',
      cardholderNameReq: `Nama Pemegang Kartu wajib diisi`,
      digits16: 'Silihakan isi 16 digit',
      digits3or4: 'Silahkan isi 3 atau 4 digit',
      digitsFirst6: 'Silahkan isi 6 digit pertama',
      digitsLast4: 'Silahkan isi 4 digit terakhir',
      digitsLast3: 'Silahkan isi 3 digit terakhir',
      mid6Digits: 'Silahkan isi digit {number} tengah ',
      expDateReq: 'Tanggal masa berlaku diperlukan',
      cvvReq: 'Kode Keamanan CVV diperlukan',
      file: 'Silahkan unggah file',
      filePhoto: 'Foto Kartu Kredit diperlukan',
      fileReceipt: 'Silahkan unggah resi',
      filePhotoReq: 'Foto Kartu diperlukan',
      amtLarger: 'Jumlah tidak boleh nol dan harus lebih besar atau sama dengan {minLimit} {currency}',
      amt0: 'Jumlah tidak boleh 0',
      amtLess: 'Jumlah dari penyetoran anda tidak boleh lebih dari {maxLimit} {currency}',
      note256: 'Batas panjang catatan adalah 256 karakter',
      noteMoreThan: 'Batas panjang catatan adalah {limit} karakter',
      noteLessThan: 'Batas panjang catatan adalah {limit} karakter',
      emailFormat: 'Silahkan isi alamat email yang benar',
      payMethodReq: 'Metode pembayaran diperlukan',
      expReq: 'Tanggal masa berlaku diperlukan',
      bankNameReq: 'Nama Bank diperlukan',
      bankCityReq: 'Nama Kota Bank diperlukan',
      bankProvinceReq: 'Nama Provinsi Bank diperlukan',
      bankAddressReq: 'Alamat Bank diperlukan',
      bankBranchReq: 'Nama Kantor Cabang Bank diperlukan',
      bankAccNumReq: 'Nomor Akun Bank diperlukan',
      ifscCodeReq: 'Kode IFSC diperlukan',
      bankBeneficiaryNameReq: 'Nama Bank Penerima diperlukan',
      bankHolderAddressReq: 'Alamat Pemegang Akun diperlukan',
      swiftReq: 'Kode SWIFT diperlukan',
      accHolderAddress: 'Alamat Pemegang Akun diperlukan',
      bsbReq: 'BSB diperlukan',
      accNumIBANReq: 'Nomor Akun Bank/IBAN diperlukan',
      accNameReq: 'Nama Akun Bank diperlukan',
      withdrawMethodReq: 'Silahkan pilih metode penarikan',
      tnc: 'Silahkan setujui Syarat dan Ketentuan',
      currPwReq: 'Password saat ini diperlukan',
      currPwWrong: 'Password saat ini salah',
      newPwReq: 'Password baru diperlukan',
      newPwFormat:
        'Kata sandi Anda harus terdiri dari 8-16 karakter dan gabungan huruf (a-z dan A-Z), angka (0-9), dan karakter khusus seperti !@#$%^&*.()',
      IdenficalPw: 'Password baru tidak boleh sama dengan password yang lama',
      confirmPwReq: 'Silahkan isi password baru sekali lagi',
      confirmNotMatch: 'Dua isian tidak sesuai',
      pwReq: 'Password diperlukan',
      accCurrReq: 'anda harus memilih mata uang akun',
      tradAccReq: 'Silahkan pilih akun trading',
      answerReq: 'Silahkan pilih',
      bankCardReq: 'Silahkan pilih kartu',
      phoneNumberReq: 'Nomor Telepon Seluler untuk Bank diperlukan',
      nationaIdReq: 'Kartu Tanda Penduduk diperlukan',
      numberReq: 'Silahkan isi dengan nomor',
      alphanumericReq: 'Silaihkan isi dengan alfanumerik',
      idTypeReq: 'Silahkan pilih Tipe tanda pengenal',
      dobReq: 'Silahkan pilih tanggal lahir anda',
      pobReq: 'Silahkan pilih tempat lahir anda',
      nationalityReq: 'Silahkan pilih kewarganegaraan anda',
      titleReq: 'Silahkan pilih status anda',
      firstNameReq: 'Silahkan masukan nama depan anda',
      lastNameReq: 'Silahkan masukan nama belakang anda',
      emailReq: 'Sialhkan masukan alamat email anda',
      addressReq: 'Silahkan masukan alamat anda',
      suburbReq: 'Sialhkan masukan kota atau kabupaten anda',
      stateReq: 'Provinsi diperlukan',
      postcodeReq: 'Silahkan masukan kode pos',
      countryReq: 'Silahkan pilih negara tempat tinggal anda',
      masterCardReq: 'Silakan gunakan Master Card untuk deposit',
      masterCardJCBReq: 'Silakan gunakan Master Card / JCB untuk penyetoran',
      masterCardVisaReq: 'Silakan gunakan Master Card / Visa untuk penyetoran',
      masterCardAmexJCBReq: 'Harap gunakan Master Card/AMEX/JCB untuk deposit',
      bankCodeReq: 'Silahkan isi Bank yang menerbitkan Kartu',
      dynamicReq: '{dynamic} diperlukan',
      mobReq: 'Telepon Seluler diperlukan',
      resetBalAmt: 'Harap tentukan jumlah antara 0 and 1.000.000',
      verCodeReq: 'Dibutuhkan kode verifikasi',
      emailFormatWrong: 'Format email salah',
      passwordFormatWrong: 'Format kata sandi salah',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Bank Transfer/Bpay/PoliPay',
      banktransfer: 'Transfer Bank',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'FasaPay',
      creditcard: 'Kartu Kredit',
      bpay: 'Bpay',
      creditdebitcard: 'Kartu Kredit/Debit',
      bankwiretransferinternational: 'Wire Transfer Bank (Internasional)',
      banktransferaustralia: 'Transfer Bank (Australia)',
      banktransferinternational: 'Transfer Bank (Internasional)',
      banktransferchina: 'Transfer Bank (Internasional)',
      banktransferequals: 'Transfer Bank (Internasional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transfer Antar Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Perbankan Elektronik (Thailand)',
      internetbankingnigeria: 'Perbankan Elektronik (Nigeria)',
      internetbankingvietnam: 'Perbankan Elektronik (Vietnam)',
      internetbankingmalaysia: 'Perbankan Elektronik (Malaysia)',
      internetbankingindonesia: 'Perbankan Elektronik (Indonesia)',
      internetbankingkorea: 'Perbankan Elektronik (Korea)',
      internetbankingindia: 'Perbankan Elektronik (India)',
      internetbankingphilippine: 'Perbankan Elektronik (Filipina)',
      internetbankingsouthafrica: 'Perbankan Elektronik (Afrika Selatan)',
      internetbankinguganda: 'Perbankan Elektronik (Uganda)',
      internetbankingrwanda: 'Perbankan Elektronik (Rwanda)',
      internetbankingzambia: 'Perbankan Elektronik (Zambia)',
      internetbankingcongo: 'Perbankan Elektronik (Kongo)',
      internetbankingcameroon: 'Perbankan Elektronik (Kamerun)',
      internetbankingburundi: 'Perbankan Elektronik (Burundi)',
      internetbankingkenya: 'Perbankan Elektronik (Kenya)',
      internetbankingghana: 'Perbankan Elektronik (Ghana)',
      internetbankingtanzania: 'Perbankan Elektronik (Tanzania)',
      internetbankingcanada: 'Perbankan Elektronik (Kanada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transfer Bank Vietnam',
      nigeriabanktransfer: 'Transfer Bank Nigeria',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Wire Transfer Bank (Australia)',
      thailandinstantbankwiretransfer: 'Wire Transfer Bank Instan Thailand ',
      nigeriainstantbankwiretransfer: 'Wire Transfer Bank Instan Nigeria',
      vietnaminstantbankwiretransfer: 'Wire Transfer Bank Instan Vietnam ',
      malaysiainstantbankwiretransfer: 'Transfer Bank Instan Malaysia',
      indonesiainstantbankwiretransfer: 'Transfer Bank Instan Indonesia',
      indiainstantbanktransfer: 'Transfer Bank Instan India ',
      southkoreabanktransfer: 'Transfer Bank Lokal Korea Selatan',
      southafricainstantbankwiretransfer: 'Wire Transfer Bank Instan Afrika Selatan',
      philippinesinstantbankwiretransfer: 'Transfer Bank Instan Filipina',
      ugandabanktransfer: 'Transfer Bank Lokal Uganda',
      rwandabanktransfer: 'Transfer Bank Lokal Rwanda',
      zambiabanktransfer: 'Transfer Bank Lokal Zambia',
      congobanktransfer: 'Transfer Bank Lokal Kongo',
      cameroonbanktransfer: 'Transfer Bank Lokal Kamerun',
      burundibanktransfer: 'Transfer Bank Lokal Burundi',
      kenyabanktransfer: 'Transfer Bank Lokal Kenya',
      ghanabanktransfer: 'Transfer Bank Lokal Ghana',
      tanzaniabanktransfer: 'Transfer Bank Lokal Tanzania',
      indiabanktransfer: 'Transfer Bank India',
      banktransferbpaypolipayaustralia: 'Transfer Bank /Bpay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transfer Bank/Bpay/PoliPay (Internasional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Transfer Bank Lokal Kanada',
      cryptobitcoin: 'Cyrpto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Cyrpto-Tether(ERC20)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrrency-USDT',
      cryptocurrencyeth: 'Cryptocurrrency-ETH',
      cryptocurrencyusdc: 'Cryptocurrrency-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Cryptocurrrency-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'MATA UANG KRIPTO',
      LOCAL_TRANSFER: 'TRANSFER LOKAL',
      archiveThisCard: 'Harap arsipkan kartu ini jika sudah tidak berlaku atau sedang digunakan',
      manageCreditCards: 'Kelola kartu kredit',
      update: 'memperbarui',
      archive: 'Arsip',
      requiredDocument: 'Dokumen yang diperlukan untuk pengarsipan kartu',
      officialLetter: 'Surat/email bank resmi yang menyatakan bahwa kartu tidak lagi tersedia',
      bankStatement: 'Penyata Bank dengan transaksi dibuat dengan kad ini',
      expiredCard: 'Kartu Kedaluwarsa/Tidak Tersedia',
      confirmArchive: 'Apakah anda yakin ingin mengarsipkan kartu ini?',
      confirmUpdate:
        'Apakah anda yakin ingin memperbarui tanggal kedaluwarsa dari kartu ini? Catatan: Puprime tidak akan bertanggung jawab jika penarikan gagal karena tanggal kedaluwarsa yang diberikan salah',
      pendingArchiveRequest:
        'Permintaan pengarsipan kartu kredit Anda saat ini sedang menunggu persetujuan, harap menunggu hingga prosesnya tuntas sebelum melakukan penarikan, atau hubungi layanan pelanggan kami melalui chat langsung untuk mempercepat prosesnya.',
    },
  },
  home: {
    pendingTooltip:
      'Setelah akun live anda disetujui, anda akan menerima email berisi detail login MT{number}anda, sehingga ada dapat memulai trading! Sementara itu, anda dapat mendanai akun anda untuk menerima prioritas pembukaan akun.',
    leverage: {
      tnc:
        '<p> Dengan mengirimkan permintaan ini, saya memahami bahwa saya menyadari bahwa leverage yang tinggi membawa risiko besar bagi modal saya dan ada kemungkinan saya dapat mengalami kerugian lebih besar dari dan tidak terbatas pada modal yang saya setorkan. <br> <br> Saya setuju bahwa penerbit produk berhak untuk meninjau aktivitas trading saya dan menyesuaikan tingkat leverage pada akun perdagangan saya atas kebijakannya sendiri dan tanpa peringatan atau pemberitahuan sebelumnya. <br> <br> Saya memahami bahwa dengan memilih leverage tinggi, Saya bisa trading dengan margin lebih besar tapi berpotensi mengalami kerugian yang signifikan. </p>',
      changeLeverageConfirm: 'Permintaan anda telah dikirimkan dan akan segera diproses oleh tim support kami.',
      changeLeverageAutoComplete: 'Permintaan perubahan leverage anda telah dipenuhi',
      changeLeverageReject: 'Permintaan perubahan leverage anda ditolak',
      changeLeverageFail: 'Permintaan perubahan leverage anda gagal, silahkan coba lagi',
      changeLeverageDefault:
        'Telah terjadi kesalahan, silahkan kontak {supportEmail} untuk menyelesaikan permohononan anda',
      alreadyApplied:
        'Anda telah meminta untuk mengubah leverage pada akun anda, dan saat ini sedang ditinjau oleh tim support {platform}. Harap menunggu sampai permintaan ini diselesaikan sebelum mengirimkan permintaan lain.',
      reduceLeverageWarn:
        'Mohon dicatat: Bahwa mengurangi leverage dapat mengakibatkan penutupan paksa dari posisi yang masih terbuka jika anda tidak memiliki margin yang cukup.',
      reduceLeverageConfirm: 'Anda masih mau melanjutkan?',
      autoRestore: `Karena ekuitas akun Anda melebihi USD 20.000, leverage maksimum yang boleh Anda manfaatkan adalah 500:1. Anda dapat mengubah leverage menjadi 1000:1 ketika ekuitas akun Anda lebih kecil dari atau sama dengan USD 20.000`,
    },
    pw: {
      error: 'Gagal memperbarui password anda. Silahkan coba lagi',
      confirm:
        'Password anda telah diperbarui, <br>anda sekarang bisa mulai menggunakan password baru anda untuk mengakses akun trading MT4/MT5 ',
      email:
        '<p class="first_info">Kami baru saja mengirimi anda email berisi petunjuk untuk mengatur ulang password anda. </p> <p> Mohon pastikan untuk juga memeriksa folder spam email anda.</p>',
    },
    activate: {
      question: 'Apakah anda mau mengaktivasi ulang akun live anda?',
      confirm: 'Kami telah menerima permintaan aktivasi anda.<br>Akun anda akan segera diaktivasi.',
    },
    accStatus: {
      active: 'Aktif',
      rejected: 'Ditolak',
      inactive: 'Tidak Aktif',
      processing: 'Sedang Diproses',
      activate: 'Diaktfikan',
      expired: 'kedaluwarsa',
    },
    pro1000: {
      desc1: `Pembatasan berikut berlaku untuk akun dengan leverage 1000:1:`,
      conditions: [
        `Akun terpilih tidak memenuhi syarat untuk berpartisipasi dalam promosi yang berkaitan dengan kredit.`,
        `Leverage bisa diturunkan menjadi 1:500 oleh sistem secara otomatis bila ekuitas akun terpilih melebihi USD20.000 atau yang setara. Bila ada posisi trading terbuka, email pemberitahuan akan dikirimkan sebelum penyesuaian leverage.`,
        `Limit leverage dapat berbeda-beda sesuai peraturan yang berlaku.`,
        `Harap dicatat bahwa Leverage 1:1000 tidak tersedia untuk instrumen keuangan dari Mata Uang Kripto, Saham, Perak, dan Komoditas. Margin untuk instrumen-instrumen tersebut dipertahankan sesuai dengan persyaratan instrumen margin tetap dan tidak terpengaruh oleh Leverage 1: 1000.`,
      ],
      desc2: `Dengan mengajukan permintaan ini, saya mengakui bahwa saya menyadari bahwa leverage tinggi membawa risiko yang besar atas modal saya dan ada kemungkinan saya dapat mengalami kerugian yang lebih besar dari dan tidak terbatas pada modal yang telah saya depositkan.`,
      desc3: `Saya setuju bahwa penerbit produk berhak untuk meninjau aktivitas trading saya dan menyesuaikan tingkat leverage pada akun trading saya atas kebijakannya sendiri dan tanpa peringatan atau pemberitahuan terlebih dahulu.`,
      desc4: `Saya memahami bahwa dengan memilih leverage tinggi, saya dapat trading dengan margin lebih besar namun berpotensi menimbulkan kerugian yang signifikan.`,
    },
  },
  paymentDetails: {
    subHeader: 'TAMBAHKAN METODE PEMBAYARAN',
    payMethod: 'Metode Pembayaran',
    tip: `<p>Silahakan pilih metode pembayaran untuk melakukan setoran dan penarikan pada masa yang akan datang, melalui menu di bawah ini.</p><br />
      <p><b>Mohon dicatat:</b> Untuk memastikan permintaan setoran dan penarikan anda segera diproses, peraturan AML/CTF mewajibkan bahwa nama yang tertera pada metode pembayaran anda
      <b>{individualUserName}</b>.</p>`,
    result: {
      defaultSuccessMessage:
        'Terima kasih telah mengirimkan metode pendanaan untuk akun trading anda. Kami sedang memproses permintaan anda dan akan segera menghubungi anda kembali',
      unionPayCardSuccessMessage:
        'Kartu pembayaran Union Pay berhasil dikirimkan, rincian bank baru anda akan segera tersedia setelah disetujui.',
    },
    history: { header: 'METODE PEMBAYARAN' },
    cc: {
      field: {
        ccNum: 'Nomor Kartu Kredit',
        first6: 'enam pertama',
        last4: 'empat terakhir',
        withrawlable:
          'Jumlah maksimal penarikan yang tersedia untuk kartu ini adalah {amount}. Untuk melakukan penarikan yang melebihi batas yang tersedia, silahkan pilih kartu atau metode penarikan lain.',
      },
      formValidation: { upload: 'Foto Kartu Kredit diperlukan' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Pengambilan status setoran anda ... Sisa {countDown} detik.',
      successMsg: 'Terima kasih atas setoran anda, dana anda akan segera muncul pada akun anda.',
      rejectMsg: 'Ditolak',
      failureMsg: 'Setoran anda tidak berhasil. Silahkan coba lagi.',
      message:
        'Terima kasih sudah mendanai akun anda. Setoran anda secara manual akan segera disetujui oleh tim akun {platform}. Dana akan masuk ke akun anda segera setelah disetujui.',
      defaultException:
        'Transaksi anda sedang dalam proses, untuk mengkonfirmasi deposit anda dengan {platform}, silakan hubungi {email}',
    },
    failed: { countDownMsg: 'Pengambilan status setoran anda ... Sisa {countDown} detik.', rejectMsg: 'Ditolak' },
  },
  deposit: {
    alert: {
      depositBonus50: `Anda berhak memperoleh Bonus Deposit 50%. Aktifkan sebelum melakukan deposit!`,
    },
    default: {
      deposit: 'Setoran',
      reference: 'Referensi anda',
      sendReceipt: 'Kirim Resi',
      sendReceiptTip:
        'Setelah adan melakukan transfer, mohon lengkapi formulir di bawah ini, sertakan tangkapan layar, foto, atau salinan pindaian resi transfer bank yang menunjukan dengan jelas nama lengkap, nomor akun, dan jumlah setoran anda sehingga kami bisa segera memproses ke dalam akun anda.',
      reminder: 'Mohon tunggu 1-2 hari kerja untuk dana dimasukan ke dalam akun anda.',
      successMsg:
        'Dana akan muncul di dalam akun trading anda setelah kami menerima setoran dan melakukan verifikasi resi transaksi anda.',
      alt: 'Atau, anda dapat mengirimkan resi transaksi anda melalui email ke {mailTo} secepat anda kehendaki.',
      reminderFundAppear:
        'Dana anda akan muncul di dalam akun anda, segera setelah kami menerima dana dari broker anda sebelumnya.',
      failed: 'Setoran gagal, silahkan coba lagi.',
      fee: 'BIAYA',
      instant: 'INSTAN',
      hour: 'JAM',
      hours: 'JAM',
      businessDays: 'HARI KERJA',
      withinOneBusinessDays: 'DALAM 1 HARI KERJA',
      secondaryNGN:
        '(Anda dapat menggunakan Nomor Rekening ini untuk transaksi ini jika Anda lebih suka menggunakan mata uang Nigerian Naira (NGN).)',
      disclaimer: `<li> Semua pembayaran anda ke {platform} bersifat rahasia dan hanya digunakan untuk penyetoran dan penarikan di akun perdagangan anda di {platform}. Semua pembayaran elektronik diproses oleh teknologi SSL (Secure Socket Layer) dan dienkripsi untuk memastikan keamanan. </li>
      <li> {platform} dengan tegas tidak menerima pembayaran dari pihak ketiga mana pun. Semua dana yang didepositkan ke akun trading Anda harus dalam nama yang sama dengan nama pada akun portal klien {platform} Anda.</li>
      <li> Klien memahami bahwa mengirimkan permintaan setoran bukan merupakan penerimaan dana oleh {platform}. Jika akun anda dekat atau dalam margin call, pastikan anda memiliki pengaturan lain. </li>
      <li> {platform} tidak akan bertanggung jawab atas keterlambatan atau kesalahan yang disebabkan oleh sistem pembayaran. </li>
      <li> {platform} tidak akan bertanggung jawab atas biaya apa pun yang mungkin ditagih oleh lembaga keuangan pengirim atau penyedia pembayaran pihak ketiga kepada anda atas pengiriman pembayaran. </li>
      <li> Klien mengakui bahwa mereka mungkin diminta untuk memberikan informasi tentang sumber dana deposit, kekayaan Klien secara umum dan informasi lain yang dianggap perlu oleh {platform} untuk mengidentifikasi sumber dana saat memproses permintaan deposit dan setuju untuk memberikan segala informasi yang diperlukan oleh {platform}. Kelalaian dalam memberikan informasi yang diperlukan dapat mengakibatkan penundaan yang panjang dalam pemrosesan permintaan Anda. {platform} tidak akan bertanggung jawab atas segala keterlambatan atau kerugian yang diderita akibat penundaan tersebut.</li>
      `,
      channels: {
        creditOrDebit: 'Kartu Kredit/Debit',
        i12BankTransfer: 'Transfer Bank Internasional',
        sticPay: 'SticPay',
        unionPay: 'Setoran UnionPay',
        unionPayCN: 'Transfer China Union Pay',
        skrill: 'Skrill/Moneybookers',
        vietInstant: 'Wire Transfer Bank Instan Vietnam ',
        thaiInstant: 'Wire Transfer Bank Instan Thailand ',
        nigeriaInstant: 'Wire Transfer Bank Instan Nigeria',
        malayInstant: 'Transfer Bank Instan Malaysia',
        indonesiaInstant: 'Transfer Bank Instan Indonesia',
        philippineInstant: 'Transfer Bank Instan Filipina',
        mobilePay: 'Mobile Pay',
        indiaInstant: 'Transfer Bank Instan India ',
      },
      rate: {
        rate: 'Nilai Tukar ({oldCurrency} / {newCurrency}):',
        result: {
          rateError: 'Pengambilan nilai {oldCurrency} ke {newCurrency} gagal.',
          channelError: 'Pengambilan kanal pembayaran gagal.',
        },
      },
      cn: { rate: 'Nilai Tukar(USD/RMB):', amt: 'RMB:' },
      upgrade:
        'Jalur pembayaran saat ini sedang dalam pemeliharaan dan hanya tersedia untuk pengguna yang telah berhasil menyetor dana melalui jalur ini sebelumnya.',
      depositInstruction: 'Instruksi Deposit',
      depositNote: 'Catatan Deposit',
    },
    method: {
      desc: 'Cara mendanai akun anda dengan {method}',
      inst1: '{number}.   Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '{number}.   Pilih bank di halaman pemilihan bank dan login ke bank online {country} anda',
      inst3: '{number}.   Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      inst4: '{number}.   Anda kemudian akan diarahkan ke formulir lain untuk memasukkan rincian kartu anda',
      inst5: `{number}.   Setelah memasukkan rincian kartu anda, tekan 'Kirim'`,
      inst6: '{number}.   Anda akan diarahkan kembali ke portal klien dan transaksi anda akan diselesaikan',
      inst7:
        '{number}.   Jika mata uang Anda bukan {currency}, bank mungkin akan mengenakan Anda biaya konversi sesuai kurs tukar bank saat ini',
      inst8:
        '{number}.   Setoran Kartu Kredit menggunakan EUR, GBP, AUD, SGD, NZD, HKD, JPY dan USD dibatasi hingga maksimum $ 5.000USD per transaksi. Setoran Kartu Kredit dalam mata uang CAD dapat diterima hingga $ 6,500CAD per transaksi',
      inst9: '{number}.   Maksimum $ 1500 USD per setoran',
      inst10: '{number}.   Halamanrincian pembayaran akan muncul',
      inst11: '{number}.   Pilih bank di halaman pemilihan bank dan login ke bank online anda',
      inst12: '{number}.   Kami menerima pembayaran dalam CAD. Maksimal $9.999 CAD per setoran',
      form: { header: 'FORMULIR {method}' },
    },
    reminder: {
      inst1: 'Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: 'Masuk ke akun {method} anda',
      inst3: 'Ikuti petunjuk di akun {method} untuk menyelesaikan pembayaran anda',
      inst4: 'anda kemudian akan diarahkan ke formulir lain untuk memasukkan rincian kartu anda',
      inst5: `Setelah memasukkan rincian kartu anda, tekan 'Kirim'`,
      inst6: 'anda akan diarahkan kembali ke portal klien dan transaksi anda akan diselesaikan',
      inst7: 'Anda kemudian akan diarahkan ke window baru yang menampilkan alamat dompet {method}',
      inst8:
        'Salin alamat dompet {method} dan kemudian masuk ke dompet {method} pribadi anda dan transfer jumlah yang anda inginkan ke alamat {method}.',
      inst9: 'Pindai kode QR untuk membuka aplikasi mobile banking anda',
      inst10: 'Ikuti petunjuk di perangkat seluler anda untuk menyelesaikan setoran',
      inst11:
        'Harap pastikan untuk membayar secara online melalui halaman pembayaran yang telah diarahkan untuk menghindari hilangnya dana.',
      inst12: 'Pilih bank di halaman pemilihan bank dan login ke bank online anda',
      inst13: 'Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      inst14:
        'Setelah anda melakukan transfer, harap lengkapi formulir di bawah ini dan lampirkan salinan resi transfer bank yang dengan jelas menunjukkan nama lengkap, nomor rekening, dan jumlah setoran anda sehingga kami dapat memproses transaksi anda.',
      inst15: 'Silahkan tunggu 2-5 hari kerja sampai dana masuk ke dalam akun anda',
      inst16: 'Harap tunggu 1 hari kerja hingga dana dikreditkan ke akun Anda.',
      inst17:
        'Harap pastikan resi secara jelas menampilkan nama lengkap, nomor akun, dan jumlah deposit sehingga kami dapat memproses transaksi Anda.',
      inst18: 'Mohon tunggu 1-6 jam agar dana selesai dikreditkan ke akun Anda.',
      note1: 'Deposit dengan {method} dibatasi hingga $25.000 USD per transaksi dan maksimum $75.000 USD per hari',
    },
    creditOrDebit: {
      header: 'Kartu Kredit',
      worldcard: {
        note1:
          'Deposit dengan Kartu Kredit menggunakan EUR, GPB, dan USD dibatasi hingga jumlah maksimum $20.000USD per transaksi. Deposit dengan Kartu Kredit menggunakan EUR, GPB, dan USD dibatasi hingga jumlah maksimum $20.000USD atau yang setara per transaksi.',
        note2:
          'Banyaknya kartu yang berbeda per pengguna adalah 3. Setiap upaya deposit yang melampaui limit tidak akan diterima.',
      },
      virtualPay: {
        note1:
          'Deposit dengan Kartu Kredit menggunakan EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC, dan USD dibatasi hingga maksimal 10.000 USD per transaksi.',
        note2:
          '50.000 USD atau maksimal 10 transaksi per kartu dalam 24 jam. Setiap upaya deposit yang melampaui limit tidak akan diterima.',
      },
      Sdpay: {
        note1:
          'Deposit dengan Kartu Kredit menggunakan EUR, GBP dan USD dibatasi hingga maksimum $2.000 USD atau mata uang yang setara per transaksi. ',
        note2: 'Maksimum 8 transaksi per hari. Setiap upaya deposit yang melampaui limit tidak akan diterima.',
        note3:
          'Harap dicatat bahwa mungkin ada penundaan berlaku pada pembayaran pertama kali Anda. Mohon bersabar sementara tim kami memverifikasi pembayaran Anda, atau Anda dapat menghubungi tim dukungan pelanggan kami untuk bantuan lebih lanjut.',
      },
      maxisPay: {
        note1: 'Deposit Kartu Kredit menggunakan USD dibatasi dengan jumlah maksimal $ 5.000 USD per transaksi',
      },
    },
    southAfrica: { header: 'Wire Transfer Bank Instan Afrika Selatan', country: 'Afrika Selatan' },
    idn: { header: 'Transfer Bank Instan Indonesia', country: 'Indonesia' },
    ida: { header: 'Transfer Bank Instan India ', country: 'India' },
    nigeria: { header: 'Wire Transfer Bank Instan Nigeria', country: 'Nigeria' },
    thailand: { header: 'Wire Transfer Bank Instan Thailand ', country: 'Thailand' },
    malay: { header: 'Transfer Bank Instan Malaysia', country: 'Malaysia' },
    php: { header: 'Transfer Bank Instan Filipina', country: 'Filipina' },
    vietnam: { header: 'Wire Transfer Bank Instan Vietnam ', country: 'Vietnam' },
    korea: { header: 'Transfer Bank Lokal Korea Selatan', country: 'Korea' },
    uganda: { header: 'Transfer Bank Lokal Uganda', country: 'Uganda' },
    rwanda: { header: 'Transfer Bank Lokal Rwanda', country: 'Rwanda' },
    zambia: { header: 'Transfer Bank Lokal Zambia', country: 'Zambia' },
    congo: { header: 'Transfer Bank Lokal Kongo', country: 'Kongo' },
    cameroon: { header: 'Transfer Bank Lokal Kamerun', country: 'Kamerun' },
    burundi: { header: 'Transfer Bank Lokal Burundi', country: 'Burundi' },
    kenya: { header: 'Transfer Bank Lokal Kenya', country: 'Kenya' },
    ghana: { header: 'Transfer Bank Lokal Ghana', country: 'Ghana' },
    tanzania: { header: 'Transfer Bank Lokal Tanzania', country: 'Tanzania' },
    canada: {
      header: 'Interac E-Transfer',
      note1: 'Kami hanya menerima pembayaran dalam CAD. Maksimum $9.999 CAD per transaksi',
    },
    net: {
      header: 'Neteller',
      note1: 'Kami dapat menerima pembayaran dalam AUD, USD, EUR, CAD, dan GBP',
      note2: 'Akun hanya dapat didanai dalam mata uang yang sama dengan akun trading MT4 Anda.',
      note3:
        'Neteller akan secara otomatis mengonversi dana ke mata uang dasar akun Anda apabila email yang digunakan salah. Mungkin dikenakan biaya.',
      note4: 'Deposit harus atas nama yang sama dengan nama pada akun STARTRADER Anda.',
    },
    vload: {
      header: 'DEPOSIT VLOAD',
      inst1: '2.   Kami dapat menerima pembayaran dalam USD, EUR dan JPY.',
      inst2: `<li>3.   Beli voucher Vload, klik <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">di sini</a>.</li>`,
      form: {
        voucherpin: 'Pin Voucher',
        voucherpinReq: 'PIN voucher diwajibkan',
      },
    },
    bankWireAu: {
      header: 'WIRE TRANSFER BANK AUSTRALIA',
      instruction:
        'Dana dapat ditransfer ke {platform} melalui fasilitas perbankan online dari institusi finansial anda, atau dengan menghubungi institusi finansial anda dan meminta transfer ke {platform}.',
      tip:
        'Pilih mata uang akun yang ingin anda setorkan. Harap pastikan anda memilih mata uang yang sama dengan mata uang akun trading anda',
      form: {
        bankDetails: 'Rincian Bank(Hanya untuk setoran AUD)',
        refTip: 'Silahkan tentukan nomor login anda di sini',
        afp: 'Pembayaran cepat Australia(HANYA untuk Setoran Domestik AUD)',
        payIdOrABNNum: 'PayID/Nomor ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: 'Mohon sertakan nomor akun trading anda {platform} di dalam isian referensi transfer bank.',
      },
    },
    bPay: {
      header: 'SETORAN BPAY',
      option: 'Bagaimana cara untuk mendanai akun dengan Bpay (hanya untuk Australia)',
      inst1: '1. Pilih akun MT4/MT5 anda untuk melihat kode Penagihan Bpay dan Nomor Referensi Bpay anda',
      inst2:
        '2. Masukan rincian pembayaran, Kode Penagihan Bpay dan Nomor Referensi Bpay anda ke dalam fasilitas internet banking dan kirimkan pembayaran anda.',
      inst3:
        '3. Kembali ke laman ini dan lengkapi formulir Bpay di bawah ini untuk memastikan pemrosesan segera dari transaksi anda',
      form: {
        header: 'FORMULIR BPAY',
        billerCode: 'Kode Bpay Biller',
        refNum: 'Nomor Referensi Bpay',
        receipt: 'Resi Transfer Bpay',
        reminderNotUploaded:
          'Kami melihat bahwa anda belum mengunggah tanda terima transaksi anda. Untuk memproses setoran anda, mohon secepatnya kirimkan salinan tanda terima melalui email ke {mailTo}.',
        reminderProc: 'Mohon dicatat. Transaksi anda TIDAK DAPAT diproses sampai kami menerima resi dari anda.',
      },
    },
    b2b: {
      header: 'BROKER KE BROKER',
      option: 'Bagaimana cara untuk melakukan transfer dari broker lain ke {platform}',
      inst1: '1. Unduh dan lengkapi {donwload}',
      inst2: '2. Lengkapi formulir online di bawah ini dan unggah formulir lengkap Transfer Broker ke Broker anda',
      inst3:
        '3. Kirimkan salinan dari formulir Broker ke Broker anda ke broker anda sebelumnya untuk memulai proses transfer',
      b2bForm: 'Formulir Transfer Broker ke Broker',
      formValidation: { upload: 'Formulir Broker ke Broker diperlukan' },
    },
    cc: {
      header: 'SETORAN DENGAN KARTU KREDIT ATAU DEBIT',
      remind:
        'Seandainya anda mengalami kesulitan dalam mendanai akun anda dengan menggunakan metode utama, silahkan menggunakan metode Kredit/Debit tambahan.',
      field: {
        fundNow: 'Danai Sekarang dengan Kartu Kredit/Debit',
        newCard: 'Kartu Baru',
        cardNum: 'Nomor Kartu Kredit',
        nameOnCard: 'Nama pada kartu',
        expDate: 'Tanggal kedaluwarsa',
        cvv: 'Kode Keamanan CVV ',
        bankCode: 'Bank Penerbit Kartu',
        example: `Kartu Sederhana`,
      },
      placeholder: { bankCode: 'Silahkan masukan nama bank penerbit kartu anda' },
      cvvTip: '3 digit yang ditampilkan di bagian belakang kartu anda',
      threeDomainSecQ: 'Apakah kartu anda mendukung 3D-Secure?',
      threeDomainSec:
        'Apa itu autentikasi 3D Secure? <br /> <strong> Autentikasi 3D Secure adalah metode pencegahan penipuan yang digunakan oleh Visa dan Mastercard. <br /> <br /> Jika kartu anda mendukung 3D Secure, dana anda dapat diproses dengan segera. </strong>',
      cardUpload:
        'Kartu yang anda gunakan untuk penyetoran dana belum ditautkan ke akun trading anda. Harap unggah foto bagian depan kartu anda untuk melakukan verifikasi. (Mohon 6 digit angka ditengah ditutup)',
      result: {
        success:
          'Terima kasih. Saat ini kami sedang melakukan verifikasi pada kartu anda, dana yang telah diverifikasi akan muncul di akun anda dalam waktu 24 jam.',
        depositSuccess: 'Terima kasih atas setoran anda, dana anda akan segera muncul pada akun anda.',
        error:
          'Untuk melindungi keamanan akun anda, <br> anda telah mencapai batas percobaan transaksi setoran dan simpanan anda telah ditangguhkan untuk sementara. <br> Harap hubungi {email}',
      },
      tipOnlyTaiwan: `Pelanggan yang terhormat, harap perhatikan bahwa penarikan melalui kartu kredit dapat tertunda karena pemrosesan bank, dan waktu kedatangan sepenuhnya bergantung pada masing-masing bank. Jika Anda memiliki kebutuhan dana yang mendesak, disarankan untuk menggunakan metode lain untuk menyetor / menarik.`,
      reminder: `<li>1. Setoran Kartu Kredit menggunakan EUR, GBP dan USD dibatasi hingga maksimum $1,900 USD atau mata uang yang setara per transaksi.</li>
      <li>2. Maksimal 8 transaksi per hari.</li>
      <li>3. Harap dicatat bahwa mungkin ada pemeriksaan keamanan pada kartu kredit / debit Anda untuk pembayaran pertama Anda. Harap bersabar saat tim kami memverifikasi pembayaran Anda, atau Anda dapat menghubungi tim dukungan pelanggan kami untuk bantuan lebih lanjut.</li>
      `,
    },
    fasa: {
      description: 'Bagaimana cara mendanai akun anda dengan FasaPay',
      form: { header: 'FORMULIR FASAPAY' },
    },
    intSwift: {
      header: 'WIRE TRANSFER BANK: SWIFT INTERNASIONAL',
      desc:
        'Dana dapat ditransfer ke {platform} melalui fasilitas perbankan online dari institusi finansial anda, atau dengan menghubungi institusi finansial anda dan meminta transfer ke {platform}.',
      desc2:
        'Setelah anda melakukan transfer, harap lengkapi formulir di bawah ini dan lampirkan salinan resi transfer bank yang dengan jelas menunjukkan nama lengkap, nomor rekening, dan jumlah setoran anda sehingga kami dapat memproses transaksi anda.',
      alertSAandNGA:
        'Jika Anda memegang mata uang selain dolar AS, harap tukarkan pengiriman uang ke dolar AS sebelum melakukan pengiriman uang. Jika Anda mengirimkan secara langsung, selisih kurs dan biaya penanganan yang terjadi saat itu akan ditanggung oleh pelanggan.',
      options: 'Silahkan pilih akun trading yang hendak anda danai',
      refInfo: 'Mohon sertakan nomor akun trading anda {platform} di dalam isian referensi transfer bank.',
      bussDayInfo: 'Silahkan tunggu 2-5 hari kerja sampai dana masuk ke dalam akun anda',
      tip:
        'Harap pastikan bahwa resi transfer anda dengan jelas menunjukkan <b> nama lengkap </b>, <b> nomor rekening </b>, dan <b> jumlah setoran </b> .',
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Kami menerima pembayaran dalam USD, AUD, EUR, USC, dan JPY',
    },
    astropay: {
      desc: 'Cara mendanai akun anda dengan ASTROPAY',
      note1: 'Kami menerima pembayaran dalam semua mata uang.',
      form: { header: 'FORMULIR ASTROPAY' },
    },
    mobile: {
      header: 'Mobile Pay',
      desc: 'Cara mendanai akun anda dengan Mobile Pay',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Pindai kode QR untuk membuka aplikasi mobile banking anda',
      inst3: '3. Ikuti petunjuk di perangkat seluler anda untuk menyelesaikan setoran',
      form: { payMethod: 'Metode Pembayaran:', rate: 'Nilai Tukar (USD / CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'Jumlah CNY tidak boleh lebih dari $ {limit}' },
    },
    crypto: {
      header: 'Setoran Cryptocurrency',
      choose: 'Silakan pilih mata uang kripto pilihan anda',
      proceedQuestion: 'Apakah anda ingin melanjutkan setoran <br /> <b> {method} </b> anda?',
      note:
        'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
      note2:
        'Harap dicatat bahwa kami tidak dapat memproses deposit atau penarikan melalui BUSDT atau ETH, harap pastikan alamat dan Mata Uang Kripto cocok dengan chain dan mata uang yang bisa kami terima atau mungkin Anda bisa kehilangan dana. Kami tidak bertanggung jawab atas kerugian yang ditimbulkan oleh kesalahan tersebut.',
      remark:
        'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
      inst2: '2. Anda kemudian akan diarahkan ke window baru yang menampilkan alamat dompet {method}',
      inst3:
        '3. Salin alamat dompet {method} dan kemudian masuk ke dompet {method} pribadi anda dan transfer jumlah yang anda inginkan ke alamat {method}.',
      inst4:
        'Mohon diperhatikan: Setoran BITCOIN diproses oleh B2BinPay dengan nilai tukar yang diberikan pada saat transaksi.',
      bitcoin: {
        button: 'BITCOIN',
        desc: 'Cara mendanai akun anda dengan BITCOIN',
      },
      usdt: {
        button: 'USDT (OMNI)',
        desc: 'Cara mendanai akun anda dengan USDT (OMNI)',
        inst1: "1. Lengkapi formulir di bawah dan tekan 'Kirim'",
        inst2: '2. Anda kemudian akan diarahkan ke window baru yang menampilkan alamat dompet USDT (OMNI)',
        inst3:
          '3. Salin alamat USDT (OMNI) lalu masuk ke dompet USDT (OMNI) pribadi anda dan transfer jumlah yang anda inginkan ke alamat USDT (OMNI).',
        inst4:
          'Mohon diperhatikan: Setoran USDT (OMNI) diproses oleh B2BinPay dengan nilai tukar yang diberikan pada saat transaksi.',
      },
      usdt_CPS: {
        button: 'USDT (ERC20)',
        desc: 'Cara mendanai akun anda dengan USDT (ERC20)',
      },
      form: {
        header_bitcoin: 'FORMULIR BITCOIN',
        header_usdt: 'FORMULIR USDT (OMNI)',
        header_usdt_CPS: 'FORMULIR USDT (ERC20)',
      },
    },
    skrill: {
      header: 'SETORAN SKRILL/MONEYBOOKERS',
      desc: 'Cara mendanai akun anda dengan Skrill',
      note1: 'Kami menerima pembayaran dalam USD, EUR, GBP, dan CAD',
      note2: 'Akun hanya dapat didanai dalam mata uang yang sama dengan akun trading MT4 Anda.',
      note3:
        'Skrill akan secara otomatis mengonversi dana ke mata uang dasar akun Anda apabila email yang digunakan salah. Mungkin dikenakan biaya.',
      note4: 'Deposit harus atas nama yang sama dengan nama pada akun STARTRADER Anda.',
      form: { header: 'FORMULIR SKRILL', email: 'Email Skrill / Moneybookers' },
      reminder:
        '<li> Mohon diperhatikan: </li><li> * Akun hanya dapat didanai dalam mata uang yang sama dengan akun tradingMT4 anda. Skrill akan secara otomatis mengonversi dana ke mata uang basis akun anda jika email yang salah digunakan. Mungkin dikenakan biaya. </li><li> * Setoran harus atas nama yang sama dengan akun {platform} anda. </li>',
    },
    sticPay: {
      header: 'SETORAN STICPAY',
      desc: 'Cara mendanai akun anda dengan STICPAY',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun SticPay anda',
      inst3: '3. Ikuti petunjuk di akun SticPay untuk menyelesaikan pembayaran anda',
      form: { header: 'FORMULIR STICPAY', email: 'Email SticPay' },
    },
    unionPay: {
      header: 'Setoran UnionPay',
      desc: 'Bagaimana cara menemukan akun UnionPay anda',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Pilih bank di halaman pemilihan bank dan login ke bank online anda',
      inst3: '3. Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      form: { header: 'FORMULIR UNION PAY' },
    },
    unionPayCN: {
      header: 'Transfer China Union Pay',
      desc: 'Cara mendanai akun anda dengan China Union Pay Transfer',
      inst1: 'Halaman perincian Pembayaran akan muncul',
      inst2:
        'Anda mungkin akan dimintai nomor keanggotaan saat melakukan deposit, silakan masukkan nomor akun trading Anda sebagai nomor Keanggotaan',
      note1:
        'Jumlah transaksi minimum per deposit adalah 500 CNY dan jumlah transaksi maksimum per deposit adalah 49.999 CNY.',
      note2:
        'Harap diperhatikan bahwa limit jumlah deposit dapat diperbarui dari waktu ke waktu tergantung pada pasar.',
      note3:
        'Harap jangan menyimpan catatan informasi bank penerima yang ditampilkan karena dapat diperbarui dari waktu ke waktu.',
      form: {
        header: 'FORMULIR CHINA UNION PAY',
        holderName: 'Nama Pemegang Kartu UnionPay:',
        holderNameInfo: 'Harap pastikan nama yang anda masukkan di atas sama dengan nama kartu bank anda',
      },
    },
    paypal: {
      header: 'Paypal',
      desc: 'Cara mendanai akun anda dengan PayPal',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun PayPal anda',
      inst3: '3. Ikuti petunjuknya dan di akun PayPal untuk menyelesaikan pembayaran anda',
      inst4: 'Mohon diperhatikan: Kami tidak dapat menerima pembayaran dari pihak ketiga',
      form: { header: 'FORMULIR PAYPAL' },
    },
    cardpay: {
      desc: 'Bagaimana mendanai akun anda dengan Kartu Kredit',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. anda kemudian akan diarahkan ke formulir lain untuk memasukkan rincian kartu anda',
      inst3: "3. Setelah memasukkan rincian kartu anda, tekan 'Kirim'",
      inst4: '4. anda akan diarahkan kembali ke portal klien dan transaksi anda akan diselesaikan',
      form: { header: 'FORMULIR KARTU KREDIT' },
    },
    tinkbit: { inst5: '5. Maksimum $ 1500 USD per setoran' },
    perfectMoney: {
      inst1: '2.   Masuk ke akun Perfect Money anda',
      inst2: '3.   Ikuti petunjuk di akun Perfect Money untuk menyelesaikan pembayaran anda',
    },
    indiaBankTransfer: {
      desc1:
        'Setelah Anda melakukan transfer, silakan lengkapi formulir di bawah ini dan lampirkan resi transfer bank yang diterima.',
      desc2:
        'Harap pastikan resi secara jelas menampilkan nama lengkap, nomor akun, dan jumlah deposit sehingga kami dapat memproses transaksi Anda.',
      bussDayInfo: 'Mohon tunggu 1-6 jam agar dana selesai dikreditkan ke akun Anda.',
    },
  },
  restrictDeposit: {
    header: 'PEMBERITAHUAN PENTING',
    info: 'Mohon maaf, kami tidak lagi menerima setoran dari klien yang tinggal di Kanada.',
  },
  withdraw: {
    title: 'Tarik Dana',
    default: {
      accountDetail: 'Rincian akun',
      withdrawalAmount: 'Jumlah penarikan ',
      example: 'Contoh Kartu Kredit / Debit',
      alert:
        'Berdsarkan peraturan AML, metode yang anda gunakan untuk menarik harus sama dengan metode yang digunakan untuk deposit anda. Jika jumlah penarikan kurang dari 100 unit mata uang basis akun anda, anda akan dikenakan 20 unit untuk biaya penanganan oleh bank.',
      how: 'Bagaimana cara mendanai akun trading anda?',
      selectCC: 'Pilih Kartu Kredit',
      selectBA: 'Pilih Rekening Bank',
      anotherCC: 'Masukkan kartu kredit secara manual',
      anotherBA: 'Tambahkan rekening bank',
      remember: 'Ingat akun saya',
      success: 'Permintaan penarikan anda berhasil dan akan segera diproses',
      ccFail:
        'Untuk melindungi keamanan akun anda, anda telah mencapai percobaan transaksi setoran maksimum dan penarikan anda untuk sementara ditangguhkan. Harap hubungi {email}.',
      checkPosFailed: 'Periksa Posisi gagal',
      fetchTradeAccFailed: 'Pengambilan Akun Perdagangan gagal',
      chooseAnother: `<p> Jumlah penarikan maksimum untuk kartu ini: 0 </p>
      <p> Pilih kartu lain atau metode pembayaran lain </p>`,
      notEnoughFunds: 'Jumlah yang dimasukkan melebihi batas untuk kartu ini',
      failed: 'Penarikan gagal. Silakan coba lagi nanti',
      unfortunate:
        'Mohon maaf, kami tidak dapat lagi memproses penarikan ke kartu kredit. Untuk selanjutnya, penarikan akan diproses melalui transfer bank ke rekening bank yang anda tunjuk.',
      noCardWarn:
        'Anda tidak memiliki kartu yang tersedia untuk melakukan penarikan, harap tautkan kartu anda di Rincian Penarikan terlebih dahulu.',
      equityStop:
        'Mohon maaf, penarikan anda tidak dapat diproses karena akan mengakibatkan margin tersedia untuk akun anda turun di bawah level stop out 100%',
      equityMargin:
        'Mohon maaf, saat ini kami tidak dapat memproses penarikan anda karena akun anda dalam keadaan hedge sepenuhnya.',
      equityContinue:
        'Kamimelihat anda saat ini memiliki posisi terbuka, anda dapat mengalami stop out setelah mengajukan permohonan penarikan, harap konfirmasi',
      disclaimer: `<li> Menarik dana saat anda memiliki posisi terbuka akan memengaruhi margin anda. Harap pastikan anda menutup posisi terbuka, atau memastikan anda menyisakan cukup dana di akun anda untuk menghindari margin call. </li>
      <li> {platform} dengan tegas tidak menerima pembayaran kepada pihak ketiga mana pun. Semua dana yang ditarik dari akun trading Anda harus dalam nama yang sama dengan nama pada akun portal klien {platform} Anda.</li>
      <li> Semua permintaan penarikan awal ke kartu kredit/debit, e-wallet, moneybookers, dan transfer bank harus diverifikasi demi keamanan dan perlindungan dengan menyediakan rekening koran bank, yang mencakup informasi pemegang rekening dan informasi bank.</li>
      <li> {platform} berhak menolak untuk memproses permintaan penarikan jika memiliki alasan yang masuk akal untuk meyakini bahwa akun trading dapat berubah menjadi saldo negatif setelah permintaan penarikan diproses. </li>
      <li> {platform} berhak menolak permintaan penarikan jika akun trading terkena tagihan balik atau penyelidikan lain yang sedang berlangsung. </li>
      <li> {platform} tidak bertanggung jawab atas biaya apa pun yang mungkin ditagih oleh lembaga keuangan penerima atau penyedia pembayaran pihak ketiga kepada anda untuk menerima pembayaran. </li>`,
      YourPaymentGreaterThanAvailableBalance: 'Pembayaran anda lebih besar dari saldo yang tersedia',
      blackList: `Sayangnya saat ini Anda tidak dapat mengirimkan permintaan penarikan. Harap hubungi {supportEmail} untuk informasi lebih lanjut`,
      NetellerChargeAlert:
        'Penarikan Neteller dibatasi hingga $10.000 per transaksi, dan biaya 2% (dibatasi pada $30) berlaku.',
      agreePolicy: 'Saya telah membaca dan menyetujui{link}',
      withdrawPolicy: 'kebijakan penarikan',
      addWithdrawalMethod: 'Tambah metode penarikan',
      addSecondaryMethod: 'Tambah metode sekunder',
      withdrawNote:
        'Harap diperhatikan bahwa kami akan mengutamakan pemrosesan penarikan Anda melalui metode kartu kredit. Bila jumlah limit kartu kredit Anda telah habis digunakan, kami akan meminta Anda agar memilih metode lain untuk menyelesaikan permintaan penarikan Anda.',
      submitYourWithdrawal: 'Ajukan penarikan Anda',
      remainingWithdraw:
        'Jumlah penarikan yang tersisa akan dibagi melalui kartu kredit atau metode penarikan lainnya.',
      onlyWithdrawalCcDesc: `Hanya sejumlah {ccAmount} kredit yang akan ditarik seluruhnya via kartu.`,
    },
    fasa: {
      field: { accName: 'Nama Akun FasaPay', accNum: 'Nomor Akun FasaPay' },
      formValidation: { accNameReq: 'Nama Akun FasaPay wajib diisi', accNumReq: 'Nomor Akun FasaPay diperlukan' },
      fasaPayChargeAlert: 'FasaPay mengenakan biaya transaksi 0.5% untuk setiap penarikan.',
    },
    neteller: { field: { email: 'Email Neteller' }, formValidation: { emailReq: 'Email Neteller diperlukan' } },
    vload: {
      field: {
        email: 'Email Terdaftar Vload',
      },
      formValidation: {
        emailReq: ' Email terdaftar di Vload diwajibkan',
      },
    },
    bitcoin: {
      field: { address: 'Alamat Dompet Bitcoin' },
      formValidation: {
        addressReq: 'Alamat Dompet Bitcoin diperlukan',
        addressFormat: `Alamat wallet Anda harus diawali dengan '1' atau '3' atau 'bc1'`,
        addressLength: 'Alamat wallet bitcoin harus di antara 26 dan 42 karakter',
      },
    },
    eth: {
      field: { address: 'Alamat Dompet ETH' },
      formValidation: {
        addressReq: 'Alamat Dompet ETH diperlukan',
        addressFormat: 'Alamat Dompet ETH diperlukan',
        addressLength: 'Alamat wallet ETH harus di antara 26 dan 42 karakter',
      },
    },
    usdc: {
      field: { address: 'Alamat Dompet USDC' },
      formValidation: {
        addressReq: 'Alamat Dompet USDC diperlukan',
        addressFormat: 'Alamat Dompet USDC diperlukan',
        addressLength: 'Alamat wallet USDC harus di antara 26 dan 42 karakter',
      },
    },
    usdt: {
      field: {
        address: 'Alamat Dompet USDT',
      },
      formValidation: {
        addressReq: 'Alamat USDT Wallet diperlukan',
        trc20WalletFormatIncorrect: `Alamat wallet Anda harus diawali dengan 'T'`,
        erc20WalletFormatIncorrect: `Alamat wallet Anda harus diawali dengan '0x'`,
      },
    },
    bitwallet: { field: { email: 'Email Bitwallet' }, formValidation: { emailReq: 'Email Bitwallet diperlukan' } },
    astropay: {
      field: { accountNumber: 'Akun Astropay' },
      formValidation: { accountNumberReq: 'Akun Astropay diperlukan' },
    },
    paypal: { field: { email: 'Email Paypal' }, formValidation: { emailReq: 'Email Paypal diperlukan' } },
    skrill: {
      field: { email: 'Email Skrill' },
      formValidation: { emailReq: 'Skirll Email diperlukan' },
      skrillChargeAlert: 'Skrill membebankan biaya transaksi 1% untuk setiap penarikan.',
    },
    sticpay: { field: { email: 'Email SticPay' }, formValidation: { emailReq: 'Email SticPay diperlukan' } },
    perfectMoney: {
      field: { email: 'Email Perfect Money' },
      formValidation: { emailReq: 'Perfect Money Email diperlukan' },
    },
    transfer: {
      info:
        '{platform} akan mengganti biaya bank yang dikenakan untuk <u> SATU </u> penarikan per bulan. Penarikan tambahan dalam sebulan akan dikenakan biaya bank sebesar 20 unit mata uang penarikan anda.',
      label: { upload: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)' },
      formValidation: {
        upload: 'Harap unggah rekening koran',
        emailReq: 'Email harus diisi',
      },
    },
    otherMethod: {
      withdrawalMethod: 'Metode Penarikan',
      manageWithdrawalDetails: 'Kelola rincian penarikan',
      verificationCode:
        'Kode verifikasi telah dikirimkan ke {email}. Harap hubungi{supportEmail} bila Anda membutuhkan bantuan lebih lanjut',
    },
  },
  transfer: {
    creditWarn:
      'Kami tidak dapat memproses transfer dana anda dari akun yang dipilih karena akun anda berisi kredit promosi.',
    blackListWarn:
      'Transfer dana dari akun MAM anda dapat mengakibatkan likuidasi posisi yang dikelola. Harap hubungi {mailTo} jika anda ingin mentransfer dana dari akun ini.',
  },
  changePw: {
    header: 'GANTI PASSWORD',
    field: { currPw: 'Password saat ini', newPw: 'Password baru', confirmPw: 'Konfirmasi Password Baru' },
    succ:
      'Password anda telah diperbarui. anda sekarang dapat menggunakan password baru anda untuk mengakses Portal Klien.',
  },
  promotion: {
    clickToGet: 'Cukup klik dan raih bonus $50 Anda',
    apply: 'DAFTAR SEKARANG',
    start: 'Mulai sekarang',
    tnc: `<li> 1. Penawaran ini tersedia selama 30 hari.</li>
    <li> 2. Penawaran ini tersedia untuk seluruh klien (Lama & Baru) yang memiliki akun live tetapi belum pernah menyetorkan deposit sebelumnya. </li>
    <li> 3. Penawaran ini tersedia untuk klien baru di kawasan berikut: Prancis, Inggris Raya, Taiwan, Italia, Kanada, Belgia, Swiss, HongKong, Nigeria, Belanda, Jepang, Luksemburg, Malta, Reunion, Jerman, Martinik, Spanyol, Latvia, Polandia, Pantai Gading, Denmark, Brasil, Selandia Baru , Portugal, Isle Of Man, Afrika Selatan, Turki, Rumania, Guadeloupe, Austria, Irlandia, Guyana Prancis, Swaziland, Argentina, Ukraina, Yunani, Swedia, Monako, Hongaria, Haiti, Finlandia, Bolivia, Cile, Kosta Rika, Kroasia, Republik Ceko, Estonia, Meksiko, Norwegia, Filipina. </li>
    <li> 4. Penawaran ini berlaku hanya pada Akun Standar dan Akun Standar Islamic. </li>
    <li> 5. Menerima dana bonus dan/atau bonus trading PADA DUA AKUN ATAU LEBIH dengan alamat IP/Dokumen Identitas/Paspor yang sama adalah dilarang. Terlepas dari banyaknya akun trading yang dimiliki oleh seorang Klien dari STARTRADER, promosi ini hanya berlaku untuk satu akun. </li>
    <li> 6. Klien akan menerima kredit 50 USD (atau setara) setelah berhasil mengikuti penawaran ini dan tanpa persyaratan untuk menyetorkan deposit. </li>
    <li> 7. Jumlah kredit tidak dapat ditarik. Namun, Anda masih dapat menarik sebagian dari keuntungan yang diperoleh dari trading Anda. </li>
    <li> 8. Anda harus menutup semua posisi terbuka sebelum mengajukan penarikan. Setelah Anda memutuskan untuk menarik, bonus akan diambil dari akun; </li>
    <li> 9. STARTRADER berhak untuk mengubah ketentuan atau menolak untuk memberikan bonus kapan saja. </li> `,
    selectDesp: '* Silakan pilih akun mana yang ingin Anda ikutkan dalam program Bonus Tanpa Deposit.',
    descp1: 'Apakah Anda ingin mulai trading tanpa perlu melakukan investasi dari nol?',
    descp2: 'Klaim bonus $50 Anda untuk mulai mencetak keuntungan<br> tanpa mempertaruhkan dana Anda sama sekali.',
    tnc2:
      'Jumlah minimum yang bisa Anda tarik adalah 100 USD (atau setara), dan jumlah maksimum yang bisa ditarik adalah 200 USD (atau setara)',
    header: 'SYARAT DAN KETENTUAN PROMOSI',
    successful: 'Pengajuan Berhasil',
    failed: 'Pengajuan Gagal',
    referFriends: {
      intro: `Selesaikan 3 langkah berikut untuk mendapatkan {amount}`,
      step1: 'Langkah pertama',
      step1Descp: 'Berhasil membuka akun',
      step2: 'Tahap kedua',
      step2Descp: 'Setoran berhasil',
      step3: 'langkah ketiga',
      step3Descp: 'Volume kesepakatan',
      totalEanringTitle: `Jumlah total penarikan <br/>(USD atau mata uang yang setara)`,
      totalEarningTip: '* Jumlah hadiah akan ditinjau.',
      redeem: 'ekstrak',
      intro2: 'Pada saat yang sama, Anda juga dapat merekomendasikan teman Anda',
      shareLink: 'Bagikan tautan rekomendasi hasil personalisasi Anda',
      shareLinkDescp: 'Klik "Bagikan" untuk mendapatkan tautan rekomendasi unik dan salin ke berbagai media sosial',
      share: 'Bagikan',
      total: 'Total referal yang ditebus',
      social: 'Bagikan tautan Anda melalui media sosial utama',
      clients: 'klien',
      table: {
        referralsNo: 'Nomor yang direkomendasikan',
        name: 'Nama',
        country: 'negara',
        openAccount: 'Berhasil membuka akun',
        despositSuccess: 'Setoran berhasil',
        volumeCompleted: 'Volume kesepakatan',
      },
      totalRedeemableTitle: `Jumlah penarikan total  <br/>(USD atau mata uang lain yang setara)`,
      totalRedeemableTip: '* Jumlah hadiah akan ditinjau.',
      referrer: 'Perujuk',
      referral: 'Wasit',
      dialog: {
        confirmQ: 'Tukarkan penghasilan Anda',
        confirmQ2: 'Jumlah total yang dikonversi ke mata uang dasar Anda adalah {amount} {currency}.',
        redeem: 'Tarik sekarang',
        agree: '* Dengan mengklik tukarkan sekarang, Anda setuju dengan semua{link}.',
        overBonus:
          'Sistem kami telah mendeteksi bahwa Anda telah mencapai jumlah maksimum kami. Untuk klaim, silakan hubungi dukungan pelanggan kami.',
      },
    },
    depositBonus: {
      banner: 'KLAIM BONUS DEPOSIT 50% ANDA!<br>   DAPATKAN HINGGA $10,000',
      depositBonus: 'KLAIM BONUS ANDA & TINGKATKAN TRADING ANDA',
      title:
        'Ingin meningkatkan margin akun Anda dan meningkatkan volume perdagangan Anda? Kemudian danai akun Anda hari ini, dan klaim bonus 50% dari kami!',
      fund: 'Danai Akun Anda',
      tnc: '*Syarat dan ketentuan berlaku, {link}',
      link: 'klik di sini untuk melihat',
      bonusHistory: 'SEJARAH BONUS',
      overBonus:
        '* Anda telah mencapai bonus deposit maksimum ${totalLimit} Deposit tambahan tidak akan memenuhi syarat untuk memperoleh bonus deposit.',
      opt: 'KONFIRMASI OPT-IN & OPT-OUT',
      tick: 'Hapus centang pada kotak jika Anda ingin memilih keluar atau centang kotak jika Anda ingin ikut serta.',
      saveChange: 'Simpan perubahan',
      depositDate: 'Tanggal Deposit',
      credit: 'Kredit',
      optIn: 'Berhasil Ikut',
      optOut: 'Berhasil Tidak Ikut',
      optInDescription: 'Silakan klik tombol di bawah ini untuk ikut serta dalam bonus deposit 50%.',
      optOutDescription: 'Klik kotak untuk memilih keluar dari hadiah.',
      activate: 'Mengaktifkan',
      signOut: 'Keluar',
      banner1: 'KLAIM BONUS DEPOSIT 50% ANDA!',
      claimDescription: `<p>Cara mengklaim</p>
      <li>1. Aktifkan sebelum Anda menyetor. </li>
      <li>2. Dana akun perdagangan Anda untuk memenuhi syarat untuk hadiah. </li>
      <li>3. Hadiah akan mendanai akun Anda secara otomatis. </li>
      <li>4. Nikmati perdagangan Anda. </li>`,
      eligibleDescription: `<p>Kelayakan.</p>
      <li>1. Hadiah harus diaktifkan sebelum Anda menyetor. </li>
      <li>2. Hadiah hanya tersedia untuk akun standar, akun standar Islam, Akun Perdana, Akun Perdana Islam. </li>
      <li>3. Anda hanya dapat mengajukan permohonan 50% bonus deposit rasio sekali, dan semua simpanan selanjutnya hanya dihitung sesuai dengan rasio 20%. </li>`,
      optInDialog: `Selamat! Anda telah berhasil mengaktifkan 50% bonus deposit.`,
      optOutDialog: `Anda telah berhasil memilih keluar untuk bonus deposit 50%.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Gandakan Tabungan Anda</span></br>
          <span class="desc">Raih cashback hingga $10.000 dan biaya swap NOL</span>
        `,
        activityBannerContext: `
          <span class="title">Gandakan Tabungan Anda</span></br>
          <span class="desc">Memperpendek tren turun atau membeli penurunan?</span></br>
          <span class="desc">Dapatkan cashback hingga $10.000 saat memperdagangkan mata uang kripto dengan STARTRADER!</span>
        `,
      },
      active: {
        title: `Aktifkan Cashback Cryptocurrency`,
        desc: `silakan klik tombol di bawah ini untuk mengaktifkan dan berpartisipasi dalam aktivitas`,
      },
      deactive: {
        title: `Nonaktifkan Cashback Cryptocurrency`,
        desc: `Silakan klik tombol di bawah ini untuk menonaktifkan dan menarik diri dari promosi Cryptocurrency Cashback.`,
      },
      button: {
        active: `mengaktifkan`,
        deactive: `menonaktifkan`,
        submit: `Kirimkan`,
        fund: `Danai AKUN ANDA`,
      },
      claimTitle: `CARA KLAIM`,
      claimDescriptions: [
        `Aktifkan promosi sebelum melakukan deposit.`,
        `Danai akun trading Anda untuk memenuhi persyaratan deposit（≥ 500 USD)`,
        `Cashback per lot akan tergantung pada jumlah total deposit bersih Anda (lihat S&K).`,
        `Nikmati perdagangan dan dapatkan nilai lebih dengan setiap perdagangan!`,
      ],
      eligibilityTitle: `PERSYARATAN`,
      eligibleDescriptions: [
        `Promosi ini harus diaktifkan sebelum Anda melakukan deposit.`,
        `Promosi ini hanya tersedia untuk Akun Standar dan Akun Standar Islam. Jika klien memiliki beberapa akun perdagangan yang memenuhi syarat, hanya SATU akun yang memenuhi syarat yang dapat dipilih untuk berpartisipasi dalam promosi ini`,
        `Promosi ini berlangsung 30 hari sejak deposit pertama Anda berhasil setelah aktivasi`,
        `Cashback didasarkan pada volume yang diperdagangkan pada semua produk cryptocurrency kecuali yang berikut: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU`,
        `Total cashback dibatasi $10.000 per klien`,
      ],
      tnc: '*Syarat dan ketentuan berlaku, {link}',
      link: 'klik di sini untuk melihat',
      message: {
        congratulate: `Selamat!`,
        congratulateContext: `Anda telah berhasil mengaktifkan promosi Cryptocurrency Cashback.`,
        signedOutPromo: `Dengan Mengklik Tombol Konfirmasi, Anda akan keluar dari promosi.`,
        deactivePromo: `Anda telah berhasil menonaktifkan Promosi Cashback Cryptocurrency.`,
      },
      dialog: {
        selectAccountContext: `Silakan pilih akun yang Anda inginkan untuk bergabung dengan aktivitas bonus perdagangan`,
        tnc: 'syarat dan Ketentuan',
        tncContext: {
          tnc1: `1.Setelah Anda mengaktifkan promosi ini, Anda juga harus menyetor dana dan memperdagangkan cryptocurrency sesuai kebutuhan untuk menerima cashback yang sesuai.`,
          tnc1desc: `*Hanya deposit baru yang dilakukan selama periode promosi yang berlaku untuk promosi. Ini tidak termasuk transfer internal, bonus referensi teman, dan penyesuaian tunai.`,
          tnc2: `2. Klien yang Memenuhi Syarat (baik klien baru maupun yang sudah ada), yang memenuhi kriteria yang ditetapkan di sini dapat menerima cashback, sebesar:`,
          tnc3: `3. Produk yang memenuhi syarat: Semua produk cryptocurrency kecuali ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. Volume perdagangan efektif: Pesanan yang baru dibuka dan ditutup selama periode promosi. Posisi terbuka kurang dari 5 menit, posisi terkunci, dan posisi lindung nilai tidak dihitung sebagai perdagangan yang valid untuk promosi ini.`,
          tnc5: `5. Durasi Promosi: 30 Hari setelah peserta melakukan deposit pertama yang berhasil.`,
          tnc5desc: `* Jika klien mengaktifkan promosi dan belum melakukan deposit, promosi juga akan berakhir secara otomatis setelah 30 hari.`,
          tnc6: `6. Penawaran ini tidak dapat digabungkan dengan penawaran lainnya. Kecuali kampanye “Anda Bisa Menjadi trader juga”,Refer A Friend dan 50% bonus deposit)`,
        },
        agreeTnc: `Saya telah membaca dan menyetujui Syarat dan Ketentuan`,
      },
      field: {
        netDeposit: `Setoran Bersih`,
        usddesc: `USD (atau mata uang yang setara)`,
        cashback: `Uang Kembali`,
        standardLot: `lot standar`,
        date: `tanggal`,
        depositAmount: `Jumlah setoran`,
        withdrawAmount: `Jumlah penarikan`,
        netDepositAmount: `Jumlah setoran bersih`,
        rebateLot: `Rabat / Lot`,
        tradingDate: `Tanggal perdagangan`,
        tradingLot: `Lot perdagangan`,
        cashRebate: `Rabat tunai`,
      },
      depositHistory: `SEJARAH DEPOSIT`,
      tradingRewardHistory: `SEJARAH HADIAH PERDAGANGAN`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">Menangkan Hadiah Senilai Hingga $5,000!</div>
          <div class="desc">Dalam undian pasti menang</div>
        `,
      },
      active: {
        title: 'MENGAKTIFKAN PROMO MAKE A WISH',
        desc: 'Harap klik tombol di bawah untuk mengaktifkan promosi Xmas Make a Wish',
      },
      deactive: {
        title: 'Promo XMAS Make A Wish',
        desc: 'Harap klik tombol di bawah untuk menonaktifkan promosi Xmas Make a Wish',
      },
      button: {
        active: 'Aktifkan',
        deactive: 'Nonaktifkan',
        submit: 'Kirim',
        fund: 'Danai Akun Anda',
        enterLuckyDraw: 'Ikut Undian',
        redeemed: 'Telah Ditukar',
      },
      claimTitle: 'CARA KLAIMNYA',
      claimDescriptions: [
        'Aktifkan promosi sebelum menyetorkan deposit.​',
        'Danai akun trading Anda untuk memenuhi persyaratan deposit sesuai kado yang Anda inginkan, lalu lakukan jumlah trading sesuai yang diperlukan. (lihat tabel kado di bawah).',
        'Bila memenuhi syarat, Anda akan mendapatkan kesempatan untuk memenangkan salah satu kado sesuai tingkatan Anda. (lihat tabel kado di bawah).',
        'Klik tombol Ikut Undian untuk mendaftarkan kado undian Anda.',
        'Kado yang dimenangkan akan dicatat, dan semua kado akan dibagikan 7 hari setelah promosi berakhir.',
      ],
      eligibilityTitle: 'ELIGIBILITAS',
      eligibleDescriptions: [
        'Tipe akun yang berhak partisipasi: Penawaran ini HANYA terbuka untuk akun Standard dan Akun Islamic Standard, keduanya di MT4/MT5.',
        'Jika klien memiliki beberapa Akun Standard, mereka hanya dapat memilih salah satu Akun Standard untuk berpartisipasi dalam promosi ini.',
        'Negara yang berhak partisipasi: Semuanya kecuali China daratan',
        'Deposit dan trading harus dilakukan selama periode promosi agar dianggap sah.',
        'Volume trading yang valid: Posisi Forex, Emas, Perak, dan Minyak Mentah dipegang selama lebih dari 5 menit.',
      ],
      moreDetail: 'Untuk detail lebih lanjut tentang pembagian hadiahnya, lihat {prize}.',
      prize: 'daftar hadiah di sini',
      tnc: '*Syarat dan ketentuan berlaku. Klik {link} untuk melihat',
      link: 'di sini',
      luckyDraw: {
        description: [
          `Tekan tombol Undian untuk memenangkan <span class="font-bold">salah satu</span> hadiah di atas!`,
          `Bukan kado yang Anda idamkan? Terus lakukan deposit dan trading untuk mencapai tingkat yang Anda inginkan.`,
        ],
        mainTitle: 'Tingkat Kado Anda Saat Ini:',
        commingSoon: {
          title: `Make a Wish`,
          content: `Segera hadir undian di pertengahan November.`,
        },
        noChanceToDraw: {
          title: `Deposit Sekarang`,
          content: `Danai akun Anda dengan deposit min. $200 untuk mengikuti undian berhadiah`,
        },
        endPromotion: {
          title: `Undian Berhadiah Telah Berakhir`,
          content: `Terima kasih atas partisipasi Anda! Pastikan Anda sudah menukarkan hadiah Anda sebelum tanggal 31 Maret 2023.`,
        },
      },
      currentDepositLots: `Deposit / Lot Diperdagangkan Saat Ini`,
      transactionHistory: `RIWAYAT TRANSAKSI`,
      transactionHistoryDesc: `*Transaksi deposit adalah jumlah yang didepositkan dan nilai deposit yang dipotong saat mengikuti undian.`,
      giftRedemption: `PENUKARAN KADO`,
      giftRedemptionDesc: [
        `Kami akan hubungi Anda kembali untuk penukaran kado fisik.`,
        `*Semua hadiah hanya dapat ditukarkan mulai tanggal 2 Februari 2023 hingga 2 Maret 2023.`,
      ],
      field: {
        depositAmount: `Jumlah Deposit`,
        lotsTraded: `Lot Diperdagangkan`,
        date: `Tanggal`,
        type: `Tipe`,
        deposit: `*Deposit`,
        tradingLots: `Lot Trading`,
        prizeWon: `Hadiah Dimenangkan`,
        creditBonus: `Bonus Kredit`,
        cashVoucher: `Voucher Tunai`,
        physicalRewards: `Hadiah Fisik`,
        depositTradingVolume: `Deposit / Lot Trading`,
        gifts: `Kado (USD)`,
        accountID: `ID Akun`,
        current: `Saat ini`,
        luckyDrawDeposit: `Deposit`,
      },
      dialog: {
        selectAccountContext: `Silakan pilih akun di mana Anda ingin menerima bonus kredit Anda (khusus akun MT4/MT5)`,
        tnc: 'SYARAT DAN KETENTUAN',
        tncContext: {
          tnc1: `1. Klien harus berhasil mengaktifkan promosi ini dari dalam portal klien mereka terlebih dahulu, lalu menyetorkan dana deposit dan trading sebagaimana yang diperlukan untuk menerima kado yang sesuai.`,
          tnc2: `2.	Volume trading efektif: Lot standar pasangan mata uang, emas, perak, spot minyak mentah, dan future minyak mentah. Order harus dibuka dan ditutup selama masa masa promosi. Posisi yang dibuka kurang dari 5 menit, posisi terkunci, dan posisi lindung nilai tidak dihitung sebagai trading yang valid untuk promosi ini.`,
          tnc3: `3.	Statistik dan volume order diambil dari waktu MT4 01/11/2022-31/01/2023.`,
          tnc4: `4.	Bila kado tidak dapat dibeli karena faktor keadaan kahar seperti produk tidak tersedia atau stok habis, pelanggan dapat mengajukan permohonan untuk menerima uang tunai yang setara dengan harga pasar kado tersebut. Harga ini didasarkan pada harga dolar AS (atau mata uang yang setara).`,
          tnc5: `5.	Penawaran ini tidak dapat digunakan bersamaan dengan penawaran lainnya. (100 bonus trading，20% bonus eksklusif, 50% bonus deposit，VN 20% bonus deposit).`,
        },
        agreeTnc: `Saya telah membaca dan menyetujui Syarat dan Ketentuan`,
        prizeList: `daftar hadiah`,
        luckyDrawTransaction: `TRANSAKSI UNDIAN`,
        xmasGift: `SELAMAT ATAS KADO NATAL ANDA!`,
      },
      message: {
        congratulate: 'Selamat!',
        congratulateContext: `Anda telah berhasil mengaktifkan promosi Xmas Make a Wish`,
        signedOutPromo: `Dengan Mengklik Tombol Konfirmasi, Anda akan keluar dari promosi!`,
        deactivePromo: `Anda telah berhasil memilih keluar untuk Promosi Xmas Make a Wish.`,
        redeemAmount: `Jumlahnya akan dikreditkan ke akun Anda!`,
      },
      tableContent: {
        luckyDraw: `Undian`,
        depositTrade: `Deposit / Trading`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Dapatkan <span class='bg_yellow'>Uang Instan</span> pada Deposit Pertama Anda</div>
          <div class="desc">Raih uang tunai hingga US$50 saat Anda menyetorkan deposit pertama di STARTRADER!</div>
        `,
      },
      active: {
        title: `Aktifkan cashback FTD ANDA`,
        desc: `Klik tombol di bawah untuk mengaktifkan dan berpartisipasi dalam aktivitas ini.`,
      },
      deactive: {
        title: `Nonaktifkan cashback FTD ANDA`,
        desc: `Silakan klik tombol di bawah ini untuk menonaktifkan dan keluar dari promosi Cashback FTD.`,
      },
      button: {
        active: `Aktifkan`,
        deactive: `Nonaktifkan`,
        fund: `Danai Akun Anda`,
      },
      claimTitle: `CARA KLAIMNYA`,
      claimDescriptions: [
        `Aktifkan promosi sebelum menyetorkan deposit.`,
        `Danai akun trading Anda guna memenuhi persyaratan deposit untuk cashback yang Anda inginkan（≥ 200 USD atau Mata Uang yang Setara)​.`,
        `Nilai voucher cashback akan tergantung pada jumlah deposit pertama Anda (lihat tabel cashback di S&K).`,
        `Untuk mengaktifkan voucher cashback Anda, trading ≥2 lot FX, emas, perak, atau minyak.`,
        `Voucher aktif Anda sekarang dapat ditukarkan. Jumlahnya akan ditampilkan dalam saldo akun Anda.`,
      ],
      eligibilityTitle: `ELIGIBILITAS`,
      eligibleDescription1: `Tipe akun yang berhak partisipasi: Penawaran ini HANYA terbuka untuk akun Standard dan Akun Islamic Standard, keduanya di MT4/MT5.`,
      eligibleDescription2: `Bila klien memiliki beberapa Akun Standard, mereka hanya bisa memilih satu Akun Standard untuk berpartisipasi dalam promosi ini.`,
      eligibleDescription3: `Negara yang berhak partisipasi: Semuanya kecuali China daratan`,
      eligibleDescription4: `Klien yang Memenuhi Syarat: Klien baru dan lama yang tidak memiliki riwayat deposit, termasuk klien yang berada di bawah IB/CPA.`,
      eligibleDescription4Desc: `*Akun trading pribadi IB/CPA tidak memenuhi syarat`,
      eligibleDescription5: `Deposit pertama harus dilakukan selama periode promosi.`,
      eligibleDescription6: `Order yang dipegang kurang dari 5 menit dianggap tidak valid untuk promosi ini.`,
      tnc: `*Syarat dan ketentuan berlaku, {link} untuk melihat`,
      link: `klik di sini`,
      dialog: {
        selectAccountContext: `Silakan pilih akun yang Anda inginkan untuk bergabung dengan aktivitas bonus perdagangan`,
        tnc: `Syarat dan Ketentuan`,
        tncContext: {
          tnc1: `1. Setelah Anda mengaktifkan promosi ini, Anda juga harus menyetor dana deposit dan trading 2 lot standar diperlukan untuk menerima cashback yang sesuai.`,
          tnc1desc: `*Hanya deposit baru yang dilakukan selama periode promosi berlaku dalam promosi ini. Ini tidak termasuk transfer internal, bonus undang teman, dan penyesuaian tunai.`,
          tnc2: `2.	Hanya deposit pertama yang dilakukan selama periode ini akan dihitung dalam perhitungan cashback.`,
          tnc3: `3.	Klien yang Memenuhi Syarat (klien baru dan lama) yang memenuhi kriteria yang ditetapkan di sini dapat menerima cashback, setara dengan:`,
          tnc4: `4.	Produk yang Memenuhi Syarat: Forex, Emas, Perak, Minyak.`,
          tnc5: `5.	Volume trading efektif: Order yang baru dibuka dan ditutup selama masa promosi. Posisi yang dibuka kurang dari 5 menit, posisi terkunci, dan posisi lindung nilai tidak dihitung sebagai transaksi trading yang valid untuk promosi ini.`,
        },
        agreeTnc: `Saya telah membaca dan menyetujui Syarat dan Ketentuan`,
        grossDeposit: `Deposit Bruto USD`,
        grossDepositDesc: `atau mata uang yang setara`,
        cbVoucher: `Voucher Cashback`,
        tableDesc: `* Klien dapat menukarkan voucher tunai hingga $50 (atau yang setara).`,
      },
      voucher: {
        title: `Cashback FTD`,
        accountID: `ID Akun`,
        accumulateVolume: `Akumulasi volume trading`,
        standardLots: `Lot standar`,
        desc: `Trading ≥2 lot FX, emas, perak, atau minyak untuk mengaktifkannya`,
        clickForRedeem: `Klik tombol di bawah untuk mengirimkannya ke saldo akun Anda.`,
        ftdLessThan200: `Deposit awal Anda kurang dari jumlah minimum yang dibutuhkan.`,
      },
      message: {
        congratulate: `Selamat!`,
        congratulateContext: `Anda telah berhasil mengaktifkan Promosi Cashback FTD.`,
        signedOutPromo: `Dengan Mengklik Tombol Konfirmasi, Anda akan keluar dari program promosi!`,
        deactivePromo: `Anda telah berhasil menonaktifkan Promosi Cashback FTD.`,
        successRedeem: `Anda berhasil menukarkan Cashback FTD Anda.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Klien yang Terhormat,',
        inst2: `Kami dengan hormat mengingatkan Anda bahwa promosi Bonus Tanpa Deposit akan berakhir pada <span>{date} (GMT+3)</span> .`,
        inst3:
          'Sesuai dengan Syarat dan Ketentuan Promosi, harap diperhatikan bahwa bonus di akun deposit Anda akan dihapus secara otomatis setelah promosi selesai. Bagi mereka yang tidak memiliki akun deposit atau catatan transaksi, bonus dan saldo akan dihapus secara otomatis setelah promosi selesai juga.',
        inst4: `Jika Anda memiliki pertanyaan atau memerlukan bantuan, silakan hubungi tim dukungan kami melalui Obrolan Langsung, email: <span>{mail}</span>, atau telepon <span>{phone}</span>.`,
        cantInerTransfer:
          'Karena Anda berpartisipasi dalam kampanye NDB kami, kami tidak dapat memproses permintaan transfer Anda',
      },
      db: {
        inst1: 'Kepada pelanggan yang terhormat,',
        inst2: `Kami ingin mengingatkan Anda bahwa promosi bonus deposit 50% akan berakhir pada <span>{date} (GMT+3)</span>.`,
        inst3:
          'Sesuai dengan syarat dan ketentuan promosi, mohon diperhatikan bahwa bonus di akun deposit Anda akan ditarik secara otomatis setelah promosi berakhir. Bagi mereka yang tidak memiliki akun deposit atau catatan transaksi, bonus dan saldo akan dihapus secara otomatis segera setelah promosi berakhir.',
        inst4: `Jika Anda memiliki pertanyaan atau memerlukan bantuan, silakan hubungi tim dukungan kami melalui obrolan langsung, email: <span>{mail}</span>, atau telepon <span>{phone}</span>.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Unduh MetaTrader 4 Untuk PC, Mac, browser web, smartphone, dan tablet',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone / iPad',
    android: 'Android / Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Unduh MetaTrader 5 Untuk PC, Mac, browser web, smartphone, dan tablet',
  },
  forgetPwReq: {
    tip: 'Masukkan alamat email akun anda untuk menyetel ulang password anda.',
    formValidation: { emailReq: 'Email login wajib diisi' },
    succ:
      'Kami baru saja mengirimi anda email berisi petunjuk untuk menyetel ulang password anda. <br> Pastikan anda memeriksa <br> folder spam pada email anda.',
  },
  loginClient: {
    header: 'PORTAL KLIEN',
    failedMasseage: 'Coba lagi',
    existingClientPortalAccess:
      'Kami melihat bahwa anda sudah memiliki <br /> akses Portal Klien. <br /> Silahkan masuk menggunakan email anda untuk membuka <br /> akun baru!',
    footer: `Peringatan Risiko: Perdagangan Contracts for Difference (CFD) memiliki risiko tingkat tinggi terhadap modal anda dan dapat mengakibatkan kerugian, anda sebaiknya hanya berdagang dengan uang yang anda mampu untuk kehilangannya. Perdagangan CFD mungkin tidak cocok untuk semua investor, harap pastikan bahwa anda sepenuhnya memahami risiko yang ada dan mengambil tindakan yang tepat untuk mengelolanya. <br> <br> <br>
    {platformEntityName} diotorisasi dan diatur oleh Otoritas Jasa Keuangan Seychelles dengan Nomor Lisensi SD050. <br>
    STARTRADER LLC disahkan dan diatur oleh Financial Services Authority of St. Vincent dan Grenadines dengan Lisensi No. 271 LLC 2020. <br>
    The entities above are duly authorized to operate under the STARTRADER brand and trademark.<br>`,
    formValidation: { emailReq: 'Alamat email wajib diisi' },
    invailidRafLink: `Tautan rujukan yang digunakan tidak valid. Daftar untuk akun baru di bawah ini.`,
  },
  myProfile: {
    changeDetails: 'Jika anda ingin memperbarui rincia profil yang ada, jangan ragu untuk menghubungi {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'PILIH PLATFORM TRADING',
    chooseAccType: 'PILIH JENIS AKUN',
    chooseCurrency: 'PILIH MATA UANG AKUN',
    chooseLeverage: 'PILIH LEVERAGE',
    chooseBalance: 'SALDO REKENING',
    notes: 'CATATAN TAMBAHAN',
    currError: 'anda harus memilih mata uang akun',
    platErr: 'anda harus memilih platform trading',
    accTypeErr: 'anda harus memilih jenis akun',
    leverageErr: 'anda harus memilih leverage',
    balanceErr: 'anda harus memilih saldo',
    succ:
      '<p> Akun live tambahan anda sedang disiapkan dan anda akan segera menerima email dengan rincian login anda. Anda dapat melihat status aplikasi anda saat ini dari halaman beranda. </p>',
    byTicking: 'Saya telah membaca dan menyetujui {tnc}.',
    tnc: 'Syarat dan ketentuan',
    pamm:
      'Akun PAMM hanya untuk investor dengan pengaturan Money Manager yang sudah ada. Akun PAMM tidak dapat diperdagangkan oleh siapa pun kecuali Money Manager.',
    pammConfirm: 'Harap konfirmasi apakah akan terus memilih jenis akun ini.',
    disclaimer: `<li> Saya menyatakan bahwa saya telah membaca, memahami, dan menerima {platform} <a href=https://{regulatorUrl}/company/legal/legal-document/clientserviceagreement/"> Perjanjian Layanan Klien </a> yang disediakan secara online sebagai bagian dari aplikasi ini. </li>
    <li> Saya juga mengonfirmasi bahwa saya telah membaca, memahami, dan setuju untuk terikat oleh <a href=https://{regulatorUrl}/company/legal/legal-document/websitetermsandconditions/">Persyaratan dan Ketentuan Situs Web</a> {platform}, <a href="https://{regulatorUrl}/company/legal/legal-document/moneylaunderingpreventionpolicy/"> Kebijakan Pencegahan Pencucian Uang</a>, <a href="https://{regulatorUrl}/company/legal/legal-document/riskdisclosure/">Pengungkapan Risiko</a> dan <a href="https://{regulatorUrl}/company/legal/legal-document/privacypolicy/">Kebijakan Privasi</a>.</li>
    <li> Saya memahami bahwa {platform} tidak akan memberi saya saran investasi apa pun tentang transaksi. </li>
    <li> Saya memahami bahwa informasi pribadi yang dikirimkan sebagai bagian dari permohonan ini akan digunakan untuk memverifikasi identitas saya dengan layanan autentikasi pihak ketiga. </li>
    <li> Saya mengonfirmasi bahwa informasi yang saya berikan dan yang dimasukkan ke dalam formulir ini adalah benar dan saya menyatakan bahwa saya berkewajiban untuk segera memberi tahu {platform} jika ada perubahan pada informasi ini. </li>
    <li> Saya menyatakan bahwa saya telah bertindak atas nama saya sendiri seperti yang ditentukan dalam permohonan ini dan bukan atas nama pihak ketiga sehubungan dengan semua masalah yang berkaitan dengan hubungan klien ini. Karenanya, semua dana yang akan disimpan dan diperdagangkan di akun dengan {platform} adalah dana saya sendiri. </li>
    <li> Saya mengonfirmasi bahwa jumlah setoran yang saya pilih mempertimbangkan keadaan keuangan saya secara menyeluruh dan saya anggap bertanggung jawab dalam hal itu. Saya memahami risiko inheren dari perdagangan CFD dan karena sifat produk yang berisiko, saya mungkin kehilangan lebih dari setoran saya. </li>`,
  },
  openDemoAdditAcc: {
    header: 'Konfigurasi Akun Demo',
    button: 'buka akun demo',
    liveButton: 'buka akun live',
    succ: 'Akun demo tambahan anda telah disiapkan dan anda akan segera menerima email dengan detail login anda.',
  },
  resetPassword: {
    header: 'SETEL ULANG PASSWORD',
    succ:
      'Password anda telah diperbarui. anda sekarang dapat menggunakan pasword baru anda untuk mengakses akun trading MT4 / MT5 anda.',
    succ2: 'Password anda telah diperbarui.',
    failed: 'Token kedaluwarsa',
    emailNotFound: 'Mohon maaf, alamat email anda tidak dikenali',
  },
  fileUploader: {
    supportedFormat: 'Jenis file yang didukung: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Ukuran file unggahan maksimum: {maxSize} MB',
    maxFileNum: 'Nomor file maksimum: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'DEPOSIT',
    withdraw: 'PENARIKAN',
    desc: {
      incomplete:
        'Jika anda menghentikan atau gagal menyelesaikan proses penyetoran, status transaksi akan ditampilkan sebagai "belum selesai". anda akan diminta untuk membuat setoran baru karena transaksi ini tidak dapat dilanjutkan.',
      success: 'Transaksi anda berhasil. Dana anda akan segera muncul di akun anda',
      proc: 'Transaksi anda sedang diproses. Harap hubungi {mailTo}',
      failed: 'Transaksi anda gagal. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      rejected: 'Transaksi anda telah ditolak. Harap hubungi {mailTo} untuk informasi lebih lanjut',
    },
    withdrawDesc: {
      sub: 'Transaksi anda telah dikirim. Mohon tunggu sampai selesai.',
      suc: 'Transaksi anda berhasil. Dana anda akan segera muncul di akun anda',
      proc: 'Transaksi anda sedang diproses. Harap hubungi {mailTo}',
      incomp:
        'Transaksi anda membutuhkan langkah tambahan untuk diselesaikan. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      cancel: 'Transaksi anda telah dibatalkan. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      fail: 'Transaksi anda gagal. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      rej: 'Transaksi anda telah ditolak. Harap hubungi {mailTo} untuk informasi lebih lanjut',
    },
    cancelWithdraw: `Apakah Anda ingin Membatalkan Permintaan Penarikan?`,
    cancelError: `Penarikan Anda sedang diproses saat ini. Oleh karena itu, kami tidak dapat menerima permintaan pembatalan Anda. Untuk informasi lebih lanjut, Anda dapat menghubungi kami menggunakan fitur obrolan langsung.`,
    dialog: {
      appliedAmount: `Jumlah yang Diminta`,
      applicationTime: `Waktu Permintaan`,
      processedTime: `Waktu Pemrosesan`,
      accountCurrency: `Mata uang akun`,
      creditDeducted: `Kredit Terpotong`,
      processedNote: `Catatan pemrosesan`,
      bankName: `Nama Bank`,
      bankAccountNumber: `Nomor Rekening Bank`,
      abaSortCode: `Kode ABA/Sort`,
      swiftCode: `Kode Swift`,
      bankStatement: `Rekening Koran Bank`,
      bankAddress: `Alamat Bank`,
      accountName: `Nama Akun`,
      accountNumber: `Nomor Akun`,
      cardholderName: `Nama Pemegang Kartu`,
      cardNumber: `Nomor Kartu`,
      cardExpiryDate: `Tanggal Kedaluwarsa Kartu`,
      walletAddress: `Alamat Wallet`,
      ewalletEmailAddress: `Alamat email e-wallet`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Sayangnya transfer Anda tidak dapat diproses karena akan berakibat ketersediaan margin akun Anda akan jatuh di bawah 100% level stop out',
      equityMargin:
        'Sayangnya saat ini kami tidak dapat memproses transfer Anda karena akun Anda sepenuhnya dalam lindung nilai.',
      equityContinue:
        'Kami mendeteksi sekarang ini Anda memiliki posisi terbuka, mungkin Anda akan mengalami stop out setelah mengajukan permintaan transfer Anda. Harap konfirmasi jika Anda ingin melanjutkan.',
      blackList: 'Akun ini telah dibatasi untuk melakukan transfer antar akun, harap hubungi {supportEmail}',
    },
    pleaseNote: 'Mohon dicatat:',
    suc: 'Dana anda telah berhasil ditransfer',
    warn1:
      'Anda memiliki tanggung jawab menjaga saldo akun anda untuk tidak akan memicu margin call. Jika anda memiliki posisi terbuka saat ini, mohon pastikan anda mempertahankan margin yang diperlukan di akun anda. Dana yang ditransfer ke rekening yang disimpan dalam mata uang yang sama diproses secara instan.',
    warn2: 'Jika transfer dana antar rekening disimpan dalam mata uang berbeda, kirim email ke {mailTo}.',
    warn3: 'Harap dicatat bahwa dana tidak dapat ditransfer antar akun read-only.',
  },
  transferHistory: {
    payStatus: { processing: 'Sedang diproses', paid: 'Dibayar', unpaid: 'Belum dibayar', rejected: 'Ditolak' },
  },
  identityProof: {
    home: {
      header: 'APLIKASI AKUN TIDAK LENGKAP',
      content:
        'Halo {name}, permohonan akun anda hampir selesai, namun anda masih perlu mengirimkan dokumen tambahan untuk menyelesaikan akun anda.',
    },
    shufti: {
      content: `Harap pastikan informasi yang diberikan sesuai dengan dokumen identitas Anda, dan dalam bahasa yang sama.`,
      countryOfDocument: `Negara sebagaimana tercantum pada dokumen`,
    },
    popup: {
      header:
        'Halo {name}, sebelum akun live anda dapat diselesaikan, anda harus mengunggah dokumen berikut untuk verifikasi.',
      addressProofSpan: `<strong> Konfirmasi Alamat </strong> <br />
      Kirimkan salah satu dokumen di bawah <br /> <br />
      - Salinan Tagihan Utilitas terkini (tidak lebih dari 6 bulan) <br />
      - Salinan Rekening Koran terbaru (tidak lebih dari 6 bulan) <br />
      PENTING! Nama pada dokumen HARUS dengan jelas menunjukkan nama lengkap dan alamat anda saat ini.`,
      idProofSpan: `<strong> Konfirmasi Tanda Pengenal </strong> <br />
      Pilih salah satu opsi di bawah <br /> <br />
      - Salinan Paspor yang Sah<br />
      - Salinan Surat Izin Mengemudi <br />
      - Fotokopi KTP (Depan dan Belakang) <br />
      PENTING! Nama pada dokumen HARUS menunjukkan nama lengkap anda dengan jelas.`,
    },
    reason: 'Dokumentasi anda tidak dapat diverifikasi, <br> Alasan: {reason}. Silahkan coba lagi.',
  },
  header: { secPortal: 'Aman <br /> Area Klien' },
  menu: {
    home: 'BERanda',
    accManagement: 'AKUN',
    liveAcc: 'AKUN LIVE',
    openAdditAcc: 'BUKA AKUN TAMBAHAN',
    homeDemo: 'AKUN DEMO',
    depositFund: 'SETOR DANA',
    withdrawFunds: 'TARIK DANA',
    transferBetweenAccs: 'TRANSFER DANA',
    payDetails: 'RINCIAN PEMBAYARAN',
    funds: 'DANA',
    transactionHis: 'RIWAYAT TRANSAKSI',
    tranHis: 'TRANSFER',
    download: 'UNDUH',
    profile: 'PROFIL',
    myProfile: 'PROFIL SAYA',
    cpPassword: 'GANTI PASSWORD',
    contactUs: 'HUBUNGI KAMI',
    register: 'LANJUTKAN APLIKASI',
    tradingTools: 'ALAT PRO TRADER',
    MARKET_BUZZ: 'BUZZ PASAR',
    ECON_CALENDAR: 'KALENDER EKONOMI',
    FEAT_FX: 'FITUR IDE',
    FX_IDEAS: 'PandaNGAN ANALIS',
    autochartist: 'AUTOCHARTIST',
    promotion: 'PROMOSI',
    noDeposit: 'BONUS TANPA DEPOSIT',
    referFriends: 'MENUNJUK TEMAN',
    depositBonus: 'BONUS DEPOSIT 50%',
    cryptoCashback: `Uang Kembali Mata Uang Kripto`,
    firstTimeDeposit: `Cashback FTD`,
    tradingTool: 'Alat perdagangan',
  },
  contact: {
    click: 'Klik',
    assist: '  untuk bantuan sekarang',
    desc:
      'Tim SUPPORT pelanggan multibahasa profesional {platform} siap membantu anda dan membantu memberi anda pengalaman perdagangan yang tak tertandingi.',
    followUs: 'IKUTI KAMI',
    tip: 'Untuk klien yang bergabung melalui {email}',
    liveChat: 'Berbicara Langsung',
  },
  depositResultDialog: { msg: 'Sudahkah anda berhasil menyelesaikan setoran anda' },
  dialog: {
    identityProof: {
      success: 'Terima kasih. Kami telah berhasil menerima dokumen anda dan sedang memprosesnya untuk verifikasi.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PENGOLAHAN APLIKASI AKUN',
      id: 'Tanda Pengenal adalah',
      poa: 'Alamatnya adalah',
      both: 'Tanda Pengenal dan Bukti alamat adalah',
      content:
        'Bukti {doc} anda saat ini sedang diproses dan akan segera diselesaikan. Untuk mempercepat pemrosesan akun live anda, anda dapat mendanai akun anda sekarang dan menerima prioritas pembukaan.',
    },
  },
  register: {
    progressStatus: { title: 'Perkembangan verifikasi', estimatedTimeTitle: 'perkiraan waktu', min: 'Menit' },
    tabs: {
      personalDetails: 'Informasi Pribadi',
      moreAboutYou: 'Lebih terperinci tentang Anda',
      accountConfig: 'Konfigurasi Akun',
      confirmYourId: 'Konfirmasi Identitas Anda',
      fundYourAcc: 'Danai Akun Anda',
    },
    btn: { next: 'BERIKUTNYA', back: 'KEMBALI', fundnow: 'DANAI SEKARANG', upload: 'Unggah', done: 'SELESAI' },
    authority: {
      dialog1: 'anda harus memiliki akun live untuk mengakses bagian portal klien ini.',
      dialog2:
        'Buka akun live sekarang untuk membuka akses penuh ke portal klien anda, yang mencakup berbagai alat perdagangan, promosi, dan banyak lagi.',
      openLiveAcc: 'buka akun live',
    },
    demo: {
      congrad: 'Selamat!',
      title: 'Akun DEMO MT {platform}  60 Hari anda sekarang sudah aktif',
      title2: 'Detail Akun DEMO anda',
      li: { login: 'LOGIN', srv: 'Server', expDate: 'Tanggal kedaluwarsa' },
      emailSent: '* Detail login juga telah dikirim ke email anda yang terdaftar.',
      dl: 'Unduh Platform Trading MT {platform}anda',
      improveQ: 'Apakah anda ingin meningkatkan trading anda？',
      improveWay:
        'Cukup, buka akun live hanya dalam beberapa menit untuk membuka berbagai alat trading dan promosi eksklusif.',
      proTrade: 'Laporan pemasaran profesional',
      customerService: '24/7 Layanan pelanggan satu-satu',
      competitivePromotionals: 'Promosi kompetitif',
      secure: 'Transaksi 3D Secure',
      openLiveAcc: 'buka akun live',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Informasi Pribadi',
        userTitle: 'Gelar',
        firstName: 'Nama Depan',
        lastName: 'Nama Belakang',
        email: 'E-mail',
        dob: 'Tanggal Lahir',
        idType: 'Tipe Identifikasi',
        referral: 'Apakah anda pernah dirujuk oleh seseorang？ (opsional)',
        middleName: 'Nama Tengah',
        nationality: 'Kewarganegaraan',
        phoneNum: 'Nomor Telepon',
        date: 'Hari',
        month: 'Bulan',
        year: 'Tahun',
        placeOfBirth: 'Tempat Lahir',
        idNum: 'Nomor identifikasi',
        passportNum: 'Nomor paspor',
        driverLicenseNum: 'Nomor Surat Izin Mengemudi',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: { passport: 'Paspor', idCard: 'Kartu Tanda Penduduk', driverLicense: 'SIM' },
      },
      page2: {
        pageTitle: 'ALAMAT TEMPAT TINGGAL UTAMA',
        mainResidency: 'Negara Domisili Utama',
        streetNumber: 'Nomor jalan',
        streetName: 'Nama jalan',
        address: 'Alamat',
        provinceOrState: 'Provinsi / Negara Bagian',
        cityOrSuburb: 'Kota / Kabupaten',
        postcode: 'Kode Pos',
        usQuestion:
          'Apakah anda Warga Negara / Penduduk AS untuk tujuan pajak atau apakah anda memiliki tautan / ikatan dengan AS (mis. Rekening Bank, dll.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'STATUS PEKERJAAN DAN RINCIAN KEUANGAN',
      pageTitle2: 'Pengetahuan dan Pengalaman Trading',
      complianceExplain: 'Pertanyaan-pertanyaan berikut dikumpulkan untuk memenuhi persyaratan AML / CTF.',
    },
    accountConfig: {
      pageTitle: 'Konfigurasi Akun',
      chooseTradingPlatform: 'Pilih Platform Trading',
      chooseAccType: 'Pilih Tipe Akun',
      chooseAccCurrency: 'Pilih Mata Uang Akun',
    },
    confirmYourId: {
      pageTitle: 'Konfirmasi Identitas Anda',
      description:
        'Sebagai broker forex teregulasi, kami diwajibkan untuk memverifikasi identitas anda. Harap unggah salah satu dokumen berikut untuk verifikasi Bukti KTP dan Bukti Alamat anda. Atau, anda dapat mengirimkan dokumen verifikasi anda melalui email ke {mailTo}',
      poidTitle: 'Bukti Identitas',
      porTitle: 'Bukti Tempat Tinggal',
      uploadDescription: 'Pilih tipe dokumen dan unggah yang sesuai',
      note: 'PENTING: Nama di dokumen HARUS menunjukkan nama lengkap anda dengan jelas.',
      pending: { upload: 'Unggahan Tertunda', verification: 'Verifikasi Tertunda' },
      poidTypes: { photoId: 'Foto Dokumen Identitas', passport: 'Paspor', drivingLicence: 'Surat Izin Mengemudi' },
      porTypes: `<li> Tagihan Utilitas </li>
      <li> Laporan Bank </li>
      <li> Sertifikat Tempat Tinggal </li>`,
    },
    finishPage: {
      title: '{individualUserName}, terima kasih telah mendaftar untuk akun live {platformName}!',
      contentDefault:
        'Kami telah menerima aplikasi anda dan menyiapkan akun live anda <b> {account} </b>. <br /> anda akan segera menerima email di <b> {email} </b> yang berisi detail login anda dan link untuk melihat status akun anda. <br />',
      contentWithoutId3WithAccount: `Akun trading live anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />
      Untuk mempercepat pemrosesan akun live anda, anda dapat mendanai akun anda sekarang dan menerima prioritas pembukaan. <br />`,
      contentWithoutId3WithoutAccount:
        'Akun trading live anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />',
    },
  },
  autochartist: {
    1: {
      title: `Pemindai Pasar`,
      desc01: `Pemindai Pasar pertama di dunia yang memungkinkan Anda memindai daftar pantauan pasar Anda untuk peluang perdagangan.`,
      button01: `Unduh sekarang`,
    },
    2: {
      title: `Laporan Pasar`,
      desc01: `Laporan Pasar Autochartist memberi Anda gambaran tentang prospek teknis pasar sebelum pembukaan pusat keuangan utama.`,
      button01: `Lihat Laporan Pasar`,
    },
    3: {
      title: `Portal Penelitian Autochartist`,
      desc01: `Portal penelitian lengkap yang memungkinkan Anda menemukan semua data yang tersedia di satu tempat.`,
      button01: `Meluncurkan`,
      button02: `Panduan pengguna`,
      button03: `Akses OTP`,
    },
    4: {
      title: `Peluang Perdagangan`,
      desc01: `Periksa pembaruan peluang perdagangan setiap 15 menit dengan pengaturan baru berdasarkan semua jenis analisis Autochartist.`,
      button01: `Lihat Peluang Perdagangan`,
    },
    5: {
      title: `Aplikasi Seluler Autochartist`,
      desc01: `Terima pemberitahuan push dan lacak peluang perdagangan yang difilter dengan probabilitas tinggi berdasarkan daftar pantauan Anda dalam platform perdagangan.`,
      desc02: `Catatan:`,
      desc03: `Kode QR dapat dipindai dari kamera biasa, asalkan ponsel pengguna memiliki pemindai QR bawaan, atau dari dalam aplikasi seluler autochartist, dengan mengklik tombol “Masuk menggunakan Kode QR”.`,
      desc04: `Kode QR hanya berlaku selama 10 menit, setelah itu gambar harus di-refresh untuk kode QR baru.`,
      desc05: `Kode QR hanya berlaku untuk sekali pakai, setelah itu gambar harus di-refresh untuk kode QR baru.`,
      button01: `Unduh Untuk iOS`,
      button02: `Unduh Untuk Android`,
    },
    6: {
      title: `Berlangganan Buletin`,
      desc01: `Dengan berlangganan buletin kami, Anda akan menerima email harian yang didukung oleh Autochartist untuk memberi Anda gambaran tentang pasar valas global. Berdagang dengan serikat pasifik, berdagang dengan dunia.`,
      button01: `Langganan`,
    },
    footer: `Autochartist adalah alat paling canggih di dunia untuk identifikasi otomatis peluang perdagangan dalam bentuk pola Bagan, pola Fibonacci, dan Level Kunci. Kepemilikan Autochartist memungkinkan mesin pencari mereka untuk memantau ribuan instrumen keuangan 24 jam per hari mengungkap peluang perdagangan saat terjadi. Selain itu, Autochartist juga menawarkan Analisis Volatilitas sebagai fitur yang akan memberi Anda penilaian risiko dan volatilitas yang lebih baik pada instrumen yang Anda perdagangkan.`,
  },
  responseMsg: {
    410: 'Validasi parameter gagal',
    411: 'Kata kunci Salah',
    420: 'Pengguna tidak ditemukan',
    421: 'Setel Ulang Kata Sandi ditolak',
    490: 'Harap jangan mengirimkan informasi kartu secara berganda',
    500: 'Terjadi kesalahan.',
    501: 'Permohonan gagal. Silakan coba lagi nanti.',
    505: 'Gagal mengunggah berkas',
    520: 'Gagal masuk. Silahkan coba lagi.',
    521: 'Seorang pengguna sudah masuk di perangkat ini!',
    522: 'Silakan login lagi',
    523: 'Silakan login lagi',
    524: 'Mohon maaf, alamat email anda tidak dikenali',
    525: 'Username atau password salah',
    527: 'Pengguna bukan seorang IB',
    528: 'Pengguna tidak ditemukan',
    529: 'Username atau password salah',
    540: 'Akun trading anda tidak dapat ditemukan',
    541: 'Akun Rabat tidak dapat ditemukan',
    542: 'Anda tidak diizinkan untuk membuka akun tambahan',
    544: 'Anda tidak diperbolehkan mendaftarkan akun tambahan hingga Bukti Identitas Anda diverifikasi',
    550: 'Nomor rekening salah',
    551: 'Saldo anda tidak mencukupi',
    553: 'anda tidak dapat menarik / mentransfer karena kredit di akun anda. Harap hubungi {email} untuk bantuan lebih lanjut',
    554: 'Jumlah yang diminta nol atau null',
    562: 'Kami tidak dapat memproses pembayaran anda, coba lagi. Jika tetap terjadi masalah, silakan hubungi kami melalui obrolan langsung, atau email {email}',
    564: 'Negara tidak sesuai dengan portal pembayaran',
    565: 'Kode Bank Salah',
    566: 'Metode pembayaran tidak ditemukan',
    568: 'Akun ini telah dibatasi untuk melakukan deposit, harap menghubungi {email}',
    581: 'anda telah berhasil mengikuti promosi. Harap dicatat bahwa anda harus melakukan deposit untuk menarik dana.',
    582: 'Karena Anda telah mengikuti program promosi dan memiliki posisi terbuka, kami tidak dapat memproses permintaan penarikan Anda',
    584: 'Anda tidak memiliki rabat yang berlaku',
    587: 'Saldo tidak mencukupi, silakan hubungi layanan pelanggan.',
    590: 'Akun transfer keluar tidak dapat ditemukan',
    591: 'Akun transfer masuk tidak dapat ditemukan',
    593: 'Kami tidak dapat memproses transfer dana Anda dari akun yang dipilih karena akun Anda mengandung kredit.',
    594: 'Transfer dana dari akun ini tidak diizinkan',
    595: 'Karena Anda telah mengikuti program promosi kami, kami tidak dapat memproses permintaan transfer Anda',
    647: 'Kode verifikasi tidak benar',
    1001: 'Akun Anda tidak tersedia untuk sementara ini, harap hubungi manajer akun Anda',
    session_timeout: 'Sesi waktu habis, mengarahkan ke halaman login.',
    vload: {
      4019: 'Tipe atau nilai voucher tidak diterima',
      4071: 'Dicurigai penipuan',
      4072: 'Voucher telah digunakan',
      4073: 'Voucher tidak aktif',
      4074: 'Voucher kedaluwarsa',
      4075: 'Voucher tidak ditemukan',
      4080: 'Nilai voucher tidak mencukupi',
      4444: 'Voucher yang digunakan tidak valid. Mata uang tidak sesuai.',
      5000: 'Data toko tidak tersedia',
    },
  },
  tradingTool: {
    mt4: {
      mt4Text1: '',
      mt4Text2: '',
      mt4Text3: '',
      mt4Text4: '',
      mt4Text5: '',
      mt4Text6: '',
      mt4Text7: '',
      mt4Text8: '',
      mt4Text9: '',
      mt4Text10: '',
      mt4Text11: '',
      mt4Text12: '',
      mt4Text13: '',
      mt4Text14: '',
      mt4Text15: '',
      mt4Text16: '',
      mt4Text17: '',
      mt4Text18: '',
      mt4Text19: '',
      mt4Text20: '',
      mt4Text21: '',
      mt4Text22: '',
      mt4Text23: '',
    },
    pamm: {
      pammSystem: 'Sistem PAMM',
      pammSystemPopular: 'Sistem PAMM adalah sistem manajemen perdagangan otomatis paling populer.',
      mostAdvantage: 'Keuntungan terbesarnya adalah dialokasikan di antara semua investor berdasarkan persentase volume perdagangan, biasanya berdasarkan saldo atau ekuitas investor',
      pammTitle: 'Sistem Manajemen Akun PAMM menawarkan keuntungan berikut.',
      pammText1: 'Berdasarkan platform perdagangan yang diakui secara global MT4',
      pammText2: 'Alokasi berdasarkan persentase volume transaksi',
      pammText3: 'Hasil perdagangan yang konsisten untuk akun investor dan akun master',
      pammText4: 'Dukung perdagangan EA',
      pammText5: 'Manajemen Pesanan Risiko Waktu Nyata',
      pammAccount: 'Manajemen Akun PAMM',
      pammMangeAccount: 'Akun Manajer PAMM',
      pammInvestAccount: 'Akun Investor PAMM',
      checkPammMange: 'Investor dapat memilih manajer PAMM yang tepat untuk mereka sesuai dengan tujuan investasi mereka',
    },
    copytrade: {
      copytrade1: 'COPY TRADE Trading Cerdas, Banyak Profit',
      copytrade2: 'COPYTRADE adalah platform yang memberi anda kesempatan untuk menyalin order dari trader profesional. Bergabunglah dengan komunitas STARTRADER COPYTRADE, dan salin transaksi Copytrade dari para provider dalam beberapa langkah mudah, pelajari strategi mereka, dan tingkatkan keterampilan trading anda.',
      tradeNow: 'Trading Sekarang',
      rank: 'Peringkat',
      copytrade3: 'Langkah untuk meng-copy order',
      copytrade4: 'Masuk ke komunitas',
      copytrade5: 'Masuk ke komunitas untuk menjadi Pengikut',
      copytrade6: 'Subscribe',
      copytrade7: 'lihat grafik pendapatan dan pilih akun provider yang ingin anda ikuti dan salin trading anda sendiri',
      copytrade8: 'Order berhasil disalin',
      copytrade9: 'Order yang disalin dapat dilihat di MT4 atau platform komunitas',
      copytrade10: 'Provider',
      copytrade11: 'Buat profil pribadi',
      copytrade12: 'Publik atau hanya dapat dilihat oleh orang tertentu',
      copytrade13: 'Batasi modal awal Follower',
      copytrade14: 'Registrasi otomatis sebagai Provider',
      copytrade15: 'Siapkan dividen untuk subscriber',
      copytrade16: 'Tetapkan profit sendiri',
      copytrade17: 'Pengikut/Follower',
      copytrade18: 'Multi Subscriptions',
      copytrade19: 'Model follow-up yang beragam',
      copytrade20: 'Pesanan dari Provider dapat disaring berdasarkan kualifikasi',
      copytrade21: 'Perlindungan keamanan order',
      copytradeBtn1: 'Menjadi Provider',
      copytradeBtn2: 'Menjadi Provider',
    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
