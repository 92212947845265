<template>
  <el-form :model="form" ref="ruleForm" label-position="top" class="confirm_id">
    <i18n class="tip" path="register.confirmYourId.description" tag="p">
      <template v-slot:mailTo>
        <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">
          <u>{{ GLOBAL_ACCOUNT_EMAIL }}</u>
        </a>
      </template>
    </i18n>
    <el-row class="upload_box" :gutter="8">
      <el-col :span="12" :xs="24">
        <div class="title">
          {{ $t('register.confirmYourId.poidTitle') }}
          <span v-if="checkIdDocFile" class="upload_warn upload_check">{{
            $t('register.confirmYourId.pending.verification') }}</span>
          <span class="upload_warn" v-else>{{ $t('register.confirmYourId.pending.upload') }}</span>
        </div>
        <vUpload :limit="uploadLimit" v-on:updateFileInfo="updateIDInfo" :selfTip="false" :selfText="true"
          :class="{ finish: checkIdDocFile }">
          <div class="message">
            <svg-icon iconClass="upload"></svg-icon>
            <p class="upload_btn">{{ $t('register.btn.upload') }}</p>
          </div>
          <template slot="warnTip">
            <div v-if="secondNationalId" class="tip_text">{{ $t('register.confirmYourId.uploadTip') }}</div>
          </template>
        </vUpload>
      </el-col>
    </el-row>
    <idPoaForm ref="idPoaForm" v-if="checkIdDocFile" :userData="foamData" :isGrid="true"></idPoaForm>
    <div class="line" v-if="checkIdDocFile"></div>
    <el-row class="upload_box" :gutter="8">
      <el-col :span="12" :xs="24">
        <div class="title">
          {{ $t('register.confirmYourId.porTitle') }}
          <span v-if="checkPocDocFile" class="upload_warn upload_check">{{
            $t('register.confirmYourId.pending.verification') }}</span>
          <span class="upload_warn" v-else>{{ $t('register.confirmYourId.pending.upload') }}</span>
        </div>
        <vUpload :limit="6" v-on:updateFileInfo="updatePOAInfo" :selfTip="false" :selfText="true"
          :class="{ finish: checkPocDocFile }">
          <div class="message">
            <svg-icon iconClass="upload"></svg-icon>
            <p class="upload_btn">{{ $t('register.btn.upload') }}</p>
          </div>
        </vUpload>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import idPoaForm from '@/components/home/UploadIdAddress/Form';
import confirmID from '@/mixins/register/confirmID';

export default {
  components: { vUpload, idPoaForm },
  mixins: [confirmID],
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/register/confirmID.scss';
.el-form {
  .upload_box {
    max-width: 800px;
  }

  .title {
    font-size: 14px;
    color: $text-primary;
    line-height: 22px;
    margin-bottom: 8px;

    .upload_warn {
      font-size: 12px;
      color: $danger;
      line-height: 18px;
      @include rtl-sass-prop(margin-left, margin-right, 8px);

      &.upload_check {
        color: $text-link;
      }
    }
  }

  .tip_text {
    font-size: 12px;
    color: $danger;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .svg-icon {
    font-size: 32px;
  }

  .upload_btn {
    font-size: 12px;
    color: #7A8699;
    line-height: 18px;
    margin-top: 4px;
  }

  .tip {
    font-size: 12px;
    line-height: 18px;
    color: $text-secondary;
    margin: -16px 0 24px;

    a {
      color: $text-link;
      @include rtl-sass-prop(margin-left, margin-right, 4px);
    }
  }

  .line {
    margin: 16px 0 40px;
    width: 100%;
    height: 0;
    border: 1px dashed #DFDFE6;
  }
}
</style>
