<template>
  <ul class="link-box">
    <!-- solidpayment-->
    <li @click="solidpaymentClick" v-if="notRestricted(1, 22)" data-testid="solidpayment">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7{{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <span>Preferred</span>
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- Worldcard -->
    <li @click="worldcardClick" data-testid="worldCard" v-if="notRestricted(1, 14)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div v-if="OnlyMasterWorldcard.includes(countryCode)" class="logo diamond masterCard"></div>
        <div v-else class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- Sdpay-->
    <li @click="sdPayClick" data-testid="sdpay" v-if="notRestricted(1, 6)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc ccJCMC"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- VirtualPay -->
    <li @click="virtualPayClick" data-testid="virtualPay" v-if="notRestricted(1, 9)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- maxisPay-->
    <!-- <li @click="maxisPayClick" data-testid="maxisPay" v-if="notRestricted(1, 17)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7{{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import showCreditcard from '@/mixins/page/deposit/showCreditcard';

export default {
  mixins: [mixin, showCreditcard],
  data() {
    return {
      OnlyMasterWorldcard: [4101, 6581] //FRANCE, SPAIN
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
