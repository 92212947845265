export default {
  en: 'ภาษาอังกฤษ',
  zh: 'ภาษาจีน',
  fr: 'ภาษาฝรั่งเศส',
  common: {
    liveChat: { desc: 'ต้องการความช่วยเหลือหรือไม่ แชทสด' },
    country: { international: 'ระหว่างประเทศ', australia: 'ออสเตรเลีย' },
    verification: {
      toSlide: 'เลื่อนเพื่อไขปริศนา',
    },
    field: {
      nameOnCard: 'ชื่อบนบัตร',
      cardholderName: `ชื่อผู้ถือบัตร`,
      first4Digits: 'ตัวเลข 4 หลักแรกบนบัตร',
      last3Digits: 'ตัวเลข 3 หลักสุดท้ายบนบัตร',
      first6Digits: 'ตัวเลข 6 ตัวแรกบนบัตร',
      last4Digits: 'ตัวเลข 4 หลักสุดท้ายบนบัตร',
      bankName: 'ชื่อธนาคาร',
      bankAddress: 'ที่อยู่ธนาคาร (สำนักงานใหญ่)',
      branchAddress: 'ที่อยู่สาขา (สำนักงานใหญ่)',
      bankBranch: 'สาขาธนาคาร (สำนักงานใหญ่)',
      bankCity: 'เมือง',
      bankProvince: 'จังหวัด',
      bankBeneficiaryName: 'ชื่อบัญชีผู้รับเงิน',
      bankBeneAccName: 'ชื่อบัญชีผู้รับเงิน',
      bankBeneAccNum: 'หมายเลขบัญชีธนาคารของผู้รับเงิน',
      bankBeneAddress: 'ที่อยู่ผู้รับเงิน',
      bankAccNum: 'หมายเลขบัญชีธนาคาร',
      bankAccName: 'ชื่อบัญชีธนาคาร',
      bankHolderAddress: 'ที่อยู่เจ้าของบัญชี',
      address: 'ที่อยู่',
      accName: 'ชื่อบัญชี',
      accNum: 'หมายเลขบัญชี',
      accNumTo: 'โอนไปยังหมายเลขบัญชี',
      bsbNum: 'หมายเลข BSB',
      swift: 'รหัส Swift',
      swiftNonAUD: 'รหัส SWIFT (บัญชีที่ไม่ใช่สกุลเงิน AUD)',
      amt: 'จำนวนเงิน',
      amtUSD: 'จำนวนเงิน (USD)',
      availableAmount: 'จำนวนที่มีอยู่',
      transitNumber: 'หมายเลขขนส่ง',
      institutionNumber: 'หมายเลขสถาบัน',
      imptNotes: 'หมายเหตุสำคัญ',
      yes: 'ใช่',
      no: 'ไม่ใช่',
      payMethod: 'วิธีการชำระเงิน:',
      expDate: 'วันหมดอายุ',
      bsb: 'รหัส BSB',
      abaSortCode: 'ABA/Sort Code:',
      country_region: 'ประเทศ/ภูมิภาค',
      currLeverage: 'เลเวอเรจปัจจุบัน',
      accEquity: 'ยอดเงินในบัญชี',
      newLeverage: 'ปรับเปลี่ยนเลเวอเรจ',
      currPw: 'รหัสผ่านปัจจุบัน',
      newPw: 'รหัสผ่านใหม่',
      confirmPw: 'ยืนยันรหัสผ่านใหม่',
      loginEmail: 'เข้าสู่ระบบด้วยอีเมล',
      email: 'อีเมล',
      pw: 'รหัสผ่าน',
      accType: 'ประเภทบัญชี',
      name: 'ชื่อ-นามสกุล',
      emailAdd: 'อีเมล',
      phone: 'หมายเลขโทรศัพท์',
      dob: 'วันเกิด',
      nat: 'สัญชาติ',
      country: 'ประเทศที่อยู่อาศัย',
      street: 'บ้านเลขที่ / ซอย / ถนน',
      suburbOrCity: 'เขต / แขวง',
      provinceOrState: 'จังหวัด',
      postcode: 'รหัสไปรษณีย์',
      empStat: 'สถานะการจ้างงาน',
      annIncome: 'รายได้ต่อปี',
      save: 'ลงทุน',
      source: 'ที่มาของรายได้',
      industry: 'อุตสาหกรรม',
      statusDef: 'คำนิยามของสถานะ',
      month: 'เดือน',
      year: 'ปี',
      select: 'เลือก',
      completed: 'สมบูรณ์',
      incomplete: 'ไม่สมบูรณ์',
      submitted: 'ส่งแล้ว',
      successful: 'สำเร็จ',
      processing: 'อยู่ระหว่างการดำเนินการ',
      cancelled: 'ถูกยกเลิก',
      failed: 'ล้มเหลว',
      rejected: 'ถูกปฎิเสธ',
      upload: 'อัปโหลด',
      pending: `รอดำเนินการ`,
      ccNum: 'หมายเลขบัตรเครดิต',
      ccExpireDate: 'วันหมดอายุ',
      bankCardNumber: 'หมายเลขบัตรธนาคาร',
      cardHolderName: 'ชื่อผู้ถือบัตร',
      reservedMobileNumber: 'หมายเลขโทรศัพท์ธนาคาร',
      nationalId: 'บัตรประชาชน',
      cardPhoto: 'ภาพถ่ายบัตร (ด้านหน้า)',
      notes: 'หมายเหตุ',
      bankAccOrIBAN: 'เลขบัญชีธนาคาร/IBAN',
      selectWithdrawCard: 'เลือกบัตรของท่านเพื่อถอนเงิน',
      updateCardPhoto: 'โปรดอัปโหลดภาพถ่ายบัตรของท่าน (ด้านหน้า)',
      actualAmount: 'จำนวนเงินจริง',
      resetBalTo: 'รีเซ็ตยอดคงเหลือเป็น',
      ipChangeWarning: 'รับอีเมลแจ้งเตือนเมื่อมีการเข้าสู่ระบบบัญชีของคุณจากสถานที่อื่น',
      enterVerificationCode: 'กรอกรหัสการยืนยัน',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'วันที่',
      type: 'ประเภท',
      server: 'เซิร์ฟเวอร์',
      status: 'สถานะ',
      comment: 'ความคิดเห็น',
      accNum: 'หมายเลขบัญชี',
      currency: 'สกุลเงิน',
      equity: 'ยอดเงินคงเหลือ',
      leverage: 'เลเวอเรจ',
      resetMT4PW: 'เปลี่ยนรหัสผ่าน MT4',
      resetMT5PW: 'เปลี่ยนรหัสผ่าน MT5',
      tradingAcc: 'บัญชีซื้อขาย',
      method: 'ช่องทาง',
      amt: 'จำนวนเงิน',
      procNote: 'หมายเหตุการดำเนินการ',
      number: 'หมายเลขบัตร',
      PLATFORM: 'แพลตฟอร์ม',
      resetBal: 'รีเซ็ตยอดคงเหลือ',
    },
    key: { from: 'จาก', to: 'ไปยัง' },
    fund: {
      must: 'จำเป็น',
      infoHover: 'ชื่อบนบัตรของท่าน{ต้อง}ตรงกับชื่อในบัญชีซื้อขายของท่าน',
      ccUploadReminder: 'โปรดอัปโหลดรูปภาพหน้าบัตรของท่านสำหรับการตรวจสอบ (กรุณาปิดตัวเลข 6 หลักตรงกลาง)',
    },
    button: {
      bkToHm: 'กลับสู่หน้าหลัก',
      smsLogin: 'เข้าสู่ระบบด้วยมือถือ',
      goToIB: 'ไปยัง IB พอร์ทัล',
      logout: 'ออกจากระบบ',
      submit: 'ส่ง',
      selectFile: 'เลือกไฟล์',
      depositFunds: 'ฝากเงิน',
      withdrawFunds: 'ถอนเงิน',
      ibPortal: 'IB พอร์ทัล',
      download: 'ดาวน์โหลด',
      changeLeverage: 'ยืนยันการแก้ไข',
      cancel: 'ยกเลิก',
      changePw: 'เปลี่ยนรหัสผ่าน',
      forgetPw: 'ลืมรหัสผ่าน',
      confirm: 'ยืนยัน',
      login: 'เข้าสู่ระบบ',
      forgotPw: 'ลืมรหัสผ่านหรือไม่',
      completeQuestionnaire: 'กรุณาตอบคำถามให้เสร็จสมบูรณ์',
      identityProof: 'กรุณากรอกคำร้องให้เสร็จสมบูรณ์',
      upload: 'อัปโหลด',
      export: 'ส่งออก',
      ok: 'ตกลง',
      hide: 'ซ่อน',
      showAll: 'แสดงทั้งหมด',
      createLiveAccount: `เปิดบัญชีจริง`,
      redeem: `แลกรับ`,
      sendCode: 'ส่งรหัส',
      resendCode: 'ส่งรหัสอีกครั้ง',
    },
    formValidation: {
      accReq: 'กรุณากรอกหมายเลขบัญชี',
      amtReq: 'กรุณากรอกจำนวนเงิน',
      ccReq: 'กรุณากรอกหมายเลขบัตรเครดิต',
      cardReq: 'กรุณากรอกหมายเลขบัตร',
      cardOnNameReq: 'กรุณากรอกชื่อผู้ถือบัตร',
      cardholderNameReq: `ต้องระบุชื่อผู้ถือบัตร`,
      digits16: 'กรุณากรอกตัวเลข 16 หลัก',
      digits3or4: 'กรุณากรอกตัวเลข 3 หรือ 4 หลัก',
      digitsFirst6: 'กรุณากรอกตัวเลข 6 หลักแรก',
      digitsLast4: 'กรุณากรอกตัวเลข 4 หลักสุดท้าย',
      digitsLast3: 'กรุณากรอกตัวเลข 3 หลักสุดท้าย',
      mid6Digits: 'กรุณากรอกตัวเลข {number} หลักตรงกลาง',
      expDateReq: 'กรุณากรอกวันหมดอายุของบัตร',
      cvvReq: 'กรุณากรอกรหัสความปลอดภัย CVV',
      file: 'โปรดอัปโหลดไฟล์',
      filePhoto: 'ต้องแนบภาพถ่ายบัตรเครดิต',
      fileReceipt: 'กรุณาอัปโหลดใบเสร็จ',
      filePhotoReq: 'ต้องแนบภาพถ่ายบัตร',
      amtLarger: 'จำนวนต้องไม่เป็น 0 และต้องมากกว่าหรือเท่ากับ {minLimit} {currency}',
      amt0: 'จำนวนไม่สามารถเป็น 0 ',
      amtLess: 'จำนวนเงินฝากต้องไม่มากกว่า {maxLimit} {currency}',
      note256: 'หมายเหตุ จำกัดความยาวที่ 256 ตัวอักษร',
      noteMoreThan: 'หมายเหตุ จำกัดความยาวที่ {limit} ตัวอักษร',
      noteLessThan: 'หมายเหตุ จำกัดความยาวที่ {limit} ตัวอักษร',
      emailFormat: 'กรุณากรอกที่อยู่อีเมลที่ถูกต้อง',
      payMethodReq: 'ต้องระบุวิธีชำระเงิน',
      expReq: 'กรุณากรอกวันหมดอายุ',
      bankNameReq: 'กรุณากรอกชื่อธนาคาร',
      bankCityReq: 'กรุณากรอกเมืองของธนาคาร',
      bankProvinceReq: 'กรุณากรอกจังหวัด',
      bankAddressReq: 'กรุณากรอกที่อยู่ธนาคาร (สำนักงานใหญ่)',
      bankBranchReq: 'กรุณากรอกสาขาธนาคาร (สำนักงานใหญ่)',
      bankAccNumReq: 'กรุณากรอกหมายเลขบัญชีธนาคาร',
      bankBeneficiaryNameReq: 'กรุณากรอกชื่อบัญชีผู้รับเงิน',
      bankHolderAddressReq: 'กรุณากรอกที่อยู่เจ้าของบัญชี',
      swiftReq: 'กรุณากรอกรหัสธนคาร Swift code',
      accHolderAddress: 'กรุณากรอกที่อยู่เจ้าของบัญชี',
      bsbReq: 'กรุณากรอกหมายเลข BSB',
      accNumIBANReq: 'กรุณากรอกหมายเลขบัญชีธนาคาร/IBAN',
      accNameReq: 'กรุณากรอกชื่อบัญชีธนาคาร',
      withdrawMethodReq: 'กรุณาเลือกวิธีการถอนเงิน',
      tnc: 'กรุณาคลิกที่ช่องว่าง',
      currPwReq: 'กรุณากรอกรหัสผ่านปัจจุบัน',
      currPwWrong: 'รหัสผ่านปัจจุบันไม่ถูกต้อง',
      newPwReq: 'กรุณากรอกรหัสผ่านใหม่',
      newPwFormat:
        'รหัสผ่านต้องมี 8-16 อักขระ และมีการผสมระหว่างตัวอักษร (a-z และ A-Z) หมายเลข (0-9) และ ตัวอักษรพิเศษ เช่น !@#$%^&*.()',
      IdenficalPw: 'รหัสผ่านใหม่ไม่สามารถซ้ำกับรหัสผ่านเก่าได้',
      confirmPwReq: 'โปรดใส่รหัสผ่านใหม่อีกครั้ง',
      confirmNotMatch: 'รหัสผ่านไม่ตรงกัน',
      pwReq: 'กรุณากรอกรหัสผ่าน',
      accCurrReq: 'โปรดเลือกสกุลเงินของบัญชีซื้อขาย',
      tradAccReq: 'โปรดเลือกบัญชีซื้อขาย',
      answerReq: 'โปรดเลือก',
      bankCardReq: 'กรุณาเลือกบัตร',
      phoneNumberReq: 'กรุณากรอกหมายเลขโทรศัพท์ของธนาคาร',
      nationaIdReq: 'ต้องแนบบัตรประชาชน',
      numberReq: 'กรุณากรอกเลข',
      alphanumericReq: 'โปรดกรอกตัวอักษรและตัวเลข',
      idTypeReq: 'โปรดเลือกประเภท ID',
      dobReq: 'กรุณาเลือกวันเกิด',
      pobReq: 'กรุณาเลือกสถานที่เกิด',
      nationalityReq: 'กรุณาเลือกสัญชาติ',
      titleReq: 'กรุณาเลือกคำนำหน้าชื่อ',
      firstNameReq: 'กรุณากรอกชื่อ',
      lastNameReq: 'กรุณากรอกนามสกุล',
      emailReq: 'กรุณากรอกอีเมล',
      addressReq: 'กรุณากรอกที่อยู่',
      suburbReq: 'กรุณาระบุเมืองหรือเขต',
      stateReq: 'กรุณากรอกจังหวัด',
      postcodeReq: 'กรุณากรอกรหัสไปรษณีย์',
      countryReq: 'โปรดระบุประเทศ',
      masterCardReq: 'โปรดใช้ Master Card เพื่อฝากเงิน',
      masterCardJCBReq: `กรุณาใช้ Master Card / JCB ในการฝากเงิน`,
      masterCardVisaReq: `กรุณาใช้ Master Card / Visa ในการฝากเงิน`,
      masterCardAmexJCBReq: `กรุณาใช้ Master Card / AMEX / JCB ในการฝากเงิน`,
      bankCodeReq: 'กรุณาระบุธนาคารผู้ออกบัตร',
      dynamicReq: '{dynamic} เป็นสิ่งจำเป็น',
      mobReq: 'ต้องระบุหมายเลขโทรศัพท์',
      resetBalAmt: 'โปรดกำหนดจำนวนเงินระหว่าง 0 ถึง 1,000,000',
      verCodeReq: 'จำเป็นต้องมีรหัสการยืนยัน',
      emailFormatWrong: 'รูปแบบอีเมลไม่ถูกต้อง',
      passwordFormatWrong: 'รูปแบบรหัสผ่านไม่ถูกต้อง',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: 'โอนเงินผ่านธนาคารระหว่างประเทศ/BPay/PoliPay',
      banktransfer: 'โอนเงินผ่านธนาคารระหว่างประเทศ',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'โอนผ่าน UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'บัตรเครดิต',
      bpay: 'Bpay',
      creditdebitcard: 'บัตรเครดิต/เดบิต',
      bankwiretransferinternational: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      banktransferaustralia: 'โอนเงินผ่านธนาคารระหว่างประเทศ(ออสเตรเลีย)',
      banktransferinternational: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      banktransferchina: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      banktransferequals: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'โอนเงินระหว่างโบรกเกอร์',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (ธนาคารในประเทศไทย)',
      internetbankingnigeria: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(ไนจีเรีย)',
      internetbankingvietnam: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(เวียดนาม)',
      internetbankingmalaysia: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(มาเลเซีย)',
      internetbankingindonesia: 'ธุรกรรมผ่านอินเตอร์เน็ต (อินโดนีเซีย)',
      internetbankingkorea: 'บริการธนาคารทางอินเทอร์เน็ต (เกาหลี)',
      internetbankingphilippine: 'ธุรกรรมผ่านอินเตอร์เน็ต (ฟิลิปปินส์)',
      internetbankingsouthafrica: 'ธุรกรรมผ่านอินเตอร์เน็ต (แอฟริกาใต้)',
      internetbankinguganda: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (ยูกันดา)',
      internetbankingrwanda: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (รวันดา)',
      internetbankingzambia: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แซมเบีย)',
      internetbankingcongo: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (คองโก)',
      internetbankingcameroon: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แคเมอรูน)',
      internetbankingburundi: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (บุรุนดี)',
      internetbankingkenya: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (เคนยา)',
      internetbankingghana: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (กานา)',
      internetbankingtanzania: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แทนซาเนีย)',
      internetbankingcanada: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แคนาดา)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'โอนเงินผ่านธนาคารเวียดนาม',
      nigeriabanktransfer: 'โอนเงินผ่านธนาคารไนจีเรีย',
      polipayment: 'ชำระเงินผ่าน POLi',
      bankwiretransferaustralia: 'โอนเงินผ่านธนาคาร(ออสเตรเลีย)',
      thailandinstantbankwiretransfer: 'อินเตอร์เน็ต แบงค์กิ้ง',
      nigeriainstantbankwiretransfer: 'โอนเงินผ่านธนาคารไนจีเรีย',
      vietnaminstantbankwiretransfer: 'โอนเงินผ่านธนาคารเวียดนาม',
      malaysiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารมาเลเซีย',
      indonesiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารอินโดนีเซีย',
      southkoreabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศเกาหลีใต้',
      southafricainstantbankwiretransfer: 'การโอนเงินผ่านธนาคารทันทีของแอฟริกาใต้',
      philippinesinstantbankwiretransfer: 'โอนเงินผ่านธนาคารฟิลิปปินส์',
      ugandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศยูกันดา',
      rwandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศรวันดา',
      zambiabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแซมเบีย',
      congobanktransfer: 'โอนเงินผ่านธนาคารในประเทศคองโก',
      cameroonbanktransfer: 'โอนเงินผ่านธนาคารในประเทศแคเมอรูน',
      burundibanktransfer: 'โอนเงินผ่านธนาคารในประเทศบุรุนดี',
      kenyabanktransfer: 'โอนเงินผ่านธนาคารในประเทศเคนยา',
      ghanabanktransfer: 'โอนเงินผ่านธนาคารในประเทศกานา',
      tanzaniabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแทนซาเนีย',
      indiabanktransfer: 'โอนเงินผ่านธนาคารอินเดีย',
      banktransferbpaypolipayaustralia: 'โอนเงินผ่านธนาคารระหว่างประเทศ/BPay/PoliPay(ออสเตรเลีย)',
      banktransferbpaypolipayinternational: 'โอนเงินผ่านธนาคารระหว่างประเทศ/BPay/PoliPay (ระหว่างประเทศ)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'โอนเงินผ่านธนาคารในประเทศแคนาดา',
      cryptobitcoin: 'สกุลเงินดิจิตัล Crypto-Bitcoin',
      cryptotetheromni: 'สกุลเงินดิจิตัล Crypto-Tether(OMNI)',
      cryptotethererc20: 'สกุลเงินดิจิตัล Crypto-Tether(ERC20)',
      cryptotethertrc20: 'สกุลเงินดิจิตัล Crypto-Tether(TRC20)',
      cryptocurrencybitcoin: 'สกุลเงินดิจิตัล Crypto-Bitcoin',
      cryptocurrencyusdt: 'สกุลเงินดิจิตัล Crypto--USDT',
      cryptocurrencyeth: 'สกุลเงินดิจิตัล Crypto-ETH',
      cryptocurrencyusdc: 'สกุลเงินดิจิตัล Crypto-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'สกุลเงินดิจิตัล Crypto-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'การโอนเงินในท้องถิ่น',
      archiveThisCard: 'โปรดเก็บบัตรใบนี้อย่างถาวรหากบัตรใช้งานไม่ได้แล้ว',
      manageCreditCards: 'จัดการบัตรเครดิต',
      update: 'อัปเดต',
      archive: 'เก็บถาวร',
      requiredDocument: 'เอกสารที่จำเป็นกรณีบัตรไม่พร้อมใช้งาน',
      officialLetter: 'จดหมายธนาคาร/อีเมลอย่างเป็นทางการที่ระบุว่าบัตรไม่สามารถใช้ได้อีกต่อไป',
      bankStatement: 'รายการธุรกรรมที่ทำรายการด้วยบัตรใบนี้',
      expiredCard: 'บัตรหมดอายุ/ไม่พร้อมใช้งาน',
      confirmArchive: 'คุณแน่ใจหรือไม่ว่าต้องการเก็บบัตรใบนี้',
      confirmUpdate:
        'คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนแปลงวันหมดอายุของบัตรนี้ หมายเหตุ: Puprime จะไม่รับผิดชอบหากการถอนเงินล้มเหลวเนื่องจากวันหมดอายุไม่ถูกต้อง',
      pendingArchiveRequest:
        'คำร้องขอเก็บบัตรเครดิตของคุณเข้าแฟ้มถาวรกำลังรอดำเนินการอนุมัติอยู่ในขณะนี้ กรุณารอให้กระบวนการนี้ดำเนินการเสร็จสิ้นก่อนดำเนินการถอนเงิน หรือติดต่อฝ่ายบริการลูกค้าของเราผ่านทางไลฟ์แชทเพื่อเร่งกระบวนการดังกล่าว',
    },
  },
  home: {
    pendingTooltip:
      'หลังจากบัญชีซื้อขายจริงของท่านได้รับการอนุมัติแล้ว ท่านจะได้รับอีเมลหมายเลขบัญชี MT{number} และรายละเอียดการเข้าถึง และสามารถเริ่มเทรดได้',
    leverage: {
      tnc:
        '<p>- ในการส่งคำร้องนี้ ฉันรับทราบและเข้าใจดีว่าการใช่เลเวอเรจที่สูงนั้น ส่งผลให้เงินทุนของฉันมีความเสี่ยงสูง อาจทำให้เกิดได้ทั้งผลกำไรและการขาดทุน รวมไปถึงสามารถทำให้ฉันสูญเสียเงินต้นไป<br/>  - ฉันยินยอมให้ผู้ออกแบบผลิตภัณฑ์มีสิทธิ์ตรวจสอบกิจกรรมการซื้อขายของฉันได้ตามเห็นสมควร และให้สิทธิ์ปรับระดับเลเวอเรจของฉันได้โดยไม่การเตือนหรือแจ้งให้ทราบล่วงหน้า<br/> - ฉันเข้าใจใจดีว่าเลเวอเรจที่สูงสามารถทำให้ฉันระดับมาร์จิ้นที่สูงขึ้น แต่ก็อาจทำให้ฉันเกิดความสูญเสียได้มากเช่นกัน</p>',
      tncConfirm: 'ฉันได้อ่านและยอมรับเงื่อนไขข้อตกลง',
      changeLeverageConfirm: 'คำร้องของท่านถูกส่งแล้วและทีมสนับสนุนของเราจะดำเนินการเร็ว ๆ นี้',
      changeLeverageAutoComplete: 'คำขอเปลี่ยนแปลงเลเวอเรจของท่านสำเร็จแล้ว',
      changeLeverageReject: 'คำขอเปลี่ยนแปลงเลเวอเรจถูกปฏิเสธ',
      changeLeverageFail: 'คำขอเปลี่ยนแปลงเลเวอเรจล้มเหลว โปรดลองอีกครั้งภายหลัง',
      changeLeverageDefault: 'เกิดข้อผิดพลาดบางประการ กรุณาติดต่อ {supportEmail} เพื่อดำเนินการสมัครให้เสร็จสิ้น',
      alreadyApplied:
        'ท่านได้ยื่นคำขอเปลี่ยนแปลงเลเวอเรจสำหรับบัญชีของท่านแล้วและกำลังอยู่ในขั้นตอนการตรวจสอบโดยฝ่ายที่เกี่ยวข้องของ {platform} โปรดรอให้คำขอนี้เสร็จสิ้นก่อนส่งคำขออื่น ๆ',
      reduceLeverageWarn: `โปรดทราบ: การลดเลเวอเรจของคุณอาจจะส่งผลให้มีการบังคับปิดสถานะที่เปิดอยู่หากบัญชีของคุณมีเงินประกันไม่เพียงพอ`,
      reduceLeverageConfirm: `คุณต้องการดำเนินการต่อหรือไม่?`,
      autoRestore: `เนื่องจากยอดคงเหลือของบัญชีเกินกว่า USD 20,000 เลเวอเรจสูงสุดที่มีสิทธิ์ใช้งานคือ 500:1 คุณสามารถเปลี่ยนเลเวอเรจเป็น 1,000:1 เมื่อยอดคงเหลือของบัญชีน้อยกว่า หรือเท่ากับ USD 20,000`,
    },
    pw: {
      error: 'การเปลี่ยนรหัสผ่านไม่สำเร็จ โปรดลองอีกครั้งภายหลัง',
      confirm: 'รหัสผ่านของท่านถูกเปลี่ยนแล้ว <br> ท่านสามารถใช้รหัสผ่านใหม่ของท่านเพื่อเข้าถึงบัญชีซื้อขาย MT4/MT5',
      email:
        '<p class="first_info">เราได้ทำการส่งขั้นตอนการเปลี่ยนรหัสผ่านไปให้ท่านทางอีเมลแล้ว</p><p>โปรดตรวจสอบโฟลเดอร์สแปมของอีเมลอีกครั้ง</p>',
    },
    activate: {
      question: 'ท่านต้องการเปิดใช้งานบัญชีซื้อขายจริงของท่านใหม่อีกครั้งใช่หรือไม่',
      confirm: 'เราได้รับคำขอจากท่านให้เปิดใช้งานบัญชี <br>บัญชีของท่านจะถูกเปิดใช้งานเร็ว ๆ นี้',
    },
    accStatus: {
      active: 'ใช้งาน',
      rejected: 'ถูกปฏิเสธ',
      inactive: 'ระงับการใช้งาน',
      processing: 'กำลังดำเนินการ',
      activate: 'เปิดใช้งาน',
      expired: 'หมดอายุแล้ว',
    },
    pro1000: {
      desc1: `มีการใช้งานข้อจำกัดดังต่อไปนี้กับบัญชีที่มีเลเวอเรจ 1,000:1:`,
      conditions: [
        `บัญชีที่เลือกไม่มีสิทธิ์เข้าร่วมโปรโมชั่นที่เกี่ยวข้องกับเครดิต`,
        `เลเวอเรจอาจลดลงเหลือ 1:500 ด้วยระบบโดยอัตโนมัติ หากยอดคงเหลือของบัญชีที่เลือกเกินกว่า USD20,000 หรือเทียบเท่า หากมีการซื้อขายที่เปิด เราจะทำการส่งอีเมลแจ้งเตือนก่อนการปรับเลเวอเรจ`,
        `ข้อจำกัดของเลเวอเรจอาจแตกต่างกันไปตามระเบียบข้อบังคับที่เกี่ยวข้อง`,
        `โปรดทราบว่า ไม่สามารถใช้งานเลเวอเรจ 1:1,000 กับตราสารทางการเงินที่เป็นของ สกุลเงินดิจิตอล หุ้น โลหะเงิน และสินค้าโภคภัณฑ์ได้ มาร์จิ้นสำหรับตราสารเหล่านี้เป็นไปตามข้อกำหนดมาร์จิ้นคงที่ของตราสาร และไม่ได้รับผลกระทบจาก 1: เลเวอเรจ 1,000`,
      ],
      desc2: `จากการส่งคำร้องขอนี้ ข้าพเจ้ารับทราบเป็นอย่างดีว่า เลเวเรจที่สูงทำให้เกิดความอย่างมากต่อเงินทุนของข้าพเจ้า และมีความเป็นไปได้ที่ข้าพเจ้จะต้องยอมรับการขาดทุนจำนวนมากกว่าจำนวนเงินที่ข้าพเจ้าได้ฝากไว้`,
      desc3: `ข้าพเจ้ายอมรับว่าผู้ให้บริการของผลิตภัณฑ์มีสิทธิ์ตรวจสอบกิจกรรมการซื้อขาย และปรับระดับเลเวอเรจในบัญชีซื้อขายของข้าพเจ้า ตามดุลยพินิจของผู้ให้บริการของผลิตภัณฑ์ และโดยไม่ต้องมีคำเตือนหรือการแจ้งเตือนล่วงหน้าแต่อย่างใด`,
      desc4: `ข้าพเจ้าเข้าใจว่า การเลือกเลเวอเรจสูงทำให้ข้าพเจ้าสามารถซื้อขายด้วยมาร์จิ้นที่มากขึ้น และอาจประสบกับการขาดทุนจำนวนมากได้`,
    },
  },
  paymentDetails: {
    subHeader: 'เพิ่มวิธีการชำระเงิน',
    payMethod: 'วิธีการชำระเงิน',
    tip:
      '<p>เลือกวิธีการชำระเงินของท่านสำหรับการฝากถอนเงินในอนาคตจากตัวเลือกด้านล่าง</p><br />\n    <p><b>โปรดทราบ:</b> <p><b>โปรดทราบ:</b> เพื่อให้แน่ใจว่ารายการฝากถอนของท่านจะถูกดำเนินการทันที ตามข้อบังคับของ AML/CTF กรุณาใส่ชื่อที่แสดงในขั้นตอนการชำระเงินที่ท่านเลือก\n      <b> {individualUserName}</b></p>',
    result: {
      defaultSuccessMessage: 'ขอบคุณสำหรับการส่งช่องทางการฝากเงิน เรากำลังดำเนินการคำขอของท่านและจะติดต่อกลับโดยเร็ว',
      unionPayCardSuccessMessage:
        'ข้อมูลของบัตร Union Pay ได้ถูกส่งไปแล้ว รายละเอียดธนาคารของท่านจะสามารถใช้ได้หลังจากได้รับการอนุมัติ',
    },
    history: { header: 'วิธีการชำระเงิน:' },
    cc: {
      field: {
        ccNum: 'หมายเลขบัตรเครดิต',
        first6: '6 หลักแรก',
        last4: '4 หลักสุดท้าย',
        withrawlable: 'จำนวนที่ถอนได้สูงสุดสำหรับบัตรนี้คือ {amount}',
      },
      formValidation: { upload: 'ต้องแนบภาพถ่ายบัตรเครดิต' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'กำลังเรียกสถานะการฝากเงินของท่าน... เหลืออีก {countDown} วินาที',
      successMsg: 'ขอบคุณสำหรับการฝากเงินของท่าน เงินจะปรากฏในบัญชีของท่านอีกสักครู่',
      rejectMsg: 'ถูกปฏิเสธ',
      failureMsg: 'การฝากเงินของท่านไม่สำเร็จ โปรดลองอีกครั้ง',
      message:
        'ขอบคุณสำหรับการฝากเงิน การฝากเงินของท่านจะถูกยืนยันโดยทีมบัญชีโดยเร็ว {platform}  เงินจะเข้าสู่บัญชีของท่านหลังจากได้รับการยืนยัน',
      defaultException: 'เพื่อยืนยันการฝากเงินของท่านกับ {platform} กรุณาติดต่อฝ่ายสนับสนุนลูกค้า {email}',
    },
    failed: { countDownMsg: 'กำลังเรียกสถานะการฝากเงินของท่าน... เหลืออีก {countDown} วินาที', rejectMsg: 'ถูกปฏิเสธ' },
  },
  deposit: {
    alert: {
      depositBonus50: `คุณมีสิทธิ์ได้รับโบนัสเงินฝาก 50% เปิดใช้งานก่อนฝากเงิน!`,
    },
    default: {
      deposit: 'ฝากเงิน',
      reference: 'หลักฐานอ้างอิงของท่าน',
      sendReceipt: 'ส่งใบเสร็จรับเงิน',
      sendReceiptTip:
        'เมื่อท่านโอนเงินสำเร็จแล้ว กรุณากรอกแบบฟอร์มด้านล่างและแนบรูปถ่ายสำเนาใบเสร็จการโอนเงิน รูปถ่ายต้องชัดและแสดงชื่อเต็ม หมายเลขบัญชี และ ยอดเงินฝาก เพื่อที่เราจะสามารถดำเนินการธุรกรรมของท่านได้',
      reminder: 'เงินจะเข้าสู่บัญชีของท่านภายใน 1-2 วันทำการ',
      successMsg: 'เงินจะปรากฎในบัญชีของท่านหลังจากที่เราได้รับเงินฝากและตรวจสอบใบเสร็จการทำธุรกรรมของท่าน',
      alt: 'อีกทางเลือกหนึ่ง ท่านสามารถส่งอีเมลรายละเอียดการทำธุรกรรมของท่านไปยัง {mailTo} ',
      reminderFundAppear: 'เงินของท่านจะปรากฎในบัญชีซื้อขายทันทีที่เราได้รับเงินจากโบรกเกอร์ที่ท่านใช้บริการก่อนหน้า',
      failed: 'การฝากเงินล้มเหลว โปรดลองอีกครั้ง',
      fee: 'ค่าธรรมเนียม',
      instant: 'ทันที',
      hour: 'ชั่วโมง',
      hours: 'ชั่วโมง',
      businessDays: 'วันทำการ',
      withinOneBusinessDays: 'ภายใน 1 วันทำการ',
      secondaryNGN: '(คุณสามารถใช้หมายเลขบัญชีนี้สำหรับธุรกรรมนี้ได้ หากคุณต้องการใช้สกุลเงินของไนจีเรีย (NGN) แทน)',
      disclaimer: `<li>All your payments to {platform} are confidential and are only used for deposit and withdrawal in your trading account of {platform}. All E-payments are processed by SSL (Secure Socket Layer) technology and encrypted to ensure the security.</li>
      <li>{platform} strictly doesn't accept payments from any third-party. All funds deposited into your trading account must be under the same name as your {platform} client portal account.</li>
      <li>Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>
      <li>{platform} will not be responsible for any delays or errors caused by payments systems.</li>
      <li>{platform} will not be responsible any fees that the sending financial institution or third-party payment providers may charge you for sending payments.</li>
      <li>Clients acknowledge that they may be required to provide information on the source(s) of the deposit(s), the Clients’ wealth in general and any other information {platform} deems necessary to identify the source of funds when processing a deposit request and agree to provide all information required by {platform}. Failure to provide the required information may result in significant delays in the process of your requests. {platform} will not be responsible for any delays or losses suffered as results of that delay.</li>`,
      channels: {
        creditOrDebit: 'บัตรเครดิต/เดบิต',
        i12BankTransfer: 'โอนเงินผ่านธนาคารระหว่างประเทศ',
        unionPay: 'ฝากเงินผ่าน Union Pay',
        unionPayCN: 'ฝากเงินผ่าน China Union Pay',
        vietInstant: 'โอนเงินผ่านธนาคารเวียดนาม',
        thaiInstant: 'อินเตอร์เน็ต แบงค์กิ้ง',
        nigeriaInstant: 'โอนเงินผ่านธนาคารไนจีเรีย',
        malayInstant: 'การโอนเงินผ่านธนาคารมาเลเซีย',
        indonesiaInstant: 'การโอนเงินผ่านธนาคารอินโดนีเซีย',
        philippineInstant: 'โอนเงินผ่านธนาคารฟิลิปปินส์',
      },
      rate: {
        rate: 'อัตราแลกเปลี่ยน ({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'การเรียกข้อมูลอัตราแลกเปลี่ยน {oldCurrency} ต่อ {newCurrency} ล้มเหลว',
          channelError: 'การเรียกช่องทางการชำระเงินล้มเหลว',
        },
      },
      cn: { rate: 'อัตราแลกเปลี่ยน (USD/RMB): ', amt: 'หยวนจีน :' },
      upgrade:
        'ขณะนี้ช่องทางการชำระเงินอยู่ระหว่างการบำรุงรักษาและให้บริการเฉพาะผู้ใช้ที่ฝากเงินผ่านช่องทางนี้มาก่อนเท่านั้น',
      depositInstruction: 'คำแนะนำการฝากเงิน',
      depositNote: 'ฝากเงินตอนนี้',
    },
    method: {
      desc: 'วิธีการฝากเงินเข้าบัญชีของคุณด้วย{method}',
      inst1: `{number}.   กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'`,
      inst2: '{number}.   โปรดเลือกธนาคารในหน้าเลือกธนาคาร และเข้าสู่ระบบธุรกรรมออนไลน์ของธนาคาร{country}ของท่าน',
      inst3: '{number}.   ปฏิบัติตามคำแนะนำและชำระเงินผ่านธุรกรรมออนไลน์ / บนมือถือของธนาคารของท่าน',
      inst4: '{number}.   จากนั้นท่านจะถูกนำไปยังหน้าแบบฟอร์มเพื่อให้กรอกรายละเอียดบัตรของท่าน',
      inst5: `{number}.   หลังจากที่กรอกรายละเอียดบัตรของท่านแล้ว ให้กด 'ส่ง'`,
      inst6: '{number}.   ท่านจะถูกนำกลับมายังหน้าต่าง Client Portal ธุรกรรมของท่านจะถูกดำเนินการ',
      inst7: `{number}.   หากสกุลเงินของคุณไม่ใช่ {currency} ธนาคารอาจเรียกเก็บค่าธรรมเนียมการแปลงจากคุณตามอัตราแลกเปลี่ยนของธนาคารในปัจจุบัน`,
      inst8: `{number}.   การฝากเงินด้วยบัตรเครดิตโดยใช้ EUR, GBP, AUD, SGD, NZD, HKD, JPY และ USD จำกัด ไว้ที่ไม่เกิน $ 5,000USD ต่อธุรกรรม การฝากเงินด้วยบัตรเครดิตในสกุลเงิน CAD สามารถรับได้ถึง $ 6,500CAD ต่อธุรกรรม`,
      inst9: `{number}.   สูงสุด $1,500 USD ต่อการฝาก`,
      inst10: `{number}.   หน้ารายละเอียดการชำระเงินจะปรากฏขึ้น`,
      inst11: `{number}.   โปรดเลือกธนาคารในหน้าเลือกธนาคาร และเข้าสู่ระบบธุรกรรมออนไลน์ของธนาคารของท่าน`,
      inst12: `{number}.    รายอมรับการชำระเงินในสกุลเงิน CAD. สูงสุด $9,999 CAD ต่อการฝาก`,
      form: {
        header: 'แบบฟอร์ม{method}',
      },
    },
    reminder: {
      inst1: `กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'`,
      inst2: 'เข้าสู่บัญชี {method} ของท่าน',
      inst3: 'ดำเนินการตามการแจ้งเตือนในบัญชี {method} เพื่อให้การชำระเงินเสร็จสมบูรณ์',
      inst4: 'จากนั้นท่านจะถูกนำไปยังหน้าแบบฟอร์มเพื่อให้กรอกรายละเอียดบัตรของท่าน',
      inst5: `หลังจากที่กรอกรายละเอียดบัตรของท่านแล้ว ให้กด 'ส่ง' `,
      inst6: 'ท่านจะถูกนำกลับมายังหน้าต่าง Client Portal ธุรกรรมของท่านจะถูกดำเนินการ ',
      inst7: 'คุณจะถูกนำไปยังหน้าต่างใหม่ที่แสดงที่อยู่บัญชี {method}',
      inst8:
        ' คัดลอกที่อยู่บัญชี {method} จากนั้นเข้าสู่ระบบบัญชี {method} ของคุณ และทำการโอนย้ายจำนวนเงินทที่ต้องการไปยังที่อยู่บัญชี {method} ที่คัดลอกมา',
      inst9: 'สแกน QR code เพื่อเปิดแอปพลิเคชันธุรกรรมออนไลน์บนมือถือของท่าน',
      inst10: 'ดำเนินการตามขั้นตอนที่ปรากฎบนอุปกรณ์มือถือของท่านเพื่อชำระเงินให้เสร็จสมบูรณ์',
      inst11:
        'โปรดตรวจสอบให้แน่ใจว่าได้ชำระเงินทางออนไลน์ผ่านทางหน้าการชำระเงินที่ถูกเปลี่ยนเส้นทางเพื่อหลีกเลี่ยงการสูญหายของเงิน',
      inst12: 'โปรดเลือกธนาคารในหน้าเลือกธนาคาร และเข้าสู่ระบบธุรกรรมออนไลน์ของธนาคารของท่าน',
      inst13: 'ปฏิบัติตามคำแนะนำและชำระเงินผ่านธุรกรรมออนไลน์ / บนมือถือของธนาคารของท่าน',
      inst14:
        'เมื่อท่านทำการโอนสำเร็จแล้ว โปรดกรอกแบบฟอร์มด้านล่างและแนบสำเนาใบเสร็จการโอนเงินที่แสดงชื่อเต็ม หมายเลขบัญชี และยอดเงินฝากอย่างชัดเจน เพื่อให้เราสามารถดำเนินการธุรกรรมของท่าน',
      inst15: 'เงินจะเข้าสู่บัญชีของท่านภายใน 2-5 วันทำการ',
      inst16: 'โปรดรอ 1 วันทำการเพื่อให้เงินเข้าไปยังบัญชีของคุณ',
      inst17:
        'โปรดตรวจสอบให้แน่ใจว่า ชื่อ นามสกุล หมายเลขบัญชี และจำนวนเงินฝากแสดงให้เห็นได้อย่างชัดเจน เพื่อที่เราจะสามารถดำเนินการทำธุรกรรมได้',
      inst18: 'โปรดรอ 1-6 ชั่วโมงเพื่อให้เงินทุนเพิ่มเข้าไปยังบัญชีของคุณ',
      note1: '{method} เงินฝากจำกัดอยู่ที่ $25,000 USD ต่อธุรกรรมหนึ่งครั้ง และจำนวนสูงสุด $75,000 USD ต่อวัน',
    },
    southAfrica: { header: 'การโอนเงินผ่านธนาคารทันทีของแอฟริกาใต้', country: 'แอฟริกาใต้' },
    idn: { header: 'การโอนเงินผ่านธนาคารอินโดนีเซีย', country: 'อินโดนีเซีย' },
    ida: { header: 'อินเดียโอนเงินผ่านธนาคารทันที', country: 'อินเดีย' },
    nigeria: { header: 'โอนเงินผ่านธนาคารไนจีเรีย', country: 'ไนจีเรีย' },
    thailand: { header: 'อินเตอร์เน็ต แบงค์กิ้ง', country: 'ประเทศไทย' },
    malay: { header: 'การโอนเงินผ่านธนาคารมาเลเซีย', country: 'มาเลเซีย' },
    php: { header: 'โอนเงินผ่านธนาคารทันทีของฟิลิปปินส์', country: 'ฟิลิปปินส์' },
    vietnam: { header: 'โอนเงินผ่านธนาคารเวียดนาม', country: 'เวียดนาม' },
    korea: { header: 'การโอนเงินผ่านธนาคารในประเทศเกาหลีใต้', country: 'เกาหลีใต้' },
    uganda: { header: 'การโอนเงินผ่านธนาคารในประเทศยูกันดา', country: 'ยูกันดา' },
    rwanda: { header: 'การโอนเงินผ่านธนาคารในประเทศรวันดา', country: 'รวันดา' },
    zambia: { header: 'โอนเงินผ่านธนาคารในประเทศแซมเบีย', country: 'แซมเบีย' },
    congo: { header: 'โอนเงินผ่านธนาคารในประเทศคองโก', country: 'คองโก' },
    cameroon: { header: 'โอนเงินผ่านธนาคารในประเทศแคเมอรูน', country: 'แคเมอรูน' },
    burundi: { header: 'โอนเงินผ่านธนาคารในประเทศบุรุนดี', country: 'บุรุนดี' },
    kenya: { header: 'โอนเงินผ่านธนาคารในประเทศเคนยา', country: 'เคนยา' },
    ghana: { header: 'โอนเงินผ่านธนาคารในประเทศกานา', country: 'กานา' },
    tanzania: { header: 'โอนเงินผ่านธนาคารในประเทศแทนซาเนีย', country: 'แทนซาเนีย' },
    creditOrDebit: {
      header: 'บัตรเครดิต',
      worldcard: {
        note1:
          'การฝากเงินผ่านบัตรเครดิตด้วยสกุลเงิน EUR, GPB และ USD จะถูกจำกัดวงเงินสูงสุดที่ $20,000USD ต่อธุรกรรมหนึ่งครั้ง การฝากเงินผ่านบัตรเครดิตด้วยสกุลเงิน EUR, GPB และ USD จะถูกจำกัดวงเงินสูงสุดที่ $20,000USD หรือเทียบเท่าต่อการทำธุรกรรมหนึ่งครั้ง',
        note2:
          'ผู้ใช้งานหนึ่งรายสามารถใช้บัตรที่แตกต่างกันได้ 3 ใบ การฝากเงินใด ๆ ที่มีจำนวนมากกว่าขีดจำกัดในการฝากเงินจะไม่ได้รับการยอมรับ',
      },
      virtualPay: {
        note1:
          'การฝากเงินผ่านบัตรเครดิตด้วยสกุลเงิน EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC และ USD จะถูกจำกัดสูงสุดไม่เกิน 10,000 USD ต่อการทำธุรกรรมหนึ่งครั้ง',
        note2:
          'สูงสุด 50,000 USD หรือ 10 รายการต่อบัตรหนึ่งใบภายใน 24 ชั่วโมง การฝากเงินใด ๆ ที่มีจำนวนมากกว่าขีดจำกัดในการฝากเงินจะไม่ได้รับการยอมรับ',
      },
      Sdpay: {
        note1:
          'การฝากเงินผ่านบัตรเครดิตด้วยสกุลเงิน EUR, GBP และ USD จะถูกจำกัดสูงสุดไม่เกิน $2,000 USD หรือในสกุลเงินที่เทียบเท่าต่อการทำธุรกรรมหนึ่งครั้ง',
        note2: 'สูงสุด 8 รายการต่อวัน การฝากเงินใด ๆ ที่มีจำนวนมากกว่าขีดจำกัดในการฝากเงินจะไม่ได้รับการยอมรับ',
        note3:
          'โปรดทราบว่าอาจมีการระงับการชำระเงินครั้งแรกของคุณ โปรดรอขณะที่ทีมงานของเรากำลังตรวจสอบการชำระเงินของคุณ หรืออาจติดต่อทีมงานฝ่ายสนับสนุนลูกค้าของเราเพื่อขอความช่วยเหลือเพิ่มเติม',
      },
      maxisPay: {
        note1: 'การฝากเงินด้วยบัตรเครดิตโดยใช้สกุลเงิน USD นั้นจำกัดจำนวนเงินสูงสุด $5,000USD ต่อธุรกรรม',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'เราสามารถรับชำระเงินเป็น AUD, USD, EUR, CAD และ GBP',
      note2: 'สามารถฝากเงินเข้าบัญชีเป็นสกุลเงินเดียวกับบัญชีซื้อขาย MT4 ของคุณเท่านั้น',
      note3: 'Neteller จะแปลงเงินเป็นสกุลเงินหลักของบัญชีโดยอัตโนมัติ หากใช้งานอีเมลที่ไม่ถูกต้อง อาจมีค่าธรรมเนียม',
      note4: 'การเงินฝากต้องเป็นชื่อเดียวกับบัญชี STARTRADER ของคุณ',
    },
    vload: {
      header: 'ฝากเงิน VLOAD',
      inst1: '2.   เรายอมรับการชำระเงินในสกุลเงิน USD, EUR และ JPY',
      inst2: `<li>3.   สั่งซื้อบัตรกำนัล Vload โปรดลิก <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">ที่นี่</a>.</li>`,
      form: {
        voucherpin: 'รหัส PIN ของบัตรกำนัล',
        voucherpinReq: 'ต้องมีรหัส PIN ของบัตรกำนัล',
      },
    },
    bankWireAu: {
      header: 'การโอนเงินผ่านธนาคาร: ออสเตรเลีย',
      instruction:
        'เงินสามารถถูกโอนไปยัง {platform} ผ่านบริการธุรกรรมออนไลน์ของสถาบันการเงินของท่าน หรือติดต่อสถาบันการเงินของท่านและส่งคำขอการโอนเงินไปยัง {platform}',
      tip: 'เลือกสกุลเงินที่ท่านต้องการทำการฝาก โปรดแน่ใจว่าท่านเลือกสกุลเงินเดียวกันกับสกุลเงินในบัญชีซื้อขายของท่าน',
      form: {
        bankDetails: 'รายละเอียดธนาคาร (สกุลเงิน AUD เท่านั้น)',
        refTip: 'กรุณาระบุหมายเลขเข้าสู่ระบบของท่านที่นี่',
        afp: 'Australian Fast Payment (ฝากภายในประเทศออสเตรเลียเท่านั้น)',
        payIdOrABNNum: 'PayID/หมายเลข ABN ',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: 'กรุณาระบุหมายเลขบัญชีซื้อขาย {platform} ของท่านในช่องหลักฐานอ้างอิงการโอนเงินผ่านธนาคาร',
      },
    },
    bitwallet: {
      note1: 'เรารับชำระเงินเป็น USD, AUD, EUR, USC และ JPY',
    },
    astropay: {
      desc: 'วิธีการฝากเงินเข้าบัญชีของคุณกับ ASTROPAY',
      note1: 'เรายอมรับการชำระเงินในทุกสกุลเงิน',
      form: { header: 'แบบฟอร์ม ASTROPAY' },
    },
    bPay: {
      header: 'ฝากเงินผ่าน BPAY ',
      option: 'วิธีฝากเงินเข้าบัญชีผ่าน BPAY (ออสเตรเลียเท่านั้น)',
      inst1: '1. เลือกบัญชี MT4/MT5 ของท่านเพื่อดูรหัส BPay Biller และ หมายเลขอ้างอิง Bpay ',
      inst2:
        '2.ใส่รายละเอียดการชำระเงิน รหัส Bpay Biller และหมายเลขอ้างอิง Bpay ของท่านไปยังเครื่องมือการทำธุรกรรมออนไลน์ผ่านอินเทอร์เน็ตของท่านและส่งการชำระเงินของท่าน',
      inst3:
        '3. กลับมาที่หน้านี้และกรอกแบบฟอร์ม Bpay ด้านล่างเพื่อให้แน่ใจว่าเราสามารถดำเนินการธุรกรรมของท่านได้ทันที ',
      form: {
        header: 'แบบฟอร์ม BPAY',
        billerCode: 'รหัส BPay Biller',
        refNum: 'หมายเลขอ้างอิง BPAY',
        receipt: 'ใบเสร็จการโอนเงินผ่าน BPAY',
        reminderNotUploaded:
          'ท่านยังไม่ได้อัปโหลดใบเสร็จการทำธุรกรรมของท่าน เพื่อดำเนินการการฝากเงินของท่าน โปรดส่งอีเมลสำเนาใบเสร็จมายัง {mailTo} เมื่อท่านสะดวก',
        reminderProc: 'โปรดทราบ ธุรกรรมของท่านไม่สามารถดำเนินการได้จนกว่าทางเราจะได้รับใบเสร็จของท่าน',
      },
    },
    b2b: {
      header: 'โบรกเกอร์สู่โบรกเกอร์',
      option: 'วิธีโอนเงินจากโบรกเกอร์อื่นไปยัง {platform}',
      inst1: '1. ดาวน์โหลดและกรอกแบบฟอร์ม {download} ให้สมบูรณ์',
      inst2: '2. กรอกแบบฟอร์มออนไลน์ด้านล่างและอัปโหลดแบบฟอร์มการโอนเงินระหว่างโบรกเกอร์ของท่านที่สมบูรณ์แล้ว',
      inst3: '3. ส่งสำเนาฟอร์ม Broker to Broker ที่ดำเนินการเสร็จแล้วไปยัง Broker เดิมเพื่อทำการโอนย้าย  ',
      b2bForm: 'แบบฟอร์มการโอนเงินระหว่างโบรกเกอร์',
      formValidation: { upload: 'กรุณากรอกแบบฟอร์มโบรกเกอร์สู่โบรกเกอร์' },
    },
    cc: {
      header: 'บัตรเครดิต หรือ เดบิต สำหรับฝากเงิน ',
      remind: 'หากคุณประสบปัญหาในการฝากเงินในบัญชีของคุณโดยใช้วิธีการหลักโปรดใช้วิธีการเครดิต / เดบิตรอง',
      field: {
        fundNow: 'ฝากเงินตอนนี้ผ่านบัตรเครดิต/เดบิต',
        newCard: 'บัตรใหม่',
        cardNum: 'หมายเลขบัตรเครดิต',
        nameOnCard: 'ชื่อบนบัตร',
        expDate: 'วันหมดอายุ',
        cvv: 'รหัสความปลอดภัย CVV',
        bankCode: 'ธนาคารผู้ออกบัตร',
        example: `การ์ดเรียบง่าย`,
      },
      placeholder: { bankCode: 'กรุณากรอกชื่อธนาคารผู้ออกบัตรของท่าน' },
      cvvTip: 'ตัวเลข 3 หลักที่ปรากฎบนหลังบัตรของท่าน',
      threeDomainSecQ: 'บัตรของท่านรองรับ 3D-Secure หรือไม่',
      threeDomainSec:
        'การรับรองความถูกต้องแบบ 3D Secure คืออะไร <br /><strong> การรับรองความถูกต้องแบบ 3D Secure หมายถึง วิธีการป้องกันการฉ้อโกงที่ถูกดำเนินการโดย Visa และ Mastercard <br /><br /> ถ้าบัตรของท่านรองรับ 3D Secure เงินของท่านจะถูกดำเนินการในทันที</strong>',
      cardUpload:
        'บัตรที่ท่านกำลังใช้ฝากเงินยังไม่ได้เชื่อมต่อกับบัญชีซื้อขาย โปรดอัปโหลดรูปถ่ายหน้าบัตรเพื่อตรวจสอบ (กรุณาปิดตัวเลข 6 หลักตรงกลาง)',
      result: {
        success:
          'ขอบคุณ เรากำลังตรวจสอบรายละเอียดบัตรของท่าน และเงินจะปรากฎในบัญชีของท่านภายใน 24 ชั่วโมงทันทีเมื่อตรวจสอบเสร็จ',
        depositSuccess: 'ขอบคุณสำหรับการฝากเงินของท่าน เงินจะปรากฏในบัญชีของท่านอีกสักครู่',
        error:
          'เพื่อความปลอดภัยของบัญชีของท่าน ขณะนี้ท่านได้ทำธุรกรรมการฝากเงินถึงจำนวนสูงสุดแล้วและได้มีการะงับการฝากเงินของท่านชั่วคราว กรุณาติดต่อ {email}',
      },
      tipOnlyTaiwan: `เรียนลูกค้าโปรดทราบว่าการถอนเงินผ่านบัตรเครดิตอาจล่าช้าเนื่องจากการประมวลผลของธนาคารและเวลาที่มาถึงขึ้นอยู่กับแต่ละธนาคาร หากคุณมีความต้องการเงินทุนเร่งด่วนขอแนะนำให้ใช้วิธีอื่นในการฝาก / ถอน`,
      reminder: `<li>1. การฝากเงินด้วยบัตรเครดิตโดยใช้ EUR, GBP และ USD จำกัด ไว้ที่ไม่เกิน $ 1,900 USD หรือสกุลเงินที่เทียบเท่าต่อธุรกรรม</li>
      <li>2. สูงสุด 8 ธุรกรรมต่อวัน</li>
      <li>3. โปรดทราบว่าอาจมีการระงับการชำระเงินครั้งแรกของคุณ โปรดอดทนรอในขณะที่ทีมของเรากำลังตรวจสอบการชำระเงินของคุณหรือคุณอาจติดต่อทีมสนับสนุนลูกค้าของเราเพื่อขอความช่วยเหลือเพิ่มเติม</li>`,
    },
    fasa: {
      description: 'วิธีฝากเงินเข้าบัญชีของท่านผ่าน FasaPay',
      form: { header: 'แบบฟอร์ม FASAPAY' },
    },
    intSwift: {
      header: 'โอนเงินผ่านธนาคาร: SWIFT ระหว่างประเทศ',
      desc:
        'เงินสามารถถูกโอนไปยัง {platform} ผ่านบริการธุรกรรมออนไลน์ของสถาบันการเงินของท่าน หรือติดต่อสถาบันการเงินของท่านและส่งคำขอการโอนเงินไปยัง {platform}',
      desc2:
        'เมื่อท่านทำการโอนสำเร็จแล้ว โปรดกรอกแบบฟอร์มด้านล่างและแนบสำเนาใบเสร็จการโอนเงินที่แสดงชื่อเต็ม หมายเลขบัญชี และยอดเงินฝากอย่างชัดเจน เพื่อให้เราสามารถดำเนินการธุรกรรมของท่าน',
      alertSAandNGA:
        'หากคุณมีสกุลเงินอื่นที่ไม่ใช่ดอลลาร์สหรัฐ โปรดแลกเปลี่ยนเงินที่โอนเป็นดอลลาร์สหรัฐก่อนทำการโอนเงิน หากคุณโอนเงินโดยตรง ลูกค้าจะเป็นผู้รับผิดชอบส่วนต่างของอัตราแลกเปลี่ยนและค่าธรรมเนียมการจัดการที่เกิดขึ้นในขณะนั้น',
      options: 'โปรดเลือกบัญชีซื้อขายที่ท่านต้องการฝากเงิน',
      refInfo: 'กรุณาระบุหมายเลขบัญชีซื้อขาย {platform} ของท่านในช่องหลักฐานอ้างอิงการโอนเงินผ่านธนาคาร',
      bussDayInfo: 'เงินจะเข้าสู่บัญชีของท่านภายใน 2-5 วันทำการ',
      tip:
        'โปรดตรวจสอบให้แน่ใจว่าใบเสร็จการโอนเงินของท่านแสดง <b>ชื่อเต็ม</b> <b>หมายเลขบัญชี</b> และ<b>จำนวนเงินฝาก</b>อย่างชัดเจน',
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'วิธีฝากเงินเข้าบัญชีผ่าน Mobile Pay',
      inst1: "1. กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'",
      inst2: '2. สแกน QR code เพื่อเปิดแอปพลิเคชันธุรกรรมออนไลน์บนมือถือของท่าน',
      inst3: '3. ดำเนินการตามขั้นตอนที่ปรากฎบนอุปกรณ์มือถือของท่านเพื่อชำระเงินให้เสร็จสมบูรณ์',
      form: { payMethod: 'วิธีการชำระเงิน:', rate: 'อัตราแลกเปลี่ยน (USD/CNY):', amt: 'หยวน:' },
      formValidation: { cnyAmt: 'จำนวนเงินหยวนต้องไม่เกิน $ {limit}' },
    },
    crypto: {
      header: 'ฝากเงินผ่าน CRYPTOCURRENCY',
      choose: 'โปรดเลือกสกุลเงินดิจิทัลที่คุณต้องการ',
      proceedQuestion: 'คุณต้องการดำเนินการฝาก <b>{method}</b> หรือไม่?',
      note:
        'โปรดทราบว่าเราไม่สามารถฝากหรือถอนผ่าน BUSDT ได้ โปรดตรวจสอบให้แน่ใจว่าที่อยู่และสกุลเงินดิจิทัลตรงกับห่วงโซ่และสกุลเงินที่เรายอมรับ มิฉะนั้นคุณอาจสูญเสียเงิน',
      note2:
        'โปรดทราบว่า เราไม่สามารถฝาก หรือถอนผ่าน BUSDT หรือ ETH ได้ โปรดตรวจสอบให้แน่ใจว่า ที่อยู่ และสกุลเงินดิจิตอลตรงกับเครือข่าย และสกุลเงินที่เรายอมรับ มิเช่นนั้นเงินของคุณอาจสูญหาย เราจะไม่รับผิดชอบต่อความสูญเสียใด ๆ ที่เกิดขึ้นจากข้อผิดพลาด',
      remark:
        'โปรดทราบว่าเราไม่สามารถฝากหรือถอนผ่าน BUSDT ได้ โปรดตรวจสอบให้แน่ใจว่าที่อยู่และสกุลเงินดิจิทัลตรงกับห่วงโซ่และสกุลเงินที่เรายอมรับ มิฉะนั้นคุณอาจสูญเสียเงิน',
      inst2: '2.   คุณจะถูกนำไปยังหน้าต่างใหม่ที่แสดงที่อยู่บัญชี {method}',
      inst3:
        '3.   คัดลอกที่อยู่บัญชี {method} จากนั้นเข้าสู่ระบบบัญชี {method} ของคุณ และทำการโอนย้ายจำนวนเงินทที่ต้องการไปยังที่อยู่บัญชี {method} ที่คัดลอกมา',
      inst4: `โปรดทราบ: การฝากเงินโดย BITCOIN ผ่านการดำเนินการโดยผู้ให้บริการทางด้านการชำระเงินของเราตามอัตราแลกเปลี่ยนที่ให้ไว้ ณ เวลาที่ทำธุรกรรม`,
      bitcoin: {
        desc: 'วิธีฝากเงินไปยังบัญชีซื้อขายด้วย BITCOIN',
      },
      usdt: {
        desc: 'วิธีฝากเงินไปยังบัญชีซื้อขายด้วย USDT(OMNI)',
        inst1: '1. กรอกแบบฟอร์มด้านล่างและกด ‘ส่ง’',
        inst2: '2. คุณจะถูกนำไปยังหน้าต่างใหม่ที่แสดงที่อยู่บัญชี USDT(OMNI)',
        inst3:
          '3. คัดลอกที่อยู่บัญชี USDT(OMNI) จากนั้นเข้าสู่ระบบบัญชี USDT(OMNI) ของคุณ และทำการโอนย้ายจำนวนเงินทที่ต้องการไปยังที่อยู่บัญชี USDT(OMNI) ที่คัดลอกมา',
        inst4: `โปรดทราบ: การฝากเงินโดย USDT(OMNI) ผ่านการดำเนินการโดยผู้ให้บริการทางด้านการชำระเงินของเราตามอัตราแลกเปลี่ยนที่ให้ไว้ ณ เวลาที่ทำธุรกรรม`,
      },
      usdt_CPS: {
        desc: 'วิธีฝากเงินไปยังบัญชีซื้อขายด้วย USDT({name})',
      },
      form: {
        header_bitcoin: 'แบบฟอร์ม BITCOIN',
        header_usdt: 'แบบฟอร์ม USDT(OMNI)',
        header_usdt_CPS: 'แบบฟอร์ม USDT({name})',
      },
    },
    skrill: {
      header: 'ฝากเงินผ่าน SKRILL/MONEYBOOKERS',
      desc: 'วิธีฝากเงินเข้าบัญชีของท่านผ่าน Skrill',
      note1: 'เรายอมรับการชำระเงินเป็น USD, EUR, GBP และ CAD',
      note2: 'สามารถฝากเงินเข้าบัญชีเป็นสกุลเงินเดียวกับบัญชีซื้อขาย MT4 ของคุณเท่านั้น',
      note3: 'Skrill จะแปลงเงินเป็นสกุลเงินหลักของบัญชีโดยอัตโนมัติ หากใช้งานอีเมลที่ไม่ถูกต้อง อาจมีค่าธรรมเนียม',
      note4: 'การเงินฝากต้องเป็นชื่อเดียวกับบัญชี STARTRADER ของคุณ',
      form: { header: 'แบบฟอร์ม SKRILL', email: 'อีเมล Skrill/Moneybookers ' },
      reminder:
        '<li>โปรดทราบ:</li><li>*สกุลเงินฝากต้องตรงกับสกุลเงินของบัญชี MT4 เท่านั้น Skrill จะแปลงสกุลเงินให้ตรงกับในบัญชี MT4 โดยอัตโนมัติหากอีเมลที่ใช้ไม่ถูกต้อง ทั้งนี้อาจมีค่าธรรมเนียม</li><li>* ชื่อผู้ฝากต้องตรงกับชื่อบัญชี {platform} ของท่าน</li>',
    },
    unionPay: {
      header: 'ฝากเงินผ่าน Union Pay',
      desc: 'วิธีการฝากเงินเข้าสู่บัญชีของท่านผ่าน UnionPay',
      inst1: "1. กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'",
      inst2: '2. โปรดเลือกธนาคารในหน้าเลือกธนาคาร และเข้าสู่ระบบธุรกรรมออนไลน์ของธนาคารของท่าน',
      inst3: '3. ปฏิบัติตามคำแนะนำและชำระเงินผ่านธุรกรรมออนไลน์ / บนมือถือของธนาคารของท่าน',
      form: { header: 'แบบฟอร์ม UNION PAY' },
    },
    unionPayCN: {
      header: 'ฝากเงินผ่าน China Union Pay',
      desc: 'วิธีฝากเงินเข้าบัญชีของท่านผ่าน China Union Pay Transfer',
      inst1: 'หน้ารายละเอียดการชำระเงินจะปรากฏขึ้น',
      inst2: 'คุณอาจถูกร้องขอหมายเลขสมาชิกขณะทำการฝากเงิน โปรดกรอกหมายเลขบัญชีซื้อขายของคุณเป็นหมายเลขสมาชิก',
      note1:
        'จำนวนธุรกรรมขั้นต่ำต่อการฝากเงินหนึ่งครั้ง คือ 500 CNY และจำนวนธุรกรรมสูงสุดต่อการฝากเงินหนึ่งครั้ง คือ 49,999 CNY',
      note2: 'โปรดทราบว่า อาจมีการปรับปรุงจำนวนจำกัดของเงินฝากเป็นครั้งคราวทั้งนี้ขึ้นอยู่กับตลาด',
      note3: 'โปรดอย่าคัดลอกรายละเอียดผู้รับผลประโยชน์ของธนาคารที่แสดงอยู่เนื่องจากอาจมีการปรับปรุงเป็นครั้งคราว',
      form: {
        header: 'แบบฟอร์ม CHINA UNION PAY',
        holderName: 'ชื่อผู้ถือบัตร UnionPay',
        holderNameInfo: 'โปรดแน่ใจว่าชื่อที่ระบุด้านบนตรงกับชื่อบนบัตรธนาคารของท่าน',
      },
    },
    paypal: {
      header: 'Paypal',
      desc: 'วิธีฝากเงินเข้าบัญชีของท่านผ่าน PayPal',
      inst1: "1. กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'",
      inst2: '2. เข้าสู่บัญชี PayPal ของท่าน ',
      inst3: '3. ดำเนินการตามขั้นตอนที่ปรากฎในบัญชี PayPal เพื่อชำระเงินของท่านให้เสร็จสมบูรณ์',
      inst4: 'โปรดทราบ: เราไม่สามารถรับการชำระเงินจากบุคคลที่สามได้',
      form: { header: 'แบบฟอร์ม PAYPAL' },
    },
    cardpay: {
      desc: 'วิธีการฝากเงินเข้าบัญชีซื้อขายด้วยบัตรเครดิต',
      inst1: "1. กรอกแบบฟอร์มด้านล่างให้สมบูรณ์และกด 'ส่ง'",
      inst2: '2. จากนั้นท่านจะถูกนำไปยังหน้าแบบฟอร์มเพื่อให้กรอกรายละเอียดบัตรของท่าน ',
      inst3: "3. หลังจากที่กรอกรายละเอียดบัตรของท่านแล้ว ให้กด 'ส่ง' ",
      inst4: '4. ท่านจะถูกนำกลับมายังหน้าต่าง Client Portal ธุรกรรมของท่านจะถูกดำเนินการ ',
      form: { header: 'แบบฟอร์ม CREDIT CARD' },
    },
    tinkbit: { inst5: '5. สูงสุด $1,500 USD ต่อการฝาก' },
    perfectMoney: {
      inst1: '2.   เข้าสู่บัญชี Perfect Money ของท่าน',
      inst2: '3.   ดำเนินการตามการแจ้งเตือนในบัญชี Perfect Money เพื่อให้การชำระเงินเสร็จสมบูรณ์',
    },
    indiaBankTransfer: {
      desc1: 'เมื่อคุณได้ทำการโอนเงินแล้ว โปรดกรอกแบบฟอร์มด้านล่างพร้อมแนบสำเนาการโอนเงินของธนาคาร',
      desc2:
        'โปรดตรวจสอบให้แน่ใจว่า ชื่อ นามสกุล หมายเลขบัญชี และจำนวนเงินฝากแสดงให้เห็นได้อย่างชัดเจน เพื่อที่เราจะสามารถดำเนินการทำธุรกรรมได้',
      bussDayInfo: 'โปรดรอ 1-6 ชั่วโมงเพื่อให้เงินทุนเพิ่มเข้าไปยังบัญชีของคุณ',
    },
  },
  withdraw: {
    title: 'ถอนเงิน',
    default: {
      accountDetail: 'รายละเอียดบัญชี',
      withdrawalAmount: 'จำนวนเงินที่ถอน',
      example: 'Credit/Debit ตัวอย่าง',
      alert:
        'เนื่องจากกฎหมาย AML วิธีที่คุณใช้ในการถอนต้องเป็นวิธีเดียวกับวิธีที่ใช้ในการฝากเงินของคุณ หากจำนวนเงินที่ถอนน้อยกว่า 100 หน่วยของสกุลเงินหลักของบัญชีคุณจะถูกเรียกเก็บค่าธรรมเนียม 20 หน่วยจากธนาคาร',
      how: 'เลือกช่องทางที่ทำการฝากเงิน',
      success: 'คำขอถอนเงินของท่านสำเร็จและจะถูกดำเนินการในไม่ช้า',
      selectCC: 'เลือกบัตรเครดิต',
      selectBA: 'เลือกบัญชีธนาคาร',
      anotherCC: 'กรอกบัตรเครดิตด้วยตนเอง',
      anotherBA: 'เพิ่มบัญชีธนาคาร',
      remember: 'จดจำบัญชีของฉัน',
      ccFail:
        'เพื่อความปลอดภัยของบัญชีของท่าน ขณะนี้ท่านได้ทำธุรกรรมการฝากเงินถึงจำนวนสูงสุดแล้วและได้มีการะงับการฝากเงินของท่านชั่วคราว กรุณาติดต่อ {email}',
      checkPosFailed: 'ตรวจสอบคำสั่งซื้อขายล้มเหลว',
      fetchTradeAccFailed: 'การเรียกบัญชีซื้อขายล้มเหลว',
      failed: 'ถอนเงินล้มเหลว โปรดลองอีกครั้ง',
      chooseAnother: `<p>จําานวนเงินถอนสูงสุดสําาหรับบัตรใบนี้: 0</p> 
      <p>โปรดเลือกบัตรหรือช่องทางการชำระเงินอื่น</p>`,
      notEnoughFunds: 'จำนวนเงินที่กรอกเกินกว่าวงเงินของบัตรใบนี้',
      unfortunate:
        'ขออภัย เราไม่สามารถดำเนินการถอนเงินผ่านบัตรเครดิตได้อีกต่อไป ในอนาคตการถอนเงินจะถูกดำเนินการผ่านธนาคารไปยังบัญชีธนาคารที่ท่านกำหนด',
      noCardWarn: 'ท่านไม่มีบัตรที่สามารถทำการถอนได้ กรุณาเชื่อมบัตรของบัตรของท่านในรายละเอียดการถอนเงินก่อน',
      equityStop:
        'การถอนเงินของท่านไม่สามารถดำเนินการได้ เนื่องจากจะส่งผลให้เงินทุนในบัญชีของท่านลดต่ำลงกว่าระดับ Stop out 100%',
      equityMargin:
        'ขออภัย ขณะนี้เราไม่สามารถดำเนินการถอนเงินของคุณได้ เนื่องจากบัญชีได้รับการป้องกันความเสี่ยงอย่างเต็มรูปแบบ',
      equityContinue:
        'เราพบว่าปัจจุบันท่านยังมีคำสั่งซื้อขายเปิดอยู่ อาจมีการบังคับปิดคำสั่งซื้อขาย หลังส่งคำขอถอนเงิน โปรดยืนยัน',
      disclaimer: `<li>การถอนเงินในขณะที่คุณเปิดสถานะจะส่งผลต่อมาร์จิ้นของคุณ โปรดตรวจสอบให้แน่ใจว่าคุณปิดสถานะที่เปิดอยู่หรือตรวจสอบให้แน่ใจว่าคุณมีเงินเพียงพอในบัญชีของคุณเพื่อหลีกเลี่ยงการเรียกหลักประกัน</li>
        <li>{platform} ไม่ยอมรับการชำระเงินจากบุคคลที่สามอย่างเด็ดขาด เงินทุนทั้งหมดที่ฝากเข้าบัญชีซื้อขายของคุณต้องเป็นชื่อที่ตรงกันกับชื่อในบัญชีพอร์ทัลลูกค้า {platform} ของคุณ</li>
        <li>บัตรเครดิต/เดบิต กระเป๋าสตางค์อิเล็กทรอนิกส์ Moneybookers และคำขอถอนเงินผ่านธนาคารจะต้องได้รับการยืนยันเพื่อความปลอดภัย และเพื่อการรักษาความปลอดภัยโดยการจัดทำรายการเดินบัญชีธนาคารซึ่งรวมถึงข้อมูลเจ้าของบัญชี และรายละเอียดธนาคาร</li>
        <li>{platform} ขอสงวนสิทธิ์ในการปฏิเสธที่จะดำเนินการตามคำขอถอนหากมีเหตุอันควรเชื่อว่าบัญชีซื้อขายอาจกลายเป็นยอดคงเหลือติดลบหลังจากดำเนินการตามคำขอถอน</li>
        <li>{platform} ขอสงวนสิทธิ์ในการปฏิเสธคำขอถอนเงินหากบัญชีซื้อขายอยู่ภายใต้การเรียกเก็บเงินคืนหรือการสอบสวนอื่น ๆ ที่กำลังดำเนินอยู่</li>
        <li>{platform} ไม่รับผิดชอบต่อค่าธรรมเนียมใด ๆ ที่สถาบันการเงินผู้รับหรือผู้ให้บริการชำระเงินบุคคลที่สามอาจเรียกเก็บจากคุณสำหรับการรับการชำระเงิน</li>`,
      YourPaymentGreaterThanAvailableBalance: 'การชำระเงินของท่านมีจำนวนมากกว่ายอดคงเหลือในบัญชี',
      blackList: `ขออภัย เราไม่สามารถส่งคำขอการถอนเงินได้ในขณะนี้ โปรดติดต่อ {supportEmail} สำหรับข้อมูลเพิ่มเติม`,
      NetellerChargeAlert:
        'การถอนเงินของ Neteller ถูกจำกัดไว้ที่ $10,000 ต่อธุรกรรม และมีค่าธรรมเนียม 2% (ต่อยอดที่ $30)',
      agreePolicy: 'ข้าพเจ้าได้อ่าน และยอมรับต่อ{link}',
      withdrawPolicy: 'นโยบายการถอนเงิน',
      addWithdrawalMethod: 'เพิ่มช่องทางในการถอนเงิน',
      addSecondaryMethod: 'เพิ่มช่องทางสำรอง',
      withdrawNote:
        'โปรดทราบว่าเราจะดำเนินการถอนเงินของคุณผ่านช่องทางบัตรเครดิตเป็นหลัก หากจำนวนยอดบัตรเครดิตของคุณเต็มวงเงิน เราจะขอให้คุณเลือกช่องทางอื่นเพื่อดำเนินการคำร้องขอถอนเงินของคุณให้เสร็จสิ้น',
      submitYourWithdrawal: 'ส่งการถอนเงินของคุณ',
      remainingWithdraw: 'จำนวนยอดเงินถอนที่เหลือจะถูกแบ่งออกไปเป็นบัตรเครดิต หรือช่องทางการถอนเงินอื่น',
      onlyWithdrawalCcDesc: `เฉพาะบัตรเครดิตที่มีจำนวนเงิน {ccAmount} เท่านั้นจึงจะสามารถถอนออกทั้งหมดได้`,
    },
    fasa: {
      field: { accName: 'ชื่อบัญชี FasaPay', accNum: 'หมายเลขบัญชี FasaPay' },
      formValidation: { accNameReq: 'กรุณากรอกชื่อบัญชี FasaPay', accNumReq: 'กรุณากรอกหมายเลขบัญชี FasaPay' },
      fasaPayChargeAlert: 'FasaPay เรียกเก็บค่าธรรมเนียมการทำธุรกรรม 0.5% สำหรับการถอนทุกครั้ง',
    },
    neteller: { field: { email: 'อีเมล Neteller' }, formValidation: { emailReq: 'กรุณากรอกอีเมล Neteller' } },
    vload: {
      field: { email: 'อีเมลที่ลงทะเบียน Vload' },
      formValidation: { emailReq: 'ต้องมีอีเมลที่ลงทะเบียนกับ Vload' },
    },
    bitcoin: {
      field: { address: 'ที่อยู่ Bitcoin Wallet' },
      formValidation: {
        addressReq: 'กรุณากรอกที่อยู่ Bitcoin Wallet',
        addressFormat: `ที่อยู่กระเป๋าสตางค์ของคุณควรขึ้นต้นด้วย '1' หรือ '3' หรือ 'bc1'`,
        addressLength: 'ที่อยู่กระเป๋าเงิน Bitcoin จะต้องประกอบด้วยตัวเลขและตัวอักษร 26-42 ตัว',
      },
    },
    eth: {
      field: { address: 'ที่อยู่ ETH Wallet' },
      formValidation: {
        addressReq: 'กรุณากรอกที่อยู่ ETH Wallet',
        addressFormat: 'กรุณากรอกที่อยู่ ETH Wallet',
        addressLength: 'ที่อยู่กระเป๋าเงิน ETH จะต้องประกอบด้วยตัวเลขและตัวอักษร 26-42 ตัว',
      },
    },
    usdc: {
      field: { address: 'ที่อยู่ USDC Wallet' },
      formValidation: {
        addressReq: 'กรุณากรอกที่อยู่ USDC Wallet',
        addressFormat: 'กรุณากรอกที่อยู่ USDC Wallet',
        addressLength: 'ที่อยู่กระเป๋าเงิน USDC จะต้องประกอบด้วยตัวเลขและตัวอักษร 26-42 ตัว',
      },
    },
    usdt: {
      field: { address: 'ที่อยู่ USDT Wallet' },
      formValidation: {
        addressReq: 'กรุณากรอกที่อยู่ USDT Wallet',
        trc20WalletFormatIncorrect: `ที่อยู่กระเป๋าสตางค์ของคุณควรขึ้นต้นด้วย 'T'`,
        erc20WalletFormatIncorrect: `ที่อยู่กระเป๋าสตางค์ของคุณควรขึ้นต้นด้วย '0x'`,
      },
    },
    paypal: { field: { email: 'อีเมล Paypal' }, formValidation: { emailReq: 'กรุณากรอกอีเมล Paypal' } },
    skrill: {
      field: { email: 'อีเมล Skrill' },
      formValidation: { emailReq: 'กรุณากรอกอีเมล Skrill ' },
      skrillChargeAlert: 'Skrill เรียกเก็บค่าธรรมเนียมการทำธุรกรรม 1% สำหรับทุกการถอน',
    },
    sticpay: {
      field: {
        email: 'อีเมล SticPay',
      },
      formValidation: {
        emailReq: 'กรุณากรอกอีเมล SticPay ',
      },
    },
    bitwallet: {
      field: {
        email: 'อีเมล Bitwallet',
      },
      formValidation: {
        emailReq: 'กรุณากรอกอีเมล Bitwallet',
      },
    },
    astropay: {
      field: {
        accountNumber: 'บัญชี Astropay',
      },
      formValidation: {
        accountNumberReq: 'จำเป็นต้องมีบัญชี Astropay',
      },
    },
    perfectMoney: {
      field: { email: 'อีเมล Perfect Money' },
      formValidation: { emailReq: 'กรุณากรอกอีเมล Perfect Money ' },
    },
    transfer: {
      info:
        '{platform} จะชำระค่าธรรมเนียมธนาคารคืนสำหรับการถอนเงิน <u>หนึ่ง</u> ครั้งต่อเดือน การถอนเงินเพิ่มเติมในเดือนนั้นจะถูกเก็บค่าธรรมเนียม 20 หน่วยของสกุลเงินที่ทำการถอน',
      label: { upload: 'โปรดอัปโหลดสำเนารายการเงินฝากถอนในบัญชีเงินฝาก (ไม่เกิน 3 เดือน)' },
      formValidation: {
        upload: 'โปรดอัปโหลดรายการเงินฝากถอนในบัญชีเงินฝาก',
        emailReq: 'จำเป็นต้องระบุอีเมล',
      },
    },
    otherMethod: {
      withdrawalMethod: 'วิธีการถอน',
      manageWithdrawalDetails: 'จัดการรายละเอียดการถอนเงิน',
      verificationCode:
        'รหัสการยืนยันได้ถูกส่งไปที่ {email} แล้ว โปรดติดต่อ {supportEmail} หากคุณต้องการความช่วยเหลือเพิ่มเติม',
    },
  },
  transfer: {
    creditWarn: 'เราไม่สามารถดำเนินการโอนย้ายเงินของท่านจากบัญชีที่ท่านเลือกได้เนื่องจากบัญชีของท่านมีโปรโมชั่นเครดิต',
    blackListWarn:
      'การโอนเงินจากบัญชี MAM ของท่านอาจส่งผลต่อการบริหารสภาพคล่องในการซื้อขาย กรุณาติดต่อ {mailTo} หากท่านต้องการโอนเงินจากบัญชีนี้',
  },
  changePw: {
    header: 'เปลี่ยนรหัสผ่าน',
    field: { currPw: 'รหัสผ่านปัจจุบัน', newPw: 'รหัสผ่านใหม่', confirmPw: 'ยืนยันรหัสผ่านใหม่' },
    succ: 'รหัสผ่านของท่านได้รับการแก้ไขแล้ว ท่านสามารถใช้รหัสผ่านใหม่เพื่อเข้าสู่พอร์ทัลลูกค้า',
  },
  promotion: {
    clickToGet: 'เพียงคลิกและรับโบนัส $ 50 ของคุณ',
    apply: 'สมัครตอนนี้',
    start: 'เริ่มเลย',
    tnc: `
    <li> 1. ข้อเสนอนี้ใช้ได้เป็นเวลา 30 วัน</li>
    <li> 2. ข้อเสนอนี้มีให้สำหรับลูกค้าทุกคน (ปัจจุบันและใหม่) ที่มีบัญชีจริง แต่ไม่เคยฝากเงินมาก่อน.</li>
    <li> 3. ข้อเสนอนี้มีให้สำหรับลูกค้าใหม่ในพื้นที่ต่อไปนี้: ฝรั่งเศส สหราชอาณาจักร ไต้หวัน อิตาลี แคนาดา เบลเยียม สวิตเซอร์แลนด์ ฮ่องกง ไนจีเรีย เนเธอร์แลนด์ ญี่ปุ่น ลักเซมเบิร์ก มอลตา เรอูนียง เยอรมนี มาร์ตินีก สเปน ลัตเวีย โปแลนด์ , โกตดิวัวร์, เดนมาร์ก, บราซิล, นิวซีแลนด์, โปรตุเกส, เกาะแมน, แอฟริกาใต้, ตุรกี, โรมาเนีย, กวาเดอลูป, ออสเตรีย, ไอร์แลนด์, เฟรนช์เกียนา, สวาซิแลนด์, อาร์เจนตินา, ยูเครน, กรีซ, สวีเดน, โมนาโก, ฮังการี, เฮติ , ฟินแลนด์, โบลิเวีย, ชิลี, คอสตาริกา, โครเอเชีย, สาธารณรัฐเช็ก, เอสโตเนีย, เม็กซิโก, นอร์เวย์, ฟิลิปปินส์</li>
    <li> 4. ข้อเสนอนี้ใช้ได้กับบัญชีมาตรฐานและบัญชีมาตรฐานอิสลามเท่านั้น.</li>
    <li> 5. ห้ามรับโบนัสและ / หรือเงินโบนัสจากการซื้อขายในบัญชีสองบัญชีหรือมากกว่าที่มีที่อยู่ IP / ID / หนังสือเดินทางเดียวกัน โดยไม่คำนึงถึงจำนวนบัญชีซื้อขายที่ลูกค้าอาจถืออยู่กับ STARTRADER โปรโมชั่นนี้ใช้ได้กับบัญชีเดียวเท่านั้น.</li>
    <li> 6. ลูกค้าจะได้รับเครดิต 50 USD (หรือเทียบเท่า) หลังจากเลือกรับข้อเสนอนี้สำเร็จและไม่มีข้อกำหนดในการฝากเงิน</li>
    <li> 7. ไม่สามารถถอนจำนวนเครดิตได้ อย่างไรก็ตามคุณยังสามารถถอนส่วนหนึ่งของกำไรที่ได้รับจากการซื้อขายของคุณได้</li>
    <li> 8. คุณต้องปิดสถานะที่เปิดอยู่ทั้งหมดก่อนที่จะทำการถอน เมื่อคุณเลือกที่จะถอนโบนัสจะถูกหักออกจากบัญชี</li>
    <li> 9. STARTRADER ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขหรือปฏิเสธการให้โบนัสได้ตลอดเวลา</li>`,
    selectDesp: '* โปรดเลือกบัญชีที่คุณต้องการเข้าร่วมกิจกรรม No Deposit Bonus',
    descp1: 'คุณต้องการเริ่มต้นการซื้อขายโดยไม่ต้องลงทุนตั้งแต่เริ่มมีอาการหรือไม่?',
    descp2: `ใช้โบนัส $ 50 ของคุณเพื่อเริ่มทำกำไรโดยไม่ต้องเสี่ยงกับเงินทุนของคุณเลย`,
    header: 'ข้อกำหนดและเงื่อนไขการส่งเสริมการขาย',
    tnc2:
      '<li> จำนวนเงินขั้นต่ำที่คุณสามารถถอนได้คือ 100 USD （หรือเทียบเท่า） และจำนวนเงินสูงสุดที่จะถอนได้คือ 200 USD （หรือเทียบเท่า） ผลกำไรใด ๆ ที่เกิดขึ้นจากจำนวนเงินที่กำหนดไว้นี้จะถูกลบออกเมื่อเราดำเนินการตามคำขอถอนของคุณ</li>',
    successful: 'ส่งเรียบร้อยแล้ว',
    failed: 'การส่งล้มเหลว',
    referFriends: {
      intro: `ทำตาม 3 ขั้นตอนต่อไปนี้เพื่อรับ {amount}`,
      step1: 'ขั้นแรก',
      step1Descp: 'เปิดบัญชีเรียบร้อยแล้ว',
      step2: 'ขั้นตอนที่สอง',
      step2Descp: 'ฝากสำเร็จ',
      step3: 'ขั้นตอนที่สาม',
      step3Descp: 'ปริมาณการซื้อขาย',
      totalEanringTitle: `จำนวนเงินที่ถอนทั้งหมด <br/>(USD หรือสกุลเงินที่เทียบเท่า)`,
      totalEarningTip: '* จำนวนรางวัลขึ้นอยู่กับการตรวจสอบ',
      redeem: 'สารสกัด',
      intro2: 'ในขณะเดียวกันคุณยังสามารถแนะนำเพื่อนของคุณ',
      shareLink: 'แชร์ลิงค์คำแนะนำส่วนตัวของคุณ',
      shareLinkDescp: 'คลิก "แชร์" เพื่อรับลิงก์คำแนะนำที่ไม่ซ้ำใครและคัดลอกไปยังโซเชียลมีเดียต่างๆ',
      share: 'แชร์',
      total: 'ผู้อ้างอิงที่แลกทั้งหมด',
      social: 'แชร์ลิงค์ของคุณผ่านโซเชียลมีเดียหลัก ๆ',
      clients: 'ลูกค้า',
      table: {
        referralsNo: 'หมายเลขที่แนะนำ',
        name: 'ชื่อ',
        country: 'ประเทศ',
        openAccount: 'เปิดบัญชีเรียบร้อยแล้ว',
        despositSuccess: 'ฝากสำเร็จ',
        volumeCompleted: 'ปริมาณการซื้อขาย',
      },
      totalRedeemableTitle: `จำนวนเงินที่ถอนทั้งหมด <br/>(USD หรือสกุลเงินอื่นที่เทียบเท่า)`,
      totalRedeemableTip: '* จำนวนรางวัลขึ้นอยู่กับการตรวจสอบ',
      referrer: 'ผู้อ้างอิง',
      referral: 'ผู้ตัดสิน',
      dialog: {
        confirmQ: 'แลกรายได้ของคุณ',
        confirmQ2: 'จำนวนเงินทั้งหมดที่แปลงเป็นสกุลเงินหลักของคุณคือ {amount} {currency}',
        redeem: 'ถอนตอนนี้',
        agree: '* การคลิกแลกทันทีแสดงว่าคุณเห็นด้วยกับ {link} ทั้งหมด',
        overBonus:
          'ระบบของเราตรวจพบว่าคุณใช้จ่ายถึงจำนวนสูงสุดแล้ว สำหรับการเรียกร้องโปรดติดต่อฝ่ายสนับสนุนลูกค้าของเรา',
      },
    },
    depositBonus: {
      banner: 'รับโบนัสการฝากเงิน 50% ของคุณ!<br>   รับสูงถึง $10,000',
      depositBonus: 'รับโบนัสของคุณและเพิ่มการซื้อขายของคุณ',
      title:
        'ต้องการเพิ่มมาร์จิ้นในบัญชีของคุณและเพิ่มปริมาณการซื้อขายของคุณหรือไม่? จากนั้นฝากเงินเข้าบัญชีของคุณวันนี้และรับโบนัส 50% จากเรา!',
      fund: 'ฝากเงินเข้าบัญชีของคุณ',
      tnc: '*เป็นไปตามข้อกำหนดและเงื่อนไข {link}',
      link: 'คลิกที่นี่เพื่อดู',
      bonusHistory: 'ประวัติโบนัส',
      overBonus:
        '* คุณมีขีดจำกัดสูงสุดของเครดิตโบนัสจำนวน ${totalLimit} แล้ว การฝากเงินฝากเพิ่มจะไม่ได้รับพิจารณาสำหรับเครดิตโบนัส.',
      opt: 'การยืนยันการเลือกเข้าร่วม & การเลือกไม่รับ',
      tick: 'โปรดยกเลิกการเลือกช่องนี้หากต้องการยกเลิกหรือทำเครื่องหมายที่ช่องหากต้องการเลือกใช้',
      saveChange: 'บันทึกการเปลี่ยนแปลง',
      depositDate: 'วันที่ฝาก',
      credit: 'เครดิต',
      optIn: 'การเลือกร่วมรายการสำเร็จ',
      optOut: 'ยกเลิกเรียบร้อย',
      optInDescription: 'โปรดคลิกปุ่มด้านล่างเพื่อเลือกรับโบนัสการฝากเงิน 50%',
      optOutDescription: 'คลิกที่กล่องเพื่อเลือกรับรางวัล',
      activate: 'เปิดใช้งาน',
      signOut: 'ออกจากระบบ',
      banner1: 'รับโบนัสการฝากเงิน 50% ของคุณ!',
      claimDescription: `<p>วิธีการเรียกร้อง</p>
      <li>1. เปิดใช้งานก่อนฝากเงิน </li>
      <li>2. กองทุนรวมบัญชีซื้อขายของคุณเพื่อให้ได้รับรางวัล </li>
      <li>3. รางวัลจะให้เงินทุนกับบัญชีของคุณโดยอัตโนมัติ </li>
      <li>4. เพลิดเพลินกับการซื้อขายของคุณ </li>`,
      eligibleDescription: `<p>การมีสิทธิ์</p>
      <li>1. รางวัลจะต้องเปิดใช้งานก่อนที่จะฝากเงิน</li>
      <li>2. รางวัลนี้มีให้สำหรับบัญชีมาตรฐาน, บัญชีมาตรฐานอิสลาม, บัญชีสำคัญ, บัญชีสำคัญของอิสลาม </li>
      <li>3. คุณสามารถใช้กับโบนัสการฝากเงินอัตราส่วน 50% เพียงครั้งเดียวและเงินฝากที่ตามมาทั้งหมดจะถูกคำนวณตามอัตราส่วน 20% เท่านั้น </li>`,
      optInDialog: `ยินดีด้วย! คุณเปิดใช้งานโบนัสการฝากเงิน 50% สำเร็จแล้ว`,
      optOutDialog: `คุณได้ยกเลิกรับโบนัสการฝากเงิน 50% เรียบร้อยแล้ว`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">เพิ่มเงินออมของคุณให้มีมูลค่าเป็นสองเท่า</span></br>
          <span class="desc">รับเงินคืนสูงถึง $10,000 และไม่ต้องเสียค่าธรรมเนียมสวอป</span>
        `,
        activityBannerContext: `
          <span class="title">เพิ่มเงินออมของคุณเป็นสองเท่า</span></br>
          <span class="desc">ย่อขาลงหรือซื้อขาลง?</span></br>
          <span class="desc">รับเงินคืนสูงถึง $10,000 เมื่อซื้อขาย cryptocurrencies กับ STARTRADER!</span>
        `,
      },
      active: {
        title: `เริ่มต้นรับเงินคืนจากคริปโต`,
        desc: `คลิกปุ่มด้านล่างเพื่อเริ่มต้นเข้าร่วมกิจกรรม`,
      },
      deactive: {
        title: `ปิดใช้งานการคืนเงินของ Cryptocurrency`,
        desc: `โปรดคลิกปุ่มด้านล่างเพื่อปิดใช้งานและถอนออกจากโปรโมชั่นคืนเงิน Cryptocurrency`,
      },
      button: {
        active: `เปิดการดำเนินการ`,
        deactive: `ยกเลิกการดำเนินการ`,
        submit: `ยื่นระบบ`,
        fund: `เริ่ทต้นฝากเงิน`,
      },
      claimTitle: `วิธีการรับโปรโมชั่น`,
      claimDescriptions: [
        `เปิดใช้งานโปรโมชั่นก่อนทำการฝากเงิน`,
        `ฝากเงินเข้าบัญชีซื้อขายของคุณเพื่อให้เป็นไปตามข้อกำหนดการฝากเงิน (≥ 500 USD)`,
        `เงินคืนต่อล็อตจะขึ้นอยู่กับจำนวนเงินฝากทั้งหมดของคุณ (ดูข้อกำหนดและเงื่อนไข)`,
        `สนุกกับการซื้อขายและรับมูลค่ามากขึ้นในทุกการซื้อขาย!`,
      ],
      eligibilityTitle: `คุณสมบัติ`,
      eligibleDescriptions: [
        `โปรโมชั่นนี้ต้องเปิดใช้งานก่อนทำการฝากเงิน`,
        `โปรโมชั่นนี้ใช้ได้กับบัญชี Standard และ Islamic Standard เท่านั้น หากลูกค้ามีบัญชีซื้อขายหลายบัญชี สามารถเลือกบัญชีที่มีสิทธิ์ได้เพียงบัญชีเดียวเพื่อเข้าร่วมในโปรโมชั่นนี้`,
        `โปรโมชั่นนี้มีอายุ 30 วันนับจากวันที่คุณทำการฝากเงินสำเร็จครั้งแรกหลังจากเปิดใช้งาน`,
        `เงินคืนขึ้นอยู่กับปริมาณการซื้อขายในผลิตภัณฑ์สกุลเงินดิจิทัลทั้งหมด ยกเว้น ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU`,
        `เงินคืนทั้งหมดจำกัดอยู่ที่ $10,000 ต่อลูกค้าหนึ่งราย`,
      ],
      tnc: '*เป็นไปตามข้อกำหนดและเงื่อนไข {link}',
      link: 'คลิกที่นี่เพื่อดู',
      message: {
        congratulate: `ยินดีด้วย!`,
        congratulateContext: `การเริ่มต้นรับเงินคืนจากโปรโมชั่นคริปโต เริ่มขึ้นแล้ว`,
        signedOutPromo: `คลิกที่นี่ หากต้องการออกจากระบบ`,
        deactivePromo: `การยกเลิกรับเงินคืนจากโปรโมชั่นคริปโต เริ่มขึ้นแล้ว`,
      },
      dialog: {
        selectAccountContext: `โปรดเลือกบัญชีที่คุณต้องการเข้าร่วมกิจกรรมโบนัสการซื้อขาย`,
        tnc: 'ข้อกำหนดและเงื่อนไข คลิกที่นี่เพื่อดู',
        tncContext: {
          tnc1: `1. หลังจากที่คุณเปิดใช้งานโปรโมชั่นนี้ คุณต้องฝากเงินและซื้อขายสกุลเงินดิจิทัลตามที่กำหนดเพื่อรับเงินคืนที่เกี่ยวข้อง`,
          tnc1desc: `*เฉพาะการฝากเงินใหม่ในช่วงระยะเวลาโปรโมชั่นเท่านั้นที่สามารถใช้ได้กับโปรโมชั่น ไม่รวมการโอนเงินภายใน โบนัสแนะนำเพื่อน และการปรับเงินสด`,
          tnc2: `2. ลูกค้าที่มีสิทธิ์ (ทั้งลูกค้าใหม่และลูกค้าปัจจุบัน) ซึ่งตรงตามเกณฑ์ที่กำหนดไว้ในที่นี้สามารถรับเงินคืนได้เท่ากับ:`,
          tnc3: `3. ผลิตภัณฑ์ที่มีสิทธิ์: ผลิตภัณฑ์สกุลเงินดิจิทัลทั้งหมด ยกเว้น ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU`,
          tnc4: `4. ปริมาณการซื้อขายที่มีผล: คำสั่งซื้อที่เปิดและปิดใหม่ในช่วงระยะเวลาโปรโมชั่น สถานะที่เปิดน้อยกว่า 5 นาที ตำแหน่งที่ถูกล็อก และสถานะที่ป้องกันความเสี่ยงจะไม่นับเป็นการซื้อขายที่ถูกต้องสำหรับโปรโมชั่นนี้`,
          tnc5: `5. ระยะเวลาโปรโมชั่น: 30 วันหลังจากผู้เข้าร่วมทำการฝากเงินครั้งแรกสำเร็จ`,
          tnc5desc: `* หากลูกค้าเปิดใช้งานโปรโมชั่นและไม่ได้ทำการฝากเงิน โปรโมชันจะสิ้นสุดโดยอัตโนมัติหลังจากผ่านไป 30 วัน`,
          tnc6: `6. ข้อเสนอนี้ไม่สามารถใช้ร่วมกับข้อเสนออื่น ๆ ได้ (ยกเว้นแคมเปญ “You Can Be a trader too”, แนะนำเพื่อนและโบนัสเงินฝาก 50%)`,
        },
        agreeTnc: `ฉันได้อ่านและยอมรับข้อกำหนดและเงื่อนไข`,
      },
      field: {
        netDeposit: `เงินฝากสุทธิ`,
        usddesc: `USD (หรือสกุลเงินเทียบเท่า)`,
        cashback: `เงินคืน`,
        standardLot: `ล็อตมาตรฐาน`,
        date: `วันที่`,
        depositAmount: `ยอดฝาก`,
        withdrawAmount: `ยอดถอน`,
        netDepositAmount: `ยอดฝากทั้งหมด`,
        rebateLot: `เงินคืน/ล็อต`,
        tradingDate: `วันที่เทรด`,
        tradingLot: `จำนวนล็อต`,
        cashRebate: `เงินคืน`,
      },
      depositHistory: `ประวัติการฝาก`,
      tradingRewardHistory: `ประวัติรางวัล`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">ลุ้นรับรางวัลมูลค่าสูงถึง $5,000!</div>
          <div class="desc">ในการจับฉลากผู้โชคดีของเรา</div>
        `,
      },
      active: {
        title: 'เปิดใช้งานโปรโมชั่น MAKE A WISH',
        desc: 'กรุณาคลิกที่ปุ่มด้านล่างเพื่อเปิดใช้งานโปรโมชั่น Xmas Make a Wish',
      },
      deactive: {
        title: 'เปิดใช้งานโปรโมชั่น MAKE A WISH',
        desc: 'กรุณาคลิกที่ปุ่มด้านล่างเพื่อเปิดใช้งานโปรโมชั่น Xmas Make a Wish',
      },
      button: {
        active: 'เปิดใช้งาน',
        deactive: 'ปิดใช้งาน',
        submit: 'ส่ง',
        fund: 'ฝากเงินเข้าบัญชีของคุณ',
        enterLuckyDraw: 'Enter Lucky Draw',
        redeemed: 'แลกแล้ว',
      },
      claimTitle: 'วิธีการรับสิทธิ์',
      claimDescriptions: [
        `เปิดใช้งานโปรโมชั่นก่อนทำการฝากเงิน​`,
        `เติมเงินในบัญชีซื้อขายของคุณเพื่อให้เป็นไปตามข้อกำหนดการฝากเงินสำหรับของขวัญที่คุณต้องการ จากนั้นทำการซื้อขายตามจำนวนที่ต้องการ (ดูตารางของขวัญด้านล่าง)`,
        `เมื่อมีสิทธิ์ คุณจะมีโอกาสชนะของขวัญตามลำดับชั้นของคุณหนึ่งชิ้น (ดูตารางของขวัญด้านล่าง)`,
        `คลิกที่ปุ่ม Enter Lucky Draw เพื่อหมุนของขวัญของคุณ`,
        `ของขวัญที่ชนะจะถูกบันทึกไว้และของขวัญทั้งหมดจะถูกแจกจ่ายภายใน 7 วันหลังจากโปรโมชั่นสิ้นสุดลง`,
      ],
      eligibilityTitle: 'คุณสมบัติ',
      eligibleDescriptions: [
        'ประเภทบัญชีที่มีสิทธิ์: ข้อเสนอนี้เปิดเฉพาะบัญชีพื้นฐาน และบัญชีอิสลามพื้นฐานทั้งใน MT4/MT5',
        'หากลูกค้ามีบัญชีมาตรฐานหลายบัญชี ลูกค้าสามารถเลือกใช้บัญชีสแตนดาร์ดเพื่อเข้าร่วมในโปรโมชั่นนี้ได้เพียงบัญชีเดียว',
        'ประเทศที่มีสิทธิ์: ทั้งหมดยกเว้นจีนแผ่นดินใหญ่',
        'การฝากเงิน และการซื้อขายต้องทำในช่วงระยะเวลาโปรโมชั่นจึงจะถือว่าเข้าร่วมในโปรโมชั่นนี้',
        'ปริมาณการซื้อขายที่ถูกต้อง: ตำแหน่ง Forex ทองคำ เงิน และน้ำมันดิบที่ถือเอาไว้นานกว่า 5 นาที',
      ],
      moreDetail: 'สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับการแจกรางวัล โปรดดูที่ {prize}.',
      prize: 'รายชื่อของรางวัลที่นี่',
      tnc: '*เป็นไปตามข้อกำหนด และเงื่อนไข คลิก {link} เพื่อดู',
      link: 'ที่นี่',
      luckyDraw: {
        description: [
          `กดปุ่ม Lucky Draw เพื่อลุ้นรับ <span class="font-bold">หนึ่งใน</span> รางวัลจากด้านบน!`,
          'ไม่ใช่ของขวัญที่คุณกำลังมองหาใช่ไหม? ทำการฝากเงิน และซื้อขายต่อไปเพื่อไปให้ถึงลำดับชั้นที่คุณต้องการ',
        ],
        mainTitle: 'ลำดับชั้นของขวัญในปัจจุบันของคุณ:',
        commingSoon: {
          title: `ขอพร`,
          content: `เข้าร่วมการจับรางวัลเร็ว ๆ นี้ในกลางเดือนพฤศจิกายน`,
        },
        noChanceToDraw: {
          title: `ฝากเงินตอนนี้`,
          content: `ฝากเงินเข้าบัญชีของคุณด้วยจำนวนเงินฝากขั้นต่ำที่ $200 เพื่อเข้าร่วมการจับรางวัล`,
        },
        endPromotion: {
          title: `การจับรางวัลสิ้นสุดลงแล้ว`,
          content: `ขอบคุณสำหรับการมีส่วนร่วมของคุณ! ตรวจสอบให้แน่ใจว่าคุณได้แลกของขวัญของคุณภายในวันที่ 31 มีนาคม พ.ศ. 2566`,
        },
      },
      currentDepositLots: `เงินฝากปัจจุบัน / ล็อตที่ซื้อขายแล้ว`,
      transactionHistory: `ประวัติการทำธุรกรรม`,
      transactionHistoryDesc: `*รายการฝากเงิน คือ จำนวนเงินฝาก และมูลค่าของเงินฝากที่ถูกหักออกจากการเข้าร่วมการจับรางวัล`,
      giftRedemption: `การแลกของขวัญ`,
      giftRedemptionDesc: [
        `เราจะติดต่อคุณอีกครั้งเพื่อแลกรับของขวัญจริง`,
        `*สามารถแลกทุกรางวัลได้ตั้งแต่วันที่ 2 กุมภาพันธ์ พ.ศ. 2566 ถึง 2 มีนาคม พ.ศ. 2566 เท่านั้น`,
      ],
      field: {
        depositAmount: `จำนวนเงินฝาก`,
        lotsTraded: `ล็อตที่ซื้อขายแล้ว`,
        date: `วันที่`,
        type: `ประเภท`,
        deposit: `*เงินฝาก`,
        tradingLots: `ล็อตการซื้อขาย`,
        prizeWon: `รางวัลที่ได้รับ`,
        creditBonus: `โบนัสเครดิต`,
        cashVoucher: `บัตรกำนัลเงินสด`,
        physicalRewards: `รางวัลจริง`,
        depositTradingVolume: `ล็อตการซื้อขาย / เงินฝาก`,
        gifts: `ของขวัญ (USD)`,
        accountID: `รหัสบัญชี`,
        current: `ปัจจุบัน`,
        luckyDrawDeposit: `เงินฝาก`,
      },
      dialog: {
        selectAccountContext: `โปรดเลือกบัญชีที่คุณต้องการรับโบนัสเครดิตใน (สำหรับบัญชี MT4/MT5 เท่านั้น)`,
        tnc: 'ข้อกำหนด และเงื่อนไข',
        tncContext: {
          tnc1: `1. ลูกค้าต้องเปิดใช้งานโปรโมชั่นนี้ให้สำเร็จภายในพอร์ทัลลูกค้าก่อน จากนั้นจึงฝากเงิน และซื้อขายตามที่กำหนดเพื่อรับของขวัญที่เกี่ยวข้อง`,
          tnc2: `2.	ปริมาณการซื้อขายที่มีประสิทธิภาพ: ล็อตมาตรฐานของคู่สกุลเงิน ตลาดซื้อขายทอง เงิน สปอตน้ำมันดิบ และน้ำมันดิบล่วงหน้า คำสั่งซื้อจะต้องเปิด และปิดในช่วงระยะเวลาของโปรโมชั่น ตำแหน่งที่เปิดน้อยกว่า 5 นาที ตำแหน่งที่ถูกล็อก และตำแหน่งที่ป้องกันความเสี่ยงจะไม่นับเป็นการซื้อขายที่ถูกต้องสำหรับโปรโมชั่นนี้`,
          tnc3: `3.	สถิติ และปริมาณการสั่งซื้อจะถูกดึงข้อมูลมาจาก MT4 ในช่วงระยะเวลา 01/11/2565-31/01/2566`,
          tnc4: `4.	หากไม่สามารถซื้อของขวัญได้เนื่องจากเหตุสุดวิสัย เช่น สินค้าไม่มีอยู่ในชั้นวาง หรือสินค้าไม่มีอยู่ในสต็อก ลูกค้าสามารถสมัครเพื่อรับเงินสดตามมูลค่าที่เทียบเท่ากับของขวัญดังกล่าวตามราคาตลาดได้ ราคานี้คิดจากราคาดอลลาร์สหรัฐ (หรือสกุลเงินเทียบเท่า)`,
          tnc5: `5.	ข้อเสนอนี้ไม่สามารถใช้ร่วมกับข้อเสนออื่นได้ (100 โบนัสการซื้อขาย，โบนัสพิเศษ 20%,โบนัสเงินฝาก 50%，โบนัสเงินฝาก VN 20%）`,
        },
        agreeTnc: `ฉันได้อ่าน และยอมรับข้อกำหนด และเงื่อนไข`,
        prizeList: `รายชื่อรางวัล`,
        luckyDrawTransaction: `ธุรกรรม LUCKY DRAW`,
        xmasGift: `ขอแสดงความยินดีกับของขวัญคริสต์มาสของคุณ!`,
      },
      message: {
        congratulate: `ขอแสดงความยินดี!​`,
        congratulateContext: `คุณเปิดใช้งาน Xmas เรียบร้อยแล้ว เปิดใช้งานโปรโมชั่น MAKE A WISH`,
        signedOutPromo: `การคลิกที่ปุ่มยืนยัน แล้วคุณจะออกจากระบบโปรโมชั่น!`,
        deactivePromo: `คุณได้ยกเลิกการเข้าร่วมโปรโมชั่น Xmas Make a Wish เรียบร้อยแล้ว`,
        redeemAmount: `จำนวนเงินจะถูกโอนเข้าไปยังบัญชีของคุณ!`,
      },
      tableContent: {
        luckyDraw: `การจับรางวัล`,
        depositTrade: `ฝาก / ซื้อขาย`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">รับ <span class='bg_yellow'>เงินสดทันใจ</span> จากการฝากเงินครั้งแรก</div>
          <div class="desc">รับเงินสดสูงถึง US$50 เมื่อคุณทำการฝากเงินครั้งแรกกับ STARTRADER!</div>
        `,
      },
      active: {
        title: `เปิดใช้งานเงินคืน FTD ของคุณ`,
        desc: `โปรดคลิกที่ปุ่มด้านล่างเพื่อเปิดใช้งาน และเข้าร่วมในกิจกรรม`,
      },
      deactive: {
        title: `ปิดใช้งานเงินคืน FTD ของคุณ`,
        desc: `โปรดคลิกที่ปุ่มด้านล่างเพื่อปิดใช้งาน และถอนเงินออกจาก โปรโมชั่นเงินคืน FTD`,
      },
      button: {
        active: `เปิดใช้งาน`,
        deactive: `ปิดใช้งาน`,
        fund: `ฝากเงินเข้าบัญชีของคุณ`,
      },
      claimTitle: `วิธีการอ้างรับสิทธิ์`,
      claimDescriptions: [
        `เปิดใช้งานโปรโมชั่นก่อนทำการฝากเงิน​`,
        `ฝากเงินเข้าบัญชีซื้อขายคุณเพื่อให้ตรงกับข้อกำหนดการฝากเงินสำหรับเงินคืนที่คุณต้องการ (มากกว่า หรือเท่ากับ 200 USD หรือในสกุลเงินที่เทียบเท่า)​.`,
        `มูลค่าในบัตรกำนัลเงินคืนจะขึ้นอยู่กับจำนวนเงินฝากครั้งแรกของคุณ (โปรดดูตารางเงินคืนในข้อกำหนด และเงื่อนไข)`,
        `เปิดใช้งานบัตรกำนัลเงินคืน แล้วทำการซื้อขายฟอเร็กซ์ ทองคำ โลหะเงิน หรือน้ำมัน มากกว่า หรือเท่ากับ 2 ล็อต`,
        `แล้วตอนนี้เงินคืนที่เปิดใช้งานแล้วของคุณก็สามารถแลกคืนได้แล้ว จำนวนเงินจะปรากฏในยอดคงเหลือบัญชีของคุณ`,
      ],
      eligibilityTitle: `คุณสมบัติ`,
      eligibleDescription1: `ประเภทบัญชีที่มีสิทธิ์: ข้อเสนอนี้เสนอให้กับบัญชีแสตนดาร์ด และบัญชีอิสลามแสตนดาร์ดเท่านั้นทั้งใน MT4/MT5`,
      eligibleDescription2: `หากลูกค้ามีบัญชีแสตนดาร์ดหลายบัญชี ลูกค้าสามารถเลือกใช้บัญชีแสตนดาร์ดเพื่อเข้าร่วมในโปรโมชั่นนี้ได้เพียงบัญชีเดียว`,
      eligibleDescription3: `ประเทศที่มีสิทธิ์: ทุกประเทศ ยกเว้นจีนแผ่นดินใหญ่`,
      eligibleDescription4: `ลูกค้าที่มีสิทธิ์: ลูกค้าใหม่ และลูกค้าปัจจุบันซึ่งเป็นผู้ที่ไม่มีประวัติเงินฝาก รวมถึงลูกค้าซึ่งอยู่ภายใต้ IB/CPA`,
      eligibleDescription4Desc: `*บัญชีซื้อขายส่วนบุคคลของ IB/CPA จะไม่ได้รับสิทธิ์`,
      eligibleDescription5: `การฝากเงินครั้งแรกจะต้องกระทำในช่วงระยะเวลาของการจัดโปรโมชั่น`,
      eligibleDescription6: `คำสั่งซื้อขายที่ถือครองน้อยกว่า 5 นาที จะไม่ได้รับพิจารณาให้เข้าร่วมในโปรโมชั่นนี้`,
      tnc: `*มีการใช้งานข้อกำหนด และเงื่อนไข {link} เพื่อเข้าดู`,
      link: `คลิกที่นี่`,
      dialog: {
        selectAccountContext: `โปรดเลือกบัญชีที่คุณต้องการเข้าร่วมกิจกรรมโบนัสการซื้อขาย`,
        tnc: `ข้อกำหนด และเงื่อนไข`,
        tncContext: {
          tnc1: `1. หลังจากเปิดใช้งานโปรโมชั่นนี้แล้ว คุณจำเป็นต้องทำการฝากเงิน และทำการซื้อขาย 2 ล็อตมาตรฐานเพื่อเป็นไปตามเกณฑ์ในการรับเงินคืนที่เกี่ยวข้องได้`,
          tnc1desc: ` *เฉพาะการฝากเงินครั้งใหม่ที่กระทำในระยะเวลาของการจัดโปรโมชั่นเท่านั้นจึงจะสามารถใช้โปรโมชั่นนี้ได้ ซึ่งจะไม่รวมการโอนเงินภายใน โบนัสการแนะนำเพื่อน และการปรับเปลี่ยนเงินสด`,
          tnc2: `2.	เฉพาะการฝากเงินครั้งแรกที่กระทำในระยะเวลาการจัดโปรโมชั่นนี้เท่านั้นที่จะสามารถนำไปทำการคำนวณเงินคืนได้`,
          tnc3: `3.	ลูกค้าที่มีสิทธิ์ (ทั้งลูกค้าใหม่ และปัจจุบัน) ผู้ซึ่งมีคุณสมบัติเป็นไปตามเกณฑ์ที่กำหนดจะสามารถได้รับเงินคืนเท่ากับ:`,
          tnc4: `4.	ผลิตภัณฑ์ที่มีสิทธิ์: ฟอเร็กซ์ ทองคำ โลหะเงิน น้ำมัน`,
          tnc5: `5.	ปริมาณการซื้อขายที่มีประสิทธิภาพ: คำสั่งซื้อขายใหม่จะต้องเปิด และปิดในช่วงระยะเวลาของโปรโมชั่นนี้ ตำแหน่งที่เปิดน้อยกว่า 5 นาที ตำแหน่งที่ถูกล็อก และตำแหน่งที่ป้องกันความเสี่ยง จะไม่นับเป็นการซื้อขายที่ถูกต้องสำหรับโปรโมชั่นนี้`,
        },
        agreeTnc: `ข้าพเจ้าได้อ่าน และเห็นด้วยกับข้อกำหนด และเงื่อนไขนี้`,
        grossDeposit: `เงินฝากรวมเป็น USD`,
        grossDepositDesc: `หรือในสกุลเงินที่เทียบเท่า`,
        cbVoucher: `บัตรกำนัลเงินคืน`,
        tableDesc: `* ลูกค้าขอแลกรับบัตรกำนัลเงินสดสูงสุดได้ $50 (หรือเทียบเท่า)`,
      },
      voucher: {
        title: `FTD คืนเงิน`,
        accountID: `รหัสบัญชี`,
        accumulateVolume: `สะสมปริมาณการซื้อขาย`,
        standardLots: `ล็อตมาตรฐาน`,
        desc: `ทำการซื้อขาย ฟอเร็กซ์ ทองคำ โลหะเงิน หรือน้ำมัน มากกว่า หรือเท่ากับ 2 ล็อต เพื่อเปิดใช้งาน`,
        clickForRedeem: `คลิกที่ปุ่มด้านล่าง เพื่อส่งไปยังยอดคงเหลือในบัญชี`,
        ftdLessThan200: `เงินฝากเริ่มต้นของคุณต่ำกว่าจำนวนเงินที่กำหนดขั้นต่ำ`,
      },
      message: {
        congratulate: `ขอแสดงความยินดี!`,
        congratulateContext: `คุณได้เปิดใช้งานเรียบร้อยแล้ว สำหรับโปรโมชั่นเงินคืน FTD`,
        signedOutPromo: `การคลิกที่ปุ่ม ยืนยัน จะทำให้คุณจะออกจากระบบโปรโมชั่นนี้!`,
        deactivePromo: `คุณได้ปิดใช้งานโปรโมชั่นเงินคืน FTD เรียบร้อยแล้ว`,
        successRedeem: `คุณได้ทำการขอแลกรับเงินคืน FTD สำเร็จแล้ว`,
      },
    },
    notification: {
      ndb: {
        inst1: 'เรียน ลูกค้าผู้มีอุปการคุณ',
        inst2: `เราขอเตือนคุณว่าโปรโมชั่น No Deposit Bonus จะสิ้นสุดในวันที่ <span>{date} (GMT+3)</span> .`,
        inst3:
          'ตามข้อกำหนดและเงื่อนไขของโปรโมชั่น โปรดทราบว่าโบนัสในบัญชีเงินฝากของคุณจะถูกลบออกโดยอัตโนมัติเมื่อโปรโมชั่นเสร็จสิ้น สำหรับผู้ที่ไม่มีบัญชีเงินฝากหรือบันทึกการทำธุรกรรม โบนัสและยอดคงเหลือจะถูกลบออกโดยอัตโนมัติเมื่อโปรโมชั่นเสร็จสิ้นเช่นกัน',
        inst4: `หากคุณมีคำถามหรือต้องการความช่วยเหลือ โปรดติดต่อทีมสนับสนุนของเราผ่านแชทสด อีเมล: <span>{mail}</span> หรือโทรศัพท์ <span>{phone}</span>`,
        cantInerTransfer: 'เนื่องจากคุณเข้าร่วมแคมเปญ NDB ของเรา เราจึงไม่สามารถดำเนินการตามคำขอโอนของคุณได้',
      },
      db: {
        inst1: 'เรียน ลูกค้าผู้มีอุปการคุณ',
        inst2: `เราขอเตือนคุณว่าโปรโมชั่นโบนัสเงินฝาก 50% จะสิ้นสุดในวันที่ <span>{date} (GMT+3)</span>`,
        inst3:
          'ตามข้อกำหนดและเงื่อนไขของโปรโมชั่น โปรดทราบว่าโบนัสในบัญชีเงินฝากของคุณจะถูกลบออกโดยอัตโนมัติเมื่อโปรโมชั่นเสร็จสิ้น สำหรับผู้ที่ไม่มีบัญชีเงินฝากหรือบันทึกธุรกรรม โบนัสและยอดคงเหลือจะถูกลบออกโดยอัตโนมัติเมื่อโปรโมชั่นเสร็จสิ้นเช่นกัน',
        inst4: `หากคุณมีคำถามหรือต้องการความช่วยเหลือใด ๆ โปรดติดต่อทีมสนับสนุนของเราผ่านการแชทสด อีเมล: <span>{mail}</span> หรือโทรศัพท์ <span>{phone}</span>`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'ดาวน์โหลด MetaTrader 4 สำหรับ PC, Mac, web browsers, สมาร์ทโฟน และ แท็บเล็ต',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'แอนดรอยด์/แท็บเล็ต',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'ดาวน์โหลด MetaTrader 5 สำหรับ PC, Mac, web browsers สมาร์ทโฟน และ แท็บเล็ต',
  },
  forgetPwReq: {
    tip: 'กรอกที่อยู่อีเมลบัญชีของคุณเพื่อรีเซ็ตรหัสผ่าน',
    formValidation: { emailReq: 'จำเป็นต้องใช้อีเมลเข้าสู่ระบบ' },
    succ: 'เราได้ทำการส่งขั้นตอนการเปลี่ยนรหัสผ่านไปให้ท่านทางอีเมลแล้ว<br><br>โปรดตรวจสอบโฟลเดอร์สแปมของอีเมลอีกครั้ง',
  },
  loginClient: {
    header: 'พอร์ทัลลูกค้า',
    failedMasseage: 'ลองอีกครั้ง',
    existingClientPortalAccess:
      'เราพบว่าท่านมีรหัสการเข้าถึง Client Portal แล้ว<br /><br />ใช้อีเมลเข้าระบบเพื่อเปิดบัญชีซื้อขายใหม่!',
    footer: `คำเตือนความเสี่ยง: การซื้อขายสัญญาเพื่อความแตกต่าง (CFDs) มีความเสี่ยงสูงต่อเงินทุนของคุณและอาจส่งผลให้เกิดการสูญเสียคุณควรทำการซื้อขายด้วยเงินที่คุณสามารถจ่ายได้ การซื้อขาย CFD อาจไม่เหมาะสำหรับนักลงทุนทุกคนโปรดตรวจสอบให้แน่ใจว่าคุณเข้าใจความเสี่ยงที่เกี่ยวข้องและใช้มาตรการที่เหมาะสมในการจัดการ.<br><br><br>
      {platformEntityName} ได้รับอนุญาตและควบคุมโดย Financial Services Authority ของเซเชลส์พร้อมใบอนุญาตเลขที่ SD050.<br>
      STARTRADER LLC ได้รับอนุญาตและควบคุมโดย Financial Services Authority of St. Vincent and the Grenadines โดยมีใบอนุญาตเลขที่ 271 LLC 2020.<br>
      The entities above are duly authorized to operate under the STARTRADER brand and trademark.<br>`,
    formValidation: { emailReq: 'กรุณากรอกที่อยู่อีเมล' },
    invailidRafLink: `ลิงค์อ้างอิงที่ใช้ไม่ถูกต้อง ลงทะเบียนสำหรับบัญชีใหม่ด้านล่าง`,
  },
  myProfile: { changeDetails: 'หากท่านต้องการแก้ไขรายละเอียดโปรไฟล์ กรุณาติดต่อ {mailTo}' },
  openAdditAcc: {
    chooseTradePlat: 'เลือกแพลตฟอร์มการซื้อขาย',
    chooseAccType: 'เลือกประเภทบัญชี',
    chooseCurrency: 'เลือกสกุลเงินของบัญชี',
    notes: 'หมายเหตุเพิ่มเติม',
    currError: 'โปรดเลือกสกุลเงินของบัญชีซื้อขาย',
    platErr: 'โปรดเลือกแพลตฟอร์มการซื้อขาย',
    accTypeErr: 'โปรดเลือกประเภทบัญชีซื้อขาย',
    succ:
      '<p>บัญชีซื้อขายจริงเพิ่มเติมของท่านกำลังรอการอนุมัติ ท่านจะได้รับอีเมลแสดงรายละเอียดการเข้าสู่ระบบในอีกสักครู่ ท่านสามารถตรวจสอบสถานะการเปิดบัญชีของท่านได้ที่หน้าหลัก</p>',
    byTicking: `ฉันได้อ่านและยอมรับข้อตกลงและ{tnc}`,
    tnc: 'เงื่อนไข',
    pamm: `บัญชี PAMM มีไว้สำหรับนักลงทุนที่มีการจัดการ Money Manager อยู่แล้วเท่านั้น ทุกคนไม่สามารถซื้อขายบัญชี PAMM ได้ยกเว้น Money Manager`,
    pammConfirm: `โปรดยืนยันว่าจะดำเนินการเลือกประเภทบัญชีนี้ต่อไปหรือไม่`,
  },
  openDemoAdditAcc: {
    header: 'เปิดบัญชีทดลองซื้อขาย',
    button: 'เปิดบัญชีทดลองซื้อขาย',
    liveButton: 'เปิดบัญชีซื้อขายจริง',
    succ: 'บัญชีตัวอย่างเพิ่มเติมของคุณได้รับการสร้างแล้วและคุณจะได้รับอีเมลรายละเอียดการเข้าสู่บัญชีเร็ว ๆ นี้',
  },
  resetPassword: {
    header: 'เปลี่ยนรหัสผ่าน ',
    succ: 'รหัสผ่านของท่านถูกแก้ไขแล้ว ท่านสามารถใช้รหัสผ่านใหม่เข้าสู่บัญชีซื้อขาย MT4/MT5 ได้',
    succ2: 'รหัสผ่านของท่านถูกแก้ไขแล้ว',
    failed: 'Token หมดอายุ',
    emailNotFound: 'ไม่พบที่อยู่อีเมลของท่าน',
  },
  fileUploader: {
    supportedFormat: 'ประเภทไฟล์ที่รองรับ: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'ขนาดไฟล์สูงสุดที่สามารถอัปโหลดได้ : {maxSize} MB',
    maxFileNum: 'จำนวนไฟล์สูงสุด: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'ฝากเงิน',
    withdraw: 'ถอนเงิน',
    desc: {
      incomplete:
        'หากคุณทำการหยุดหรือปิดหน้าต่างขณะทำธุรกรรมระหว่างที่ระบบกำลังดำเนินการ สถานะการฝากของคุณจะแสดงผลการฝากว่า "ไม่สมบูรณ์" คุณจำเป็นต้องดำเนินการฝากเงินใหม่อีกครั้ง เนื่องจากธุรกรรมนี้จะไม่สามารถดำเนินการต่อได้',
      success: 'การโอนเงินสำเร็จ เงินจะปรากฎในบัญชีของท่านในไม่ช้า',
      proc: 'เรากำลังดำเนินการให้คุณ โปรดติดต่อ {mailTo}',
      failed: 'การโอนเงินล้มเหลว โปรดติดต่อ {mailTo} เพื่อขอรับข้อมูลเพิ่มเติม',
      rejected: 'คำร้องขอโอนเงินของคุณถูกปฎิเสธ โปรดติดต่อ {mailTo} เพื่อขอรับข้อมูลเพิ่มเติม',
    },
    withdrawDesc: {
      sub: 'ธุรกรรมของท่านถูกส่งแล้ว โปรดรอเพื่อให้กระบวนการสมบูรณ์',
      suc: 'ธุรกรรมของท่านสำเร็จ เงินจะปรากฎในบัญชีของท่านในไม่ช้า',
      proc: 'ธุรกรรมของท่านกำลังถูกดำเนินการ กรุณาติดต่อ {mailTo}',
      incomp: 'ธุรกรรมของท่านยังไม่สำเร็จ กรุณาติดต่อ {mailTo} สำหรับข้อมูลเพิ่มเติม',
      cancel: 'ธุรกรรมของท่านถูกยกเลิก กรุณาติดต่อ  {mailTo} สำหรับข้อมูลเพิ่มเติม',
      fail: 'ธุรกรรมของท่านล้มเหลว กรุณาติดต่อ {mailTo} สำหรับข้อมูลเพิ่มเติม',
      rej: 'ธุรกรรมของท่านถูกปฏิเสธ กรุณาติดต่อ {mailTo} สำหรับข้อมูลเพิ่มเติม',
    },
    cancelWithdraw: `คุณต้องการยกเลิกคำขอถอนเงินหรือไม่?`,
    cancelError: `การถอนเงินของคุณกำลังดำเนินการอยู่ในขณะนี้ ดังนั้นเราจึงไม่สามารถยอมรับคำขอยกเลิกของคุณได้ สำหรับข้อมูลเพิ่มเติม คุณสามารถติดต่อเราโดยใช้คุณสมบัติการแชทสด`,
    dialog: {
      appliedAmount: `จำนวนเงินที่ใช้แล้ว`,
      applicationTime: `เวลารับสมัคร`,
      processedTime: `เวลาดำเนินการ`,
      accountCurrency: `สกุลเงินของบัญชี`,
      creditDeducted: `เครดิตที่หักออกแล้ว`,
      processedNote: `หมายเหตุที่ประมวลผลแล้ว`,
      bankName: `ชื่อธนาคาร`,
      bankAccountNumber: `หมายเลขบัญชีธนาคาร`,
      abaSortCode: `ABA/Sort Code`,
      swiftCode: `Swift Code`,
      bankStatement: `รายการแสดงความเคลื่อนไหวของบัญชีธนาคาร`,
      bankAddress: `ที่อยู่ธนาคาร`,
      accountName: `ชื่อบัญชี`,
      accountNumber: `หมายเลขบัญชี`,
      cardholderName: `ชื่อผู้ถือบัตร`,
      cardNumber: `หมายเลขบัตร`,
      cardExpiryDate: `วันหมดอายุของบัตร`,
      walletAddress: `ที่อยู่กระเป๋าสตางค์`,
      ewalletEmailAddress: `ที่อยู่อีเมลของกระเป๋าสตางค์อิเล็กทรอนิกส์`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'ขออภัย เราไม่สามารถดำเนินการโอนเงินของคุณได้ เนื่องจากอาจส่งผลให้มาร์จิ้นที่ใช้งานได้ในบัญชีของคุณต่ำกว่าระดับการหยุด 100%',
      equityMargin:
        'ขออภัย ขณะนี้เราไม่สามารถดำเนินการโอนเงินของคุณได้ เนื่องจากบัญชีของคุณบัญชีของคุณได้รับการป้องกันความเสี่ยงเด็มรูปแบบ',
      equityContinue:
        'เราได้ตรวจพบว่าคุณมีตำแหน่งที่เปิดอยู่ในขณะนี้ คุณอาจประสบกับการหยุดหลังจากส่งใบขอการถอนเงิน โปรดยืนยันหากคุณต้องการให้เราดำเนินการ',
      blackList: 'บัญชีนี้ถูกจำกัดการโอนเงินระหว่างบัญชีอื่น โปรดติดต่อ {supportEmail}',
    },
    pleaseNote: 'โปรดทราบ:',
    suc: 'เงินของท่านถูกโอนสำเร็จ',
    warn1:
      'ท่านมีหน้าที่รักษายอดเงินในบัญชีไม่ให้ต่ำกว่าระดับมาร์จิ้นที่กำหนด หากท่านมีการซื้อขายที่เปิดอยู่ โปรดตรวจสอบให้แน่ใจว่าท่านได้รักษาระดับมาร์จิ้นในบัญชีของท่านให้อยู่ในระดับที่กำหนด เงินที่โอนไปยังบัญชีที่มีสกุลเงินเดียวกันจะถูกดำเนินการทันที',
    warn2: 'ถ้าต้องการโอนเงินระหว่างบัญชีที่มีสกุลเงินต่างกัน กรุณาส่งอีเมลไปที่ {mailTo}',
    warn3: 'โปรดทราบ เงินไม่สามารถโอนระหว่างบัญชีอ่านอย่างเดียวได้',
  },
  transferHistory: {
    payStatus: {
      processing: 'กำลังดำเนินการ',
      paid: 'ชำระเงินแล้ว',
      unpaid: 'ยังไม่ได้ชำระเงิน',
      rejected: 'ถูกปฏิเสธ',
    },
  },
  identityProof: {
    home: {
      header: 'การสมัครไม่สมบูรณ์',
      content:
        'สวัสดี คุณ {name} การสมัครเปิดบัญชีของท่านใกล้จะเสร็จสมบูรณ์แล้ว แต่ท่านยังต้องส่งเอกสารเพิ่มเติมเพื่อทำให้บัญชีของท่านเสร็จสมบูรณ์ ',
    },
    shufti: {
      content: `โปรดตรวจสอบให้แน่ใจว่ารายละเอียดที่ให้มานั้นตรงกับเอกสารประจำตัวของคุณและเป็นภาษาเดียวกัน`,
      countryOfDocument: `ประเทศตามที่แสดงในเอกสาร`,
    },
    popup: {
      header: 'สวัสดี {name} ก่อนที่บัญชีซื้อขายของท่านจะได้รับการอนุมัติ ท่านจจะต้องอัปโหลดเอกสารต่อไปนี้เพื่อยืนยัน ',
      addressProofSpan: `<strong>ยืนยันที่อยู่</strong><br />
      ส่ง หนึ่ง ในเอกสารด้านล่าง<br />
      - สำเนาบิลค่าสาธารณูปโภคล่าสุด (ไม่เกิน 6 เดือน)<br />
      - สำเนารายการเดินบัญชีล่าสุด (ไม่เกิน 6 เดือน)<br />
      <br />
      ข้อควรระวัง!เอกสารต้องแสดงชื่อ-สกุล และที่อยู่อย่างชัดเจน`,
      idProofSpan: `<strong>ยืนยันตัวตน</b></strong><br />
      ส่งหนึ่ง ในตัวเลือกด้านล่าง<br />
      - สำเนาพาสปอร์ต<br />
      - สำเนาใบขับขี่(หน้าและหลัง)<br />
      - สำเนาบัตรประจำตัวประชาชน<br /><br />
      ข้อควรระวัง! เอกสารต้องแสดงชื่อ-สกุลอย่างชัดเจน<br />`,
    },
    reason: 'Your documentation was unable to be verified,<br> Reason: {reason}. Please try again.',
  },
  header: { secPortal: 'พื้นที่ปลอดภัย<br />สำหรับลูกค้า' },
  menu: {
    home: 'หน้าหลัก',
    accManagement: 'บัญชี',
    liveAcc: 'บัญชีซื้อขายจริง',
    openAdditAcc: 'บัญชีเพิ่มเติม',
    homeDemo: 'บัญชีทดลองซื้อขาย',
    depositFund: 'ฝากเงิน',
    withdrawFunds: 'ถอนเงิน',
    transferBetweenAccs: 'โอนเงิน',
    payDetails: 'ข้อมูลการชำระเงิน',
    funds: 'ธุรกรรม',
    transactionHis: 'ประวัติการทำธุรกรรม',
    tranHis: 'ประวัติการโอนเงิน',
    download: 'ดาวน์โหลด',
    profile: 'ข้อมูลส่วนตัว',
    myProfile: 'ข้อมูลส่วนตัว',
    cpPassword: 'เปลี่ยนรหัสผ่าน',
    contactUs: 'ติดต่อเรา',
    register: 'ดำเนินการสมัครต่อไป',
    tradingTools: 'เครื่องมือซื้อขายแบบมืออาชีพ',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'ปฏิทินเศรษฐกิจ',
    FEAT_FX: 'ไอเดียที่โดดเด่น',
    FX_IDEAS: 'มุมมองนักวิเคราะห์',
    promotion: 'โปรโมชั่น',
    noDeposit: 'ไม่มีโบนัสเงินฝาก',
    referFriends: 'แนะนำเพื่อน',
    depositBonus: 'โบนัสการฝากเงิน 50%',
    cryptoCashback: `คริปโทเคอร์เรนซี เงินคืน`,
    firstTimeDeposit: `FTD คืนเงิน`,
    tradingTool: 'เครื่องมือการซื้อขาย',
  },
  contact: {
    desc:
      'ทีมสนับสนุนลูกค้าของ {platform} ที่สามารถพูดได้หลายภาษามีความพร้อมที่จะช่วยเหลือท่านและช่วยให้ท่านได้รับประสบการณ์การซื้อขายที่ไม่สามารถหาทีอื่นเทียบได้ ',
    followUs: 'ตามเรามา',
    tip: 'สำหรับลูกค้าที่ลงทะเบียนผ่านทาง {email}',
    liveChat: 'ไลฟ์แชท',
  },
  depositResultDialog: { msg: 'ท่านฝากเงินสำเร็จหรือไม่' },
  dialog: { identityProof: { success: 'ขอบคุณ เราได้รับเอกสารของท่านเรียบร้อยแล้วและกำลังดำเนินการตรวจสอบ' } },
  notification: {
    pendingIdentityProof: {
      header: 'กำลังดำเนินการเปิดบัญชี',
      id: 'เอกสารยืนยันตัวตนคือ',
      poa: 'ที่อยู่',
      both: 'เอกสารยืนยันตัวตนและที่อยู่คือ',
      content:
        'กำลังประมวลผล {doc} ของท่านจะได้รับการสรุปในไม่ช้า หากต้องการตรวจสอบสถานะการเปิดบัญชีของท่านอย่างรวดเร็ว ท่านสามารถฝากเงินในบัญชีตอนนี้และบัญชีของท่านจะได้รับการดำเนินการเป็นลำดับต้น',
    },
  },
  register: {
    progressStatus: {
      title: 'ความคืบหน้าการยืนยัน',
      estimatedTimeTitle: 'เวลาโดยประมาณที่จะเสร็จสมบูรณ์:',
      min: 'นาที',
    },
    tabs: {
      personalDetails: 'ข้อมูลส่วนบุคคล',
      moreAboutYou: 'เพิ่มเติมเกี่ยวกับท่าน',
      accountConfig: 'การกำหนดค่าบัญชี',
      confirmYourId: 'ยืนยันตัวตนของท่าน',
      fundYourAcc: 'ฝากเงินในบัญชีของท่าน',
    },
    btn: { next: 'ถัดไป', back: 'กลับ', fundnow: 'ฝากเงินตอนนี้', upload: 'อัปโหลด', done: 'เสร็จสิ้น' },
    authority: {
      dialog1: 'ท่านต้องมีบัญชีเทรดจริงเพื่อเขาถึงส่วนนี้ของ Client Portal',
      dialog2:
        'เปิดบัญชีจริงเพื่อปลดล็อคการเข้าถึงพอร์ทัลลูกค้าของท่านอย่างเต็มรูปแบบซึ่งรวมถึงเครื่องมือการซื้อขาย โปรโมชั่นและอื่น ๆ อีกมากมาย',
      openLiveAcc: 'เปิดบัญชีซื้อขายจริง',
    },
    demo: {
      congrad: 'ขอแสดงความยินดี!',
      title: 'บัญชีทดลองเทรด MT{platform} อายุการใช้งาน 60 วันของท่านใช้งานได้แล้ว',
      title2: 'รายละเอียดบัญชีทดลองเทรดของท่าน',
      li: { login: 'เข้าสู่ระบบ', password: 'รหัสผ่าน', srv: 'เซิร์ฟเวอร์', expDate: 'วันหมดอายุ' },
      emailSent: '* รายละเอียดการเข้าสู่ระบบได้ถูกส่งไปยังอีเมลที่ท่านลงทะเบียนแล้ว',
      dl: 'ดาวน์โหลดแพลตฟอร์มการซื้อขาย MT {platform} ของท่าน',
      improveQ: 'คุณต้องการที่จะพัฒนาการซื้อขายของคุณหรือไม่',
      improveWay: 'เพียงแค่ปิดบัญชีจริงในไม่กี่นาทีเพื่อปลดล็อคเครื่องมือการซื้อขายและโปรโมชั่นพิเศษมากมาย',
      proTrade: 'เครื่องมือซื้อขายแบบมืออาชีพ',
      customerService: 'บริการลูกค้าแบบตัวต่อตัวตลอด 24 ชั่วโมง',
      competitivePromotionals: 'การส่งเสริมการแข่งขัน',
      secure: 'ธุรกรรมปลอดภัย 3D',
      openLiveAcc: 'เปิดบัญชีซื้อขายจริง',
    },
    personalDetails: {
      page1: {
        pageTitle: 'ข้อมูลส่วนบุคคล',
        userTitle: 'คำนำหน้าชื่อ',
        firstName: 'ชื่อจริง',
        lastName: 'นามสกุล',
        email: 'อีเมล',
        dob: 'วันเกิด',
        idType: 'ประเภทบัตรประจำตัว',
        referral: 'ท่านได้รับคำแนะจากผู้ใดหรือไม่ (ไม่บังคับ)',
        middleName: 'ชื่อกลาง',
        nationality: 'สัญชาติ',
        phoneNum: 'หมายเลขโทรศัพท์',
        date: 'วัน',
        month: 'เดือน',
        year: 'ปี',
        placeOfBirth: 'สถานที่เกิด',
        idNum: 'หมายเลขประจำตัว',
        passportNum: 'หมายเลขพาสปอร์ต',
        driverLicenseNum: 'หมายเลขใบขับขี่',
        titleDropdown: { mr: 'นาย', mrs: 'นาง', ms: 'นางสาว', miss: 'นางสาว', dr: 'ดอกเตอร์', prof: 'ศาสตราจารย์' },
        idTypeDropdown: { passport: 'พาสปอร์ต', idCard: 'บัตรประชาชน', driverLicense: 'ใบอนุญาติขับขี่' },
      },
      page2: {
        pageTitle: 'ที่อยู่อาศัยหลัก',
        mainResidency: 'ประเทศที่อยู่อาศัยหลัก',
        streetNumber: 'เลขที่ถนน',
        streetName: 'ชื่อถนน',
        address: 'ที่อยู่',
        provinceOrState: 'จังหวัด/รัฐ',
        cityOrSuburb: 'เมือง / ชานเมือง',
        postcode: 'รหัสไปรษณีย์',
        usQuestion:
          'ท่านเป็นพลเมืองสหรัฐฯ / ถิ่นที่อยู่เพื่อวัตถุประสงค์ด้านภาษีหรือท่านมีเครือเส้นสาย / ความสัมพันธ์ในสหรัฐอเมริกา (เช่นบัญชีธนาคาร ฯลฯ )',
      },
    },
    moreAboutYou: {
      pageTitle1: 'การจ้างงานและรายละเอียดทางการเงิน',
      pageTitle2: 'ความรู้และประสบการณ์การซื้อขาย',
      complianceExplain: 'คำถามต่อไปนี้ถูกรวบรวมเพื่อให้สอดคล้องกับข้อกำหนดของ AML / CTF',
    },
    accountConfig: {
      pageTitle: 'การกำหนดค่าบัญชี',
      chooseTradingPlatform: 'เลือกแพลตฟอร์มการซื้อขาย',
      chooseAccType: 'เลือกประเภทบัญชี',
      chooseAccCurrency: 'เลือกสกุลเงินของบัญชี',
    },
    confirmYourId: {
      pageTitle: 'ยืนยันตัวตนของท่าน',
      description:
        'ในฐานะโบรกเกอร์ฟอเร็กซ์ที่ได้รับการควบคุม เราจำเป็นต้องยืนยันตัวตนของท่าน โปรดอัปโหลดหนึ่งในเอกสารต่อไปนี้สำหรับการยืนยันตัวตนและการยืนยันที่อยู่ หรือมิฉะนั้นท่านอาจส่งอีเมลเอกสารยืนยันไปที่ {mailTo}',
      poidTitle: 'หลักฐานยืนยันตัวตน',
      porTitle: 'หลักฐานยืนยันที่อยู่',
      uploadDescription: 'เลือกประเภทเอกสารและอัปโหลดตามนั้น',
      note: 'สำคัญ: ชื่อในเอกสารจะต้องแสดงชื่อเต็มของท่านอย่างชัดเจน',
      pending: { upload: 'รอการอัปโหลด', verification: 'รอการตรวจสอบ' },
      poidTypes: { photoId: 'ภายถ่ายตัวตน', passport: 'พาสปอร์ต', drivingLicence: 'ใบอนุญาติขับขี่' },
      porTypes:
        '<li> บิลค่าสาธารณูปโภค </li>\n      <li> รายการเดินบัญชีธนาคาร </li>\n     <li> ใบรับรองถิ่นที่อยู่ </li>',
    },
    finishPage: {
      title: '{individualUserName} ขอบคุณสำหรับการสมัครบัญชีเทรดจริง {platformName}!',
      contentDefault:
        'เราได้รับใบสมัครของท่านและตั้งค่าบัญชีเทรดจริงของท่าน <b> {บัญชี} </b> <br />\n      ในไม่ช้าท่านจะได้รับอีเมลที่ <b> {email} </b> ที่มีรายละเอียดการเข้าสู่ระบบและลิงก์เพื่อดูสถานะบัญชีของท่าน <br />',
      contentWithoutId3WithAccount:
        'ขณะนี้บัญชีซื้อขายของท่านอยู่ระหว่างดำเนินการและท่านจะได้รับอีเมลที่มีข้อมูลเพิ่มเติมในไม่ช้า <br />\n     หากต้องการติดตามการดำเนินการบัญชีเทรดจริงของท่านอย่างรวดเร็วท่านสามารถฝากเงินในบัญชีของท่านตอนนี้และรับการเปิดในลำดับต้น <br />',
      contentWithoutId3WithoutAccount:
        'ขณะนี้บัญชีซื้อขายของท่านอยู่ระหว่างดำเนินการและท่านจะได้รับอีเมลที่มีข้อมูลเพิ่มเติมในไม่ช้า <br />',
    },
  },
  autochartist: {
    1: {
      title: `เครื่องสแกนตลาด`,
      desc01: `Market Scanner เครื่องแรกของโลกที่ให้คุณสแกนรายการเฝ้าดูตลาดเพื่อหาโอกาสในการซื้อขาย`,
      button01: `ดาวน์โหลดเดี๋ยวนี้`,
    },
    2: {
      title: `รายงานตลาด`,
      desc01: `Autochartist Market Reports ช่วยให้คุณเห็นภาพรวมของแนวโน้มทางเทคนิคของตลาดก่อนการเปิดศูนย์การเงินที่สำคัญ`,
      button01: `ดูรายงานตลาด`,
    },
    3: {
      title: `พอร์ทัลการวิจัย Autochartist`,
      desc01: `พอร์ทัลการวิจัยที่ครอบคลุมอย่างสมบูรณ์ที่ช่วยให้คุณค้นหาข้อมูลที่มีอยู่ทั้งหมดได้ในที่เดียว`,
      button01: `เปิด`,
      button02: `คู่มือผู้ใช้`,
      button03: `การเข้าถึง OTP`,
    },
    4: {
      title: `โอกาสในการซื้อขาย`,
      desc01: `ตรวจสอบการอัปเดตโอกาสในการซื้อขายทุกๆ 15 นาทีด้วยการตั้งค่าใหม่ตามประเภทการวิเคราะห์ของ Autochartist ทั้งหมด`,
      button01: `ดูโอกาสในการซื้อขาย`,
    },
    5: {
      title: `แอพมือถือ Autochartist`,
      desc01: `รับการแจ้งเตือนแบบพุชและติดตามโอกาสในการซื้อขายที่กรองความเป็นไปได้สูงตามรายการเฝ้าดูของคุณภายในแพลตฟอร์มการซื้อขาย`,
      desc02: `หมายเหตุ:`,
      desc03: `คุณสามารถสแกน QR Code ได้จากกล้องปกติหากโทรศัพท์ของผู้ใช้มีเครื่องสแกน QR ในตัวหรือจากภายในแอปพลิเคชันมือถือ autochartist โดยคลิกปุ่ม "เข้าสู่ระบบโดยใช้ QR Code"`,
      desc04: `รหัส QR มีอายุ 10 นาทีเท่านั้นหลังจากนั้นจะต้องรีเฟรชภาพสำหรับรหัส QR ใหม่`,
      desc05: `รหัส QR ใช้ได้สำหรับการใช้งานครั้งเดียวเท่านั้นหลังจากนั้นภาพจะต้องถูกรีเฟรชสำหรับรหัส QR ใหม่`,
      button01: `ดาวน์โหลดสำหรับ IOS`,
      button02: `ดาวน์โหลดสำหรับ Andriod`,
    },
    6: {
      title: `การสมัครรับจดหมายข่าว`,
      desc01: `เมื่อสมัครรับจดหมายข่าวของเราคุณจะได้รับอีเมลรายวันที่ขับเคลื่อนโดย Autochartist เพื่อให้ภาพรวมของตลาดฟอเร็กซ์ทั่วโลก ทำการค้ากับสหภาพแปซิฟิกทำการค้ากับโลก`,
      button01: `ติดตาม`,
    },
    footer: `Autochartist เป็นเครื่องมือที่ทันสมัยที่สุดในโลกสำหรับการระบุโอกาสทางการค้าโดยอัตโนมัติในรูปแบบแผนภูมิรูปแบบ Fibonacci และระดับคีย์ กรรมสิทธิ์ของ Autochartist ช่วยให้เครื่องมือค้นหาตรวจสอบเครื่องมือทางการเงินหลายพันรายการได้ตลอด 24 ชั่วโมงต่อวันเพื่อเปิดเผยโอกาสทางการค้าที่เกิดขึ้น นอกจากนี้ Autochartist ยังนำเสนอ Volatility Analysis ซึ่งจะช่วยให้คุณสามารถประเมินความเสี่ยงและความผันผวนของตราสารที่คุณซื้อขายได้ดีขึ้น`,
  },
  responseMsg: {
    410: 'การตรวจสอบค่าตัวเลขล้มเหลว',
    411: 'รหัสผ่านไม่ถูกต้อง',
    420: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    421: 'ปฏิเสธการรีเซ็ตรหัสผ่าน',
    490: 'โปรดอย่าส่งข้อมูลบัตรที่ซ้ำกัน',
    500: 'เกิดข้อผิดพลาด',
    501: 'แอปพลิเคชันล้มเหลว โปรดลองอีกครั้งภายหลัง',
    505: 'การอัปโหลดไฟล์ล้มเหลว',
    520: 'เข้าสู่ระบบล้มเหลว โปรดลองอีกครั้ง',
    521: 'ผู้ใช้งานลงชื่อเข้าใช้บนอุปกรณ์นี้แล้ว!',
    522: 'โปรดเข้าสู่ระบบอีกครั้ง',
    523: 'โปรดเข้าสู่ระบบอีกครั้ง',
    524: 'ไม่พบที่อยู่อีเมลของท่าน',
    525: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    527: 'ผู้ใช้งานไม่ใช่ IB',
    528: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    529: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    540: 'ไม่พบบัญชีการซื้อขายของท่าน',
    541: 'ไม่พบบัญชีค่าคอมมิชชั่น',
    542: 'คุณไม่สามารถเปิดบัญชีเพิ่มเติมได้',
    544: 'คุณไม่ได้รับอนุญาตให้สมัครบัญชีเพิ่มเติมจนกว่าจะได้รับการยืนยันหลักฐานยืนยันตัวตนของคุณ',
    550: 'หมายเลขบัญชีไม่ถูกต้อง',
    551: 'เงินไม่เพียงพอ',
    553: 'ท่านไม่สามารถถอนได้เนื่องจากเครดิตในบัญชีซื้อขายของท่าน โปรดติดต่อ {email} เพื่อขอความช่วยเหลือเพิ่มเติม',
    554: 'จำนวนเงินที่ร้องขอคือศูนย์หรือไม่มี',
    564: 'ประเทศไม่ตรงกับช่องทางการชำระเงิน',
    565: 'รหัสธนาคารไม่ถูกต้อง',
    566: 'ไม่พบวิธีการชำระเงิน',
    568: 'บัญชีนี้ถูกจำกัดการฝากเงิน กรุณาติดต่อ {email}',
    581: 'คุณเลือกเข้าร่วมการส่งเสริมการขายสำเร็จแล้ว โปรดทราบว่าคุณต้องทำการฝากเงินเพื่อถอนเงิน',
    582: 'คุณเข้าร่วมกิจกรรมและมีตำแหน่งดังนั้นคุณจึงไม่สามารถส่งใบสมัครถอนได้',
    584: 'ท่านไม่มีการคืนเงินที่เกี่ยวข้อง',
    587: 'ยอดเงินไม่เพียงพอกรุณาติดต่อฝ่ายบริการลูกค้า',
    590: 'ไม่พบบัญชีที่โอนออก',
    591: 'ไม่พบบัญชีที่โอนเข้า',
    593: 'เราไม่สามารถดำเนินการโอนเงินของคุณจากบัญชีที่เลือกได้เนื่องจากบัญชีของคุณมีเครดิต',
    594: 'ไม่อนุญาตให้โอนเงินออกจากบัญชีนี้',
    595: 'ในขณะที่คุณเข้าร่วมในกิจกรรมของเราคำขอโอนของคุณไม่สามารถดำเนินการได้ในขณะนี้',
    647: 'รหัสการยืนยันไม่ถูกต้อง',
    1001: 'บัญชีของคุณใช้งานไม่ได้ชั่วคราว โปรดติดต่อผู้จัดการบัญชี',
    session_timeout: 'หมดเวลา กำลังกลับไปหน้าเข้าสู่ระบบ',
    vload: {
      4019: 'ประเภทของบัตรกำนัลหรือมูลค่าไม่ถูกยอมรับ',
      4071: 'การต้องสงสัยการฉ้อโกง',
      4072: 'บัตรกำนัลถูกบล็อก',
      4073: 'บัตรกำนัลใช้งานไม่ได้',
      4074: 'บัตรกำนัลหมดอายุ',
      4075: 'ไม่พบบัตรกำนัล',
      4080: 'บัตรกำนัลมีมูลค่าไม่เพียงพอ',
      4444: 'ได้ใช้บัตรกำนัลที่ใช้งานไม่ได้ สกุลเงินไม่ตรงกัน',
      5000: 'ข้อมูลที่จัดเก็บไม่พร้อมใช้งาน',
    },
  },
  tradingTool: {
    mt4: {
      mt4Text1: '',
      mt4Text2: '',
      mt4Text3: '',
      mt4Text4: '',
      mt4Text5: '',
      mt4Text6: '',
      mt4Text7: '',
      mt4Text8: '',
      mt4Text9: '',
      mt4Text10: '',
      mt4Text11: '',
      mt4Text12: '',
      mt4Text13: '',
      mt4Text14: '',
      mt4Text15: '',
      mt4Text16: '',
      mt4Text17: '',
      mt4Text18: '',
      mt4Text19: '',
      mt4Text20: '',
      mt4Text21: '',
      mt4Text22: '',
      mt4Text23: '',
    },
    pamm: {
      pammSystem: 'ระบบ PAMM',
      pammSystemPopular: 'ระบบ PAMM เป็นระบบการจัดการการซื้อขายอัตโนมัติที่ได้รับความนิยมมากที่สุด',
      mostAdvantage: 'ข้อได้เปรียบที่ใหญ่ที่สุดคือการจัดสรรตามเปอร์เซ็นต์ของปริมาณการซื้อขายในหมู่นักลงทุนทั้งหมดโดยปกติจะขึ้นอยู่กับบาลานซ์หรืออิควิตี้ของนักลงทุน',
      pammTitle: 'ระบบการจัดการบัญชี PAMM มีข้อดีดังต่อไปนี้',
      pammText1: 'พัฒนาบนพื้นฐานของแพลตฟอร์มการซื้อขายที่ได้รับการยอมรับทั่วโลกอย่าง MT4',
      pammText2: 'การจัดสรรตามเปอร์เซ็นต์ของปริมาณการคำสั่งซื้อขาย',
      pammText3: 'ผลการซื้อขายที่สอดคล้องกันระหว่างบัญชีนักลงทุนและบัญชีมาสเตอร์',
      pammText4: 'รองรับการซื้อขายด้วย EA',
      pammText5: 'การจัดการความเสี่ยงแบบเรียลไทม์',
      pammAccount: 'การจัดการบัญชี PAMM',
      pammMangeAccount: 'บัญชีผู้จัดการ PAMM',
      pammInvestAccount: 'บัญชีนักลงทุน PAMM',
      checkPammMange: 'นักลงทุนสามารถเลือกผู้จัดการ PAMM ที่เหมาะสมตามวัตถุประสงค์การลงทุนของตนเองได้',
    },
    copytrade: {
      copytrade1: 'คัดลอกการเทรด เทรดอย่างชาญฉลาด เพื่อกำไรที่มากกว่า',
      copytrade2: 'COPYTRADE เป็นแพลตฟอร์มที่เปิดโอกาสให้คุณคัดลอกคำสั่งซื้อขายของนักเทรดมืออาชีพ เข้าร่วมชุมชน STARTRADER COPYTRADE และคัดลอกการซื้อขายของผู้ให้บริการด้วยขั้นตอนง่ายๆ ไม่กี่ขั้นตอน เรียนรู้กลยุทธ์ของพวกเขาและพัฒนาทักษะการซื้อขายของคุณ',
      tradeNow: 'เทรดเลย',
      rank: 'จัดอันดับ',
      copytrade3: 'ขั้นตอนติดตามคัดลอก',
      copytrade4: 'เข้าสู่ระบบ',
      copytrade5: 'เข้าสู่ระบบ - เข้าสู่ระบบชุมชนเพื่อเป็นผู้ติดตาม',
      copytrade6: 'สมัครติดตามคัดลอก',
      copytrade7: 'ดูกราฟกำไรและเลือกบัญชีผู้ให้บริการที่คุณต้องการสมัครและคัดลอกการซื้อขายด้วยตัวคุณเอง',
      copytrade8: 'คัดลอกคำสั่งซื้อสำเร็จแล้ว',
      copytrade9: 'คำสั่งซื้อที่คัดลอกสามารถดูได้บน MT4 หรือแพลตฟอร์มก็อปปี้เทรด',
      copytrade10: 'ผู้ให้บริการ',
      copytrade11: 'สร้างโปรไฟล์ส่วนตัว',
      copytrade12: 'เปิดเป็นสาธารณะหรือมองเห็นได้เฉพาะบางคนเท่านั้น',
      copytrade13: 'จำกัดเงินทุนเริ่มต้นของผู้ติดตามคัดลอด',
      copytrade14: 'ลงทะเบียนเป็นผู้ให้บริการอัตโนมัติ',
      copytrade15: 'ตั้งค่าส่วนแบ่งผลกำไรกับสำหรับสมาชิกผู้ติดตาม',
      copytrade16: 'กำหนดส่วนแบ่งผลกำไรได้ด้วยตัวเอง',
      copytrade17: 'ผู้ติดตาม',
      copytrade18: 'ติดตามคัดลอกได้หลายคนพร้อมกัน',
      copytrade19: 'รูปแบบการติดตามคัดลอกที่หลากหลาย',
      copytrade20: 'สามารถตั้งค่าคัดลอกคำสั่งซื้อจากผู้ให้บริการได้ตามต้องการ',
      copytrade21: 'การป้องกันความปลอดภัยของออเดอร์',
      copytradeBtn1: 'ต้องการเป็นผู้ให้บริการ',
      copytradeBtn2: 'ต้องการเป็นผู้ติดตาม',
    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
