<template>
  <div class="page_wrapper" :class="{ tabs: tabs.length > 0 }">
    <div class="page_wrapper_tab" v-if="tabs.length > 0">
      <div
        ref="tabItem"
        :data-testid="tab.dataTestId"
        class="item"
        :class="{ 'is-active': innerTabActive === tab.name }"
        v-for="tab in tabs"
        @click="onTabClick(tab)"
      >
        {{ tab.label }}
      </div>
      <div class="bar" :style="getTabBarStyle"></div>
    </div>
    <div class="container">
      <slot></slot>
    </div>
    <slot name="fixedFooter"></slot>
  </div>
</template>

<script>
export default {
  name: 'PageWrapper',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabActive: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      barWidth: 0,
      barTransformX: 0,
    }
  },
  watch: {
    tabActive: {
      handler() {
        if (this.tabs.length === 0) return
        this.$nextTick(() => {
          const tabs = this.$refs.tabItem
          const activeElement = tabs.find(item => Array.prototype.includes.call(item.classList, 'is-active'))
          this.barWidth = activeElement.offsetWidth - 40
          this.barTransformX = activeElement.offsetLeft + 16
        })
      },
      immediate: true,
    },
  },
  computed: {
    innerTabActive: {
      get() {
        return this.tabActive
      },
      set(val) {
        this.$emit('update:tabActive', val)
      },
    },
    getTabBarStyle() {
      return {
        width: this.barWidth + 'px',
        transform: `translateX(${this.barTransformX}px)`,
      }
    },
  },
  methods: {
    onTabClick(tab) {
      this.innerTabActive = tab.name
      this.$emit('tabClick', tab)
    },
  },
}
</script>

<style lang="scss" scoped>
.page_wrapper {
  position: relative;
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;

  .page_wrapper_tab {
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);
    background: $white;
    padding: 0 4px;
    height: 40px;
    line-height: 40px;
    display: flex;
    overflow-x: auto;
    width: 100%;
    z-index: 100;

    &::-webkit-scrollbar {
      display: none;
    }

    .bar {
      position: absolute;
      bottom: 0;
      left: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: $primary;
      z-index: 1;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }

    .item {
      width: fit-content;
      font-size: 16px;
      font-weight: 400;
      padding: 0 20px;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        font-weight: 700;
        color: $primary;
      }
    }
  }

  .container {
    padding: 16px 24px;

    @include screen-mobile {
      padding: 16px 0;
    }
  }
}
</style>
