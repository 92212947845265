import { instance } from '@/axios/index.js'
import { personalDetailUrl, verificationUrl, questionUrl } from './index'

export const apiCountries = lang => instance.get(`${personalDetailUrl}countries?lang=${lang}`)
export const apiProvinces = lang => instance.get(`${personalDetailUrl}provinces?lang=${lang}`)
export const apiNationalities = lang => instance.get(`${personalDetailUrl}nationalities?lang=${lang}`)
export const apiBirthPlace = lang => instance.get(`${personalDetailUrl}birthPlace?lang=${lang}`)
export const apiPhoneCodes = () => instance.get(`${personalDetailUrl}phoneCodes`)
export const apiID3Status = () => instance.get(`${personalDetailUrl}id3Passed`)

export const apiEmploymentFinance = lang => instance.get(`${questionUrl}employmentFinance?lang=${lang}`)
export const apiTrading = lang => instance.get(`${questionUrl}trading?lang=${lang}`)

export const apiGetData = step => instance.get(`${verificationUrl}getData?step=${step}`)
export const apiProcess = data => instance.post(`${verificationUrl}process`, data)
