import axios from 'axios'
import { startLoading, endAjaxLoading } from '@/util/loading'
import respHandler from './respHandler'
import DOMPurify from 'dompurify'
import allowedHtmlTag from '@/util/allowedHtmlTag'
import qs from 'qs'

const instance = axios.create()
const instanceExternal = axios.create()

const iterateData = obj => {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      iterateData(obj[key])
      return
    }
    if (typeof obj[key] === 'string') {
      obj[key] = DOMPurify.sanitize(obj[key], { ALLOWED_TAGS: allowedHtmlTag })
    }
  })
}

// 修改post请求数据格式
instance.interceptors.request.use(
  config => {
    startLoading('ajax')
    config.withCredentials = true

    if (config.method === 'post') {
      const data = config.data

      if (typeof data === 'string') {
        const parseData = qs.parse(data)
        iterateData(parseData)
        config.data = DOMPurify.sanitize(qs.stringify(parseData), { ALLOWED_TAGS: allowedHtmlTag })
      }

      if (typeof data === 'object') {
        iterateData(config.data)
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 修改post请求数据格式
instance.interceptors.response.use(
  resp => respHandler(resp),
  function(error) {
    endAjaxLoading()
    console.log(error)
    return Promise.reject(error)
  }
)

export { instance, instanceExternal }
