const fsa = require('@/regulator/fsa.js')
const svg = require('@/regulator/svg.js')

const regulator = {
  fsa,
  svg,
}

module.exports = {
  platformLang: 'en_US',
  name: 'star',
  isAU: false,
  isPUG: false,
  isStar: true,
  info: {
    fullName: 'STARTRADER',
    capitalName: 'STARTRADER',
    getUrl(reg) {
      return reg ? regulator[reg].info.url : ''
    },
    getEmail(reg) {
      return reg ? regulator[reg].info.email : ''
    },
    getAccountEmail(reg) {
      return reg ? regulator[reg].info.accountEmail : ''
    },
    getBlacklistEmail(reg) {
      return reg ? regulator[reg].info.blacklistEmail : ''
    },
  },
  getMenus(reg) {
    return reg ? regulator[reg].menus : []
  },
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard',
    2: 'Prime',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Islamic Standard',
    7: 'Islamic Prime',
    8: 'PRO ECN',
    9: 'VIP STP',
    10: 'PAMM', // mt4
    101: 'MT5 Standard STP',
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Standard',
    14: 'Prime',
    15: 'Islamic Standard',
    16: 'Islamic Prime',
    17: 'MT5 PRO ECN',
    19: 'Cent',
    20: 'Islamic-Cent',
    22: 'Prime Cent',
    23: 'Islamic Prime Cent',
    24: 'Cent', //mt5 Standard Stp Cent
    25: 'Islamic-Cent', //mt5 Islamic Cent
    26: 'Prime Cent', //mt5 Prime Cent
    27: 'Islamic Prime Cent', //mt5 Islamic Prime Cent
    28: 'PAMM', // mt5
    31: 'Pro Standard', // MT4 pro 1000
    32: 'MT5 Pro Standard', // MT5 pro 1000
    33: 'ECN',
    34: 'MT5 ECN',
  },
  getTradingPlatform(reg) {
    return reg ? regulator[reg].tradingPlatform : {}
  },
  restrictDepositFundsByCountries(reg) {
    return reg ? regulator[reg].restrictDepositFundsByCountries : {}
  },
  restrictOpenAdditionAccountCountries(reg) {
    return reg ? regulator[reg].restrictOpenAdditionAccountCountries : {}
  },
  openLiveAccount: {
    choosePlatform(reg) {
      return regulator[reg].openLiveAccount.choosePlatform
    },
    // open mt4 live account
    chooseLiveType(reg) {
      return regulator[reg].openLiveAccount.chooseLiveType
    },
    // register mt4 account
    mt4RegisterChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4RegisterChooseType
    },
    // open mt4 demo account
    mt4DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4DemoChooseType
    },
    // open mt5 live account
    mt5ChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5ChooseType
    },
    // register mt5 account
    mt5RegisterChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5RegisterChooseType
    },
    // open mt5 demo account
    mt5DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5DemoChooseType
    },
    chooseCurrency(reg, account = '') {
      return regulator[reg].openLiveAccount.chooseCurrencyByType[account]
        ? regulator[reg].openLiveAccount.chooseCurrencyByType[account]
        : regulator[reg].openLiveAccount.chooseCurrency
    },
    chooseLeverage(reg) {
      return regulator[reg].openLiveAccount.chooseLeverage
    },
    chooseBalance(reg) {
      return regulator[reg].openLiveAccount.chooseBalance
    },
    getAgreeCondition(reg) {
      return regulator[reg].openLiveAccount.getAgreeCondition
    },
  },
  getMetaTraderLinks(reg, url) {
    return regulator[reg].metaTraderLinks(url)
  },
  getIntBankTransferCurrency(reg) {
    if (reg == 'fsa') {
      return regulator[reg].bankInfoDetails.currency
    } else {
      const paymentType = 'payment1'
      return regulator[reg].bankInfoDetails[paymentType].currency
    }
  },
  bank_infos(currency, reg, type) {
    let bank_infos = []

    if (reg == 'fsa') {
      bank_infos.push({
        title: 'common.field.bankName',
        info: type ? regulator[reg].bankInfoDetails[type].bank_name : regulator[reg].bankInfoDetails.bank_name,
      })
      bank_infos.push({
        title: 'common.field.branchAddress',
        info: type
          ? regulator[reg].bankInfoDetails[type].branch_address[currency]
          : regulator[reg].bankInfoDetails.branch_address[currency],
      })
      // 非AUD
      if (type == 1) {
        bank_infos.push({
          title: 'common.field.bankBranch',
          info: regulator[reg].bankInfoDetails[type].branch_name[currency],
        })

        // only AUD
        bank_infos.push({
          title: 'common.field.bsbNum',
          info: regulator[reg].bankInfoDetails[type].bsb_number[currency],
        })
      }
      bank_infos.push({
        title: 'common.field.bankBeneAccName',
        info: type ? regulator[reg].bankInfoDetails[type].account_name : regulator[reg].bankInfoDetails.account_name,
      })
      bank_infos.push({
        title: 'common.field.bankBeneAccNum',
        info: type
          ? regulator[reg].bankInfoDetails[type].account_number[currency]
          : regulator[reg].bankInfoDetails.account_number[currency],
      })
      // 非AUD
      bank_infos.push({
        title: 'common.field.bankBeneAddress',
        info: type
          ? regulator[reg].bankInfoDetails[type].beneficiary_address
          : regulator[reg].bankInfoDetails.beneficiary_addres,
      })

      bank_infos.push({
        title: 'common.field.swift',
        info: type ? regulator[reg].bankInfoDetails[type].swift_code : regulator[reg].bankInfoDetails.swift_code,
      })
    } else {
      bank_infos.push({
        title: 'common.field.bankBeneAccName',
        info: type ? regulator[reg].bankInfoDetails[type].account_name : regulator[reg].bankInfoDetails.account_name,
      })

      bank_infos.push({
        title: 'common.field.bankBeneAccNum',
        info: type
          ? regulator[reg].bankInfoDetails[type].account_number[currency]
          : regulator[reg].bankInfoDetails.account_number[currency],
      })

      bank_infos.push({
        title: 'common.field.bsbNum',
        info: regulator[reg].bankInfoDetails[type].bsb_number[currency],
      })

      bank_infos.push({
        title: 'common.field.swift',
        info: type ? regulator[reg].bankInfoDetails[type].swift_code : regulator[reg].bankInfoDetails.swift_code,
      })

      bank_infos.push({
        title: 'common.field.bankName',
        info: type ? regulator[reg].bankInfoDetails[type].bank_name : regulator[reg].bankInfoDetails.bank_name,
      })
      bank_infos.push({
        title: 'common.field.branchAddress',
        info: type
          ? regulator[reg].bankInfoDetails[type].branch_address[currency]
          : regulator[reg].bankInfoDetails.branch_address[currency],
      })

      bank_infos.push({
        title: 'common.field.bankBeneAddress',
        info: type
          ? regulator[reg].bankInfoDetails[type].beneficiary_address
          : regulator[reg].bankInfoDetails.beneficiary_addres,
      })

      if (type === 'payment1') {
        bank_infos.push({
          title: 'common.field.bankAddress',
          info: regulator[reg].bankInfoDetails[type].bank_address,
        })
      }
    }

    return bank_infos
  },
  country_transferOptions: [
    {
      code: 2,
      nameKey: 'common.country.international',
    },
  ],
  paymentMethods: [{ label: 'common.withdrawChannel.unionpay', component: 'UnionPayRegister' }],
  withdrawMethods(reg) {
    return regulator[reg].withdrawMethods
  },
  pageLoader(regulator = 'fsa', componentName) {
    return import(`@/views/regulator/${regulator}/${componentName}`)
  },
  componentLoader(regulator = 'fsa', componentName, checkFileExist = false) {
    function fileInfo(exist = false, component) {
      this.exist = exist
      this.component = component
    }

    let file = new fileInfo()

    if (checkFileExist) {
      let components
      if (regulator == 'fsa') {
        components = require.context(`@/components/regulator/fsa`, true, /^\.\/.*\.vue$/)
      } else if (regulator == 'svg') {
        components = require.context(`@/components/regulator/svg`, true, /^\.\/.*\.vue$/)
      }

      if (components) {
        components.keys().map(requireContext => {
          const fileName = requireContext.replace('./', '').replace('.vue', '')
          if (fileName === componentName) {
            file.exist = true
            file.component = import(`@/components/regulator/${regulator}/${componentName}`)
          }
        })
      }
    } else {
      file.exist = true
      file.component = import(`@/components/regulator/${regulator}/${componentName}`)
    }

    return file
  },
  allowIBTDeposit({ reg, countryCode }) {
    const { ALLOW_ALL, WHITELIST } = regulator[reg].internationalBankTransfer.DEPOSIT
    if (ALLOW_ALL) {
      if (typeof WHITELIST[countryCode] !== 'undefined' && !WHITELIST[countryCode]) {
        return false
      }
      return true
    }
    return WHITELIST[countryCode]
  },
  restrictCreditCardCountry({ reg, countryCode }) {
    return regulator[reg].restrictCreditCardCountryList.includes(countryCode)
  },
  depositDefaultAmountMin: 50,
  depositDefaultAmountMax: 99999999.99,
  depositOneAmountMin: 1,
  depositUSCAmountMin: 2000,
  depositUSC5000Amount: 5000,
  depositUSCAmountMax: 100000,
  withdrawalDefaultAmountMin: 40,
  usdRateFromApiCurrencyList: ['JPY', 'HKD', 'USC'], // currencies whitch calling api to get rate (USD to specific currency)
  getWebtradeCode(reg) {
    return reg ? regulator[reg].webtradeCode : ''
  },
  getPammLinks(reg) {
    return reg ? regulator[reg].pammLinks : ''
  },
  getProtocolUrl(reg) {
    return reg ? regulator[reg].protocolUrl : ''
  },
  getProtocol(reg) {
    return reg ? regulator[reg].protocol : ''
  },
  getTradingToolTabs(reg) {
    return reg ? regulator[reg].tradingToolTabs : ''
  },
}
