<template>
  <div class="status_message">
    <div class="title">
      <svg-icon className="icon" :iconClass="type"></svg-icon>
      <span> {{ title }}</span>
    </div>
    <div v-if="!$slots.content && content" class="content">{{ content }}</div>
    <div v-else>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusMessage',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
      validator: value => ['success', 'error', 'warning', 'info'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.status_message {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-title;
    font-size: 18px;

    .icon {
      font-size: 26px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
    }
  }

  .content {
    text-align: center;
    color: $text-secondary;
    font-size: 14px;
    margin-top: 16px;
  }
}
</style>
