import { apiGetData } from '@/resource/register'

const NATIONID_REQUIRELENGTH = 2

export default {
  data() {
    return {
      form: {
        idDocFilePathList: [],
        poaDocFilePathList: [],
        step: 5,
      },
      idProofType: 0,
      foamData: null,
      showIdDialog: false,
      showPoaDialog: false,
    }
  },
  inject: ['onIdPlayerReady', 'onPoaPlayerReady'],
  computed: {
    isNationalId() {
      return this.idProofType === 1
    },
    secondNationalId() {
      return this.isNationalId && this.form.idDocFilePathList.length > 0
    },
    uploadLimit() {
      return this.isNationalId ? NATIONID_REQUIRELENGTH : 6
    },
    checkIdDocFile() {
      return this.form.idDocFilePathList.length > 0
    },
    checkPocDocFile() {
      return this.form.poaDocFilePathList.length > 0
    },
  },
  methods: {
    getUserData() {
      apiGetData(this.form.step)
        .then(resp => {
          this.idProofType = resp.data.data.idType
          this.foamData = resp.data.data
        })
        .catch(err => {
          this.errorMessage($t('responseMsg.' + result.data.code))
        })
    },
    updateIDInfo(fileInfo) {
      this.form.idDocFilePathList = fileInfo.fileList
    },
    updatePOAInfo(fileInfo) {
      this.form.poaDocFilePathList = fileInfo.fileList
    },
    onIdPlayerClick() {
      this.showIdDialog = true
      this.onIdPlayerReady()
    },
    onPoaPlayerClick() {
      this.showPoaDialog = true
      this.onPoaPlayerReady()
    },
    closeID() {
      this.showIdDialog = false
    },
    closePOA() {
      this.showPoaDialog = false
    },
  },
  mounted() {
    this.getUserData()
  },
}
