export default {
  en: 'English',
  zh: '中文',
  fr: 'Francês',
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    country: { international: 'Internacional', australia: 'Austrália' },
    verification: { toSlide: 'Deslize para completar o quebra-cabeça' },
    field: {
      nameOnCard: 'Nome no cartão',
      cardholderName: `Nome do titular do Cartão`,
      first4Digits: 'Primeiros 4 Dígitos no Cartão',
      last3Digits: 'Últimos 3 Dígitos no Cartão',
      first6Digits: 'Primeiros 6 Dígitos no Cartão',
      last4Digits: 'Últimos 4 Dígitos no Cartão',
      bankName: 'Nome do Banco',
      bankAddress: 'Endereço do Banco',
      branchAddress: 'Endereço da Filial',
      bankBranch: 'Agência Bancária',
      bankCity: 'Cidade do Banco',
      bankProvince: 'Província do Banco',
      bankBeneficiaryName: 'Nome do Beneficiário Bancário',
      bankBeneAccName: 'Nome da Conta do Beneficiário Bancário',
      bankBeneAccNum: 'Número de Conta do Beneficiário Bancário',
      bankBeneAddress: 'Endereço do Beneficiário',
      bankAccNum: 'Número de Conta Bancária',
      bankAccName: 'Nome da Conta Bancária',
      bankHolderAddress: 'Endereço do Titular da Conta',
      address: 'Endereço',
      accName: 'Nome da Conta',
      accNum: 'Número de Conta',
      accNumTo: 'Número de Conta',
      bsbNum: 'Número BSB',
      swift: 'Código SWIFT',
      ifscCode: 'IFSC code',
      swiftNonAUD: 'Código SWIFT (Contas Não AUD)',
      amt: 'Valor',
      amtUSD: 'Valor (USD)',
      availableAmount: 'Valor Disponível',
      transitNumber: 'Número de Trânsito',
      institutionNumber: 'Número da Instituição',
      imptNotes: 'Notas importantes',
      yes: 'Sim',
      no: 'Não',
      payMethod: 'Método de Pagamento:',
      expDate: 'Data de Validade',
      bsb: 'BSB',
      abaSortCode: 'Código ABA/Sort:',
      country_region: 'País / Região',
      currLeverage: 'Alavancagem Atual',
      accEquity: 'Capital da conta',
      newLeverage: 'Alavancagem Desejada',
      currPw: 'Palavra-passe Atual',
      newPw: 'Nova Palavra-passe',
      confirmPw: 'Confirmar a Nova Palavra-passe',
      loginEmail: 'E-mail para Início de Sessão',
      email: 'E-mail',
      pw: 'Palavra-passe',
      accType: 'Tipo de Conta',
      name: 'Nome',
      emailAdd: 'Endereço de E-mail',
      phone: 'Número de Telefone',
      dob: 'Data de Nascimento',
      nat: 'Nacionalidade',
      country: 'País de residência',
      street: 'Número da Rua/Nome da Rua',
      suburbOrCity: 'Freguesia/Cidade',
      provinceOrState: 'Concelho/Distrito',
      postcode: 'Código Postal',
      empStat: 'Situação Profissional',
      annIncome: 'Rendimento Anual',
      save: 'Investimento',
      source: 'Fonte de Rendimento',
      industry: 'Atividade',
      statusDef: 'DEFINIÇÕES DO ESTADO',
      month: 'Mês',
      year: 'Ano',
      select: 'Selecionar',
      completed: 'Concluído',
      incomplete: 'Incompleto',
      submitted: 'Submetido',
      successful: 'Com Sucesso',
      processing: 'Em Processamento',
      cancelled: 'Cancelado',
      failed: 'Falhado',
      rejected: 'Rejeitado',
      upload: 'Carregar',
      pending: `pendente`,
      ccNum: 'Número do Cartão de Crédito',
      ccExpireDate: 'Expiry Date',
      bankCardNumber: 'Número do Cartão Bancário',
      cardHolderName: 'Nome do Titular do Cartão',
      reservedMobileNumber: 'Número de Telemóvel para o Banco',
      nationalId: 'Cartão Nacional de Identidade',
      cardPhoto: 'Foto do Cartão (frente)',
      notes: 'Notas',
      bankAccOrIBAN: 'Número da Conta Bancária/IBAN',
      selectWithdrawCard: 'Selecione o Seu Cartão para Levantamentos',
      updateCardPhoto: 'por favor, faça o carregamento da foto do seu cartão (Frente)',
      actualAmount: 'montante atual',
      resetBalTo: 'Redefinir o Saldo para',
      ipChangeWarning: 'Receba um e-mail de alerta quando alguém entrar na sua conta a partir de um local diferente',
      enterVerificationCode: 'Insira o Código de Verificação',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'DATA',
      type: 'TIPO',
      server: 'SERVIDOR',
      status: 'ESTADO',
      comment: 'COMENTÁRIO',
      accNum: 'NÚMERO DE CONTA',
      currency: 'MOEDA',
      equity: 'CAPITAL PRÓPRIO',
      leverage: 'ALAVANCAGEM',
      resetMT4PW: 'REDEFINIR A PALAVRA PASSE DO MT4',
      resetMT5PW: 'REDEFINIR A PALAVRA PASSE DO MT5',
      tradingAcc: 'CONTA DE NEGOCIAÇÃO',
      method: 'MÉTODO',
      amt: 'VALOR',
      procNote: 'NOTAS DO PROCESSO',
      number: 'NÚMERO DO CARTÃO',
      PLATFORM: 'PLATAFORMA',
      resetBal: 'REDEFINIR O SALDO',
    },
    key: { from: 'DE', to: 'PARA' },
    fund: {
      must: 'OBRIGAÇÃO',
      infoHover: 'O nome no seu cartão {deve} corresponder ao nome da sua conta de negociação',
      ccUploadReminder:
        'Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
    },
    button: {
      bkToHm: 'VOLTAR AO INÍCIO',
      smsLogin: 'INÍCIO DE SESSÃO NO TELEMÓVEL',
      goToIB: 'IR Para o Portal IB',
      logout: 'ENCERRAR SESSÃO',
      submit: 'Submeter',
      selectFile: 'Selecionar Ficheiro',
      depositFunds: 'DEPOSITAR FUNDOS',
      withdrawFunds: 'LEVANTAR FUNDOS',
      ibPortal: 'PORTAL IB',
      download: 'DESCARREGAMENTOS',
      changeLeverage: 'ALTERAR ALAVANCAGEM',
      cancel: 'Cancelar',
      changePw: 'ALTERAR PALAVRA-PASSE',
      forgetPw: 'ESQUECEU A PALAVRA-PASSE',
      confirm: 'Confirmar',
      login: 'INÍCIO DE SESSÃO',
      forgotPw: 'Esqueceu a Palavra-passe?',
      completeQuestionnaire: 'QUESTIONÁRIO COMPLETO',
      identityProof: 'INSCRIÇÃO COMPLETA',
      upload: 'CARREGAR',
      export: 'EXPORTAR',
      ok: 'OK',
      hide: 'OCULTAR',
      showAll: 'MOSTRAR TUDO',
      createLiveAccount: `Criar Conta Real`,
      redeem: `Resgatar`,
      sendCode: 'Enviar Código',
      resendCode: 'Reenviar código',
    },
    formValidation: {
      accReq: 'O número da conta é obrigatório',
      amtReq: 'O valor é obrigatório',
      ccReq: 'O número do cartão de crédito é obrigatório',
      cardReq: 'O número do cartão é obrigatório',
      cardOnNameReq: 'O nome no cartão é obrigatório',
      cardholderNameReq: `O nome do titular do cartão é obrigatório`,
      digits16: 'Por favor, insira 16 dígitos',
      digits3or4: 'Por favor, insira 3 ou 4 dígitos',
      digitsFirst6: 'Por favor, insira os primeiros 6 dígitos',
      digitsLast4: 'Por favor, insira os últimos 4 dígitos',
      digitsLast3: 'Por favor, insira os últimos 3 dígitos',
      mid6Digits: 'Por favor, insira os {number} dígitos do meio',
      expDateReq: 'A data de validade é obrigatória',
      cvvReq: 'O Código de Segurança CVV é obrigatório',
      file: 'Por favor, faça o carregamento de um ficheiro',
      filePhoto: 'A Foto do Cartão de Crédito é obrigatória',
      fileReceipt: 'Por favor, faça o carregamento de um recibo',
      filePhotoReq: 'A Foto do Cartão é obrigatória',
      amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a {minLimit} {currency}',
      amt0: 'O valor não pode ser 0',
      amtLess: 'O valor do seu depósito não pode ser superior a {maxLimit} {currency}',
      note256: 'O limite do comprimento da nota é de 256 caracteres',
      noteMoreThan: 'O limite do comprimento da nota é de {limit} caracteres',
      noteLessThan: 'O limite do comprimento da nota é de {limit} caracteres',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      payMethodReq: 'O método de pagamento é obrigatório',
      expReq: 'A data de validade é obrigatória',
      bankNameReq: 'O Nome do Banco é obrigatório',
      bankCityReq: 'A Cidade do Banco é obrigatória',
      bankProvinceReq: 'O Concelho do Banco é obrigatório',
      bankAddressReq: 'O Endereço do Banco é obrigatório',
      bankBranchReq: 'A Agência Bancária é obrigatória',
      bankAccNumReq: 'O Número da Conta Bancária é obrigatório',
      ifscCodeReq: 'IFSC Code is required',
      bankBeneficiaryNameReq: 'O Nome do Beneficiário Bancário é obrigatório',
      bankHolderAddressReq: 'O Endereço do Titular da Conta é obrigatório',
      swiftReq: 'O código Swift é obrigatório',
      accHolderAddress: 'O Endereço do Titular da Conta é obrigatório',
      bsbReq: 'O BSB é obrigatório',
      accNumIBANReq: 'O Número da Conta Bancária/IBAN é obrigatório',
      accNameReq: 'O Nome da Conta Bancária é obrigatório',
      withdrawMethodReq: 'Por favor, selecione um método de levantamento',
      tnc: 'Por favor, concorde com os Termos e Condições.',
      currPwReq: 'A palavra-passe atual é obrigatória',
      currPwWrong: 'A palavra-passe atual está incorreta',
      newPwReq: 'A nova palavra-passe é obrigatória',
      newPwFormat:
        'A sua palavra-chave deve ter entre 8-16 caracteres e uma mistura de letras (a-z e A-Z), números (0-9) e caracteres especiais como !@#$%^&*.()',
      IdenficalPw: 'A nova palavra-passe não pode ser igual à antiga',
      confirmPwReq: 'Por favor, insira a nova palavra-passe novamente',
      confirmNotMatch: 'Os dois dados inseridos não coincidem',
      pwReq: 'A palavra-passe é obrigatória',
      accCurrReq: 'Selecione uma moeda de base para sua conta',
      tradAccReq: 'Por favor, selecione uma conta de negociação',
      answerReq: 'Por favor, selecione',
      bankCardReq: 'Por favor, selecione um cartão',
      phoneNumberReq: 'O Número de Telemóvel para o Banco é obrigatório',
      nationaIdReq: 'A identificação Nacional é obrigatória',
      numberReq: 'Por favor, insira o número',
      alphanumericReq: 'Por favor, insira o alfanumérico',
      idTypeReq: 'Por favor, escolha um Tipo de Identificação',
      dobReq: 'Por favor, escolha a sua data de nascimento',
      pobReq: 'Por favor, escolha o seu local de nascimento',
      nationalityReq: 'Por favor, escolha a sua nacionalidade',
      titleReq: 'Por favor, escolha o seu título',
      firstNameReq: 'Por favor, insira o seu nome próprio',
      lastNameReq: 'Por favor, insira o seu sobrenome',
      emailReq: 'Por favor, insira o seu endereço de e-mail',
      addressReq: 'Por favor, insira a sua morada',
      suburbReq: 'Por favor, insira a sua cidade ou freguesia',
      stateReq: 'O distrito ou concelho é obrigatório',
      postcodeReq: 'Por favor, insira o seu código postal',
      countryReq: 'Por favor, selecione o seu país de residência',
      masterCardReq: 'Por favor, utilize Master Card para depositar',
      masterCardJCBReq: `Use um Master Card / JCB para depositar`,
      masterCardVisaReq: `Use um Master Card / Visa para depositar`,
      masterCardAmexJCBReq: `Use um Master Card / AMEX / JCB para depositar`,
      bankCodeReq: 'Por favor, insira o Banco Emissor do Cartão',
      dynamicReq: '{dynamic} é obrigatório',
      mobReq: 'o telemóvel é obrigatório',
      resetBalAmt: 'Por favor, defina o montante entre 0 e 1.000.000',
      verCodeReq: 'O código de verificação é obrigatório',
      emailFormatWrong: 'Formato de e-mail incorreto',
      passwordFormatWrong: 'Formato de palavra-passe incorreto',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Transferência Bancária/BPay/PoliPay',
      banktransfer: 'Transferência Bancária',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferência UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Cartão de Crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Cartão de Crédito/Débito',
      bankwiretransferinternational: 'Transferência Bancária (Internacional)',
      banktransferaustralia: 'Transferência Bancária (Austrália)',
      banktransferinternational: 'Transferência Bancária (Internacional)',
      banktransferchina: 'Transferência Bancária (Internacional)',
      banktransferequals: 'Transferência Bancária (Internacional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferência de Corretora para Corretora',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Serviço Bancário Online (Tailândia)',
      internetbankingnigeria: 'Serviço Bancário Online (Nigéria)',
      internetbankingvietnam: 'Serviço Bancário Online (Vietname)',
      internetbankingmalaysia: 'Serviço Bancário Online (Malásia)',
      internetbankingindonesia: 'Serviço Bancário Online (Indonésia)',
      internetbankingkorea: 'Serviço Bancário Online (Coréia)',
      internetbankingindia: 'Serviço Bancário Online (India)',
      internetbankingphilippine: 'Serviço Bancário Online (Philippine)',
      internetbankingsouthafrica: 'Serviço Bancário Online (África do Sul)',
      internetbankinguganda: 'Serviço Bancário Online (Uganda)',
      internetbankingrwanda: 'Serviço Bancário Online (Ruanda)',
      internetbankingzambia: 'Serviço Bancário Online (Zâmbia)',
      internetbankingcongo: 'Serviço Bancário Online (Congo)',
      internetbankingcameroon: 'Serviço Bancário Online (Camarões)',
      internetbankingburundi: 'Serviço Bancário Online (Burundi)',
      internetbankingkenya: 'Serviço Bancário Online (Quênia)',
      internetbankingghana: 'Serviço Bancário Online (Gana)',
      internetbankingtanzania: 'Serviço Bancário Online (Tanzânia)',
      internetbankingcanada: 'Serviço Bancário Online (Canadá)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferência Bancária do Vietnam',
      nigeriabanktransfer: 'Transferência Bancária da Nigéria',
      polipayment: 'Pagamento com Poli',
      bankwiretransferaustralia: 'Transferência Bancária (Austrália)',
      thailandinstantbankwiretransfer: 'Transferência Bancária Instantânea Tailandesa',
      nigeriainstantbankwiretransfer: 'Transferência Bancária Instantânea Nigeriana',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malásia',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      southkoreabanktransfer: 'Transferência bancária local da Coreia do Sul',
      southafricainstantbankwiretransfer: 'Transferência Bancária Instantânea África do Sul',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea Philippines',
      ugandabanktransfer: 'Transferência de banco local de Uganda',
      rwandabanktransfer: 'Transferência de banco local de Ruanda',
      zambiabanktransfer: 'Transferência de banco local da Zâmbia',
      congobanktransfer: 'Transferência de banco local do Congo',
      cameroonbanktransfer: 'Transferência de banco local de Camarões',
      burundibanktransfer: 'Transferência bancária local do Burundi',
      kenyabanktransfer: 'Transferência bancária local do Quênia',
      ghanabanktransfer: 'Transferência de banco local de Gana',
      tanzaniabanktransfer: 'Transferência de banco local da Tanzânia',
      indiabanktransfer: 'Transferência Bancária da India',
      banktransferbpaypolipayaustralia: 'Transferência Bancária/BPay/PoliPay (Austrália)',
      banktransferbpaypolipayinternational: 'Transferência Bancária/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptomoeda-Bitcoin',
      cryptotetheromni: 'Criptomoeda-Tether(OMNI)',
      cryptotethererc20: 'Criptomoeda-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      cryptocurrencyeth: 'Criptomoeda-ETH',
      cryptocurrencyusdc: 'Criptomoeda-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Criptomoeda-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRIPTOCURRÊNCIA',
      LOCAL_TRANSFER: 'TRANSFERÊNCIA LOCAL',
      archiveThisCard: 'Por favor arquive este cartão se já não for válido ou não estiver em uso',
      manageCreditCards: 'Gerir cartões de crédito',
      update: 'Atualização',
      archive: 'Arquivo',
      requiredDocument: 'Documentos necessários para o arquivamento de cartões',
      officialLetter: 'Carta/email oficial do banco declarando que o cartão já não está disponível',
      bankStatement: 'Extrato bancário apresentando uma transação feita com este cartão',
      expiredCard: 'Cartões expirados/não disponíveis',
      confirmArchive: 'Tem a certeza de que quer arquivar este cartão?',
      confirmUpdate:
        'Tem a certeza de que quer atualizar a data de validade deste cartão? Nota: A Puprime não será responsável se o levantamento falhar devido à data de expiração incorreta fornecida',
      pendingArchiveRequest:
        'O seu pedido de arquivo de cartão de crédito está atualmente pendente de aprovação, por favor, aguarde até que o processo esteja concluído antes de fazer um levantamento, ou contacte o nosso serviço de apoio ao cliente através de chat ao vivo para agilizar o processo.',
    },
  },
  home: {
    pendingTooltip:
      'Depois da sua conta real ser aprovada, receberá um e-mail com os seus detalhes de início de sessão na MT{number} para que possa começar a negociar! Entretanto, pode depositar fundos na sua conta para receber uma abertura prioritária de conta.',
    leverage: {
      tnc:
        '<span>Termos e Condições</span><p>Ao submeter este pedido, eu reconheço que estou ciente de que a elevada alavancagem acarreta um elevado grau de risco para o meu capital e que existe a possibilidade de sofrer uma perda maior que, e não limitada, ao capital que eu depositei.<br><br>Concordo que o emissor do produto tem o direito de rever as minhas atividades de negociação e ajustar os níveis de alavancagem na minha conta de negociação a seu critério e sem nenhum aviso ou notificação prévia.<br><br>Eu compreende que, ao escolher uma elevada alavancagem, posso negociar com margem maior, mas sofrer potencialmente perdas significativas.</p>',
      tncConfirm: 'Eu li e concordo com os termos e condições.',
      changeLeverageConfirm: 'O seu pedido foi submetido e será processado em breve pela nossa equipa de suporte.',
      changeLeverageAutoComplete: 'O seu pedido de alteração de alavancagem foi concluído',
      changeLeverageReject: 'O seu pedido de alteração da alavancagem foi rejeitado',
      changeLeverageFail: 'O pedido de alteração da alavancagem falhou, por favor, tente mais tarde',
      changeLeverageDefault: 'An error has occurred, please contact {supportEmail} to finalise your application',
      alreadyApplied:
        'Já solicitou a alteração da alavancagem da sua conta e está atualmente em revisão pela equipa de suporte da {platform}. Por favor, aguarde até que este pedido seja finalizado antes de enviar outro pedido.',
      reduceLeverageWarn:
        'Observação: A redução de sua alavancagem pode resultar no fechamento forçado de posições abertas se sua conta tiver margem insuficiente.',
      reduceLeverageConfirm: 'Você deseja continuar?',
      autoRestore: `Como a equidade da sua conta ultrapassou USD 20.000, a alavancagem máxima a que está elegível para usar é de 500:1. Pode alterar a alavancagem para 1000:1 quando a equidade da sua conta for menor ou igual a USD 20.000`,
    },
    pw: {
      error: 'Falha ao redefinir a sua palavra-passe. Por favor, tente novamente mais tarde',
      confirm:
        'A sua palavra-passe foi atualizada.<br>Agora pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação MT4/MT5.',
      email:
        '<p class="first_info">Acabamos de lhe enviar um e-mail com instruções para redefinir a sua palavra-passe.</p><p>Por favor, certifique-se de que verifica a pasta de spam do seu e-mail.</p>',
    },
    activate: {
      question: 'Deseja reativar a sua conta real?',
      confirm: 'Recebemos o seu pedido de ativação.<br>A sua conta será ativada em breve.',
    },
    accStatus: {
      active: 'Ativa',
      rejected: 'Rejeitado',
      inactive: 'Inativa',
      processing: 'Em Processamento',
      activate: 'Ativar',
      expired: 'Expirada',
    },
    pro1000: {
      desc1: `As seguintes restrições aplicam-se a contas com alavancagem de 1000:1:`,
      conditions: [
        `A conta selecionada não é elegível para participar numa promoção relacionada com crédito.`,
        `A alavancagem pode ser reduzida automaticamente pelo sistema para 1:500 se a equidade da conta selecionada exceder USD20.000 ou equivalente. Se existir uma negociação aberta, será enviada uma notificação por e-mail antes do ajuste da alavancagem.`,
        `Os limites de alavancagem podem variar de acordo com os regulamentos aplicáveis.`,
        `Por favor, tenha em atenção que a Alavancagem de 1:1000 não está disponível para instrumentos financeiros pertencentes a Criptomoedas, Ações, Prata e Matérias-primas. A margem para estes instrumentos é mantida de acordo com os requisitos de margem fixa dos instrumentos e não é afetada por Alavancagem de 1:1000.`,
      ],
      desc2: `Ao submeter este pedido, reconheço que estou ciente de que a elevada alavancagem acarreta uma grande quantidade de risco para meu capital e existe a possibilidade de eu sofrer uma perda maior e não limitada ao capital que depositei.`,
      desc3: `Concordo que o emissor dos produtos tem o direito de rever as minhas atividades de negociação e ajustar os níveis de alavancagem na minha conta de negociação a seu critério e sem avisos ou notificações prévias.`,
      desc4: `Eu compreendo que, ao escolher uma elevada alavancagem, posso negociar com uma margem maior, mas potencialmente incorrer em perdas significativas.`,
    },
  },
  paymentDetails: {
    subHeader: 'ADICIONE UM MÉTODO DE PAGAMENTO',
    payMethod: 'Forma de Pagamento',
    tip:
      '<p>Selecione o seu método de pagamento preferido para depósitos e levantamentos futuros, no menu abaixo.</p><br />\n   <p><b>Por favor, tenha  atenção:</b> Para garantir que os seus pedidos de depósito e levantamento possam ser processados imediatamente, os regulamentos de AML/CTF exigem que o nome apresentado no método de pagamento escolhido seja\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Obrigado por submeter o método de financiamento para a sua conta de negociação. Estamos a processar o seu pedido e entraremos em contacto em breve.',
      unionPayCardSuccessMessage:
        'Os detalhes do Union Pay Card foram submetidos com sucesso, os seus novos dados bancários estarão disponíveis assim que forem aprovados',
    },
    history: { header: 'MÉTODOS DE PAGAMENTO' },
    cc: {
      field: {
        ccNum: 'Número do Cartão de Crédito',
        first6: 'Primeiros Seis',
        last4: 'Últimos Quatro',
        withrawlable: 'O levantamento máximo disponível para este cartão é {amount}.',
      },
      formValidation: { upload: 'A Foto do Cartão de Crédito é obrigatória' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'A buscar o seu estado de depósito... {countDown} segundos restantes.',
      successMsg: 'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve.',
      rejectMsg: 'Rejeitado',
      failureMsg: 'O seu depósito não foi bem sucedido. Por favor, tente novamente.',
      message:
        'Obrigado por depositar na sua conta. O seu depósito será aprovado manualmente pela equipa de contas da {platform} em breve. Os fundos serão depositados na sua conta assim que forem aprovados',
      defaultException:
        'Para confirmar o seu depósito com a {platform}, por favor entre em contacto com o suporte {email}',
    },
    failed: {
      countDownMsg: 'A buscar o seu estado de depósito... {countDown} segundos restantes.',
      rejectMsg: 'Rejeitado',
    },
  },
  deposit: {
    alert: {
      depositBonus50: `É elegível para receber 50% de bónus de depósito. Ative antes de depositar!`,
    },
    default: {
      deposit: 'Depositar',
      reference: 'A sua referência',
      sendReceipt: 'Enviar recibo',
      sendReceiptTip:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma captura de tela, foto ou cópia digitalizada do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      reminder: 'Por favor, aguarde de 1 a 2 dias úteis para que os fundos sejam creditados na sua conta.',
      successMsg:
        'Os fundos vão aparecer na sua conta de negociação assim que recebermos o depósito e verificarmos o recibo da transação.',
      alt: 'Como alternativa, pode enviar o recibo da transação por e-mail para {mailTo} o mais brevemente possível.',
      reminderFundAppear:
        'Os seus fundos vão aparecer na sua conta assim que recebermos os fundos da sua corretora anterior.',
      failed: 'Falha no depósito, por favor tente novamente mais tarde.',
      fee: 'TAXA',
      instant: 'INSTANTÂNEO',
      hour: 'HORA',
      hours: 'HORAS',
      businessDays: 'DIAS ÚTEIS',
      withinOneBusinessDays: 'DENTRO DE 1 DIA ÚTIL',
      secondaryNGN:
        '(Você pode usar este número de conta para esta transação se preferir usar a moeda Naira da Nigéria (NGN).)',
      disclaimer: `<li>All your payments to {platform} are confidential and are only used for deposit and withdrawal in your trading account of {platform}. All E-payments are processed by SSL (Secure Socket Layer) technology and encrypted to ensure the security.</li>
      <li>A {platform} não aceita estritamente pagamentos de quaisquer terceiros. Todos os fundos depositados na sua conta de negociação devem estar sob o mesmo nome que a sua conta do portal do cliente {platform}.</li>
      <li>Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>
      <li>{platform} will not be responsible for any delays or errors caused by payments systems.</li>
      <li>{platform} will not be responsible any fees that the sending financial institution or third-party payment providers may charge you for sending payments.</li>
      <li>Os Clientes reconhecem que poderão ser obrigados a fornecer informações sobre a(s) fonte(s) do(s) depósito(s), o património do Cliente em geral e qualquer outra informação que a {platform} considere necessária para identificar a fonte de fundos ao processar um pedido de depósito e concordam em fornecer todas as informações exigidas pela {platform}. O não fornecimento das informações necessárias pode resultar em atrasos significativos no processo dos seus pedidos. A {platform} não será responsável por quaisquer atrasos ou perdas sofridas como resultado desse atraso.</li>`,
      channels: {
        creditOrDebit: 'Cartão de Crédito/Débito',
        i12BankTransfer: 'Transferência Bancária Internacional',
        unionPay: 'Depósito com Union Pay',
        unionPayCN: 'Transferência China Union Pay',
        vietInstant: 'Transferência Bancária Instantânea do Vietnam',
        thaiInstant: 'Transferência Bancária Instantânea Tailandesa',
        nigeriaInstant: 'Transferência Bancária Instantânea da Nigéria',
        malayInstant: 'Transferência Bancária Instantânea da Malásia',
        indonesiaInstant: 'Transferência Bancária Instantânea da Indonésia',
        philippineInstant: 'Transferência Bancária Instantânea da Philippine',
        indiaInstant: 'Transferência Bancária Instantânea da India',
      },
      rate: {
        rate: 'Taxas de Câmbio({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Falha ao buscar a taxa de {oldCurrency} para {newCurrency}.',
          channelError: 'Falha ao buscar o canal de pagamento.',
        },
      },
      cn: { rate: 'Taxas de Câmbio(USD/RMB): ', amt: 'RMB: ' },
      upgrade:
        'O canal de pagamento está atualmente em manutenção e disponível apenas para usuários que já depositaram fundos com sucesso por meio deste canal.',
      depositInstruction: 'Instruções de Depósito',
      depositNote: 'Nota de Depósito',
    },
    method: {
      desc: 'Como depositar na sua conta com o {method}',
      inst1: '{number}.   Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: `{number}.   Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online da {country}`,
      inst3: '{number}.   ga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4: '{number}.   Será então redirecionado para outro formulário para inserir os detalhes do seu cartão',
      inst5: '{number}.   Depois de inserir os detalhes do seu cartão, pressione "Submeter"',
      inst6: '{number}.   Será redirecionado de volta ao portal do cliente e a sua transação será finalizada',
      inst7: `{number}.   Kung ang iyong pera ay hindi {currency}, maaaring singilin ka ng bangko ng isang bayad sa conversion alinsunod sa kasalukuyang rate ng palitan ng bangko.`,
      inst9: `{number}.   Máximo de $1500 por depósito`,
      inst10: `{number}.   Será exibida uma página de detalhes do Pagamento`,
      inst11: `{number}.   Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online`,
      inst12: `{number}.   Podemos aceitar o pagamento em CAD. Máximo de $9.999 CAD por depósito`,
      form: {
        header: 'Formulário de {method}',
      },
    },
    reminder: {
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Inicie a sessão na sua conta {method}',
      inst3: 'iga as instruções na conta {method} para concluir o seu pagamento',
      inst4: 'Será então redirecionado para outro formulário para inserir os detalhes do seu cartão',
      inst5: 'Depois de inserir os detalhes do seu cartão, pressione "Submeter"',
      inst6: 'Será redirecionado de volta ao portal do cliente e a sua transação será finalizada',
      inst7: 'Você será redirecionado para uma nova janela exibindo o endereço de carteira {method}',
      inst8:
        'Copie o endereço da carteira de {method} e depois inicie a sessão na sua carteira de {method} pessoal e transfira o valor desejado para o endereço {method}.',
      inst9: 'Leia o código QR para abrir a sua aplicação bancária móvel',
      inst10: 'Siga as instruções no seu dispositivo móvel para concluir o seu depósito',
      inst11:
        'Por favor, certifique-se de que paga online através da página redirecionada de pagamento para evitar qualquer perda de fundos.',
      inst12: 'Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online',
      inst13: 'Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst14:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma cópia do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      inst15: 'Por favor, aguarde de 2 a 5 dias úteis para que os fundos sejam creditados na sua conta.',
      inst16: 'Por favor, aguarde 1 dia útil para que os fundos sejam creditados na sua conta.',
      inst17:
        'Por favor, certifique-se de que o seu nome completo, número de conta, e montante do depósito estão claramente visíveis, para que possamos processar a sua transação.',
      inst18: 'Por favor, aguarde 1 a 6 horas para que os fundos sejam creditados na sua conta.',
      note1: 'Os depósitos {method} estão limitados a $25.000 USD por transação e a um máximo de $75.000 USD por dia',
    },
    southAfrica: { header: 'Transferência Bancária Instantânea da África do Sul', country: 'África do Sul' },
    idn: { header: 'Transferência Bancária Instantânea da Indonésia', country: 'Indonésia' },
    ida: { header: 'Transferência Bancária Instantânea da India', country: 'India' },
    nigeria: { header: 'Transferência Bancária Instantânea da Nigéria', country: 'Nigéria' },
    thailand: { header: 'Transferência Bancária Instantânea Tailândia', country: 'Tailândia' },
    malay: { header: 'Transferência Bancária Instantânea da Malásia', country: 'Malásia' },
    php: { header: 'Transferência Bancária Instantânea da Philippine', country: 'Philippine' },
    vietnam: { header: 'Transferência Bancária Instantânea do Vietnam', country: 'Vietnam' },
    korea: { header: 'Transferência bancária local da Coreia do Sul' },
    uganda: { header: 'Transferência de banco local de Uganda', country: 'Uganda' },
    rwanda: { header: 'Transferência de banco local de Ruanda', country: 'Ruanda' },
    zambia: { header: 'Transferência de banco local da Zâmbia', country: 'Zâmbia' },
    congo: { header: 'Transferência de banco local do Congo', country: 'Congo' },
    cameroon: { header: 'Transferência de banco local de Camarões', country: 'Camarões' },
    burundi: { header: 'Transferência bancária local do Burundi', country: 'Burundi' },
    kenya: { header: 'Transferência bancária local do Quênia', country: 'Quênia' },
    ghana: { header: 'Transferência de banco local de Gana', country: 'Gana' },
    tanzania: { header: 'Transferência de banco local da Tanzânia', country: 'Tanzânia' },
    creditOrDebit: {
      worldcard: {
        note1:
          'Os depósitos com Cartão de Crédito utilizando EUR, GPB e USD estão limitados ao montante máximo de $20.000USD por transação. Os depósitos com Cartão de Crédito utilizando EUR, GPB e USD estão limitados ao montante máximo de $20.000USD ou equivalente por transação.',
        note2:
          'O número de cartões diferentes por utilizador é de 3. Qualquer tentativa de depósito para além da limitação não será aceite.',
      },
      virtualPay: {
        note1:
          'Os depósitos com Cartão de Crédito utilizando EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC e USD estão limitados a um máximo de 10.000 USD por transação. ',
        note2:
          '50.000 USD ou 10 transações no máximo por cartão em 24 horas. Qualquer tentativa de depósito para além da limitação não será aceite.',
      },
      Sdpay: {
        note1:
          'Os depósitos com Cartão de Crédito utilizando EUR, GBP e USD estão limitados a um máximo de $2.000 USD ou moedas equivalentes por transação. ',
        note2: 'Máximo de 8 transações por dia. Qualquer tentativa de depósito para além da limitação não será aceite.',
        note3:
          'Por favor, tenha em atenção que pode haver um pedido de suspensão dos seus primeiros pagamentos. Por favor, seja paciente enquanto a nossa equipa estiver a verificar o seu pagamento, ou pode contactar a nossa equipa de suporte ao cliente para mais assistência.',
      },
      maxisPay: {
        note1:
          'Os depósitos com Cartão de Crédito utilizando USD estão limitados ao montante máximo de $5.000USD por transação.',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'Podemos aceitar pagamentos em AUD, USD, EUR, CAD e GBP',
      note2: 'As contas só podem ser financiadas na mesma moeda que a da sua conta de negociação MT4.',
      note3:
        'A Neteller irá converter automaticamente os fundos para a moeda base da sua conta se utilizar um e-mail incorreto. Podem aplicar-se taxas.',
      note4: 'Os depósitos devem estar no mesmo nome que a sua conta STARTRADER.',
    },
    vload: {
      header: 'DEPÓSITO DE VLOAD',
      inst1: '2.   Podemos aceitar pagamentos em USD, EUR e JPY.',
      inst2: `<li>3.   Comprar voucher Vload, clique <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">aqui</a>.</li>`,
      form: {
        voucherpin: 'Pin do Voucher',
        voucherpinReq: 'É necessário o PIN do Voucher',
      },
    },
    bankWireAu: {
      header: 'TRANSFERÊNCIA BANCÁRIA: AUSTRÁLIA',
      instruction:
        'Os fundos podem ser transferidos para a {platform} através do serviço bancário online da sua instituição financeira ou, em alternativa, entrando em contacto com a instituição financeira e solicitando a transferência para a {platform}.',
      tip:
        'Selecione a moeda da conta na qual deseja depositar. Por favor, certifique-se de que seleciona a mesma moeda que a moeda da sua conta de negociação',
      form: {
        bankDetails: 'Detalhes Bancários (Apenas Depósito em AUD)',
        refTip: 'Por favor, especifique aqui o seu Número de Cliente.',
        afp: 'Pagamento Rápido Australiano (APENAS Depósito Nacional em AUD)',
        payIdOrABNNum: 'PayID/Número ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Por favor, inclua o número da sua conta de negociação da {platform} no campo de referência da transferência bancária.',
      },
    },
    bPay: {
      header: 'DEPÓSITO BPAY',
      option: 'Como depositar na sua conta com o BPay (apenas na Austrália)',
      inst1:
        '1. Selecione a sua conta MT4/MT5 para visualizar o seu Código de Faturação BPay e o Número de Referência Bpay',
      inst2:
        '2. Insira os detalhes do pagamento, o seu Código de Faturação BPay e o Número de Referência BPay no seu serviço bancário online e submeta o seu pagamento',
      inst3:
        '3. Retorne a esta página e preencha o formulário BPay abaixo para garantir que poderemos processar a sua transação imediatamente',
      form: {
        header: 'FORMULÁRIO DO BPAY',
        billerCode: 'Código de faturação BPay',
        refNum: 'Número de Referência BPay',
        receipt: 'Recibo de transferência BPAY',
        reminderNotUploaded:
          'Nós reparámos que não fez o carregamento do recibo da transação. Para processarmos o seu depósito, por favor envie um e-mail com a cópia do recibo para {mailTo} o mais brevemente possível.',
        reminderProc:
          'Por favor, tenha em atenção. A sua transação NÃO PODE ser processada até recebermos o seu recibo.',
      },
    },
    b2b: {
      header: 'CORRETORA PARA CORRETORA',
      option: 'Como transferir fundos de outra corretora para a {platform}',
      inst1: '1. Descarregue e complete o {download}',
      inst2:
        '2. Preencha o formulário online abaixo e faça o carregamento do Formulário de Transferência de Corretora para Corretora preenchido',
      inst3:
        '3.   Send a copy of your completed Broker to Broker form to your previous broker to initiate the transfer',
      b2bForm: 'Formulário de Corretora para Corretora',
      formValidation: { upload: 'O formulário de Corretora para Corretora é obrigatório' },
    },
    cc: {
      header: 'DEPÓSITO DE CARTÃO DE CRÉDITO OU DÉBITO',
      remind:
        'Caso tenha alguma dificuldade em depositar na sua conta utilizando o método Primário, por favor utilize o método Crédito/Débito Secundário',
      field: {
        fundNow: 'Deposite Agora com Cartão de Crédito / Débito',
        newCard: 'Novo Cartão',
        cardNum: 'Número do Cartão de Crédito',
        nameOnCard: 'Nome no cartão',
        expDate: 'Data de Validade',
        cvv: 'Código de Segurança CVV',
        bankCode: 'Banco Emissor do Cartão',
        example: `Cartão Simples`,
      },
      placeholder: { bankCode: 'Por favor, insira o nome do banco emissor do cartão' },
      cvvTip: 'Os 3 dígitos exibidos no <br />verso do seu cartão',
      threeDomainSecQ: 'O seu cartão suporta 3D-Secure?',
      threeDomainSec:
        'O que é autenticação 3D Secure? <br /><strong>A autenticação 3D Secure é um método de prevenção de fraudes reconhecido pela Visa e Mastercard.<br /><br />Se o seu cartão suportar o 3D Secure, os seus fundos serão processados imediatamente.</strong>',
      cardUpload:
        'O cartão que está a utilizar para depositar fundos não foi vinculado à sua conta de negociação. Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
      result: {
        success:
          'Obrigado. Neste momento, estamos a verificar os detalhes do seu cartão e, uma vez verificados, os fundos deverão aparecer na sua conta dentro de 24 horas.',
        depositSuccess: 'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve',
        error:
          'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus depósitos. Por favor, entre em contacto com {email}.',
      },
      tipOnlyTaiwan: `Prezado cliente, esteja ciente de que saques via cartão de crédito podem atrasar devido ao processamento do banco e o tempo de chegada depende inteiramente de cada banco. Se você tiver necessidades urgentes de fundos, é recomendável usar outros métodos para depositar / sacar.`,
      reminder: `<li>1. Os depósitos com cartão de crédito em EUR, GBP e USD são limitados a um máximo de $1.900 USD ou moedas equivalentes por transação.</li>
      <li>2. Máximo de 8 transações por dia.</li>
      <li>3. Observe que pode haver uma retenção aplicável aos seus primeiros pagamentos. Por favor, seja paciente enquanto nossa equipe está verificando seu pagamento, ou você pode entrar em contato com nossa equipe de suporte ao cliente para obter mais assistência.</li>
      `,
    },
    fasa: {
      description: 'Como depositar na sua conta com o FasaPay',
      form: { header: 'FORMULÁRIO FASAPAY' },
    },
    intSwift: {
      header: 'TRANSFERÊNCIA BANCÁRIA: SWIFT INTERNACIONAL',
      desc:
        'Os fundos podem ser transferidos para a {platform} através do serviço bancário online da sua instituição financeira ou, em alternativa, entrando em contacto com a instituição financeira e solicitando a transferência para a {platform}.',
      desc2:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma cópia do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      alertSAandNGA:
        'Se você possui uma moeda que não seja o dólar americano, troque a remessa por dólares americanos antes de fazer a remessa. Se você remeter diretamente, a diferença da taxa de câmbio e as taxas de manuseio incorridas naquele momento serão suportadas pelo cliente.',
      options: 'Por favor, selecione a conta de negociação que deseja financiar',
      refInfo:
        'Por favor, inclua o número da sua conta de negociação da {platform} no campo de referência da transferência bancária.',
      bussDayInfo: 'Por favor, aguarde de 2 a 5 dias úteis para que os fundos sejam creditados na sua conta.',
      tip:
        'Por favor, verifique se o recibo da transferência mostra claramente o <b>nome completo</b>, <b> número da conta</b> e o <b>valor do depósito</b>.',
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Aceitamos pagamentos em USD, AUD, EUR, USC e JPY',
    },
    astropay: {
      desc: 'Como depositar na sua conta com o ASTROPAY',
      note1: 'Aceitamos pagamentos em todas as moedas.',
      form: { header: 'FORMULÁRIO ASTROPAY' },
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Como depositar na sua conta com o Mobile Pay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Leia o código QR para abrir a sua aplicação bancária móvel',
      inst3: '3. Siga as instruções no seu dispositivo móvel para concluir o seu depósito',
      form: { payMethod: 'Método de pagamento:', rate: 'Taxas de Câmbio (USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'O Valor CNY não pode exceder $ {limit}' },
    },
    crypto: {
      header: 'DEPÓSITO EM CRIPTOMOEDA',
      choose: 'Por favor, escolha a sua criptomoeda preferida',
      proceedQuestion: 'Você deseja prosseguir com seu depósito <br /><b>{método}</b>?',
      note:
        'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
      note2:
        'Por favor, tenha em atenção que não nos é possível depositar ou levantar via BUSDT ou ETH, por favor, certifique-se de que o endereço e a Criptomoeda correspondem à cadeia e moeda que aceitamos ou poderá perder os fundos. Não seremos responsáveis por qualquer perda incorrida devido aos erros.',
      remark:
        'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
      inst2: '2. Você será redirecionado para uma nova janela exibindo um endereço de carteira {method}',
      inst3:
        '3. Copie o endereço da carteira de {method} e depois inicie a sessão na sua carteira de {method} pessoal e transfira o valor desejado para o endereço {method}.',
      inst4:
        'Por favor, tenha em atenção: os depósitos de Bitcoin são processados pelo B2BinPay à taxa de câmbio fornecida no momento da transação.',
      bitcoin: {
        button: 'BITCOIN',
        desc: 'Como depositar na sua conta com BITCOIN',
      },
      usdt: {
        button: 'TETHER (OMNI)',
        desc: 'Como depositar na sua conta com o TETHER(OMNI)',
        inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
        inst2: '2. Você será redirecionado para uma nova janela exibindo o endereço de carteira TETHER(OMNI)',
        inst3:
          '3. Copie o endereço da carteira do Tether(OMNI) e inicie a sessão na sua carteira pessoal do Tether e transfira o valor desejado para o endereço do Tether(OMNI).',
        inst4:
          'Por favor, tenha em atenção: os depósitos de Tether(OMNI) são processados pelo B2BinPay à taxa de câmbio será fornecida no momento da transação.',
      },
      usdt_CPS: {
        button: 'TETHER({name})',
        desc: 'HComo depositar na sua conta com o TETHER({name})',
      },
      form: {
        header_bitcoin: 'FORMULÁRIO DE BITCOIN',
        header_usdt: 'FORMULÁRIO DO TETHER(OMNI)',
        header_usdt_CPS: 'FORMULÁRIO DO TETHER({name})',
      },
    },
    skrill: {
      header: 'DEPÓSITO SKRILL/MONEYBOOKERS',
      desc: 'Como depositar na sua conta com o Skrill',
      note1: 'Aceitamos pagamentos em USD, EUR, GBP e CAD',
      note2: 'As contas só podem ser financiadas na mesma moeda que a da sua conta de negociação MT4.',
      note3:
        'A Skrill irá converter automaticamente os fundos para a moeda base da sua conta se utilizar um e-mail incorreto. Podem aplicar-se taxas.',
      note4: 'Os depósitos devem estar no mesmo nome que a sua conta STARTRADER.',
      form: { header: 'FORMULÁRIO DO SKRILL', email: 'E-mail do Skrill/Moneybookers' },
      reminder:
        '<li>Por favor, tenha em atenção:</li>\n        <li>* As contas só podem ser financiadas na mesma moeda da sua conta de negociação MT4. O Skrill irá converter os fundos automaticamente para a moeda base da sua conta se for utilizado o e-mail incorreto. Podem ser aplicadas taxas.</li>\n        <li>* Os depósitos devem ter o mesmo nome da sua conta {platform}.</li>',
    },
    sticPay: {
      header: 'DEPÓSITO STICPAY',
      desc: 'Como depositar na sua conta com o STICPAY',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta SticPay',
      inst3: '3. Siga as instruções na conta SticPay para concluir o seu pagamento',
      form: { header: 'FORMULÁRIO DO STICPAY', email: 'E-mail do SticPay' },
    },
    unionPay: {
      header: 'DEPÓSITO UNION PAY',
      desc: 'Como depositar na sua conta com o UnionPay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online',
      inst3: '3.   Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      form: { header: 'FORMULÁRIO DO UNION PAY' },
    },
    unionPayCN: {
      header: 'Transferência China Union Pay',
      desc: 'Como depositar na sua conta com a Transferência China Union Pay',
      inst1: 'Irá aparecer uma página de detalhes de pagamento',
      inst2:
        'Poderá ser-lhe solicitado o número de membro enquanto faz um depósito, por favor, introduza o número da sua conta de negociação como número de Membro',
      note1:
        'O montante Mínimo de transação por depósito é de 500 CNY e o montante Máximo de transação por depósito é de 49.999 CNY.',
      note2:
        'Por favor, tenha em atenção que o limite do montante do depósito pode ser atualizado de tempos a tempos, dependendo do mercado.',
      note3:
        'Por favor, não copie os dados dos beneficiários bancários que estão a ser apresentados, pois podem ser atualizados de tempos a tempos.',
      form: {
        header: 'FORMULÁRIO DO CHINA UNION PAY',
        holderName: 'Nome do Titular do Cartão UnionPay:',
        holderNameInfo:
          'Por favor, certifique-se de que o nome inserido acima seja igual ao nome do titular do seu cartão bancário',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Como depositar na sua conta com o PayPal',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta PayPal',
      inst3: '3. Siga as instruções na conta do PayPal para concluir o seu pagamento',
      inst4: 'Por favor, tenha em atenção: Nós não podemos aceitar pagamentos de terceiros',
      form: { header: 'FORMULÁRIO DO PAYPAL' },
    },
    cardpay: {
      desc: 'Como depositar na sua conta com Cartão de Crédito',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Será então redirecionado para outro formulário para inserir os detalhes do seu cartão',
      inst3: '3. Depois de inserir os detalhes do seu cartão, pressione "Submeter"',
      inst4: '4. Será redirecionado de volta ao portal do cliente e a sua transação será finalizada',
      form: { header: 'FORMULÁRIO DO CARTÃO DE CRÉDITO' },
    },
    tinkbit: { inst5: '5. Máximo de $1500 por depósito' },
    perfectMoney: {
      inst1: '2.   Inicie a sessão na sua conta Perfect Money',
      inst2: '3.   Siga as instruções na conta Perfect Money para concluir o seu pagamento',
    },
    indiaBankTransfer: {
      desc1:
        'Assim que tiver efetuado a transferência, por favor, preencha o formulário abaixo e anexe uma cópia do comprovativo da transferência bancária.',
      desc2:
        'Por favor, certifique-se de que o seu nome completo, número de conta, e montante do depósito estão claramente visíveis, para que possamos processar a sua transação.',
      bussDayInfo: 'Por favor, aguarde 1 a 6 horas para que os fundos sejam creditados na sua conta.',
    },
  },
  restrictDeposit: {
    header: 'IMPORTANT NOTIFICATION',
    info: 'Unfortunately we are no longer accepting deposits from clients residing in Canada.',
  },
  withdraw: {
    title: 'LEVANTAR FUNDOS',
    default: {
      accountDetail: 'Detalhes da Conta',
      withdrawalAmount: 'Montante a levantar',
      example: 'Exemplo de Cartão de Crédito/Débito',
      alert:
        'Devido às leis AML, o método que você usa para retirar deve ser o mesmo que o método usado para seu depósito. Se o valor do saque for inferior a 100 unidades da moeda base de sua conta, será cobrada uma taxa de 20 unidades pelo banco para manuseio.',
      how: 'Como financiou a sua conta de negociação？',
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      success: 'O seu pedido de levantamento foi bem-sucedido e será processado brevemente',
      ccFail:
        'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus levantamentos. Por favor, entre em contacto com {email}.',
      checkPosFailed: 'Falha na Verificação da Posição',
      fetchTradeAccFailed: 'Falha na busca da Conta de Negociação',
      chooseAnother:
        '<p>Valor máximo de saque para este cartão: 0</p> \n      <p>Por favor escolha outro cartão ou outra forma de pagamento</p>',
      notEnoughFunds: 'O valor inserido excede o limite para este cartão',
      failed: 'O levantamento falhou. Por favor, tente novamente mais tarde',
      unfortunate:
        'Infelizmente,  não é mais possível processar levantamentos de cartões de crédito. A partir de agora, os levantamentos serão processados por transferência bancária direatmente para sua conta bancária designada.',
      noCardWarn:
        'Você não possui nenhum cartão disponível para fazer o levantamento, por favor, em primeiro lugar registe o seu cartão em Detalhes de Levantamento.',
      equityStop:
        'Infelizmente, o seu levantamento não pode ser processado, porque vai resultar na queda da margem disponível da sua conta abaixo do nível permitido de 100%',
      equityMargin:
        'Infelizmente, atualmente não podemos processar seu saque porque sua conta está totalmente coberta.',
      equityContinue:
        'Detectamos que atualmente você tem posições em aberto; poderá ocorrer uma interrupção após a submissão do pedido de levantamento, por favor confirme.',
      disclaimer: `<li>Retirar fundos enquanto você tem posições abertas afetará sua margem. Certifique-se de fechar as posições em aberto ou de deixar fundos suficientes em sua conta para evitar uma chamada de margem.</li>
        <li>A {platform} não aceita estritamente pagamentos de quaisquer terceiros. Todos os fundos levantados da sua conta de negociação devem estar sob o mesmo nome que a sua conta do portal do cliente {platform}.</li>
        <li>Todos os pedidos iniciais de cartão de crédito/débito, e-wallet, reservas de dinheiro e levantamentos por transferência bancária devem ser verificados quanto à segurança e proteção através do fornecimento de um extrato bancário, que inclua informações sobre o titular da conta e dados bancários.</li>
        <li>A {platform} reserva-se o direito de recusar o processamento de um pedido de levantamento se tiver motivos razoáveis ​​para acreditar que a conta de negociação pode ficar com um saldo negativo após o processamento do pedido de levantamento.</li>
        <li>A {platform} reserva-se o direito de recusar um pedido de retirada se a conta de negociação estiver sujeita a um estorno ou qualquer outra investigação em andamento.</li>
        <li>A {platform} não é responsável por quaisquer taxas que a instituição financeira receptora ou provedores de pagamento terceirizados possam cobrar de você pelo recebimento de pagamentos.</li>`,
      YourPaymentGreaterThanAvailableBalance: 'Seu pagamento é maior que o saldo disponível',
      blackList: `Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações`,
      NetellerChargeAlert:
        'As retiradas do Neteller são limitadas a $ 10.000 por transação e aplica-se uma taxa de 2% (limitada a $ 30).',
      agreePolicy: 'Li e concordo com a{link}',
      withdrawPolicy: 'política de levantamento',
      addWithdrawalMethod: 'Adicione um método de levantamento',
      addSecondaryMethod: 'Adicione um método secundário',
      withdrawNote:
        'Por favor, tenha em consideração que processaremos o seu levantamento principalmente através do método de cartão de crédito. Se o valor do seu cartão de crédito estiver esgotado, pedir-lhe-emos que escolha outro método a fim de finalizar o seu pedido de levantamento.',
      submitYourWithdrawal: 'Submeter o seu levantamento',
      remainingWithdraw:
        'O montante restante do levantamento será dividido noutro cartão de crédito ou método de levantamento.',
      onlyWithdrawalCcDesc: `Apenas o montante de {ccAmount} do cartão de crédito será totalmente levantado.`,
    },
    fasa: {
      field: { accName: 'Nome da conta do FasaPay', accNum: 'Número da conta do FasaPay' },
      formValidation: {
        accNameReq: 'O nome da conta do FasaPay é obrigatório',
        accNumReq: 'O número da conta do FasaPay é obrigatório',
      },
      fasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
    },
    neteller: {
      field: { email: 'E-mail do Neteller' },
      formValidation: { emailReq: 'E-mail do Neteller é obrigatório' },
    },
    vload: {
      field: { email: 'E-mail Registado Vload' },
      formValidation: { emailReq: 'É necessário o E-mail de Vload Registado' },
    },
    bitcoin: {
      field: { address: 'Endereço da Carteira Bitcoin' },
      formValidation: {
        addressReq: 'O endereço da Carteira Bitcoin é obrigatório',
        addressFormat: `O endereço da sua carteira deve começar com "1" ou "3" ou "bc1".`,
        addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      },
    },
    eth: {
      field: { address: 'Endereço da Carteira ETH' },
      formValidation: {
        addressReq: 'O endereço da Carteira ETH é obrigatório',
        addressFormat: 'O endereço da Carteira ETH é obrigatório',
        addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      },
    },
    usdc: {
      field: { address: 'Endereço da Carteira USDC' },
      formValidation: {
        addressReq: 'O endereço da Carteira USDC é obrigatório',
        addressFormat: 'O endereço da Carteira USDC é obrigatório',
        addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      },
    },
    usdt: {
      field: { address: 'Endereço da carteira USDT' },
      formValidation: {
        addressReq: 'O endereço da Carteira USDT é obrigatório',
        trc20WalletFormatIncorrect: `O endereço da sua carteira deve começar com "T".`,
        erc20WalletFormatIncorrect: `O endereço da sua carteira deve começar com "0x".`,
      },
    },
    bitwallet: { field: { email: 'Bitwallet Email' }, formValidation: { emailReq: 'Bitwallet Email is required' } },
    astropay: {
      field: {
        accountNumber: 'Conta Astropay',
      },
      formValidation: {
        accountNumberReq: 'É necessária uma conta Astropay',
      },
    },
    paypal: { field: { email: 'E-mail do Paypal' }, formValidation: { emailReq: 'O e-mail do Paypal é obrigatório' } },
    skrill: {
      field: { email: 'E-mail do Skrill' },
      formValidation: { emailReq: 'O e-mail do Skrill é obrigatório' },
      skrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
    },
    sticpay: { field: { email: 'SticPay Email' }, formValidation: { emailReq: 'SticPay Email is required' } },
    perfectMoney: {
      field: { email: 'E-mail do Perfect Money' },
      formValidation: { emailReq: 'O e-mail do Perfect Money é obrigatório' },
    },
    transfer: {
      info:
        'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload: 'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
      },
      formValidation: {
        upload: 'Por favor, faça o envio de um extrato bancário',
        emailReq: 'E-mail é obrigatório',
      },
    },
    otherMethod: {
      withdrawalMethod: 'Método de Levantamento',
      manageWithdrawalDetails: 'Gerir detalhes de levantamento',
      verificationCode:
        'O código de verificação foi enviado para {email}. Por favor, entre em contacto com {supportEmail} se precisar de mais assistência',
    },
  },
  transfer: {
    creditWarn:
      'Não podemos processar a sua transferência de fundos da conta selecionada uma vez que a sua conta contém crédito promocional.',
    blackListWarn:
      'A transferência de fundos da sua conta MAM pode resultar na liquidação de posições geridas. Por favor, entre em contacto com {mailTo} se desejar transferir fundos desta conta.',
  },
  changePw: {
    header: 'ALTERAR PALAVRA-PASSE',
    field: { currPw: 'Palavra-passe Atual', newPw: 'Nova Palavra-passe', confirmPw: 'Confirmar a Nova Palavra-passe' },
    succ:
      'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder ao Portal do Cliente.',
  },
  promotion: {
    clickToGet: 'Basta clicar e obter seu bônus de $ 50',
    apply: 'Aplique agora',
    start: 'Comece agora',
    tnc: `
    <li> 1. Esta oferta está disponível por 30 dias.</li>
    <li> 2. Esta oferta está disponível para todos os clientes (Existentes e Novos) que possuem uma conta ativa, mas nunca fizeram um depósito antes.</li>
    <li> 3. Esta oferta está disponível para novos clientes nas seguintes áreas: França, Reino Unido, Taiwan, Itália, Canadá, Bélgica, Suíça, Hong Kong, Nigéria, Holanda, Japão, Luxemburgo, Malta, Reunião, Alemanha, Martinica, Espanha, Letônia, Polônia , Costa do Marfim, Dinamarca, Brasil, Nova Zelândia, Portugal, Ilha de Man, África do Sul, Turquia, Romênia, Guadalupe, Áustria, Irlanda, Guiana Francesa, Suazilândia, Argentina, Ucrânia, Grécia, Suécia, Mônaco, Hungria, Haiti , Finlândia, Bolívia, Chile, Costa Rica, Croácia, República Tcheca, Estônia, México, Noruega, Filipinas;</li>
    <li> 4. Esta oferta é elegível para contas padrão e contas padrão islâmicas apenas。</li>
    <li> 5. É proibido obter bônus e / ou negociar fundos de bônus EM DUAS OU MAIS CONTAS com o mesmo endereço IP / ID / passaporte. Independentemente do número de contas de negociação que um Cliente possa manter na STARTRADER, a promoção é aplicável para apenas uma conta.</li>
    <li> 6. Os clientes receberão créditos de 50 USD (ou equivalente) após a adesão a esta oferta com sucesso e não há necessidade de fazer um depósito;</li>
    <li> 7. Os valores de crédito não podem ser sacados. No entanto, você ainda pode sacar parte do lucro obtido em suas negociações.</li>
    <li> 8. Você deve fechar todas as posições em aberto antes de solicitar saque. Assim que você optar pelo saque, o bônus será deduzido da conta.</li>
    <li> 9. A STARTRADER reserva-se o direito de alterar as condições ou recusar a concessão de um bônus a qualquer momento.</li>`,
    selectDesp: '* Selecione a conta na qual gostaria de participar da atividade de bônus sem depósito.',
    descp1: 'Você quer começar a negociar sem comprometer um investimento desde o início? ',
    descp2: `Aplique seu bônus de $ 50 para começar<br> a ter lucro sem arriscar nenhum de seus fundos.`,
    header: 'TERMOS E CONDIÇÕES DE PROMOÇÃO',
    referFriends: {
      intro: `Conclua as 3 etapas a seguir para obter {amount}`,
      step1: 'Primeiro passo',
      step1Descp: 'Uma conta aberta com sucesso',
      step2: 'Segundo passo',
      step2Descp: 'Depósito bem sucedido',
      step3: 'terceiro passo',
      step3Descp: 'Volume de negócios',
      totalEanringTitle: `Valor total da retirada <br/>(USD ou moeda equivalente)`,
      totalEarningTip: '* Credit of bonus into your account is subject to approval.',
      redeem: 'extrair',
      intro2: 'Ao mesmo tempo, você também pode recomendar a seus amigos',
      shareLink: 'Compartilhe seu link de recomendação personalizada',
      shareLinkDescp:
        'Clique em "Compartilhar" para obter um link de recomendação exclusivo e copiá-lo para várias redes sociais',
      share: 'Compartilhar',
      total: 'Total de referências resgatadas',
      social: 'Compartilhe seu link nas principais redes sociais',
      clients: 'cliente',
      table: {
        referralsNo: 'Número recomendado',
        name: 'Nome',
        country: 'país',
        openAccount: 'Uma conta aberta com sucesso',
        despositSuccess: 'Depósito bem sucedido',
        volumeCompleted: 'Volume de negócios',
      },
      totalRedeemableTitle: `Valor total da retirada <br/>(USD ou outra moeda equivalente)`,
      totalRedeemableTip: '* O valor da recompensa está sujeito a revisão.',
      referrer: 'Referrer',
      referral: 'Juiz',
      dialog: {
        confirmQ: 'Resgate seus ganhos',
        confirmQ2: 'O valor total convertido em sua moeda base é {amount} {currency}.',
        redeem: 'Retire agora',
        agree: '* Ao clicar em resgatar agora, você concorda com todos os{link}.',
        overBonus:
          'Nosso sistema detectou que você atingiu nosso valor máximo. Para reclamações, entre em contato com nosso suporte ao cliente.',
      },
    },
    depositBonus: {
      banner: 'RECLAMA O SEU BÔNUS DE NEGOCIAÇÃO 100%',
      depositBonus: 'RECLAMA SEU BÔNUS E AUMENTE SUAS NEGOCIAÇÕES',
      title:
        'Quer aumentar a margem de sua conta e aumentar seus volumes de negociação? Então coloque fundos em sua conta hoje e resgate nosso bônus de 50%!',
      fund: 'Financiar a sua conta',
      tnc: '* Termos e condições se aplicam, {link}',
      link: 'clique aqui para visualizar',
      bonusHistory: 'HISTÓRIA DE BÔNUS',
      overBonus:
        '* Atingiu o máximo de ${totalLimit} de créditos de bónus. Os depósitos adicionais não se qualificam para créditos de bónus.',
      opt: 'CONFIRMAÇÃO DE OPT-IN & OPT-OUT',
      tick: 'Desmarque a caixa se desejar cancelar ou marque a caixa se desejar.',
      saveChange: 'Salvar alterações',
      depositDate: 'Data de Depósito',
      credit: 'Crédito',
      optIn: 'Participação com sucesso',
      optOut: 'Desativação com sucesso',
      optInDescription: 'Clique no botão abaixo para optar pelo bônus de depósito de 50%.',
      optOutDescription: 'Clique na caixa para desativar a recompensa.',
      activate: 'Ativar',
      signOut: 'Sair',
      banner1: 'RECLAMA O SEU BÔNUS DE 50% DE DEPÓSITO!',
      claimDescription: `<p>Como reivindicar</p>
      <li>1. Ativar antes de depositar. </li>
      <li>2. Financie sua conta de negociação para se qualificar para a recompensa. </li>
      <li>3. Recompensa financiará para sua conta automaticamente. </li>
      <li>4. Aproveite sua negociação. </li>`,
      eligibleDescription: `<p>Elegibilidade</p>
      <li>1. A recompensa deve ser ativada antes de depositar. </li>
      <li>2. A recompensa só está disponível para conta padrão, conta padrão islâmica, conta principal, conta principal islâmica. </li>
      <li>3. Você só pode solicitar o bônus de depósito de taxa de 50% uma vez, e todos os depósitos subseqüentes são calculados apenas de acordo com a proporção de 20%.</li>`,
      optInDialog: `Parabéns! Você ativou com sucesso o bônus de depósito de 50%.`,
      optOutDialog: `Você optou por não receber o bônus de depósito de 50%.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Duplique as Suas Poupanças</span></br>
          <span class="desc">e Obtenha até $10.000 de reembolso em dinheiro e ZERO taxas de swap</span>
        `,
        activityBannerContext: `
          <span class="title">Dobre suas economias</span></br>
          <span class="desc">Encurtando a tendência de baixa ou comprando o mergulho?</span></br>
          <span class="desc">Receba até $ 10.000 em cashback ao negociar criptomoedas com a STARTRADER!</span>
        `,
      },
      active: {
        title: `Ativar o Reembolso de Criptomoedas`,
        desc: `por favor, clique no botão abaixo para ativar e participar na atividade`,
      },
      deactive: {
        title: `Desativar o Reembolso de Criptomoedas`,
        desc: `Por favor, clique no botão abaixo para desativar e abandonar a promoção Reembolso de Criptomoedas.`,
      },
      button: {
        active: 'Ativar',
        deactive: 'Desativar',
        submit: 'Submeter',
        fund: 'FINANCIE A SUA CONTA',
      },
      claimTitle: 'COMO RECLAMAR',
      claimDescriptions: [
        `Ative a promoção antes de fazer um depósito.`,
        `Financie a sua conta de negociação para atender aos requisitos de depósito（≥ 500 USD)`,
        `O reembolso por lote dependerá do valor líquido total do depósito (consulte os Termos e Condições).`,
        `Aproveite a negociação e obtenha mais valor com cada negociação!`,
      ],
      eligibilityTitle: 'ELEGIBILIDADE',
      eligibleDescriptions: [
        `Esta promoção deve ser ativada antes de fazer um depósito.`,
        `Esta promoção está disponível apenas para Contas Standard e Contas Standard Islâmicas. Se um cliente tiver várias contas de negociação elegíveis, apenas UMA conta elegível pode ser selecionada para participar nesta promoção`,
        `Esta promoção dura 30 dias a partir do momento do seu primeiro depósito bem-sucedido após a ativação`,
        `O reembolso é baseado no volume negociado em todos os produtos de criptomoeda, exceto os seguintes: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU`,
        `O reembolso total está limitado a $10.000 por cliente`,
      ],
      tnc: '* Termos e condições se aplicam, {link}',
      link: 'clique aqui para visualizar',
      message: {
        congratulate: `Parabéns!`,
        congratulateContext: `Ativou com sucesso a promoção de Reembolso de Criptomoedas.`,
        signedOutPromo: `Ao clicar no botão Confirmar estará a cancelar a adesão à promoção.`,
        deactivePromo: `Desativou com sucesso a promoção de Reembolso de Criptomoedas.`,
      },
      dialog: {
        selectAccountContext: `Por favor, selecione a conta com a qual gostaria de participar na atividade de bónus de negociação`,
        tnc: `Termos e Condições`,
        tncContext: {
          tnc1: `1. Depois de ativar esta promoção, também deve depositar fundos e negociar criptomoedas conforme necessário para receber o reembolso correspondente.`,
          tnc1desc: `*Somente os novos depósitos feitos durante o período da promoção são válidos para a promoção. Isto exclui transferências internas, bónus por recomendação de um amigo e ajustes em dinheiro.`,
          tnc2: `2. Os Clientes elegíveis (tanto os novos como os já existentes), que satisfaçam os critérios aqui estabelecidos, podem receber reembolso, igual a:`,
          tnc3: `3. Os produtos elegíveis: Todos os produtos de criptomoeda, exceto ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. O volume efetivo de negociação: As ordens recém-abertas e fechadas durante o período da promoção. Posições abertas por menos de 5 minutos, posições bloqueadas e posições cobertas, não contam como negociações válidas para esta promoção.`,
          tnc5: `5. Duração da Promoção: 30 Dias após os participantes fazerem o primeiro depósito com sucesso.`,
          tnc5desc: `* Caso o cliente ative a promoção e não tenha feito um depósito, a promoção também terminará automaticamente após 30 dias.`,
          tnc6: `6. Esta oferta não pode ser utilizada em conjunto com qualquer outra oferta. （Exceto a "Campanha “Também Pode Ser um Negociador”, Recomendar um Amigo e bónus de depósito de 50%)`,
        },
        agreeTnc: `Li e concordo com os Termos e Condições`,
      },
      field: {
        netDeposit: `Depósito Liquido`,
        usddesc: `USD (ou moeda equivalente)`,
        cashback: `Reembolso`,
        standardLot: `lote standard`,
        date: `Data`,
        depositAmount: `Montante do depósito`,
        withdrawAmount: `Montante do levantamento`,
        netDepositAmount: `Montante líquido do depósito`,
        rebateLot: `Compensação / Lote`,
        tradingDate: `Data da negociação`,
        tradingLot: `Lote de negociação`,
        cashRebate: `Compensação em dinheiro`,
      },
      depositHistory: `HISTÓRICO DE DEPÓSITOS`,
      tradingRewardHistory: `HISTÓRICO DE RECOMPENSAS DE NEGOCIAÇÃO`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">Ganhe Prémios Que Valem Até $5.000!</div>
          <div class="desc">No nosso sorteio com ganho garantido</div>
        `,
      },
      active: {
        title: 'ATIVAR A PROMOÇÃO PEÇA UM DESEJO',
        desc: 'Por favor, clique no botão abaixo para ativar a promoção de Natal Peça um Desejo',
      },
      deactive: {
        title: 'Promoção de Natal Peça um Desejo',
        desc: 'Por favor, clique no botão abaixo para ativar a promoção de Natal Peça um Desejo',
      },
      button: {
        active: 'Ativar',
        deactive: 'Desativar',
        submit: 'Submeter',
        fund: 'Financie a Sua Conta',
        enterLuckyDraw: 'Entrar no Sorteio',
        redeem: 'Resgatar',
        redeemed: 'Resgatado',
      },
      claimTitle: 'COMO RECLAMAR',
      claimDescriptions: [
        'Ative a promoção antes de fazer um depósito.​',
        'Financie a sua conta de negociação para satisfazer os requisitos de depósito para a oferta que deseja, depois faça o número correspondente de negociações necessárias. (veja a tabela de ofertas abaixo).',
        'Quando elegível, terá a oportunidade de ganhar uma das ofertas do seu nível correspondente. (veja a tabela de ofertas abaixo).',
        'Clique no botão Entrar no Sorteio para rolar para o seu presente.',
        'As ofertas ganhas serão registadas, e todas as ofertas serão distribuídas 7 dias após o fim da promoção.',
      ],
      eligibilityTitle: 'ELIGIBILIDADE',
      eligibleDescriptions: [
        'Tipos de contas elegíveis: Esta oferta está aberta APENAS a contas Standard e Contas Standard Islâmicas, ambas em MT4/MT5.',
        'Se um cliente tiver várias Contas Standard, só pode selecionar uma Conta Standard para participar nesta promoção.',
        'Países elegíveis: Todos exceto a China continental.',
        'Os depósitos e as negociações devem ser realizadas durante o período da promoção para serem considerados válidos.',
        'Volume de negociação válido: Posições de Forex, Ouro, Prata e Petróleo Bruto mantidas durante mais de 5 minutos.',
      ],
      moreDetail: 'Para mais detalhes sobre a distribuição dos prémios, veja a {prize}.',
      prize: 'lista de prémios aqui',
      tnc: '*Aplicam-se os termos e condições. Clique {link} para visualizar',
      link: 'aqui',
      luckyDraw: {
        description: [
          `Carregue no botão Sorteio para ganhar <span class="font-bold">um</span> dos prémios acima!`,
          'Não são as ofertas que procura? Continue a fazer depósitos e a negociar para atingir o nível que deseja.',
        ],
        mainTitle: 'O seu Atual Nível de Ofertas:',
        commingSoon: {
          title: `Peça um Desejo`,
          content: `Brevemente, em meados de novembro, para entrar no sorteio.`,
        },
        noChanceToDraw: {
          title: `Deposite Agora`,
          content: `Financie a sua conta com um depósito mínimo de $200 para participar no sorteio`,
        },
        endPromotion: {
          title: `O Lucky Draw Terminou`,
          content: `Agradecemos a sua participação! Certifique-se de ter resgatado as suas ofertas até 31 de março de 2023.`,
        },
      },
      currentDepositLots: `Depósito Atual / Lotes Negociadoss`,
      transactionHistory: `HISTÓRICO DE TRANSAÇÕES`,
      transactionHistoryDesc: `*A transação de depósito é o montante depositado e o valor do depósito depois de deduzida a entrada no sorteio.`,
      giftRedemption: `RESGATAR OFERTA`,
      giftRedemptionDesc: [
        `Iremos contactá-lo novamente para receber a(s) oferta(s) física(s).`,
        `*Todos os prémios só podem ser resgatados de 2 de fevereiro de 2023 até 2 de março de 2023.`,
      ],
      field: {
        depositAmount: `Montante do Depósito`,
        lotsTraded: `Lotes Negociados`,
        date: `Data`,
        type: `Tipo`,
        deposit: `*Depósito`,
        tradingLots: `Lotes de Negociação`,
        prizeWon: `Prémio(s) ganho(s)`,
        creditBonus: `Bónus de Crédito`,
        cashVoucher: `Voucher em Dinheiro`,
        physicalRewards: `Recompensas Físicas`,
        depositTradingVolume: `Depósito / Lotes de Negociação`,
        gifts: `Ofertas (USD)`,
        accountID: `ID da Conta`,
        current: `Atual`,
        luckyDrawDeposit: `Depósito`,
      },
      dialog: {
        selectAccountContext: `Por favor, selecione a conta na qual gostaria de receber o seu bónus de crédito (apenas na conta MT4/MT5)`,
        tnc: 'TERMOS E CONDIÇÕES',
        tncContext: {
          tnc1: `1. Os clientes precisam em primeiro lugar de ativar com sucesso esta promoção dentro do seu portal de cliente, e depois depositar fundos e negociar como requerido para receber as ofertas correspondentes.`,
          tnc2: `2.	Volume efetivo de negociação: Lotes standard de pares de moedas, ouro, prata, spot de petróleo bruto, e futuros de petróleo bruto. As ordens devem ser abertas e fechadas durante o período da promoção. As posições abertas por menos de 5 minutos, as posições bloqueadas e as posições cobertas não são consideradas válidas para esta promoção.`,
          tnc3: `3.	As estatísticas e o volume de ordens são retirados com o horário da MT4 01/11/2022-31/01/2023.`,
          tnc4: `4.	Se a oferta não puder ser comprada devido a fatores de força maior, tais como o produto estar fora das prateleiras ou fora de stock, o cliente pode pedir para receber o equivalente em dinheiro ao preço de mercado da referida oferta. Este preço é baseado no preço do dólar americano (ou moeda equivalente).`,
          tnc5: `5.	Esta oferta não pode ser utilizada em conjunto com qualquer outra oferta. （100 bónus de negociação, 20% de bónus exclusivo, 50% de bónus de depósito, VN 20% de bónus de depósito).`,
        },
        agreeTnc: `Li e concordo com os Termos e Condições`,
        prizeList: `lista de prémios`,
        luckyDrawTransaction: `TRANSAÇÃO DE SORTEIO`,
        xmasGift: `PARABÉNS PELA SUA OFERTA DE NATAL!`,
      },
      message: {
        congratulate: 'Parabéns!',
        congratulateContext: `Ativou com sucesso a Promoção de Natal Promoção Peça um Desejo.`,
        signedOutPromo: `Ao Clicar no Botão Confirmar, será removido da promoção!`,
        deactivePromo: `Cancelou com sucesso a adesão à Promoção de Natal Peça um Desejo.`,
        redeemAmount: `O montante será creditado na sua conta!`,
      },
      tableContent: {
        luckyDraw: `Sorteio`,
        depositTrade: `Depósito / Negociação`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Receba <span class='bg_yellow'>Dinheiro Imediato</span> no seu primeiro depósito</div>
          <div class="desc">Receba até US$50 em dinheiro quando fizer o seu primeiro depósito com a STARTRADER!</div>
        `,
      },
      active: {
        title: `Ative o SEU reembolso em dinheiro FTD`,
        desc: `Por favor, clique no botão abaixo para ativar e participar na atividade.`,
      },
      deactive: {
        title: `Desativar o SEU reembolso em dinheiro FTD`,
        desc: `Por favor, clique no botão abaixo para desativar e abandonar a Promoção de reembolso em dinheiro FTD.`,
      },
      button: {
        active: `Ativar`,
        deactive: `Desativar`,
        fund: `Financie a Sua Conta`,
        redeem: `Resgatar`,
      },
      claimTitle: `COMO RECLAMAR`,
      claimDescriptions: [
        `Ative a promoção antes de fazer um depósito.`,
        `Financie a sua conta de negociação para satisfazer os requisitos de depósito para o reembolso em dinheiro que pretende（≥ 200 USD ou Moedas Equivalentes).`,
        `O valor do voucher de reembolso em dinheiro dependerá do montante do seu primeiro depósito (consulte a tabela de reembolso em dinheiro nos Termos e Condições).`,
        `Para ativar o seu voucher de reembolso em dinheiro, negocie ≥2 lotes de FX, ouro, prata, ou petróleo.`,
        `O seu voucher ativado pode agora ser resgatado. Este montante será refletido no saldo da sua conta.`,
      ],
      eligibilityTitle: `ELIGIBILIDADE`,
      eligibleDescription1: `Tipos de conta elegíveis: Esta oferta está aberta APENAS para contas Standard e Standard Islâmicas, ambas em MT4/MT5.`,
      eligibleDescription2: `Se um cliente tiver várias Contas Standard, só pode selecionar uma Conta Standard para participar nesta promoção.`,
      eligibleDescription3: `Países elegíveis: Todos exceto China continental`,
      eligibleDescription4: `Clientes Elegíveis: Clientes novos e existentes que não tenham histórico de depósitos, incluindo clientes que estão sob IBs/CPAs.`,
      eligibleDescription4Desc: `*As contas de negociação pessoais de IB/CPA não são elegíveis`,
      eligibleDescription5: `O primeiro depósito deve ser feito durante o período da promoção.`,
      eligibleDescription6: `As Ordens mantidas durante menos de 5 minutos não são consideradas válidas para a promoção.`,
      tnc: `*Aplicam-se os termos e condições, {link} para ver`,
      link: `clique aqui`,
      dialog: {
        selectAccountContext: `Por favor, selecione a conta com a qual gostaria de participar na atividade de bónus de negociação`,
        tnc: `Termos e Condições`,
        tncContext: {
          tnc1: `1. Depois de ativar esta promoção, terá também de depositar fundos e negociar 2 lotes standard, conforme requisitado, para receber o reembolso em dinheiro correspondente.`,
          tnc1desc: `*Apenas os novos depósitos feitos durante o período da promoção são válidos para a promoção. Isto exclui transferências internas, bónus por recomendação de amigo, e ajustes em dinheiro.`,
          tnc2: `2.	Apenas o primeiro depósito efetuado durante este período será contabilizado para o cálculo do reembolso em dinheiro.`,
          tnc3: `3.	Os Clientes elegíveis (tanto os novos como os já existentes), que satisfaçam os critérios aqui estabelecidos, têm a possibilidade de receber um reembolso em dinheiro, equivalente a:`,
          tnc4: `4.	Os Produtos Elegíveis: Forex, Ouro, Prata, Petróleo.`,
          tnc5: `5.	O volume efetivo de negociação: As novas ordens abertas e fechadas durante o período de promoção. As posições abertas por menos de 5 minutos, as posições bloqueadas e as posições cobertas não são consideradas como negociações válidas para esta promoção.`,
        },
        agreeTnc: `Li e concordo com os Termos e Condições`,
        grossDeposit: `Depósito Bruto USD`,
        grossDepositDesc: `ou moeda equivalente`,
        cbVoucher: `Voucher de reembolso (USD)`,
        tableDesc: `* Os clientes podem resgatar até $50 (ou equivalente) em voucher de dinheiro.`,
      },
      voucher: {
        title: `Reembolso FTD`,
        accountID: `ID da Conta`,
        accumulateVolume: `Volume de Negociação Acumulado`,
        standardLots: `Lotes padrão`,
        desc: `Negocie ≥2 lotes de FX, ouro, prata, ou petróleo para o ativar`,
        clickForRedeem: `Clique no botão abaixo para o enviar para o saldo da sua conta.`,
        ftdLessThan200: `O seu depósito inicial é inferior ao montante mínimo exigido.`,
      },
      message: {
        congratulate: `Parabéns!`,
        congratulateContext: `Ativou com sucesso a Promoção de Reembolso FTD.`,
        signedOutPromo: `Ao Clicar no Botão Confirmar, será removido da promoção!`,
        deactivePromo: `Desativou com sucesso a Promoção de Reembolso em Dinheiro FTD.`,
        successRedeem: `O seu Reembolso FTD foi resgatado com sucesso.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Prezado cliente,',
        inst2: `Lembramos que a promoção Bônus Sem Depósito terminará em <span>{date} (GMT+3)</span> .`,
        inst3:
          'De acordo com os Termos e Condições da Promoção, informamos que o bônus na sua conta depositada será automaticamente removido assim que a promoção terminar. Para aquelas contas sem depósito ou registro de transação, o bônus e o saldo serão automaticamente removidos assim que a promoção terminar.',
        inst4: `Se você tiver alguma dúvida ou precisar de ajuda, entre em contato com nossa equipe de suporte através do Live Chat, e-mail: <span>{mail}</span>, ou telefone <span>{phone}</span>.`,
        cantInerTransfer:
          'Como você está participando de nossa campanha NDB, não podemos processar sua solicitação de transferência',
      },
      db: {
        inst1: 'Caro cliente,',
        inst2: `Lembramos que a promoção Bônus  Depósito 50% terminará em <span>{date} (GMT+3)</span> .`,
        inst3:
          'De acordo com os Termos e Condições da Promoção, informamos que o bônus na sua conta depositada será automaticamente removido assim que a promoção terminar. Para aquelas contas sem depósito ou registro de transação, o bônus e o saldo serão automaticamente removidos assim que a promoção terminar.',
        inst4: `Se você tiver alguma dúvida ou precisar de ajuda, entre em contato com nossa equipe de suporte através do Live Chat, e-mail: <span>{mail}</span>, ou telefone <span>{phone}</span>.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Descarregue a MetaTrader 4 Para PC, Mac, navegadores de internet, smartphones e tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Descarregue a MetaTrader 5 Para PC, Mac, navegadores de internet, smartphones e tablets',
  },
  forgetPwReq: {
    tip: 'Digite o endereço de e-mail de sua conta para redefinir sua senha.',
    formValidation: { emailReq: 'O e-mail de início de sessão é obrigatório' },
    succ:
      'Acabamos de lhe enviar um e-mail com instruções para redefinir a sua palavra-passe.<br><br>Por favor, certifique-se de que verifica a pasta de spam do seu e-mail.',
  },
  loginClient: {
    header: 'PORTAL DO CLIENTE',
    failedMasseage: 'Por favor, tente novamente.',
    existingClientPortalAccess:
      'Verificamos que já tem acesso ao Portal do Cliente.<br /><br />Inicie a sessão utilizando o seu e-mail para abrir uma nova conta!',
    footer:
      'Aviso de risco: A negociação de contratos por diferença (CFDs) carrega um alto nível de risco para seu capital e pode resultar em perdas, você só deve negociar com dinheiro que pode se dar ao luxo de perder. A negociação de CFDs pode não ser adequada para todos os investidores, por favor, certifique-se de compreender completamente os riscos envolvidos e tome as medidas apropriadas para gerenciá-los.<br><br><br>\n {platformEntityName} é autorizada e regulada pela Autoridade de Serviços Financeiros das Seicheles com Licença No. SD050.<br>\n A STARTRADER LLC é autorizada e regulada pela Autoridade de Serviços Financeiros de São Vicente e Granadinas com Licença No. 271 LLC 2020.<br>The entities above are duly authorized to operate under the STARTRADER brand and trademark.<br>',
    formValidation: { emailReq: 'O endereço de E-mail é obrigatório' },
    invailidRafLink: `O link de recomendação utilizado é inválido. Registe-se para uma nova conta abaixo.`,
  },
  myProfile: {
    changeDetails:
      'Se desejar atualizar os detalhes do seu perfil existente, não hesite em entrar em contacto com {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'ESCOLHA UMA PLATAFORMA DE NEGOCIAÇÃO',
    chooseAccType: 'ESCOLHA UM TIPO DE CONTA',
    chooseCurrency: 'ESCOLHA UMA MOEDA DE CONTA',
    chooseLeverage: 'Escolha Alavancagem',
    chooseBalance: 'SALDO DA CONTA',
    notes: 'NOTAS ADICIONAIS',
    currError: 'Selecione uma moeda de base para sua conta',
    platErr: 'Deve selecionar uma plataforma de negociação',
    accTypeErr: 'Deve selecionar um tipo de conta',
    leverageErr: 'Você deve selecionar uma alavancagem',
    balanceErr: 'Você deve selecionar um saldo',
    succ:
      '<p>A sua conta real adicional está a ser configurada e brevemente receberá um e-mail com os seus detalhes de acesso.</p><p>Pode visualizar o estado atual da sua inscrição na página inicial.</p>',
    byTicking: 'Ao marcar esta caixa  {tnc}',
    pamm: `As contas PAMM são apenas para investidores com um acordo existente de Money Manager. As contas PAMM não podem ser negociadas por ninguém, exceto o Money Manager.`,
    pammConfirm: `Confirme se deseja continuar selecionando este tipo de conta.`,
  },
  openDemoAdditAcc: {
    header: 'Configuração da Conta de demonstração',
    button: 'abrir conta de demonstração',
    liveButton: 'abrir conta real',
    succ:
      'A sua conta de demonstração adicional foi configurada e brevemente receberá um e-mail com seus detalhes de acesso.',
  },
  resetPassword: {
    header: 'REDEFINIR PALAVRA-PASSE',
    succ:
      'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação da MT4/MT5.',
    succ2: 'A sua palavra-passe foi atualizada.',
    failed: 'O token expirou',
    emailNotFound: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'DEPOSITAR',
    withdraw: 'LEVANTAR',
    desc: {
      incomplete:
        'Se terminar ou não concluir o processo de depósito, o estado da transação será exibido como "incompleto". Será solicitado que crie um novo depósito, uma vez que esta transação não pode ser retomada.',
      success: 'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      failed: 'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rejected: 'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
    withdrawDesc: {
      sub: 'A sua transação foi submetida. Por favor, aguarde que seja concluída.',
      suc: 'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      incomp:
        'A sua transação requer etapas adicionais para ser finalizada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      cancel: 'A sua transação foi cancelada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      fail: 'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rej: 'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
    cancelWithdraw: `Deseja Cancelar a Solicitação de Retirada?`,
    cancelError: `Sua retirada está sendo processada no momento. Portanto, não podemos aceitar sua solicitação de cancelamento. Para obter mais informações, você pode entrar em contato conosco usando o recurso de bate-papo ao vivo.`,
    dialog: {
      appliedAmount: `Montante Aplicado`,
      applicationTime: `Data de Candidatura`,
      processedTime: `Duração de Processamento`,
      accountCurrency: `Moeda da conta`,
      creditDeducted: `Crédito Deduzido`,
      processedNote: `Nota processada`,
      bankName: `Nome do Banco`,
      bankAccountNumber: `Número da Conta do Banco`,
      abaSortCode: `ABA/Código de Classificação`,
      swiftCode: `Código Swift`,
      bankStatement: `Extrato Bancário`,
      bankAddress: `Endereço do Banco`,
      accountName: `Nome da Conta`,
      accountNumber: `Número da Conta`,
      cardholderName: `Nome do Titular do Cartão`,
      cardNumber: `Número do Cartão`,
      cardExpiryDate: `Data de Validade do Cartão`,
      walletAddress: `Endereço da Carteira`,
      ewalletEmailAddress: `Endereço de e-mail da carteira eletrónica`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Infelizmente, a sua transferência não pode ser processada porque resultará na queda da margem disponível na sua conta abaixo do nível de stop out de 100%',
      equityMargin:
        'Infelizmente, não podemos, de momento, processar a sua transferência porque a sua conta está totalmente coberta.',
      equityContinue:
        'Detetámos que tem atualmente posições em aberto, poderá enfrentar stop out depois de submeter o seu pedido de transferência. Por favor, confirme se deseja prosseguir.',
      blackList: 'Esta conta foi restringida de fazer transferências entre contas, por favor, contacte {supportEmail}',
    },
    pleaseNote: 'Por favor, tenha em atenção:',
    suc: 'Os seus fundos foram transferidos com sucesso',
    warn1:
      'Você é responsável por manter um saldo de conta que não acione um aviso de margem. Se tem alguma posição em aberto no momento, por favor certifique-se de que mantém a margem necessária na sua conta. Os fundos transferidos para as contas mantidas na mesma moeda são processados instantaneamente.',
    warn2: 'Se estiver a transferir fundos entre contas mantidas em moedas diferentes, envie um e-mail para {mailTo}.',
    warn3: 'Por favor, tenha em atenção que os fundos não podem ser transferidos entre contas em modo somente leitura.',
  },
  transferHistory: {
    payStatus: { processing: 'Em Processamento', paid: 'Pago', unpaid: 'Por pagar', rejected: 'Rejeitado' },
  },
  identityProof: {
    home: {
      header: 'INSCRIÇÃO DE CONTA INCOMPLETA',
      content:
        'Olá, {name}, a sua inscrição de conta está quase completa, no entanto, ainda é necessário enviar documentação adicional para finalizar a sua conta.',
    },
    shufti: {
      content: `Certifique-se de que os detalhes fornecidos correspondem à sua documentação de identificação e estão no mesmo idioma.`,
      countryOfDocument: `País conforme mostrado no documento`,
    },
    popup: {
      header:
        'Olá, {name}, antes que a sua conta real possa ser finalizada, é necessário fazer o carregamento do seguinte documento para verificação.',
      addressProofSpan:
        '<strong>\n      <b>Confirmar Endereço</b>\n      </strong>\n      <br />\n      <I>\n        Submeta <b>um</b> dos documentos abaixo\n        <br /><br />\n      </I>\n      - Cópia de uma Fatura de Serviços recente </br> (com até 6 meses de validade)\n      <br />\n      <b class="or">or</b><br />\n      -  Cópia de um extrato bancário recente </br> (com até 6 meses de validade)\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> O documento DEVE mostrar claramente o seu nome completo e endereço atual.\n      <br />',
      idProofSpan:
        '<strong>\n      <b>Confirmar a Identidade</b>\n      </strong>\n      <br />\n      <I>escolha\n       <b>uma</b> das opções abaixo\n        <br />\n        <br />\n      </I>\n      - Cópia de Passaporte Válido<br />\n\n      - - Cópia da Carta de Condução Válida\n      <br />\n   (frente e verso)\n      <br />\n      ou</b><br />\n      - Cópia do Cartão de Identidade Nacional\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> O documento DEVE mostrar claramente o seu nome completo.<br />',
    },
    reason: 'Não foi possível verificar a sua documentação,<br> Motivo: {reason}. Por favor, tente novamente.',
  },
  header: { secPortal: 'Área de cliente<br />Segura' },
  menu: {
    home: 'PÁGINA INICIAL',
    accManagement: 'CONTAS',
    liveAcc: 'CONTAS REAIS',
    openAdditAcc: 'CONTAS ADICIONAIS',
    homeDemo: 'CONTAS DE DEMONSTRAÇÃO',
    depositFund: 'DEPOSITAR FUNDOS',
    withdrawFunds: 'LEVANTAR FUNDOS',
    transferBetweenAccs: 'TRANSFERIR FUNDOS',
    payDetails: 'DETALHES DO PAGAMENTO',
    funds: 'FUNDOS',
    transactionHis: 'HISTÓRICO DE TRANSAÇÕES',
    tranHis: 'TRANSFERÊNCIAS',
    download: 'DESCARREGAMENTOS',
    profile: 'PERFIL',
    myProfile: 'O MEU PERFIL',
    cpPassword: 'MUDAR SENHA',
    contactUs: 'Contato',
    register: 'CONTINUAR A APLICAÇÃO',
    tradingTools: 'Aplicativos de Trade',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'CALENDÁRIO ECONÓMICO',
    FEAT_FX: 'IDEIAS EM DESTAQUE',
    FX_IDEAS: 'OPINIÕES DO ANALISTA',
    promotion: 'PROMOTION',
    noDeposit: 'SEM BÔNUS DE DEPÓSITO',
    referFriends: 'INDIQUE UM AMIGO',
    depositBonus: '50% de bônus de crédito',
    cryptoCashback: `Cashback de criptomoeda`,
    firstTimeDeposit: `Reembolso FTD`,
    tradingTool: 'Ferramentas de operação',
  },
  contact: {
    click: 'Clique',
    assist: ' CONTACTE-NOS',
    desc:
      'A equipa profissional de suporte ao cliente multilíngue da {platform} está pronta para o atender e ajudar a fornecer uma experiência de negociação incomparável.',
    followUs: 'Siga-nos',
    tip: 'Para clientes que integraram via {email}',
    liveChat: 'Chat ao Vivo',
  },
  depositResultDialog: { msg: 'Completou com sucesso o seu depósito' },
  dialog: {
    identityProof: {
      success: 'Obrigado. Recebemos os seus documentos com sucesso e estamos a processá-los para verificação.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESSAMENTO DA INSCRIÇÃO DE CONTA',
      id: 'ID',
      poa: 'endereço',
      both: 'O ID e o comprovativo de endereço são',
      content:
        'O seu Comprovativo de {doc} está a ser processado de momento e será finalizado brevemente. Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.',
    },
  },
  register: {
    progressStatus: {
      title: 'Progresso da verificação',
      estimatedTimeTitle: 'Tempo Estimado para Conclusão:',
      min: 'minutos',
    },
    tabs: {
      personalDetails: 'Detalhes Pessoais',
      moreAboutYou: 'Mais Sobre Si',
      accountConfig: 'Configuração da Conta',
      confirmYourId: 'Confirme a sua Identidade',
      fundYourAcc: 'Financiar a sua Conta',
    },
    btn: { next: 'PRÓXIMO', back: 'VOLTAR', fundnow: 'FINANCIAR AGORA', upload: 'Carregar', done: 'CONCLUÍDO' },
    authority: {
      dialog1: 'Deve ter uma conta real para aceder a esta secção do portal do cliente.',
      dialog2:
        'Abra uma conta real agora para desbloquear o acesso total ao portal do cliente, o que inclui uma variedade de ferramentas de negociação, promoções e muito mais.',
      openLiveAcc: 'abrir conta real',
    },
    demo: {
      congrad: 'Parabéns!',
      title: 'A sua conta DEMO MT{platform} de 60 dias está agora ativa',
      title2: 'Detalhes da sua Conta de DEMONSTRAÇÃO',
      li: { login: 'Login: ', password: 'Password: ', srv: 'Server: ', expDate: 'Expiry Date: ' },
      emailSent: '* Os detalhes de início de sessão também foram enviados para o seu e-mail registado.',
      dl: 'Descarregue a sua Plataforma de Negociação MT{platform}',
      improveQ: 'Quer melhorar a sua negociação？',
      improveWay:
        'Basta abrir uma conta real em apenas alguns minutos para desbloquear uma variedade de ferramentas de negociação e promoções exclusivas.',
      proTrade: 'Ferramentas Pro Trader',
      depositBonus: 'Bónus de Depósito de 20%',
      smartTradeTool: 'Ferramentas Smart Trader',
      openLiveAcc: 'abrir conta real',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Detalhes Pessoais',
        userTitle: 'Título',
        firstName: 'Nome Próprio',
        lastName: 'Apelido',
        email: 'E-mail',
        dob: 'Data de Nascimento',
        idType: 'Tipo de Identificação',
        referral: 'Foi recomendado por alguém？ (opcional)',
        middleName: 'Nome do Meio',
        nationality: 'Nacionalidade',
        phoneNum: 'Número de Telefone',
        date: 'Dia',
        month: 'Mês',
        year: 'Ano',
        placeOfBirth: 'Naturalidade',
        idNum: 'Número de Identificação',
        passportNum: 'Número do Passaporte',
        driverLicenseNum: 'Número da Carta de Condução',
        titleDropdown: { mr: 'Sr.', mrs: 'Sra.', ms: 'Sra.', miss: 'Srta.', dr: 'Dr.', prof: 'Professor' },
        idTypeDropdown: {
          passport: 'Passaporte',
          idCard: 'Cartão Nacional de Identidade',
          driverLicense: 'Carta de Condução',
        },
      },
      page2: {
        pageTitle: 'ENDEREÇO DE RESIDÊNCIA PRINCIPAL',
        mainResidency: 'País de residência principal',
        streetNumber: 'Número na Rua',
        streetName: 'Nome de Rua',
        address: 'Endereço',
        provinceOrState: 'Concelho / Distrito',
        cityOrSuburb: 'Cidade / Freguesia',
        postcode: 'Código Postal',
        usQuestion:
          'É Cidadão/Residente nos EUA para fins fiscais ou possui ligações/laços nos EUA (por exemplo, conta Bancária etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'DETALHES FINANCEIROS E DA SITUAÇÃO PROFISSIONAL',
      pageTitle2: 'Conhecimento e Experiência de Negociação',
      complianceExplain: 'As seguintes perguntas são recolhidas para conformidade com os requisitos da AML/CTF.',
    },
    accountConfig: {
      pageTitle: 'Configuração da Conta',
      chooseTradingPlatform: 'Escolha a Plataforma de Negociação',
      chooseAccType: 'Escolha o Tipo de Conta',
      chooseAccCurrency: 'Escolha a Moeda da Conta',
    },
    confirmYourId: {
      pageTitle: 'Confirme a sua Identidade',
      description:
        'Como corretora forex regulamentada, somos obrigados a verificar a sua identidade. Por favor, faça o carregamento de um dos seguintes documentos, para a verificação do seu Comprovativo de Identificação e Comprovativo de Morada. Como alternativa, pode enviar os seus documentos de verificação por e-mail para {mailTo}',
      poidTitle: 'Comprovativo de Identificação',
      porTitle: 'Comprovativo de Endereço',
      uploadDescription: 'Escolha um tipo de documento e faça o carregamento em concordância',
      note: 'IMPORTANTE: O documento DEVE mostrar claramente o seu nome completo.',
      pending: { upload: 'Carregamento Pendente', verification: 'Verificação Pendente' },
      poidTypes: { photoId: 'Identificação com Foto', passport: 'Passaporte', drivingLicence: 'Carta de Condução' },
      porTypes:
        '<li>Fatura de Serviços</li>\n      <li>Extrato Bancário</li>\n      <li>Certificado de Residência</li>',
    },
    finishPage: {
      title: '{individualUserName}, obrigado por se inscrever numa conta real {platformName}!',
      contentDefault:
        'Recebemos a sua inscrição e configuramos a sua conta real <b>{account}</b>. <br />\n      Em breve, receberá um e-mail em <b>{email}</b> contendo os seus detalhes de início de sessão e um link para visualizar o estado da sua conta. <br />',
      contentWithoutId3WithAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br /><br />\n      Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.<br /><br />',
      contentWithoutId3WithoutAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br />',
    },
  },
  autochartist: {
    1: {
      title: `Market Scanner`,
      desc01: `O primeiro Market Scanner do mundo que permite que você analise sua lista de observação do mercado em busca de oportunidades de negociação.`,
      button01: `Baixe Agora`,
    },
    2: {
      title: `Relatório de Mercado`,
      desc01: `Os Relatórios de Mercado Autochartist fornecem um instantâneo da perspectiva técnica dos mercados antes da abertura dos principais centros financeiros.`,
      button01: `Exibir relatórios de mercado`,
    },
    3: {
      title: `Portal de Pesquisa Autochartist`,
      desc01: `Um portal de pesquisa totalmente abrangente que permite encontrar todos os dados disponíveis em um só lugar.`,
      button01: `Lançar`,
      button02: `Guia de usuario`,
      button03: `Acesso OTP`,
    },
    4: {
      title: `Oportunidades de negociação`,
      desc01: `Verifique atualizações sobre oportunidades de negociação a cada 15 minutos com novas configurações baseadas em todos os tipos de análise do Autochartist.`,
      button01: `Exibir oportunidades de negociação`,
    },
    5: {
      title: `Autochartist Mobile App`,
      desc01: `Receba notificações push e rastreie oportunidades de negociação filtradas de alta probabilidade com base em sua lista de observação na plataforma de negociação.`,
      desc02: `Notas:`,
      desc03: `O código QR pode ser lido da câmera normal, desde que o telefone do usuário tenha um scanner QR integrado, ou de dentro do aplicativo autochartist móvel, clicando no botão “Login usando QR Code”.`,
      desc04: `O código QR é válido por apenas 10 minutos, após isso a imagem deve ser atualizada para um novo código QR.`,
      desc05: `O código QR é válido apenas para uso único, após isso a imagem deve ser atualizada para um novo código QR.`,
      button01: `Baixar para IOS`,
      button02: `Baixar para Andriod`,
    },
    6: {
      title: `Assinatura de Newsletter`,
      desc01: `Ao assinar nosso boletim informativo, você receberá e-mails diários da Autochartist para dar uma ideia dos mercados globais de câmbio Comércio com o sindicato pacífico, comércio com o mundo.`,
      button01: `Se inscrever`,
    },
    footer: `O Autochartist é a ferramenta mais avançada do mundo para a identificação automática de oportunidades de comércio na forma de padrões de gráfico, padrões de Fibonacci e nível de chave. O proprietário do Autochartist permite que seus mecanismos de pesquisa monitorem milhares de instrumentos financeiros 24 horas por dia, descobrindo oportunidades de comércio conforme elas ocorrem. Além disso, o Autochartist também oferece a Análise de Volatilidade como um recurso que lhe dará uma melhor avaliação do risco e da volatilidade nos instrumentos que você negocia.`,
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    553: 'Não pode levantar/transferir devido ao crédito na sua conta. Por favor, entre em contacto com {email} para obter mais ajuda',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    568: 'Esta conta está impedida de fazer um depósito, por favor contacte {email}',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    582: 'Você está participando do evento e possui vagas, portanto não pode enviar um pedido de retirada.',
    584: 'Não tem compensações aplicáveis',
    587: 'Saldo insuficiente, entre em contato com o atendimento ao cliente.',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    595: 'Como você está participando de nossas atividades, sua solicitação de transferência não pode ser processada no momento.',
    647: 'O código de verificação está incorreto',
    1001: 'A sua conta está temporariamente indisponível, por favor, contacte o seu gestor de conta',
    session_timeout:
      'O tempo limite da sessão foi excedido, está a ser redirecionado para a página de início de sessão.',
    vload: {
      4019: 'Tipo ou valor de Voucher não é aceite',
      4071: 'Suspeita de fraude',
      4072: 'O Voucher é utilizado',
      4073: 'O voucher não está ativo',
      4074: 'O Voucher está expirado',
      4075: 'O Voucher não é encontrado',
      4080: 'O valor do voucher é insuficiente',
      4444: 'Voucher inválido utilizado. Incompatibilidade da moeda.',
      5000: 'Os dados da loja não estão disponíveis',
    },
  },
  tradingTool: {
    mt4: {
      mt4Text1: '',
      mt4Text2: '',
      mt4Text3: '',
      mt4Text4: '',
      mt4Text5: '',
      mt4Text6: '',
      mt4Text7: '',
      mt4Text8: '',
      mt4Text9: '',
      mt4Text10: '',
      mt4Text11: '',
      mt4Text12: '',
      mt4Text13: '',
      mt4Text14: '',
      mt4Text15: '',
      mt4Text16: '',
      mt4Text17: '',
      mt4Text18: '',
      mt4Text19: '',
      mt4Text20: '',
      mt4Text21: '',
      mt4Text22: '',
      mt4Text23: '',
    },
    pamm: {
      pammSystem: 'Sistema PAMM',
      pammSystemPopular: 'O sistema PAMM é o sistema automatizado de gerenciamento de trading mais popular.',
      mostAdvantage: 'Sua maior vantagem é que é alocado entre todos os investidores com base em um percentual do volume operado, geralmente com base no saldo ou patrimônio do investidor',
      pammTitle: 'O sistema de gerenciamento de contas PAMM oferece as seguintes vantagens.',
      pammText1: 'Com base na plataforma de trading MT4 reconhecida mundialmente',
      pammText2: 'Alocação com base na porcentagem do volume da transação',
      pammText3: 'Alocação com base na porcentagem do volume da transação',
      pammText4: 'Suporta trading com EA',
      pammText5: 'Gerenciamento de ordens de risco em tempo real',
      pammAccount: 'Gestão de contas PAMM',
      pammMangeAccount: 'Conta de administrador PAMM',
      pammInvestAccount: 'Conta de Investidor PAMM',
      checkPammMange: 'Os investidores podem escolher o gestor PAMM certo para eles de acordo com seus objetivos de investimento',
    },
    copytrade: {
      copytrade1: 'COPY TRADE Opere de forma inteligente, lucre muito',
      copytrade2: 'COPYTRADE é uma plataforma que lhe dá a oportunidade de copiar as ordens de traders profissionais. Junte-se à comunidade STARTRADER COPYTRADE e copie as operações dos provedores em alguns passos fáceis, aprenda suas estratégias e melhore suas habilidades de trading.',
      tradeNow: 'Opere agora',
      rank: 'Classificação',
      copytrade3: 'Etapas para copiar os pedidos',
      copytrade4: 'Faça login na comunidade',
      copytrade5: 'Faça login na comunidade para se tornar um seguidor',
      copytrade6: 'Assinar',
      copytrade7: 'consulte o gráfico de ganhos e selecione a conta do provedor que deseja assinar e copie as operações por conta própria',
      copytrade8: 'Ordem copiada com sucesso',
      copytrade9: 'As ordens copiadas podem ser visualizados na MT4 ou na plataforma da comunidade',
      copytrade10: 'Provedor',
      copytrade11: 'Criar perfil pessoal',
      copytrade12: 'público ou visível apenas para pessoas específicas',
      copytrade13: 'Limitar o capital inicial do Seguidor',
      copytrade14: 'Registro automático como provedor',
      copytrade15: 'Configurar dividendos para assinantes',
      copytrade16: 'Definir lucro por si mesmo',
      copytrade17: 'Seguidor',
      copytrade18: 'várias assinaturas',
      copytrade19: 'Modelos de acompanhamento diversificados',
      copytrade20: 'Pedidos de fornecedores podem ser filtrados por qualificações',
      copytrade21: 'Proteção de segurança de pedidos',
      copytradeBtn1: 'Torne-se um Provedor',
      copytradeBtn2: 'Torne-se um seguidor',
    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
