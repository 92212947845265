<template>
  <ul class="link-box">
    <li v-if="countryCode == inCountryCode" @click="indiaBankTransferClick" data-testid="indiaBankTransfer">
      <div class="top title">{{ $t('common.withdrawChannel.indiabanktransfer') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1-6 {{ $t('deposit.default.hours') }}, 24/7
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="isValidCountry" @click="internationalClick(1)" data-testid="international">
      <div class="top title">{{ $t('deposit.default.channels.i12BankTransfer') }}</div>
      <div class="info">
        <div class="logo circle international"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            2-5 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="excludeEqualsIBTCountries" @click="internationalClick(2)" data-testid="international2">
      <div class="top title">{{ $t('deposit.default.channels.i12BankTransfer') }}</div>
      <div class="info">
        <div class="logo circle international"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            2-5 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="countryCode === ngaCountryCode" @click="nigeriaBankTransferClick" data-testid="nigeriaBankTransfer">
      <div class="top title">{{ $t('common.withdrawChannel.nigeriabanktransfer') }}</div>
      <div class="info">
        <div class="logo circle bankNga"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            {{ $t('deposit.default.withinOneBusinessDays') }}, 24/7
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';

export default {
  mixins: [mixin],
  data() {
    return {
      isValidCountry: this.$config.allowIBTDeposit({
        reg: this.$store.state.common.regulator,
        countryCode: this.$store.state.common.countryCode
      })
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
