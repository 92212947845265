const {
  REFER_A_FRIEND_PATHNAME,
  DEPOSIT_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_PATHNAME,
  TRADING_BONUS_PATHNAME,
  DEPOSIT_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
} = require('@/constants/route')
const { PROMOTION_TYPE } = require('@/constants/promotion')
const { WHITELIST2 } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'http://startrader.com/',
    email: 'info@startrader.com',
    accountEmail: 'info@startrader.com',
    blacklistEmail: 'info@startrader.com',
  },
  menus: [
    {
      name: 'menu.home',
      path: '/home',
      icon: 'menu_icon_home',
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: 'menu_icon_account',
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
        },
        // {
        //   optional: true,
        //   name: 'menu.openAdditAcc',
        //   path: '/openLiveAccount',
        // },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: 'menu_icon_funds',
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
        },
        {
          name: 'menu.payDetails',
          path: '/paymentDetails',
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
        },
      ],
    },
    {
      name: 'menu.tradingTool',
      path: '/tradingTool',
      icon: 'menu_icon_tool',
    },
    {
      name: 'menu.promotion',
      path: '/activity',
      icon: 'menu_icon_promotion',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'menu_icon_contact',
    },
    {
      name: 'menu.register',
      path: '/register',
      icon: 'menu_icon_register',
    },
  ],
  // tradingPlatform: {
  //   mt4: true,
  //   mt5: true,
  // },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [],
  openLiveAccount: {
    choosePlatform: [
      {
        value: 'mt4',
        label: '4',
      },
      {
        value: 'mt5',
        label: '5',
      },
    ],
    chooseLiveType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'bbb',
        label: 'Social Trading Account',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent-Account',
        restrictCountries: [
          3382, //UAE
        ],
      },
    ],
    mt4RegisterChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'bbb',
        label: 'Social Trading Account',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent-Account',
        restrictCountries: [
          3382, //UAE
        ],
      },
    ],
    mt4DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'bbb',
        label: 'Social Trading Account',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent-Account',
        restrictCountries: [
          3382, //UAE
        ],
      },
    ],
    mt5ChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
    ],
    mt5RegisterChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
    ],
    mt5DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
      {
        value: 'aaa',
        label: 'Islamic-ECN',
        restrictCountries: [
          6991, //Vietnam
          1,    //China
          5860, //Japan
          7041, //Korea
          4131, //Philippines
          6645, //Singapore
          6163, //Thailand
          7043  //Taiwan
        ],
      },
    ],
    chooseCurrency: [
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: 'USD',
        currency: 'USD',
      },
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: 'USC',
        currency: 'USC',
      },
    ],
    chooseCurrencyByType: {
      standardSTP: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      ECN: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      swapFreeStandardSTP: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      aaa: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      bbb: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      STANDARD_STP_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
    },
    chooseLeverage: [100, 200, 300, 400, 500],
    chooseBalance: [
      { value: 2000, name: '$2000' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 20000, name: '$20k' },
      { value: 50000, name: '$50k' },
      { value: 100000, name: '$100k' },
    ],
    // getAgreeCondition: 'openAdditAcc.disclaimer',
  },
  metaTraderLinks(url) {
    return {
      mt4: {
        METATRADER4_windows: `https://download.mql5.com/cdn/web/startrader.international.pty/mt4/startraderinternational4setup.exe`,
        // METATRADER4_iPhone: `https://download.mql5.com/cdn/mobile/mt4/ios?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203,PUPrime-Live%204`,
        METATRADER4_iOS: `https://download.mql5.com/cdn/mobile/mt4/ios?server=STARTRADERINTL-Demo,STARTRADERINTL-Live,STARTRADERINTL-Live2`,
        METATRADER4_Android: `https://apk-startrader-com.oss-cn-hongkong.aliyuncs.com/MetaTrader%204_400.1356_apkcombo.com.apk`,
        // METATRADER4_WebTrader: `https://webtrader.puprime.net/`,
        METATRADER4_MAC: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=support.metaquotes.net&utm_campaign=download.mt4.macos`,
      },
      // mt5: {
      //   METATRADER5_windows: `https://download.mql5.com/cdn/web/pu.prime.pty/mt5/puprime5setup.exe`,
      //   METATRADER5_iPhone: `https://download.mql5.com/cdn/mobile/mt5/ios?server=PUPrime-Demo,PUPrime-Live`,
      //   METATRADER5_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg`,
      //   METATRADER5_Android: `https://download.mql5.com/cdn/mobile/mt5/android?server=PUPrime-Demo,PUPrime-Live`,
      //   METATRADER5_WebTrader: `https://webtrader.puprime.net/`,
      // },
    }
  },
  bankInfoDetails: {
    payment1: {
      currency: ['EUR', 'USD', 'GBP', 'AUD', 'NZD', 'HKD', 'CAD', 'SGD', 'JPY', 'USC'],
      bank_name: { show: true, value: 'National Australia Bank' },
      branch_address: {
        AUD: { show: false, value: '546 George St,Sydney NSW 2000' },
        USD: { show: false, value: '546 George St,Sydney NSW 2000' },
        USC: { show: false, value: '546 George St,Sydney NSW 2000' },
        EUR: { show: false, value: '546 George St,Sydney NSW 2000' },
        GBP: { show: false, value: '546 George St,Sydney NSW 2000' },
        JPY: { show: false, value: '546 George St,Sydney NSW 2000' },
        CAD: { show: false, value: 'Ground floor,46 Market Street Sydney NSW 2000' },
        SGD: { show: false, value: 'Ground floor,46 Market Street Sydney NSW 2000' },
        NZD: { show: false, value: 'Ground floor,46 Market Street Sydney NSW 2000' },
        HKD: { show: false, value: 'Ground floor,46 Market Street Sydney NSW 2000' },
      },
      branch_name: {
        AUD: { show: true, value: 'Town Hall Sydney' },
        USD: { show: true, value: 'Town Hall Sydney' },
        USC: { show: true, value: 'Town Hall Sydney' },
        EUR: { show: true, value: 'Town Hall Sydney' },
        GBP: { show: true, value: 'Town Hall Sydney' },
        JPY: { show: true, value: 'Town Hall Sydney' },
        CAD: { show: true, value: 'George & Market Sts Sydney branch' },
        SGD: { show: true, value: 'George & Market Sts Sydney branch' },
        NZD: { show: true, value: 'George & Market Sts Sydney branch' },
        HKD: { show: true, value: 'George & Market Sts Sydney branch' },
      },
      account_name: { show: true, value: 'PACIFIC UNION (SEYCHELLES) LIMITED' },
      account_number: {
        AUD: { show: true, value: '899 584 269' },
        USD: { show: true, value: 'PAUNSUSD01' },
        USC: { show: true, value: 'PAUNSUSD01' },
        EUR: { show: true, value: 'PAUNSEUR01' },
        GBP: { show: true, value: 'PAUNSGBP01' },
        JPY: { show: true, value: 'PAUNSJPY01' },
        CAD: { show: true, value: 'PAUNSCAD01' },
        SGD: { show: true, value: 'PAUNSSGD01' },
        NZD: { show: true, value: 'PAUNSNZD01' },
        HKD: { show: true, value: 'PAUNSHKD01' },
      },
      beneficiary_address: { show: true, value: 'House of Francis, Room 302, lle Du Port, Mahe, Seychelles' },
      bsb_number: {
        AUD: { show: true, value: '082 039' },
        USD: { show: true, value: '082 039' },
        USC: { show: true, value: '082 039' },
        EUR: { show: true, value: '082 039' },
        GBP: { show: true, value: '082 039' },
        JPY: { show: true, value: '082 039' },
        CAD: { show: true, value: '082 039' },
        SGD: { show: true, value: '082 039' },
        NZD: { show: true, value: '082 039' },
        HKD: { show: true, value: '082 039' },
      },
      swift_code: { show: true, value: 'NATAAU3303M' },
      bank_address: { show: true, value: 'Pitt & Bathurst Streets, 292 Pitt St, Sydney NSW 2000, Australia' },
    },
    payment2: {
      currency: ['EUR', 'USD', 'GBP', 'AUD', 'NZD', 'HKD', 'CAD', 'SGD', 'JPY', 'USC'],
      bank_name: { show: true, value: 'Equals Money UK' },
      branch_address: {
        AUD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        USD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        USC: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        EUR: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        GBP: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        JPY: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        CAD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        SGD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        NZD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
        HKD: { show: true, value: 'Vintners Place, FairFX, Floor 3, 68 Upper Thames Street, London, United Kingdom' },
      },
      branch_name: {
        AUD: { show: true, value: 'Town Hall Sydney' },
        USD: { show: true, value: 'Town Hall Sydney' },
        USC: { show: true, value: 'Town Hall Sydney' },
        EUR: { show: true, value: 'Town Hall Sydney' },
        GBP: { show: true, value: 'Town Hall Sydney' },
        JPY: { show: true, value: 'Town Hall Sydney' },
        CAD: { show: true, value: 'George & Market Sts Sydney branch' },
        SGD: { show: true, value: 'George & Market Sts Sydney branch' },
        NZD: { show: true, value: 'George & Market Sts Sydney branch' },
        HKD: { show: true, value: 'George & Market Sts Sydney branch' },
      },
      account_name: { show: true, value: 'Pacific Union Client Segregated Account' },
      account_number: {
        AUD: { show: true, value: 'GB11SPPV23188458487826' },
        USD: { show: true, value: 'GB11SPPV23188458487826' },
        USC: { show: true, value: 'GB11SPPV23188458487826' },
        EUR: { show: true, value: 'GB11SPPV23188458487826' },
        GBP: { show: true, value: 'GB11SPPV23188458487826' },
        JPY: { show: true, value: 'GB11SPPV23188458487826' },
        CAD: { show: true, value: 'GB11SPPV23188458487826' },
        SGD: { show: true, value: 'GB11SPPV23188458487826' },
        NZD: { show: true, value: 'GB11SPPV23188458487826' },
        HKD: { show: true, value: 'GB11SPPV23188458487826' },
      },
      beneficiary_address: { show: true, value: 'House of Francis, Room 302, Ile Du Port, Mahe, Seychelles' },
      bsb_number: {
        AUD: { show: true, value: '062-028' },
        USD: { show: true, value: '062-028' },
        USC: { show: true, value: '062-028' },
        EUR: { show: true, value: '062-028' },
        GBP: { show: true, value: '062-028' },
        JPY: { show: true, value: '062-028' },
        CAD: { show: true, value: '062-005' },
        SGD: { show: true, value: '062-005' },
        NZD: { show: true, value: '062-005' },
        HKD: { show: true, value: '062-005' },
      },
      swift_code: { show: true, value: 'SPPVGB2L' },
    },
  },
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
      13: 'Nigeria Bank Transfer',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    60: 'Internet Banking (Canada)',
    61: 'Perfect Money',
    65: 'Local Depositor',
    80: 'Cryptocurrency-ETH',
    81: 'Cryptocurrency-USDC(ERC20)',
  },
  internationalBankTransfer: {
    DEPOSIT: {
      ALLOW_ALL: false,
      WHITELIST: WHITELIST2,
    },
  },
  restrictCreditCardCountryList: ['3589'], //Pakistan
  webtradeCode: `new MetaTraderWebTerminal( "webterminal", {
    version: 4,
    servers: ["STARTRADERINTL-Demo","STARTRADERINTL-Live", "STARTRADERINTL-Live2"],
    server: "STARTRADERINTL-Live",
    utmSource: "www.startrader.com",
    startMode: "",
    language: "en",
    colorScheme: "black_on_white"
} );`,
  pammLinks: {
    manager: 'https://portal.pamm.club/app/auth/manager',
    investor: 'https://portal.pamm.club/app/auth/investor',
  },
  protocolUrl: 'https://www.startrader.com/pdf/STARTRADER-Client-Agreement-Nov-2022.pdf',
  protocol: [
    'openAdditAcc.protocol.protocol11',
    'openAdditAcc.protocol.protocol12',
    'openAdditAcc.protocol.protocol13',
    'openAdditAcc.protocol.protocol14',
    'openAdditAcc.protocol.protocol15',
    'openAdditAcc.protocol.protocol16',
    'openAdditAcc.protocol.protocol17',
    'openAdditAcc.protocol.protocol19',
  ],
  tradingToolTabs: [
    { name: 'tab1', label: 'MT4' },
    { name: 'tab2', label: 'Pamm' },
    { name: 'tab3', label: 'Copytrade' },
    { name: 'tab4', label: 'Webtrader' },
  ]
}
