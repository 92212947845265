import rounding from '@/util/rounding'
import { countryIsoCode } from '@/constants/countryCode'
import { countryCodeEnum } from '@/constants/country'
import { validateEmail } from '@/util/validation'
import { apiCps_payment } from '@/resource/cps'
import { cpsCurrencyCode } from '@/constants/cpsCurrencyCode'
import { cpsActualCurrencyNumber } from '@/constants/cpsActualCurrencyNumber'
import { apiExchangeDepositRate } from '@/resource'
import i18n from '@/lang'
//
export default {
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: this.minLimit,
              currency: this.accountCurrency,
            })
          )
        )
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimit,
              currency: this.accountCurrency,
            })
          )
        )
      } else {
        callback()
      }
    }
    return {
      loading: false,
      accountCurrency: null, // for Japanese yen which no decimal point
      cryptoPayment: [
        'T00400', // Eth
      ],
      walletPaymentTemplate: [
        'T00100_052', // Perfect money
        'T00100_062', // GoPay
        'T00100_063', // Wowspay-VND-Momopay
      ],
      bankTransferTemplate: [
        'T00600_044', // Wowspay-VND-Remit
        'T00600_053', // Zotapay XAF / GHS / IDR / KES / MYR / PHP / RWF / TZS / THB / UGX / VND / ZAR
        'T00600_055', // Eeziepay-IDR / MYR / THB / VND
        'T00600_062', // XPay-THB / IDR / MYR / VND
        'T00600_063', // Webpayment-ZAR / Webpayment-KES
        'T00600_070', // Wowspay-IDR-VA Offline
        'T00600_071', // Paytrust-IDR / MYR
        'T00600_082', // Wowspay-IDR-Remit
      ],
      upiTemplate: [
        'T00312_026', // UPI
      ],
      localDepositorTemplate: [
        'T00600_059', // Local Depositor JOD / MYR / IDR / AED
      ],
      cpsForm: {
        accountNumber: '',
        amount: '',
        attachVariables: [],
        notes: '',
      },
      selectRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'change',
      },
      emailRules: [
        {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t('register.personalDetails.page1.email'),
          }),
          trigger: 'blur',
        },
        {
          validator: validateEmail,
          trigger: 'blur',
        },
      ],
      inputRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'blur',
      },
      cpsRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
      },
      bankHTML: '',
      currencyOrderNumber: '',
      actualCurrencyNumber: '',
      // Please order payment_methods by numbers
      permittedCpsCryptoPaymentMethods: [
        'T00400', // Cryptocurrency
      ],
      permittedCpsEWalletPaymentMethods: [
        'T00100_052', // Perfect Money
        'T00312_026', // Eeziepay-INR-UPI
      ],
      permittedCpsLocalTransferPaymentMethods: [
        'T00100_062', // Wowspay-IDR-GoPay
        'T00100_063', // Wowspay-VND-Momopay
        'T00312_020', // Wowspay QR'
        'T00600_044', // Wowspay-VND-Remit
        'T00600_053', // Zotapay XAF / GHS / IDR / KES / MYR / PHP / RWF / TZS / THB / UGX / VND / ZAR
        'T00600_055', // Eeziepay India / IDR / MYR/ THB / VND /
        'T00600_056', // Dollarsmart South Korea
        'T00600_059', // Local Depositor JOD / MYR / IDR / AED
        'T00600_062', // XPay-THB / IDR / MYR / VND
        'T00600_063', // Webpayment Nigeria / Webpayment-ZAR / Webpayment-KES
        'T00600_070', // Wowspay-IDR-VA Offline
        'T00600_071', // Paytrust-IDR / MYR
        'T00600_082', // Wowspay-IDR-Remit
      ],
      minLimit: this.$config.depositDefaultAmountMin,
      maxLimit: this.$config.depositDefaultAmountMax,
      minLimitByJPY: 5000,
      minLimitByHKD: 400,
      minLimitByUSC: this.$config.depositUSCAmountMin,
      maxLimitByUSC: this.$config.depositUSCAmountMax,
    }
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.cpsForm.amount, 2)
    },
    paymentDetails() {
      return this.$store.state.cps.payment
    },
    tradeAccountCurrencies() {
      let arr = []
      let value = this.paymentDetails.merchant_variable?.trade_account
      arr = value.split(',')
      return arr
    },
    country() {
      const userCountry = countryIsoCode.filter(item => {
        return this.$store.state.common.countryCode == item.id
      })

      if (userCountry && userCountry.length > 0) {
        return userCountry[0].id
      }
      return null
    },
  },
  methods: {
    ////////////////////////////////////////////
    //
    // Please order payment_methods by numbers
    //
    ////////////////////////////////////////////
    paymentLogo(value) {
      switch (value) {
        // Perfect Money
        case 'T00100_052':
          this.logo = 'perfectMoney diamond'
          this.redirectType = 1
          break

        // Wowspay-IDR-Remit
        case 'T00100_062':
          this.logo = 'gopay rectangle'
          this.redirectType = 2
          break

        // Wowspay-VND-Momopay
        case 'T00100_063':
          this.logo = 'momopay circle'
          this.redirectType = 2
          break

        // Wowspay QR
        case 'T00312_020':
          this.logo = 'bank circle'
          this.redirectType = 2
          break

        // Cryptocurrency
        case 'T00400':
          this.logo = 'USDT_2 circle'
          this.redirectType = 1
          break

        // Eeziepay India
        case 'T00600_055':
          this.logo = 'bank circle'
          this.redirectType = 1
          break

        // Dollarsmart South Korea
        case 'T00600_056':
          this.logo = 'bank circle'
          this.redirectType = 2
          break

        // Webpayment Nigeria / Webpayment-ZAR/ Webpayment-KES
        case 'T00600_063':
          this.logo = 'bank circle'
          this.redirectType = 2
          break

        // Wowspay-IDR-VA Offline
        case 'T00600_070':
          this.logo = 'bank circle'
          this.redirectType = 2
          break

        // Wowspay-IDR-Remit
        case 'T00600_082':
          this.logo = 'bank circle'
          this.redirectType = 2
          break

        default:
          this.logo = 'bank circle'
          this.redirectType = 1
          break
      }

      return this.logo
    },
    setAccountNumber(accountNumber) {
      this.cpsForm.accountNumber = accountNumber
    },
    setCurrency(currency) {
      this.accountCurrency = currency
      this.currencyOrderNumber = cpsCurrencyCode[currency] // currency_number
      this.actualCurrencyNumber = cpsActualCurrencyNumber[currency] // actual_currency_number
    },
    submitCpsForm() {
      this.$refs['cpsForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDeposit()
            .then(result => {
              this.cpsHandling(result)
            })
            .catch(err => {
              this.loading = false
              this.errorMessage(this.$t('deposit.default.failed'))
            })
        } else {
          return false
        }
      })
    },
    cpsHandling(result) {
      let channelData = JSON.parse(result.data.data)
      if (result.data.code == 0 && result.data.data) {
        this.bankHTML = channelData.payment_content

        if (this.bankHTML) {
          this.redirectHandling()
        } else {
          this.loading = false
          this.errorMessage(this.$t('deposit.default.failed'))
        }
      } else {
        this.loading = false
        if (result.data.code !== 562) {
          this.errorMessage(this.$t('deposit.default.failed'))
        }
      }
    },
    redirectHandling() {
      this.$nextTick(function() {
        if (document.getElementById('paysubmit') != null) {
          if (document.getElementById('paysubmit')) {
            document.getElementById('paysubmit').submit()
          } else {
            this.loading = false
            this.errorMessage(this.$t('deposit.default.failed'))
          }
        }
      })
    },
    submitDeposit() {
      // Map attach variables
      let attachVariables = {}
      if (this.cpsForm.attachVariables) {
        this.cpsForm.attachVariables.map(a => {
          attachVariables[a.key] = a.value
        })
      }

      let requestBody = {
        mt4Account: this.cpsForm.accountNumber,
        operateAmount: this.cpsForm.amount,
        applicationNotes: this.cpsForm.notes,
        depositAmount: this.exchangeRates ? this.rateChange : this.cpsForm.amount,
        cpsCode: this.paymentDetails.payment_method,
        rate: this.exchangeRates ? this.rate : 1,
        orderCurrency: this.paymentDetails.merchant_variable?.multiple_currency
          ? this.currencyOrderNumber
          : this.paymentDetails.currency_number,
        actualCurrency: this.paymentDetails.merchant_variable?.multiple_currency
          ? this.actualCurrencyNumber
          : this.paymentDetails.actual_currency_number,
        mandatory: this.cpsForm.mandatory ? this.cpsForm.mandatory.toString() : [],
        attachVariables: JSON.stringify(attachVariables),
      }

      return apiCps_payment(requestBody, this.token)
    },
    determineExchangeRate() {
      // check if exchange rates is required
      if (!this.accountCurrency) {
        return
      }

      if (
        (this.accountCurrency != this.paymentDetails.currency_code &&
          !this.cryptoPayment.includes(this.paymentDetails.payment_method)) ||
        (this.cryptoPayment.includes(this.paymentDetails.payment_method) && this.accountCurrency != 'USD')
      ) {
        if (this.paymentDetails.merchant_variable?.multiple_currency && this.accountCurrency != 'USC') {
          this.rate = 1
          this.exchangeRates = false
          this.showExchangeRates = false
        } else {
          if (!this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
            this.showExchangeRates = true
          }

          this.exchangeRates = true
          this.queryRate()
        }
      } else {
        this.rate = 1
        this.exchangeRates = false
        this.showExchangeRates = false
      }
    },
    queryRate() {
      let fromCurrency = this.accountCurrency
      let toCurrency = this.cryptoPayment.includes(this.paymentDetails.payment_method)
        ? 'USD'
        : this.paymentDetails.actual_currency_code
      apiExchangeDepositRate({
        fromCurrency: fromCurrency,
        toCurrency: toCurrency,
      })
        .then(resp => {
          if (resp.data.code == 0) {
            this.rate = resp.data.data
          }
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', {
              oldCurrency: this.accountCurrency,
              newCurrency: this.cryptoPayment.includes(this.paymentDetails.payment_method)
                ? 'USD'
                : this.paymentDetails.actual_currency_code,
            })
          )
        })
    },
    updateLimit() {
      let newLimit = false

      if (!this.accountCurrency) {
        return
      }

      if (this.paymentDetails.merchant_variable?.limit) {
        this.paymentDetails.merchant_variable?.limit.some(limit => {
          if (this.accountCurrency == limit.account_currency) {
            this.maxLimit = limit.max
            this.minLimit = limit.min
            newLimit = true
            return true
          }
        })
      }

      if (!newLimit) {
        switch (this.accountCurrency) {
          case 'JPY':
            this.minLimit = this.minLimitByJPY
            break
          case 'HKD':
            this.minLimit = this.minLimitByHKD
            break
          case 'USC':
            this.maxLimit = this.maxLimitByUSC
            this.minLimit = this.minLimitByUSC
            break
          default:
            this.maxLimit = this.$config.depositDefaultAmountMax
            this.minLimit = this.$config.depositDefaultAmountMin
            break
        }
      }

      // Special Requirement for Philippines - ACM-12415
      if (this.country == countryCodeEnum.PHILIPPINES && this.accountCurrency == 'USC') {
        this.minLimit = 5000.0
      }
    },
    langTranslate(prefix, key) {
      const filteredKey = key ? key.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase() : ''
      const comparedKey = prefix + filteredKey
      const translatedLang = i18n.t(prefix + filteredKey)

      return comparedKey === translatedLang ? key : translatedLang
      // return translatedLang // Return Translate Key
    },
    setAmtLabel(currency, amount) {
      return currency === 'USC'
        ? `${this.$t('common.field.amt')}    ${this.$t('common.field.actualAmount')}= ${amount / 100} USD`
        : this.$t('common.field.amt')
    },
  },
}
