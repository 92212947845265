<template>
  <el-form :model="apiForm" ref="ruleForm">
    <p class="tip">{{ $t('register.moreAboutYou.complianceExplain') }}</p>
    <el-row>
      <el-col v-for="q in EFQuestion" v-bind:key="q.questionId">
        <el-form-item :prop="`${0}_${q.questionId}`"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }">
          <div class="label">{{ q.desc }}</div>
          <div class="question">
            <el-radio-group :disabled="disableField" v-model="apiForm[`${0}_${q.questionId}`]" data-testid="question">
              <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id" :data-testid="qo.id">
                {{ qo.desc }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="line"></div>
    <p class="title">{{ $t('register.moreAboutYou.pageTitle2') }}</p>
    <el-row class="clearfix">
      <el-col v-for="q in tradingQuestions" v-bind:key="q.questionId">
        <el-form-item :prop="`${1}_${q.questionId}`"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }">
          <div class="label">{{ q.desc }}</div>
          <div class="question">
            <el-radio-group :disabled="disableField" v-model="apiForm[`${1}_${q.questionId}`]">
              <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id">
                {{ qo.desc }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import moreAboutYou from '@/mixins/register/moreAboutYou';

export default {
  mixins: [moreAboutYou],
  methods: {
    submit() {
      let employmentFinanceAnswers = [],
        tradingAnswers = [];
      Object.keys(this.apiForm).map((m, i) => {
        const split = m.split('_');
        split[0] == 0
          ? employmentFinanceAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] })
          : tradingAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] });
      });
      this.form.employmentFinanceAnswers = employmentFinanceAnswers;
      this.form.tradingAnswers = tradingAnswers;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/register/moreAboutYou.scss';
.tip {
  font-size: 12px;
  line-height: 18px;
  color: $text-secondary;
  margin: -16px 0 24px;
}

.label {
  font-size: 14px;
  line-height: 22px;
  color: $text-secondary;
}

.question {

  /deep/ .el-radio-group {
    .el-radio-button {
      .el-radio-button__inner {
        font-size: 14px;
        color: $text-primary;
        background-color: $background-color-base;
        height: 32px;
        border: none;
        border-radius: 16px;
        margin-top: 8px;
      }

      &:not(:last-child) {
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      &.is-active {
        .el-radio-button__inner {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}

.line {
  margin: 40px 0;
  width: 100%;
  height: 0;
  border: 1px dashed #DFDFE6;
}

.el-row {
  .el-col:nth-last-child(1) {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
