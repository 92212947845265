<template>
  <div class="pagination">
    <el-pagination
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      v-bind="getBindProps"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'SPagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    getBindProps() {
      return {
        total: this.total,
        layout: 'total, sizes, prev, pager, next, jumper',
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        'hide-on-single-page': true,
        ...this.$attrs,
      }
    },
  },
  methods: {
    onSizeChange(size) {
      this.$emit('update:pageSize', size)
      this.$emit('change', this.currentPage, size)
      this.$emit('size-change', size)
    },
    onCurrentChange(page) {
      this.$emit('update:currentPage', page)
      this.$emit('change', page, this.pageSize)
      this.$emit('current-change', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
