import { Loading } from 'element-ui'
import store from '@/store'

let loadingInstance,
  isTimeout = true,
  ajaxCount = 0

function startLoading(method) {
  if (method == 'router') {
    isTimeout = false
  }
  if (method == 'ajax') ajaxCount++

  loadingInstance = Loading.service({
    lock: true,
    fullscreen: true,
    spinner: 'client-portal-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  store.commit('common/setIsLoading', true)
}

function endLoading() {
  if (loadingInstance)
    setTimeout(() => {
      isTimeout = true
      if (ajaxCount == 0) {
        loadingInstance.close()
        store.commit('common/setIsLoading', false)
      }
    }, 500)
}

function endAjaxLoading() {
  ajaxCount--
  if (loadingInstance && isTimeout && ajaxCount == 0) {
    loadingInstance.close()
    store.commit('common/setIsLoading', false)
  }
}

export { startLoading, endLoading, endAjaxLoading }
