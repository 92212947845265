export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  common: {
    liveChat: {
      desc: '需要帮助？在线客服',
    },
    country: { international: '国际', australia: '澳大利亚' },
    verification: {
      toSlide: '请拖动滑块完成拼图',
    },
    field: {
      nameOnCard: '信用卡持有人姓名',
      cardholderName: `持卡人姓名`,
      first4Digits: '卡号前4位',
      last3Digits: '卡号前3位',
      first6Digits: '卡号前6位',
      last4Digits: '卡号後4位',
      bankName: '银行名称',
      bankAddress: '银行地址',
      bankBranch: '银行支行',
      branchAddress: '支行地址',
      bankCity: '银行所在城市',
      bankProvince: '银行所在省',
      bankBeneficiaryName: '收款人姓名',
      bankBeneAccName: '收款账户名称',
      bankBeneAccNum: '收款帐号',
      bankBeneAddress: '收款人地址',
      bankAccNum: '银行账号',
      bankAccName: '银行账户名',
      bankHolderAddress: '账号持有人地址',
      address: '地址',
      accName: '账户名',
      accNum: '账号',
      accNumTo: '账号',
      bsbNum: 'BSB号码',
      swift: 'SWIFT代码',
      swiftNonAUD: 'SWIFT代码 (非澳元账户)',
      amt: '数额',
      amtUSD: '数额(美元)',
      availableAmount: '可用金额',
      transitNumber: '中转号码',
      institutionNumber: '机构编号',
      imptNotes: '备注',
      yes: '是',
      no: '否',
      payMethod: '支付方式:',
      expDate: '过期时间',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort 代码',
      country_region: '国家/地区',
      currLeverage: '目前杠杆',
      accEquity: '账户净值',
      newLeverage: '变更杠杆至',
      currPw: '现密码',
      newPw: '新密码',
      confirmPw: '确认新密码',
      loginEmail: '登录邮箱',
      email: '电子邮件',
      pw: '密码',
      accType: '账号类型',
      name: '姓名',
      emailAdd: '电子邮件地址',
      phone: '电话',
      dob: '生日',
      nat: '国籍',
      country: '居住国家',
      street: '街道名称 & 号码',
      suburbOrCity: '区/市',
      provinceOrState: '省/州',
      postcode: '邮编',
      empStat: '就业状况',
      annIncome: '年收入',
      save: '投资',
      source: '资金来源',
      industry: '行业',
      statusDef: '状态注释',
      month: '月份',
      year: '年份',
      select: '请选择',
      completed: '已完成',
      incomplete: '未完成',
      submitted: '已提交',
      successful: '成功',
      processing: '处理中',
      cancelled: '取消',
      failed: '失败',
      rejected: '被拒绝',
      upload: '上传',
      pending: `未决定`,
      ccNum: '信用卡号码',
      expDate: '到期日',
      ccExpireDate: '过期时间',
      bankName: '银行名称',
      bankCardNumber: '银行卡号',
      bankBranch: '支行名称',
      cardHolderName: '持卡人姓名',
      reservedMobileNumber: '银行预留手机号码',
      nationalId: '身份证号',
      cardPhoto: '银行卡正面照',
      notes: '备注',
      bankAccOrIBAN: '银行账号/IBAN',
      selectWithdrawCard: '请选择出金卡',
      updateCardPhoto: '请上传银行卡正面照片',
      actualAmount: '实际入金',
      resetBalTo: '重置余额至',
      ipChangeWarning: '当有人从其他位置登录您的账户时收到提醒电子邮件',
      enterVerificationCode: '输入验证码',
      tips: '提示',
      pleaseInput: '请输入',
      confirmClose: '确定关闭吗？',
      noBind: '未绑定',
      infoDetails: '信息详情',
      loginWarn: '登录提醒',
      noDataNow: '暂无数据',
      hasAccountAlready: '已有帐户',
      noAccountData: '暂无可用账户',
      platform: '交易平台',
      addDemoAccount: '新增模拟账户',
      addLiveAccount: '新增真实账户',
      accountType: '账户类型',
      currency: '货币类型',
      leverageRatio: '杠杆比例',
      accountBalance: '账户余额',
      setLeverage: '设置杠杆',
      operationSuccessful: '操作成功',
      operationFailed: '操作失败',
      startTime: '开始时间',
      endTime: '结束时间',
      exportData: '导出数据',
      unionPayCard: '银联卡',
      pleaseAddBankCard: '请添加银行卡',
      singleClick: '单击',
      add: '添加',
      addUnionPayCard: '添加银联卡',
      viewDetail: '查看详情',
      bankCard: '银行卡',
    },
    column: {
      date: '日期',
      type: '类型',
      server: '服务器',
      status: '状态',
      comment: '备注',
      accNum: '账号',
      currency: '货币类型',
      equity: '净值',
      leverage: '杠杆 ',
      resetMT4PW: '重置MT4登录密码',
      resetMT5PW: '重置MT5登录密码',
      tradingAcc: '交易账户',
      method: '方式',
      amt: '数额',
      procNote: '备注',
      number: '卡号',
      PLATFORM: '交易平台',
      resetBal: '重置余额',
    },
    key: {
      from: '从',
      to: '至',
    },
    fund: {
      must: '必须',
      infoHover: '持卡人姓名 {must} 与交易账户姓名一致',
      ccUploadReminder: '请上传您的信用卡正面照以便审核. (请遮挡中间6位数字)',
    },
    button: {
      bkToHm: '回到主页',
      smsLogin: '手机号登录',
      goToIB: '前往IB后台',
      logout: '登出',
      submit: '提交',
      selectFile: '上传文件',
      depositFunds: '入金',
      withdrawFunds: '出金',
      ibPortal: 'IB后台',
      download: '下载',
      changeLeverage: '变更杠杆',
      cancel: '取消',
      changePw: '变更密码',
      forgetPw: '忘记密码',
      confirm: '确认',
      login: '登入',
      forgotPw: '忘记密码？',
      completeQuestionnaire: '完成调查问卷',
      identityProof: '完成账户申请',
      upload: '上传',
      export: '导出',
      ok: '确认',
      hide: '隐藏',
      showAll: '显示所有',
      createLiveAccount: `创建真实账户`,
      redeem: `兑现`,
      sendCode: '发送验证码',
      resendCode: '重发验证码',
      addAccount: '新增账户',
      addBankCard: '添加银行卡',
      singleClickUpload: '单击上传',
    },
    formValidation: {
      accReq: '请填写帐号',
      amtReq: '请填写数额',
      ccReq: '请填写卡号',
      cardReq: '请填写卡号',
      cardOnNameReq: '请填写持卡人',
      cardholderNameReq: `持卡人姓名为必填项`,
      digits16: '请填写16位卡号',
      digits3or4: '请填写3或4位卡号',
      digitsFirst6: '请填写卡号前6位',
      digitsLast4: '请填写卡号后4位',
      digitsLast3: '请填写卡号后3位',
      mid6Digits: '请填写卡号中间{number}位',
      expDateReq: '请填写过期时间',
      cvvReq: '请填写安全码 CVV',
      file: '请上传文件',
      filePhoto: '请上传卡照片',
      fileReceipt: '请上传收据',
      filePhotoReq: '请上传卡照片',
      amtLarger: '金额不能为空，同时必须大于或等于 {minLimit} {currency}',
      amt0: '金额不能为0',
      amtLess: '金额不能大于 {maxLimit} {currency}',
      note256: '字符串不能大于256个字',
      noteMoreThan: '字符串不能大于{limit}个字',
      noteLessThan: '字符串不能少于{limit}个字',
      emailFormat: '请填写正确的电邮地址',
      payMethodReq: '请填写付款方式',
      expReq: '请填写过期时间',
      bankNameReq: '请填写银行名称',
      bankCityReq: '请填写银行所在市',
      bankProvinceReq: '请填写银行所在省',
      bankAddressReq: '请填写银行所在地',
      bankBranchReq: '请填写银行支行',
      bankAccNumReq: '请填写银行帐号',
      bankBeneficiaryNameReq: '请填写收款人姓名',
      bankHolderAddressReq: '请填写持卡人住址',
      swiftReq: '请填写Swift号',
      accHolderAddress: '请填写帐号持有人住址',
      bsbReq: '请填写BSB',
      accNumIBANReq: '请填写银行账号/IBAN',
      accNameReq: '请填写帐号名称',
      withdrawMethodReq: '请选择出金方式',
      tnc: '请确认条款条件',
      currPwReq: '请填写密码',
      currPwWrong: '密码错误',
      newPwReq: '请填写新密码',
      newPwFormat: '您的密码必须在8-16个字符之间，并且是字母(a-z和A-Z)、数字(0-9)和特殊字符(如!@#$%^&*)的组合。',
      IdenficalPw: '新密码不能与旧密码相同',
      confirmPwReq: '请再次填写新密码',
      confirmNotMatch: '两次填写的新密码不一致',
      pwReq: '请填写密码',
      accCurrReq: '您必须选择一个货币类型',
      tradAccReq: '请选择一个交易帐号',
      answerReq: '请选择',
      bankCardReq: '您必须请选择一张卡',
      phoneNumberReq: '请填写银行卡预留手机号码',
      nationaIdReq: '请填写身份证',
      numberReq: '请输填写号码',
      alphanumericReq: '请填写字母数字',
      idTypeReq: '请选择证件类型',
      dobReq: '请选择出生日期',
      pobReq: '请选择出生地',
      nationalityReq: '请选择国籍',
      titleReq: '请选择头衔',
      firstNameReq: '请填写名字',
      lastNameReq: '请填写姓氏',
      emailReq: '请填写邮箱',
      addressReq: '请填写详细地址',
      suburbReq: '请填写城市或区',
      stateReq: '请填写省份或州',
      postcodeReq: '请填写邮政编码',
      countryReq: '请选择主要居住国家',
      masterCardReq: `请填写 Master Card`,
      masterCardJCBReq: `请使用万事达卡/ JCB存款`,
      masterCardVisaReq: `请使用万事达卡/ Visa存款`,
      masterCardAmexJCBReq: `请使用万事达卡/ AMEX / JCB存款`,
      bankCodeReq: '请填写发卡行',
      dynamicReq: '请填写{dynamic}',
      mobReq: '请填写电话号码',
      resetBalAmt: '设置数值应在0到1,000,000之间',
      verCodeReq: '验证码为必填项',
      emailFormatWrong: '邮箱格式不正确',
      passwordFormatWrong: '密码格式不正确',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: '银行转帐/BPay银行转帐/PoLi支付',
      banktransfer: '国际汇款',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: '中国银联',
      unionpaytransfer: '银联转帐',
      fasapay: 'FasaPay',
      creditcard: '信用卡',
      bpay: 'Bpay',
      creditdebitcard: '信用卡/借记卡',
      bankwiretransferinternational: '银行转帐 (国际)',
      banktransferaustralia: '银行转帐 (澳大利亚)',
      banktransferinternational: '银行转帐 (国际)',
      banktransferchina: '银行转帐 (国际)',
      banktransferequals: '银行转帐 (国际)',
      mobilepay: '移动支付',
      brokertobrokertransfer: '交易商到交易商',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '线上银行 (泰国)',
      internetbankingnigeria: '线上银行 (尼日利亚)',
      internetbankingvietnam: '线上银行 (越南)',
      internetbankingmalaysia: '线上银行 (马来西亚)',
      internetbankingindonesia: '线上银行 (印度尼西亚)',
      internetbankingkorea: '线上银行 (韩国)',
      internetbankingindia: '线上银行 (印度)',
      internetbankingphilippine: '线上银行 (菲律宾)',
      internetbankingsouthafrica: '线上银行 (南非)',
      internetbankinguganda: '线上银行 (乌干达)',
      internetbankingrwanda: '线上银行 (卢旺达)',
      internetbankingzambia: '线上银行 (赞比亚)',
      internetbankingcongo: '线上银行 (刚果)',
      internetbankingcameroon: '线上银行 (喀麦隆)',
      internetbankingburundi: '线上银行 (布隆迪)',
      internetbankingkenya: '线上银行 (肯尼亚)',
      internetbankingghana: '线上银行 (加纳)',
      internetbankingtanzania: '线上银行 (坦桑尼亚)',
      internetbankingcanada: '线上银行 (加拿大)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '越南银行银行转帐',
      nigeriabanktransfer: '尼日利亚银行转帐',
      polipayment: 'Poli支付',
      bankwiretransferaustralia: '银行转帐 (澳大利亚)',
      thailandinstantbankwiretransfer: '泰国银行即时转账',
      nigeriainstantbankwiretransfer: '尼日利亚银行即时转账',
      vietnaminstantbankwiretransfer: '越南银行即时转账',
      malaysiainstantbankwiretransfer: '马来西亚银行即时转账',
      indonesiainstantbankwiretransfer: '印度尼西亚银行即时转账',
      southkoreabanktransfer: '韩国本地银行转账',
      southafricainstantbankwiretransfer: '南非银行即时转账',
      philippinesinstantbankwiretransfer: '菲律宾银行即时转账',
      ugandabanktransfer: '乌干达当地银行转账',
      rwandabanktransfer: '卢旺达当地银行转账',
      zambiabanktransfer: '赞比亚当地银行转账',
      congobanktransfer: '刚果当地银行转账',
      cameroonbanktransfer: '喀麦隆当地银行转账',
      burundibanktransfer: '布隆迪当地银行转账',
      kenyabanktransfer: '肯尼亚当地银行转账',
      ghanabanktransfer: '加纳当地银行转账',
      tanzaniabanktransfer: '坦桑尼亚当地银行转账',
      indiabanktransfer: '印度银行转帐',
      banktransferbpaypolipayaustralia: '银行转帐/BPay银行转帐/PoLi支付 (澳大利亚)',
      banktransferbpaypolipayinternational: '银行转帐/BPay银行转帐/PoLi支付 (国际)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: '加拿大本地银行转帐',
      cryptobitcoin: '加密货币-Bitcoin',
      cryptotetheromni: '加密货币-Tether(OMNI)',
      cryptotethererc20: '加密货币-Tether(ERC20)',
      ryptotethertrc20: '加密货币-Tether(TRC20)',
      cryptocurrencybitcoin: '加密货币-Bitcoin',
      cryptocurrencyusdt: '加密货币-USDT',
      cryptocurrencyeth: '加密货币-ETH',
      cryptocurrencyethcps: '加密货币-ETH',
      cryptocurrencyusdc: '加密货币-USDC(ERC-20)',
      cryptocurrencyusdcerc20: '加密货币-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: '电子钱包',
      CRYPTOCURRENCY: '加密货币',
      LOCAL_TRANSFER: '本地转帐',
      archiveThisCard: '如果此卡不再有效或不再使用，请归档此卡',
      manageCreditCards: '管理信用卡',
      update: '更新',
      archive: '归档',
      requiredDocument: '归档卡所需的文件',
      officialLetter: '官方银行信函/电子邮件说明该卡不再可用',
      bankStatement: '使用此卡进行交易的银行对账单',
      expiredCard: '过期/不可用的卡',
      confirmArchive: '您确定要归档这​​张卡片吗？',
      confirmUpdate: '您确定要更新此卡的有效期吗？注：如因提供的到期日不正确导致提款失败，STARTRADER 概不负责',
      pendingArchiveRequest:
        '您的信用卡存档请求目前正在等待批准，请稍等至流程完成后再进行提款，或通过在线聊天联系我们的客户服务，我们将协助您加快流程。',
    },
  },
  home: {
    pendingTooltip:
      '您的真实帐户获得批准后，您将收到一封包含MT{number}登录详细信息的电子邮件，以便您可以开始交易！同时，您可以先进行入金以获得优先开户的权利.',
    leverage: {
      tnc:
        '<p>通过提交此请求，我承认我已了解高杠杆会给我的资本带来高风险，且承受的损失可能大于但不限于我所存入的资本。<br><br>我同意产品发行方有权自行决定审查我的交易活动，并调整我的交易账户的杠杆水平，且不会事先发出任何警告或通知。<br><br>我了解选择高杠杆可以使我获得更大的保证金，但也可能会造成重大损失。</p>',
      changeLeverageConfirm: '您的请求已提交，我们会尽快为您处理',
      changeLeverageAutoComplete: '您的杠杆修改申请已完成',
      changeLeverageReject: '您的杠杆修改申请被拒绝',
      changeLeverageFail: '杠杆修改申请失败, 请重试',
      changeLeverageDefault: '发生错误, 请联系{supportEmail}关于您的杠杆修改申请',
      alreadyApplied:
        '您已经提交更改账户杠杆的请求, {platform} 的客服团队正在审查您的请求。在提交另一个请求之前，请等待我们对此次请求的审查。',
      reduceLeverageWarn: `请注意：如果您的账户保证金不足，减少杠杆会导致未结头寸被迫平仓。`,
      reduceLeverageConfirm: `你想继续吗？`,
      autoRestore: `由于您的账户净值超过了20,000美元，您有资格使用的最大杠杆为500:1。当您的账户资产小于或等于20,000美元时，则可以将杠杆率改为1000:1。`,
    },
    pw: {
      error: '重置密码失败。 请稍后再试一次',
      confirm: '您的密码已经更新<br>您现在可以使用您的新密码登录您的MT4/MT5账户',
      email: '<p class="first_info">重置密码的邮件已经发送至您的邮箱</p><p>请确保您已经检查了垃圾邮件信箱</p>',
    },
    activate: {
      question: '您希望重新激活您的交易账户么？',
      confirm: '我们已经收到您的请求<br>您的账户将会很快被激活',
    },
    accStatus: {
      active: '启用',
      rejected: '拒绝',
      inactive: '未启用',
      processing: '处理中',
      activate: '激活',
      expired: '失效',
    },
    pro1000: {
      desc1: `以下限制仅适用于杠杆率为1000:1的账户：`,
      conditions: [
        `所选账户不能参加与信贷相关的促销活动。`,
        `如果所选账户资产超过20,000美元或同等价值，系统可能会自动将杠杆率降至1:500。如果有未完成的交易，在调整杠杆之前，将会给您发送电子邮件通知。`,
        `杠杆限制可能会根据法律法规而变化。`,
        `请注意，1:1000的杠杆率不适用于加密货币、股票、白银和大宗商品的金融产品。这些产品的保证金是按照固定保证金要求持有的，不受1:1000杠杆的影响。`,
      ],
      desc2: `通过提交此申请，我了解高杠杆对我的资本有很大的风险，我有可能遭受大于且不限于我所存入资本的损失。`,
      desc3: `我同意产品发行方有权审查我的交易活动，并酌情调整我的交易账户的杠杆水平，而不需要任何事先警告或通知。`,
      desc4: `我明白，通过选择高杠杆，我可以用更大的保证金进行交易，但有可能产生巨大的损失。`,
    },
  },
  paymentDetails: {
    subHeader: '添加支付方式',
    payMethod: '支付方式',
    tip: `<p>请从下面的菜单中选择您希望使用的出入金方式</p><br />
    <p><b请注意：</b> 为了确保我们可以及时处理您的出入金请求，根据AML/CTF法规规定，您在选择的支付方式的中的姓名必须是<b> {individualUserName}</b></p>`,
    result: {
      defaultSuccessMessage: '感谢您为交易账户提交入金方式。我们正在处理您的请求，并会尽快与您联系。',
      unionPayCardSuccessMessage: '您的银联卡绑卡申请已提交, 在审核完成后, 您就可以使用此银行信息进行出金',
    },
    history: {
      header: '支付方式',
    },
    cc: {
      field: {
        ccNum: '信用卡号码',
        first6: '前六位',
        last4: '后四位',
        withrawlable: '此卡片的最大可用提款额为 {amount}。',
      },
      formValidation: {
        upload: '请上传卡照片',
      },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: '正在获取入金信息, 还有 {countDown} 秒',
      successMsg: '感谢您的入金, 您的入金将很快在您的交易账户中显示',
      rejectMsg: '拒绝',
      failureMsg: '您的支付失败了,请重试',
      message: '感谢您的入金, {platform} 团队需要手动进行审核这笔交易. 审核成功后, 资金将会立刻存入到您的交易账户中',
      defaultException: '请联系我们的客服来确认您在 {platform} 的入金',
    },
    failed: {
      countDownMsg: '正在获取入金信息, 还有 {countDown} 秒',
      rejectMsg: '拒绝',
    },
  },
  deposit: {
    alert: {
      depositBonus50: `你有资格获得50%的存款赠金。请在入金前激活!`,
    },
    default: {
      deposit: '入金',
      reference: '备注',
      sendReceipt: '发送收据',
      sendReceiptTip:
        '当您完成转账后，请完成下面的表格并附上您转账收据的截图、照片或复印件，请确保转账收据上能够清楚地显示您的全名，账号和转账金额，以便我们处理您的转账。',
      reminder: '我们需要1-2个工作日将您的入金放到您的交易账户中',
      successMsg: '您的入金将会在我们收到并确认您的转账后显示在您的交易账户中',
      alt: '您也可以将您的转账收据发送到 {mailTo}',
      reminderFundAppear: '您的资金将会在我们从您之前的交易商处获取后立即出现在您的交易账户中',
      failed: '入金失败。 请稍后再试一次',
      fee: '手续费',
      instant: '即时',
      hour: '小时',
      hours: '小时',
      businessDays: '个工作日',
      withinOneBusinessDays: '1个工作日内',
      secondaryNGN: '(如果您选择使用尼日利亚奈拉 (NGN) 货币，您可以选择使用此帐号进行此交易。)',
      disclaimer: `<li>您在 {platform} 的所有付款都是保密的，并且仅用作在 {platform} 的交易账户资金出入使用。电子支付使用SSL(安全套接字层)技术进行处理，并进行加密以确保安全性。</li>
      <li>{platform} 坚决不接受其他第三方付款。所有向您交易账户存入资金的账户必须与您在{platform}的个人账户同名。</li>
      <li>您应当理解，提交入金申请并不代表 {platform} 接受您的资金。如果您的交易账户正在接近或正被要求追加保证金，请确保您已经提前做好准备。</li>
      <li>{platform} 不会对任何由支付系统造成的延迟或错误负责。</li>
      <li>{platform} 不会对任何第三方支付方式提供者或您的银行对您收取的费用负责。</li>
      <li>客户同意他们可能被要求提供有关入金来源的信息，包括客户的资产和任何其他{platform}认为必要的信息，以便在处理存款请求时确定资金来源，并同意提供{platform}要求的所有信息。如果不能提供所需信息，可能会导致您的请求在处理过程中出现重大延误。{platform}将不负责任何延误或因延误而造成的损失。</li>`,
      channels: {
        creditOrDebit: '信用卡或借记卡入金',
        i12BankTransfer: '国际汇款',
        unionPay: '银联入金',
        unionPayCN: '中国银联转账',
        vietInstant: '越南银行即时转账',
        thaiInstant: '泰国银行即时转账',
        nigeriaInstant: '尼日利亚银行即时转账',
        malayInstant: '马来西亚银行即时转账',
        indonesiaInstant: '印度尼西亚银行即时转账',
        philippineInstant: '菲律宾银行即时转账',
        mobilePay: '移动支付',
      },
      rate: {
        rate: '汇率（{oldCurrency}/{newCurrency}）: ',
        result: {
          rateError: '获取汇率失败',
          channelError: '获取入金渠道失败',
        },
      },
      cn: {
        rate: '汇率（美元/人民币）: ',
        amt: '人民币: ',
      },
      upgrade: '渠道正进行升级中， 仅支持曾使用该入口成功入金过的客户使用。',
      depositInstruction: '入金指示',
      depositNote: '入金说明',
    },
    method: {
      desc: '如何通过{method}入金',
      inst1: '{number}.   完成下面的表单并点击“提交”',
      inst2: '{number}.   在银行选择页面选择您的银行，并登入您的{country}在线网银',
      inst3: '{number}.   按照提示，通过您的手机银行/在线网银完成入金',
      inst4: '{number}.   然后，您将被跳转到到另一页面来输入您的卡详细信息',
      inst5: '{number}.   输入您的银行卡详细信息后，按“提交”',
      inst6: '{number}.   您将被重定向回客户后台，并且您的交易将完成',
      inst7: `{number}.   如果您的货币不是{currency}，则银行可能会根据当前银行汇率向您收取转换费。`,
      inst8: `{number}.   信用卡可使用EUR，GBP，AUD，SGD，NZD，HKD，JPY和USD的入金每笔交易最高限额为$ 5,000美元。 即每笔入金最多接受为6,500加元的存款`,
      inst9: `{number}.   每次存款最高 $ 1500 USD`,
      inst10: `{number}.   支付信息页面将会弹出`,
      inst11: `{number}.   在银行选择页面中选择银行，然后登录到您的在线银行`,
      inst12: `{number}.   我们可以接受加元付款。每次存款最高$ 9,999 CAD`,
      form: {
        header: '{method}表单',
      },
    },
    reminder: {
      inst1: '完成下面的表单并点击“提交”',
      inst2: '登入您的{method}账户',
      inst3: '按照{method}帐户中的提示完成付款',
      inst4: '然后，您将被跳转到到另一页面来输入您的卡详细信息',
      inst5: '输入您的银行卡详细信息后，按“提交”',
      inst6: '您将被重定向回客户后台，并且您的交易将完成',
      inst7: '您将会被转到一个显示{method}钱包地址和数额的新窗口',
      inst8: '复制{method}钱包地址，然后登录到您的个人{method}钱包，然后将所需金额转出到页面上的{method}地址',
      inst9: '扫描二维码，打开您的手机银行应用程序',
      inst10: '按照手机上的提示完成入金',
      inst11: '请确保通过重新定向的付款页面进行在线支付，以避免任何资金损失。',
      inst12: '在银行选择页面中选择银行，然后登录到您的在线银行',
      inst13: '按照提示，通过您的手机银行/在线网银完成入金',
      inst14:
        '当您完成汇款后，请完成下面的表格并附上您汇款收据的截图、照片或复印件，请确保汇款收据上能够清楚的显示您的全名，账号和汇款金额，以便我们处理您的汇款。',
      inst15: '我们需要2-5个工作日将您的入金放到您的交易账户中',
      inst16: '请允许资金将在1个工作日内存入您的账户。.',
      inst17: '请确保收据清楚显示您的全名、账号和存款金额，以便我们处理您的交易。',
      inst18: '资金将在 1-6 小时内存入您的账户。',
      note1: '{method}每笔交易的存款限额为25,000美元，每天最多为75,000美元。',
    },
    southAfrica: { header: '南非银行即时转账', country: '南非' },
    idn: { header: '印度尼西亚银行即时转账', country: '印度尼西亚' },
    ida: { header: '印度银行即时转账', country: '印度' },
    nigeria: { header: '尼日利亚银行即时转账', country: '尼日利亚' },
    thailand: { header: '泰国银行即时转账', country: '泰国' },
    malay: { header: '马来西亚银行即时转账', country: '马来西亚' },
    php: { header: '菲律宾银行即时转账', country: '菲律宾' },
    vietnam: { header: '越南银行即时转账', country: '越南' },
    korea: { header: '韩国本地银行转账' },
    canada: { header: 'Interac电子转帐', note1: '我们只接受用加元付款。每笔交易的最高金额为9,999加元。' },
    uganda: { header: '乌干达当地银行转账', country: '乌干达' },
    rwanda: { header: '卢旺达当地银行转账', country: '卢旺达' },
    zambia: { header: '赞比亚当地银行转账', country: '赞比亚' },
    congo: { header: '刚果当地银行转账', country: '刚果' },
    cameroon: { header: '喀麦隆当地银行转账', country: '喀麦隆' },
    burundi: { header: '布隆迪当地银行转账', country: '布隆迪' },
    kenya: { header: '肯尼亚当地银行转账', country: '肯尼亚' },
    ghana: { header: '加纳当地银行转账', country: '加纳' },
    tanzania: { header: '坦桑尼亚当地银行转账', country: '坦桑尼亚' },
    creditOrDebit: {
      header: '信用卡',
      worldcard: {
        note1:
          '使用欧元、英镑及美元的信用卡存款，每笔交易的最高金额为20,000美元。使用欧元、英镑及美元的信用卡存款，每笔交易的最高金额为20,000美元或同等金额。',
        note2: '每个用户最多可持有3张不同的卡。任何超过限制的存款将不被接受。',
      },
      virtualPay: {
        note1:
          '使用欧元、英镑、澳元、新加坡元、新西兰元、港币、日元以及美元的信用卡存款，每笔交易的最高限额为10,000美元。',
        note2: '24小时内每张卡最多可存入50,000美元或10笔交易。任何超过限制的存款将不被接受。',
      },
      Sdpay: {
        note1: '使用欧元、英镑及美元的信用卡存款，每笔交易的最高金额为20,000美元或同等金额。',
        note2: '每天最多只能进行8次交易。任何超过限制的存款将不被接受。',
        note3:
          '请注意，您的首次付款可能会被搁置。请耐心等待我们的团队验证您的付款，或者您可以直接联系我们的客户支持团队以获得进一步的帮助。',
      },
      maxisPay: {
        note1: '使用美元的信用卡存款每笔交易的最高金额限制为 5,000 美元',
      },
    },
    net: {
      header: 'Neteller',
      note1: '我们接受使用澳元、美元、欧元、加元和英镑的支付。',
      note2: '您只能使用与MT4交易账户相同的货币种类为您的账户存款。',
      note3: '如果使用错误的电子邮件，Neteller会自动将资金转换为您账户的基础货币。这可能会产生费用。',
      note4: '存款名必须与您的STARTRADER账户同名。',
    },
    vload: {
      header: 'VLOAD存款',
      inst1: '2.   我们可以接受美元、欧元和日元的付款。',
      inst2: `<li>3.   购买Vload凭证，点击 <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">此处</a>.</li>`,
      form: {
        voucherpin: '凭证PIN',
        voucherpinReq: '请填写凭证PIN',
      },
    },
    bankWireAu: {
      header: '银行转账: 澳大利亚',
      instruction: '入金可以通过您的在线网银转至 {platform}，您也可以通过联系您的银行将入金转至 {platform}.',
      tip: '选择您想存入账户的货币类型。请确保您选择了与交易账户相同的货币类型',
      form: {
        bankDetails: '银行信息（仅限澳币入金）',
        refTip: '请注明您的账户号码',
        afp: '澳大利亚快速支付（仅限本地澳币入金）',
        payIdOrABNNum: 'PayID/ABN号码',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: '请在备注中注明您在 {platform} 的交易账户号码',
      },
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: '我们接受使用美元、澳元、欧元、USC和日元的支付。',
    },
    astropay: {
      desc: '如何通过ASTROPAY入金',
      note1: '我们接受所有币种的支付。',
      form: { header: 'ASTROPAY表单' },
    },
    bPay: {
      header: 'BPAY入金',
      option: '如何通过BPAY方式入金（仅限澳大利亚）',
      inst1: '1.   选择您的MT4/MT5账户查看您的BPAY Biller Code和BPAY Reference Number',
      inst2: '2.   在您的网上银行支付界面中输入您的支付信息，BPAY Biller Code和BPAY Reference Number后提交支付请求',
      inst3: '3.   返回到这个页面并完成下面的BPAY表单，以确保我们可以尽快处理您的入金',
      form: {
        header: 'BPAY表单',
        billerCode: 'BPay Biller Code',
        refNum: 'BPay Reference Number',
        receipt: 'BPAY转账收据',
        reminderNotUploaded:
          '我们发现您还没有上传您的转账收据，为了尽快处理您的入金，请将您的转账收据尽快发送到 {mailTo}',
        reminderProc: '请注意，在没有收到您的转账收据之前，我们无法处理您的入金',
      },
    },
    b2b: {
      header: '交易商到交易商',
      option: '如何从其他交易商处将资金转至 {platform}',
      inst1: '1.   下载并完成 {download}',
      inst2: '2.   完成以下在线表单，并上传填写完成的交易商到交易商转账表单',
      inst3: '3.   将交易商到交易商转账表单发送到您之前的交易商处，以开始转账流程',
      b2bForm: '交易商到交易商转账表单',
      formValidation: {
        upload: '请上传交易商到交易商转账表单',
      },
    },
    cc: {
      header: '信用卡或借记卡入金',
      remind: '如果您在使用 "主要" 方法入金时遇到任何困难，请尝试使用 "次要" 贷记/借记卡入金方法.',
      field: {
        fundNow: '立刻使用信用卡/借记卡入金',
        newCard: '使用新卡',
        cardNum: '信用卡号码',
        nameOnCard: '信用卡持有人姓名',
        expDate: '过期时间',
        cvv: '安全码 CVV',
        bankCode: '发卡行',
        example: `信用卡样例`,
      },
      placeholder: {
        bankCode: '请输入您的发卡行名称',
      },
      cvvTip: '卡背面显示的3位数字',
      threeDomainSecQ: '您的卡支持3D-Secure吗？',
      threeDomainSec:
        '<strong>3D安全验证（3D Secure）是一种Visa和万事达（Mastercard）信用卡认可的防欺诈方式。<br /><br />支持3D安全验证的信用卡入金将会即刻到账</strong>',
      cardUpload: '您正在使用的银行卡尚未链接到您的交易账户上，请上传您的信用卡正面照以便审核. (请遮挡中间6位数字)',
      result: {
        success: '感谢您的入金, 我们现正在核实您的卡信息, 一经核实, 您的入金将会在24小时内显示于您的账户中',
        depositSuccess: '感谢您的入金, 您的入金将很快在您的交易账户中显示',
        error: '为了保护您账户的安全，您已达到最大的尝试存款交易次数，请联系{email}',
      },
      tipOnlyTaiwan: `親愛的客戶提醒您，信用卡出金速度受各家銀行作業時間影響，實際到賬時間依銀行帳務時間為主。若您有資金調度需求，建議使用其他方式入金。`,
      reminder: `<li>1. 每笔交易使用EUR，GBP和USD的信用卡存款上限为$1,900 USD或等值其他货币。</li>
      <li>2. 每天最多8笔交易。</li>
      <li>3. 若您首次使用该通道入金，需平台审核后到账。请您耐心等待，如有问题请您联系在线客服。</li>`,
    },
    fasa: {
      description: '如何使用Fasapay入金',
      form: {
        header: 'FASAPAY表单',
      },
    },
    intSwift: {
      header: '银行汇款（国际SWIFT）',
      desc: '入金可以通过您的在线网银汇款至 {platform} ，您也可以通过联系您的银行将入金汇款至 {platform}.',
      desc2:
        '当您完成汇款后，请完成下面的表格并附上您汇款收据的截图、照片或复印件，请确保汇款收据上能够清楚的显示您的全名，账号和汇款金额，以便我们处理您的汇款。',
      alertSAandNGA:
        '若您持有非美金币种，请您自行换汇成美金后再进行汇款。若您直接汇款，届时产生的汇率差及手续费等需客户自行承担。',
      options: '请选择您的交易账户',
      refInfo: '请您在国际汇款时将 {platform} 的交易账户填写在备注中',
      bussDayInfo: '我们需要2-5个工作日将您的入金放到您的交易账户中',
      tip: '请确保您的转帐收据清晰显示您的<b>全名</b>，<b>交易帐号</b>和<b>存款金额</b>',
    },
    mobile: {
      header: '移动支付',
      desc: '如何通过移动支付入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   扫描二维码，打开您的手机银行应用程序',
      inst3: '3.   按照手机上的提示完成入金',
      form: {
        payMethod: '支付方式:',
        rate: '汇率（美元/人民币）:',
        amt: '人民币:',
      },
      formValidation: {
        cnyAmt: '人民币金额不能多于${limit}',
      },
    },
    crypto: {
      header: '加密货币入金',
      choose: '请选择您想要入金的加密货币类型',
      proceedQuestion: '您是否要继续进行 <b>{method}</b> 入金?',
      note:
        '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
      note2:
        '请注意，我们不支持以BUSDT及ETH存款或取款，请确保您的地址和加密货币与我们接受的链和货币相符，否则可能会造成资金损失。我们不对任何错误所造成的损失负责。',
      remark:
        '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
      inst2: '2.   您将会被转到一个显示{method}钱包地址和数额的新窗口',
      inst3: '3.   复制{method}钱包地址，然后登录到您的个人{method}钱包，然后将所需金额转出到页面上的{method}地址',
      inst4: `请注意：比特币入金由B2BinPay以交易时提供的汇率处理`,
      bitcoin: {
        desc: '如何通过BITCOIN入金',
      },
      usdt: {
        desc: '如何通过USDT(OMNI)入金',
        inst1: '1.   完成下面的表单并点击“提交”',
        inst2: '2.   您将会被转到一个显示USDT(OMNI)钱包地址和数额的新窗口',
        inst3:
          '3.   复制USDT(OMNI)钱包地址，然后登录到您的个人USDT(OMNI)钱包，然后将所需金额转出到页面上的USDT(OMNI)地址',
        inst4: `请注意：USDT(OMNI)入金由B2BinPay以交易时提供的汇率处理`,
        note:
          '受ERC20的矿工费上涨影响，出金处理时间也相应延迟。为了保障您的出金处理速度，您可以选择使用TRC20，或支付较高的矿工费申请出金，以加快您的出金申请进度。',
      },
      usdt_CPS: {
        desc: '如何通过USDT({name})入金',
      },
      form: {
        header_bitcoin: 'BITCOIN表单',
        header_usdt: 'USDT表单',
        header_usdt_CPS: 'USDT({name}) 表单',
      },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS入金',
      desc: '如何通过Skrill入金',
      note1: '我们接受使用美元、欧元、英镑和加元的支付。',
      note2: '您只能使用与MT4交易账户相同的货币种类为您的账户存款。',
      note3: '如果使用错误的电子邮件，Skrill 会自动将资金转换为您账户的基础货币。这可能会产生费用。',
      note4: '存款名必须与您的STARTRADER账户同名。',
      form: {
        header: 'SKRILL表单',
        email: 'Skrill/Moneybookers电子邮件地址',
      },
      reminder: `<li>请注意：</li>
      <li>* 入金的货币类型必须要与您MT4交易账户一致，如果您使用了不正确的电子邮件地址，Skrill会自动为您转换货币类型。可能会向您收取额外的费用</li>
      <li>* 入金必须与您的 {platform} 账户姓名一致</li>`,
    },
    sticPay: {
      header: 'STICPAY入金',
      desc: '如何通过SticPay入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的SticPay账户',
      inst3: '3.   按照SticPay帐户中的提示完成付款',
      form: {
        header: 'STICPAY表单',
        email: 'SticPay电子邮件地址',
      },
    },
    unionPay: {
      header: '银联入金',
      desc: '如何通过银联入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   在银行选择页面选择您的银行，并登入您的在线网银',
      inst3: '3.   按照提示，通过您的手机银行/在线网银完成入金',
      form: {
        header: '银联入金表单',
      },
    },
    unionPayCN: {
      header: '中国银联转账',
      desc: '如何通过中国银联转账入金',
      inst1: '即将弹出付款详情页',
      inst2: '入金时可能会要求您提供会员号，请输入您的交易账号作为会员号。',
      note1: '每次存款的最低交易额为500元人民币，每次存款的最高交易额为49,999元人民币。',
      note2: '请注意，入金限额可能会根据市场情况不时更新。',
      note3: '请不要复制显示的银行收款人详细信息，因为它可能会不时更新。',
      form: {
        header: '中国银联入金表单',
        holderName: '银联卡持有人姓名:',
        holderNameInfo: '请确保您填写了与银行卡持有人相同的姓名',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: '如何通过PayPal入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的PayPal账户',
      inst3: '3.   按照提示，通过您的PayPal账户完成入金',
      inst4: '请注意: 我们不接受第三方渠道入金',
      form: {
        header: 'PAYPAL表单',
      },
    },
    cardpay: {
      desc: '如何通过Credit Card入金',
      inst1: '1.   填写下面的表格，然后按“提交”',
      inst2: '2.   然后，您将被跳转到到另一页面来输入您的卡详细信息',
      inst3: '3.   输入您的银行卡详细信息后，按“提交”',
      inst4: '4.   您将被重定向回客户后台，并且您的交易将完成',
      form: {
        header: 'Credit Card表单',
      },
    },
    tinkbit: { inst5: '5.   每次存款最高 $ 1500 USD' },
    perfectMoney: {
      inst1: '2.   登入您的Perfect Money账户',
      inst2: '3.   按照Perfect Money帐户中的提示完成付款',
    },
    indiaBankTransfer: {
      desc1: '完成转账后，请填写以下表格并附上银行转账收据副本。',
      desc2: '请确保收据清楚显示您的全名、账号和存款金额，以便我们处理您的交易。',
      bussDayInfo: '资金将在 1-6 小时内存入您的账户。',
    },
  },
  withdraw: {
    title: '出金',
    default: {
      accountDetail: '账户详细资料',
      withdrawalAmount: '提款金额',
      alert:
        '根据反洗钱法，提取资金的方式必须与用于存款的方式相同。如果提款金额少于您帐户基础货币的100个单位，则银行将向您收取20个单位的手续费。',
      how: '您是通过何种途径完成入金的？',
      success: '您的出金请求已成功提交，我们会尽快为您处理。',
      selectCC: '选择信用卡',
      selectBA: '选择银行账户',
      anotherCC: '手动输入信用卡',
      anotherBA: '添加银行帐户',
      remember: '记住我的帐号',
      ccFail: '为了保护您帐户的安全，您已达到最大尝试存款交易次数，我们暂时中止了您的出金。请联系{email}',
      checkPosFailed: '获取价位失败',
      fetchTradeAccFailed: '获取交易账户失败',
      chooseAnother: `<p>此卡的最高提款额：0</p> 
      <p>请选择其他卡或其他付款方式</p>`,
      notEnoughFunds: '输入的数量超过了本卡的限制',
      failed: '出金失败, 请稍后再尝试',
      unfortunate:
        '很抱歉的通知您，我们即日起不再受理信用卡出金请求，我们将会通过银行电汇的方式将您的资金转至您指定的银行账户中。',
      noCardWarn: '没有有效的卡, 请先绑卡',
      equityStop: '出金后您的预付款比例预计小于或等于平台的强制平仓比：100%，鉴于此风险您的出金申请无法提交',
      equityMargin: '由于账号处于完全锁仓状态，您的出金申请无法提交',
      equityContinue: '我们检测到您目前正在交易, 申请此次出金可能导致爆仓, 请确认',
      disclaimer: `<li>开仓时出金会影响您的保证金比例。请保持账户在空仓状态，或确保您的账户中留有足够的资金以避免追加保证金。</li>
      <li>{platform}坚决不接受其他第三方付款。所有从您交易账户提取资金的账户必须与您在{platform}的个人账户同名。</li>
      <li>所有信用卡/借记卡、电子钱包、Moneybookers和银行电汇提款请求都必须通过提供包括账户持有人信息和银行细节的银行对账单来验证其安全性和可靠性。</li>
      <li>如果{platform}有合理的理由相信在处理提款请求后交易账户可能会变成负余额，则{platform}保留拒绝处理提款请求的权利。</li>
      <li>如果交易账户遭到退款或其他任何持续调查，{platform}保留拒绝提款请求的权利。</li>
      <li>{platform}对收款金融机构或第三方付款提供商可能收取的任何费用概不负责。</li>`,
      YourPaymentGreaterThanAvailableBalance: '您的出金金额大于可用余额',
      blackList: `很遗憾，您目前无法提交提款请求。请联系{supportEmail}取得更多信息`,
      NetellerChargeAlert: 'Neteller 出金限额为每笔交易 10,000 美元，并收取 2% 的手续费（上限为 30 美元）。',
      agreePolicy: '我已经阅读并且同意{link}',
      withdrawPolicy: '提款政策',
      addWithdrawalMethod: '添加提款方式',
      addSecondaryMethod: '添加第二方式',
      withdrawNote:
        '请注意，我们首选通过信用卡方式处理您的提款。如果您的信用卡金额已用完，我们会要求您选择另一种方式来完成您的提款请求。',
      submitYourWithdrawal: '提交您的提款',
      remainingWithdraw: '剩余的提款金额将被拆分利用其他信用卡或提款方式。',
      onlyWithdrawalCcDesc: `只有信用卡中的 {ccAmount} 将被全额提取`,
    },
    fasa: {
      field: {
        accName: 'FasaPay账户名',
        accNum: 'FasaPay帐号',
      },
      formValidation: {
        accNameReq: '请填写FasaPay账户名',
        accNumReq: '请填写FasaPay帐号',
      },
      fasaPayChargeAlert: 'FasaPay 对每次出金收取 0.5% 的手续费。',
    },
    neteller: {
      field: {
        email: 'Neteller电邮',
      },
      formValidation: {
        emailReq: '请填写Neteller电邮',
      },
    },
    vload: {
      field: {
        email: '已注册Vload电邮',
      },
      formValidation: {
        emailReq: '请填写已注册Vload电邮',
      },
    },
    bitcoin: {
      field: {
        address: 'Bitcoin钱包地址',
      },
      formValidation: {
        addressReq: '请填写Bitcoin钱包地址',
        addressFormat: `您的钱包地址应以“1”、“3”或“bc1”开头`,
        addressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      },
    },
    eth: {
      field: {
        address: 'ETH钱包地址',
      },
      formValidation: {
        addressReq: '请填写ETH钱包地址',
        addressFormat: '请填写ETH钱包地址',
        addressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      },
    },
    usdc: {
      field: {
        address: 'USDC钱包地址',
      },
      formValidation: {
        addressReq: '请填写USDC钱包地址',
        addressFormat: '请填写USDC钱包地址',
        addressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      },
    },
    usdt: {
      field: {
        address: 'USDT钱包地址',
      },
      formValidation: {
        addressReq: '请填写USDT钱包地址',
        trc20WalletFormatIncorrect: `您的钱包地址应以“T”开头`,
        erc20WalletFormatIncorrect: `您的钱包地址应以“0x”开头`,
      },
    },
    bitwallet: {
      field: {
        email: 'Bitwallet电邮',
      },
      formValidation: {
        emailReq: '请填写Bitwallet电邮',
      },
    },
    astropay: {
      field: {
        accountNumber: 'Astropay 账户',
      },
      formValidation: {
        accountNumberReq: '需要 Astropay 账户',
      },
    },
    paypal: {
      field: {
        email: 'Paypal电邮',
      },
      formValidation: {
        emailReq: '请填写Paypal电邮',
      },
    },
    skrill: {
      field: {
        email: 'Skrill电邮',
      },
      formValidation: {
        emailReq: '请填写Skrill电邮',
      },
      skrillChargeAlert: 'Skrill 对每次出金收取 1% 的手续费。',
    },
    sticpay: {
      field: {
        email: 'Sticpay电邮',
      },
      formValidation: {
        emailReq: '请填写Sticpay电邮',
      },
    },
    perfectMoney: {
      field: {
        email: 'Perfect Money电邮',
      },
      formValidation: {
        emailReq: '请填写Perfect Money电邮',
      },
    },
    transfer: {
      info: '{platform} 会垫付每个月第一次出金所产生的银行费用. 当月内的其他出金将会产生20单位出金货币的银行费用.',
      label: {
        upload: '请上传一张近3个月银行对账单的截图、照片或复印件',
      },
      formValidation: {
        upload: '请上传银行帐单',
        emailReq: '请填写电子邮件',
      },
    },
    otherMethod: {
      withdrawalMethod: '提款方法',
      manageWithdrawalDetails: '管理提款详情',
      verificationCode: '验证码已发送至{email}。若您需要进一步协助，请联系{supportEmail}。',
    },
  },
  transfer: {
    creditWarn: '由于您选择的账户具有信用值, 我们无法进行账户间转账操作。',
    blackListWarn: 'MAM账户中移出资金可能会导致开仓交易被强制平仓。在从此账户移出资金前，请联系{mailTo}',
  },
  changePw: {
    header: '变更密码',
    field: {
      currPw: '现密码',
      newPw: '新密码',
      confirmPw: '确认新密码',
    },
    succ: '您的密码已经更新，您现在可以使用新密码访问账户管理中心',
  },
  promotion: {
    clickToGet: '只需单击即可获得$ 50零存款赠金',
    apply: '现在申请',
    start: '现在注册',
    tnc: `
    <li> 1.该活动激活后30天内有效</li>
    <li> 2.此活动适用于所有拥有真实账户（已有或刚注册）但之前从未在STARTRADER存入过资金的客户</li>
    <li> 3.此活动适用于以下地区的新客户：法国、英国、台湾、意大利、加拿大、比利时、瑞士、香港、尼日利亚、荷兰、日本、卢森堡、马耳他、留尼汪岛、德国、马提尼克岛、西班牙、拉脱维亚、波兰 、科特迪瓦、丹麦、巴西、新西兰、葡萄牙、马恩岛、南非、土耳其、罗马尼亚、瓜德罗普岛、奥地利、爱尔兰、法属圭亚那、斯威士兰、阿根廷、乌克兰、希腊、瑞典、摩纳哥、匈牙利、海地 、芬兰、玻利维亚、智利、哥斯达黎加、克罗地亚、捷克共和国、爱沙尼亚、墨西哥、挪威、菲律宾</li>
    <li> 4.此活动仅适用于标准帐户和伊斯兰标准帐户</li>
    <li> 5.具有相同IP地址/ ID /护照的客户只允许申请一次赠金。不论客户在STARTRADER拥有多少个交易账户，该促销活动仅适用于一个账户</li>
    <li> 6.成功申请参与本活动后，客户将获得50美元（或等值的货币）的信用金，并且没有入金要求。</li>
    <li> 7.赠金不能提取。但是，您可以提取您从利用赠金交易中获得的部分利润.</li>
    <li> 8.您必须在申请出金前关闭所有未平仓交易。选择出金后，赠金将从帐户中移除</li>
    <li> 9.STARTRADER保留随时更改条件或拒绝发放赠金的权利</li>`,
    selectDesp: '*请选择您要加入“零存款赠金”活动的帐户。',
    descp1: '您是否想尝试一次无需自己存入资金的交易体验？',
    descp2: `申请$ 50零存款赠金开始尝试获利，<br>且无需承受任何投资风险。`,
    header: '促销条款和条件',
    tnc2:
      '<li> 最小出金金额100 USD(或等值的货币)，最大出金金额200 USD(或等值的货币)。高于200 USD(或等值的货币)的余额会在提出申请后和Credit同时被扣除。</li>',

    successful: '提交成功',
    failed: '提交失败',
    referFriends: {
      intro: `完成以下3个步骤即可获得{amount}`,
      step1: '第一步',
      step1Descp: '成功开设账户',
      step2: '第二步',
      step2Descp: '入金成功',
      step3: '第三步',
      step3Descp: '达成交易量',
      totalEanringTitle: `总可提取金额 <br/>（美元或等值货币）`,
      totalEarningTip: '*奖励金额需经审核。',
      peroidForAudit: `*系统会在7天内处理。`,
      redeem: '提取',
      intro2: '同时，您也可以推荐您的朋友',
      shareLink: '分享您的个性化推荐链接',
      shareLinkDescp: '单击“分享”即可获取独一无二的推荐链接，并将其复制到多样的社交媒体',
      share: '分享',
      total: '总兑换推荐数量',
      social: '通过各大社交媒体分享您的链接',
      clients: '客户',
      table: {
        referralsNo: '推荐人数',
        name: '姓名',
        country: '国家',
        openAccount: '成功开设账户',
        despositSuccess: '入金成功',
        volumeCompleted: '达成交易量',
      },
      totalRedeemableTitle: `总可提取金额 <br/>(USD或其他等值货币)`,
      totalRedeemableTip: '*奖励金额需经审核。',
      referrer: '推荐人',
      referral: '被推荐人',
      dialog: {
        confirmQ: '兑换您的收益',
        confirmQ2: '转换为您的基础货币的总金额为{amount} {currency}。',
        redeem: '立即提取',
        agree: '*点击立即兑换，即表示您同意所有{link}。',
        overBonus: '我们的系统检测到您已累计达到我们的最大限额。如需进一步申请，请联系我们的客服代表。',
      },
    },
    depositBonus: {
      banner: '现在入金并获得您的50%入金奖励!<br>  最高可领取$10,000',
      depositBonus: '领取您的赠金从而帮助您的交易',
      title:
        '想要立即增加您的账户保证金从而提高交易量吗？ 那么今天就为您的帐户存入资金，并从我们这里领取 50% 的赠金吧！',
      fund: '入金',
      tnc: '*包含条款和条件，点击{link}查看',
      link: '此处',
      bonusHistory: '赠金记录',
      overBonus: '* 您已经达到${totalLimit}奖励的上限。其后的存款并不会再获得奖励。',
      opt: '选择加入或者退出',
      tick: '如果您想选择退出，请取消选中该框，如果您想选择加入，请勾选该框。',
      saveChange: '保存更改',
      depositDate: '入金日期',
      credit: '赠金',
      optIn: '选择加入成功',
      optOut: '选择退出成功',
      optInDescription: '请单击下面的按钮以参与50% 入金奖励活动。',
      optOutDescription: '请单击以下按键以选择退出该赠金活动。',
      activate: '激活',
      signOut: '退出',
      banner1: '现在入金并获得您的50%入金奖励!',
      claimDescription: `<p>参与步骤</p>
      <li>1.存入资金前请先激活该活动。 </li>
      <li>2.为您的交易账户存入资金。 </li>
      <li>3.赠金会在您入金后自动到账。 </li>
      <li>4.开始您的交易。 </li>`,
      eligibleDescription: `<p>参与要求</p>
      <li>1.您必须在入金之前在客户端激活本赠金活动。</li>
      <li>2.该赠金仅适用于标准账户，伊斯兰标准账户，Prime账户，伊斯兰奖金。</li>
      <li>3.您只能申请50％的比率的赠金一次，所有后续入金将按照20％的比率计算。 </li>`,
      optInDialog: `恭喜！ 您已成功激活 50% 入金奖励。`,
      optOutDialog: `您已成功选择退出 50% 的入金奖励。`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">让你的储蓄翻倍</span></br>
          <span class="desc">获得高达 10,000 美元的现金返现和零隔夜仓息</span>
        `,
        activityBannerContext: `
          <span class="title">让你的储蓄翻倍</span></br>
          <span class="desc">是做空跌势，还是买入跌势？</span></br>
          <span class="desc">与STARTRADER进行加密货币交易，可获得高达10,000美元的现金回馈！</span>
        `,
      },
      active: {
        title: '激活加密货币返现活动',
        desc: '请点击下方按钮，激活并参与该活动',
      },
      deactive: {
        title: '停止加密货币现金返还',
        desc: '请点击下面的按钮以停用并退出加密货币现金返还促销活动。',
      },
      button: {
        active: '立即激活',
        deactive: '解除活动',
        submit: '提交',
        fund: '立即给您的账户入金',
      },
      claimTitle: '如何申请',
      claimDescriptions: [
        '在入金前激活该活动。',
        '为您的交易账户入金，并满足入金要求（≥500美元）。',
        '每手的返现金额还将取决于您的总净入金金额（具体要求见条款）。',
        '享受交易，并在每次交易中获得更多收益！',
      ],
      eligibilityTitle: '活动参与条件及资格',
      eligibleDescriptions: [
        '本活动须在入金前完成激活。',
        '本活动仅适用于标准账户和伊斯兰标准账户。如果一个客户有多个符合条件的交易账户，只能选择一个符合条件的账户来参与本活动。',
        '本活动从客户激活后第一次入金成功开始，持续30天。',
        '返现是基于所有加密货币产品的交易量，以下产品除外：ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU。',
        '每个客户的返现上限为10,000美元。',
      ],
      tnc: '*包含条款和条件，点击{link}查看',
      link: '此处',
      message: {
        congratulate: '恭喜您!',
        congratulateContext: '您已成功激活了加密货币返现活动。',
        signedOutPromo: `点击该选项，您将会退出该活动。`,
        deactivePromo: `恭喜，您已成功退出该活动。`,
      },
      dialog: {
        selectAccountContext: `请选择您想参加本活动的账户`,
        tnc: '活动条款及条件',
        tncContext: {
          tnc1: `1. 当您激活本活动后，您必须按要求存入资金并交易加密货币，以获得相应的返现金额。`,
          tnc1desc: `* 只有在活动期间的新入金才对活动有效。不包括内部转账、推荐朋友奖励，以及现金调整。`,
          tnc2: `2. 符合活动条件的新老客户，只要符合本活动的返现标准，就能获得相应返现金额：`,
          tnc3: `3. 不参与本次返现活动的产品包括：ALGUSD、MTCUSD、XRPUSD、BCHUSD、XLMUSD、EOSUSD、BTCBCH、BTCETH、BTCLTC、BTCXAU、ETHBCH、ETHLTC、ETHXAU。`,
          tnc4: `4. 有效交易量定义：在活动期间新开和关闭的订单。开仓时间少于5分钟的仓位、锁定仓位和对冲仓位均不计入本次活动的有效交易。`,
          tnc5: `5. 活动期限：参与者首次入金成功后30天。`,
          tnc5desc: `* 如果客户激活了本活动但并未入金，该活动也将于30日后自动结束。`,
          tnc6: `6. 该活动不与任何其他活动同享。 （除「你也可以成为交易者」、「推荐好友奖励」以及「50%信用赠金」活动之外）`,
        },
        agreeTnc: `我已阅读并同意该条款及条件。`,
      },
      field: {
        netDeposit: `净入金`,
        usddesc: `美元（或等值货币）`,
        cashback: `返现`,
        standardLot: `标准手`,
        date: `入金日期`,
        depositAmount: `存款金额`,
        withdrawAmount: `提现金额`,
        netDepositAmount: `存款净额`,
        rebateLot: `返点 / 手数`,
        tradingDate: `交易日期`,
        tradingLot: `交易手数`,
        cashRebate: `返现金额`,
      },
      depositHistory: `入金历史`,
      tradingRewardHistory: `交易奖励历史`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">赢取价值高达5,000美元的奖品！</div>
          <div class="desc">一抽即中的抽奖活动</div>
        `,
      },
      active: {
        title: '参加许愿活动',
        desc: '请点击下面的按钮，以参加圣诞节许愿活动',
      },
      deactive: {
        title: '圣诞节许愿活动',
        desc: '请点击下方按钮以取消参与圣诞节许愿活动。',
      },
      button: {
        active: '参加活动',
        deactive: '取消激活',
        submit: '提交',
        fund: '为您的账户入金',
        enterLuckyDraw: '进入幸运抽奖',
        redeem: '兑换',
        redeemed: '已兑换',
      },
      claimTitle: '如何参加',
      claimDescriptions: [
        '请在入金前激活本活动。​',
        '向您的交易账户入金金额以满足您想要的礼物的要求，然后进行相应手数的交易。(详情参考下方礼品表)',
        '当符合条件时，您将有机会赢得相应层级中的礼物之一。(详情参考下方礼品表)',
        '点击按钮进入幸运转盘，以确认您的奖品。',
        '赢得的奖品将被记录，所有奖品将在本活动结束后7天内发放。',
      ],
      eligibilityTitle: '参赛资格',
      eligibleDescriptions: [
        '符合条件的账户类型：该活动仅对标准账户和伊斯兰标准账户开放，MT4/MT5均可。',
        '如果一个客户有多个标准账户，那么也只能选择一个标准账户来参加本活动。',
        '符合条件的国家：除中国大陆以外的所有国家。',
        '只有在活动期间进行的入金和交易才算有效。',
        '有效的交易量：外汇、黄金、白银和原油头寸持有时间超过5分钟。',
      ],
      moreDetail: '关于奖品分配的更多细节，点击这里查看{prize}。',
      prize: '奖品清单',
      tnc: '*注其他附带条件。点击{link}以查看详情。',
      link: '此处',
      luckyDraw: {
        description: [
          `点击幸运抽奖按钮赢取上述奖品<span class="font-bold">之一</span>。`,
          '不是您中意的奖品？ 继续入金和交易，以达到您想要的奖品层级。',
        ],
        mainTitle: '目前您的奖品层级：',
        commingSoon: {
          title: `许愿活动`,
          content: `将在11月中旬开始幸运抽奖。`,
        },
        noChanceToDraw: {
          title: `立即入金`,
          content: `在您的账户中存入至少200美元，即可参加幸运抽奖。`,
        },
        endPromotion: {
          title: `幸运抽奖活动已结束`,
          content: `感谢您的参与! 请确保您在2023年3月31日之前完成奖品兑换。`,
        },
      },
      currentDepositLots: `当前入金 / 交易手数`,
      transactionHistory: `交易历史`,
      transactionHistoryDesc: `*入金交易是指进入幸运抽奖的入金金额和扣除的入金价值。`,
      giftRedemption: `奖品兑换`,
      giftRedemptionDesc: [
        `为兑换实物奖品，我们将与再次与您取得联系。`,
        `*所有奖品仅能在2023年2月2日到2023年3月2日期间进行兑换。`,
      ],
      field: {
        depositAmount: `入金金额`,
        lotsTraded: `已交易手数`,
        date: `日期`,
        type: `类型`,
        deposit: `*入金`,
        tradingLots: `交易手数`,
        prizeWon: `赢得奖品`,
        creditBonus: `信用赠金`,
        cashVoucher: `现金券`,
        physicalRewards: `实物奖励`,
        depositTradingVolume: `入金 / 交易手数`,
        gifts: `礼物（USD）`,
        accountID: `账户ID`,
        current: `目前`,
        luckyDrawDeposit: `入金`,
      },
      dialog: {
        selectAccountContext: `请选择您想获得奖金的账户（仅限MT4/MT5账户）。`,
        tnc: '活动条款及条件',
        tncContext: {
          tnc1: `1. 客户需要先在个人网站内成功激活该活动，然后按要求存入资金和交易相应手数，以获得相应的奖品。`,
          tnc2: `2.	有效交易量：货币对、黄金、白银、原油现货和原油期货的标准手。订单必须在该活动期间开启和关闭。开仓时间少于5分钟的仓位、锁定仓位和对冲仓位将不被计入本次活动的有效交易。`,
          tnc3: `3.	数据统计及订单量将参考MT4时间：01/11/2022-31/01/2023。`,
          tnc4: `4.	如果由于产品下架或缺货等不可抗力因素而无法购买奖品，客户可以申请获得与奖品市场价格相对应的现金奖励。该价格是以美元价格（或等值货币）为基础的。`,
          tnc5: `5.	该活动不与其他活动同享。(包括100交易赠金活动，20%优享赠金，50%入金赠金及越南 20%存款赠金活动)。`,
        },
        agreeTnc: `我已阅读并同意该条款及条件。`,
        prizeList: `奖品清单`,
        luckyDrawTransaction: `幸运抽奖`,
        xmasGift: `祝贺您获得了圣诞礼物！`,
      },
      message: {
        congratulate: '恭喜！',
        congratulateContext: `您已成功激活圣诞节许愿活动`,
        signedOutPromo: `点击“确认”按钮，将取消参与该活动。`,
        deactivePromo: `您已成功退出圣诞节许愿活动。`,
        redeemAmount: `该金额将记入您的账户中！`,
      },
      tableContent: {
        luckyDraw: `幸运抽奖`,
        depositTrade: `入金 / 交易`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">首次入金获得<span class='bg_yellow'>即时现金奖励</span></div>
          <div class="desc">在STARTRADER进行首次存款，即可获得高达50美元的现金奖励!</div>
        `,
      },
      active: {
        title: `激活“首次入金返现（FTD）”活动`,
        desc: `请点击下方按钮，激活并参与该活动。`,
      },
      deactive: {
        title: `停用您的“首次入金返现（FTD）”活动`,
        desc: `请点击下方按钮，停用“首次入金返现（FTD）”活动。`,
      },
      button: {
        active: `激活`,
        deactive: `停用`,
        fund: `为您的账户存款`,
        redeem: `兑现`,
      },
      claimTitle: `活动申请步骤`,
      claimDescriptions: [
        `在入金前激活该活动。`,
        `向您的交易账户存入资金（≥200美元或等值货币），以满足您想获得的现金奖励的存款要求。`,
        `现金奖励的价值将取决于您首次存款的金额（见条款与条件中的返现表）。`,
        `要激活您的现金奖励，请交易≥2手的外汇、黄金、白银或原油。`,
        `您激活的现金奖励现在可以进行兑换了，该金额将反映在您的账户余额中。`,
      ],
      eligibilityTitle: `活动参与条件`,
      eligibleDescription1: `合格的账户类型：该活动只针对MT4/MT5上的标准账户和伊斯兰标准账户开放。`,
      eligibleDescription2: `如果一个客户有多个标准账户，则只能选择一个标准账户来参与该活动。`,
      eligibleDescription3: `符合条件的国家：除中国大陆以外的所有国家及地区。`,
      eligibleDescription4: `符合条件的客户：没有存款记录的新客户和现有客户，包括IB/CPA旗下的客户。`,
      eligibleDescription4Desc: `*IB/CPA的个人交易账户不符合条件。`,
      eligibleDescription5: `首次存款必须在活动期间进行。`,
      eligibleDescription6: `若订单持有时间少于5分钟，将不被视为有效订单。`,
      tnc: `*“点击此处{link}活动条款及条件”`,
      link: `阅读更多`,
      dialog: {
        selectAccountContext: `请选择您想参加本活动的账户`,
        tnc: `活动条款及条件`,
        tncContext: {
          tnc1: `1. 在您激活该活动后，您还需按要求存入资金并交易2个标准手，以获得相应的现金返还奖励。`,
          tnc1desc: `*只有在活动期间的新存款才被视为有效。其中，不包括内部转账、推荐朋友的奖金以及现金调整。`,
          tnc2: `2.	只有在活动期间的首次存款才会被计入返现的计算。`,
          tnc3: `3.	符合条件的客户（包括新客户和现有客户），只要符合这里规定的标准，就可以获得现金返还，相当于：`,
          tnc4: `4.	符合活动条件的交易产品：外汇、黄金、白银、石油。`,
          tnc5: `5.	有效交易量：在活动期间新开仓和平仓的订单。开仓时间少于5分钟的仓位、锁定仓位和对冲仓位将不计入本次活动的有效交易。`,
        },
        agreeTnc: `我已阅读并同意该条款和条件`,
        grossDeposit: `存款总额USD`,
        grossDepositDesc: `或同等货币`,
        cbVoucher: `现金奖励`,
        tableDesc: `* 客户可以兑换最多50美元（或等值货币）的现金奖励。`,
      },
      voucher: {
        title: `FTD 入金返现`,
        accountID: `账户ID`,
        accumulateVolume: `累计交易量`,
        standardLots: `标准手数`,
        desc: `交易≥2手外汇、黄金、白银或原油以激活现金奖励。`,
        clickForRedeem: `点击下方按钮，将其兑现至您的账户余额中。`,
        ftdLessThan200: `您的首次存款低于最低要求金额。`,
      },
      message: {
        congratulate: `恭喜您！`,
        congratulateContext: `您已经成功激活了FTD返现活动。`,
        signedOutPromo: `点击下方“确认”按钮，您将退出该活动！`,
        deactivePromo: `您已成功退出“首次入金返现（FTD）”活动`,
        successRedeem: `您已成功兑换了“首次入金返现（FTD）”的返现奖励。`,
      },
    },
    notification: {
      ndb: {
        inst1: '尊敬的客户，',
        inst2: `谨此提醒您，零存款赠金活动将于<span>{date} (GMT+3)</span> 结束。`,
        inst3:
          '根据活动条款和条件，请注意，活动结束后，您帐户中的赠金将自动被移除。 若活动期间没有入金或交易记录，活动结束后，赠金和账户余额也将全部清零。',
        inst4: `如果您有任何疑问或需要任何帮助，请通过在线聊天、电子邮件：<span>{mail}</span> 或电话 <span>{phone}</span> 联系我们的客服团队。`,
        cantInerTransfer: '由于您在参加我们的 NDB 活动，我们无法处理您的转账请求',
      },
      db: {
        inst1: '尊敬的客户，',
        inst2: `我们谨提醒您，50%存款赠金活动将于 <span>{date} (GMT+3)</span> 结束。`,
        inst3:
          '根据活动的条款规定，请注意，活动结束后，您账户内的赠金将自动取消。 对于没有存款或交易记录的账户，赠金和余额也将在活动结束后自动取消。',
        inst4: `如果您有任何疑问或需要任何帮助，请通过在线聊天、电子邮件：<span>{mail}</span> 或电话 <span>{phone}</span> 联系我们的支持团队。`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: '下载适用于PC， Mac，网页浏览器， 智能手机和平板电脑的MT4',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: '下载适用于PC， Mac，网页浏览器， 智能手机和平板电脑的MT5',
  },
  forgetPwReq: {
    tip: '输入您帐户的电子邮件地址来重置您的密码。',
    formValidation: {
      emailReq: '请填写登录邮箱',
    },
    succ: '重置密码的邮件已经发送至您的邮箱<br><br>请确保您已经检查了垃圾邮件信箱',
  },
  loginClient: {
    header: '账户管理中心',
    failedMasseage: '再试一次',
    existingClientPortalAccess: '查看到您已有账户管理中心登录账号。<br /><br />请登录您的账户管理中心进行开户申请。',
    footer: `风险提示：差价合约交易产生的风险较高，损失可能超过您的初始投资资本，您应仅投入能够承担全额亏损的资金进行交易。差价合约交易未必适合所有投资者，请确保您了解所有交易风险并采取适当措施来管理您的风险。<br><br><br>
      {platformEntityName} 由塞舌尔金融服务管理局(FSA)授权和监管，牌照编号：SD050。<br>
      STARTRADER LLC 由圣文森特和格林纳丁斯金融服务管理局授权和监管，许可证号为271 LLC 2020。<br>
      The entities above are duly authorized to operate under the STARTRADER brand and trademark.<br>`,
    formValidation: {
      emailReq: '请填写邮箱',
    },
    invailidRafLink: `使用的推荐链接无效。在下方注册一个新帐户。`,
  },
  myProfile: {
    changeDetails: '如您想更改账户资讯, 请联系: {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: '选择交易平台',
    chooseAccType: '选择账户类型',
    chooseCurrency: '选择货币类型',
    chooseLeverage: '选择杠杆比例',
    chooseBalance: '选择账户余额',
    notes: '备注',
    currError: '您必须选择一个货币类型',
    platErr: '您必须选择一个交易平台',
    accTypeErr: '您必须选择一个账户类型',
    leverageErr: '您必须选择一个杠杆比例',
    balanceErr: '您必须选择一个账户余额',
    succ: '<p>您的新账户正在建立中，您将很快收到一封包含登录信息的电子邮件。您可以在主页上查看申请的当前状态。</p>',
    byTicking: `我已阅读并同意{tnc}。`,
    tnc: '条款及条件',
    pamm: `PAMM账户仅适用于已有基金管理人安排的交易者。除基金管理人外，任何人都不能交易PAMM账户。`,
    pammConfirm: `请确认是否继续选择此账户类型。`,
  },
  openDemoAdditAcc: {
    header: '申请模拟账户',
    button: '申请模拟账户',
    liveButton: '申请真实账户',
    succ: `<p>您的新账户正在建立中，您将很快收到一封包含登录信息的电子邮件。</p>`,
  },
  resetPassword: {
    header: '重置密码',
    succ: '您的密码已经更新，您现在可以使用您的新密码登录您的MT4/MT5账户',
    succ2: '您的密码已经更新',
    failed: '超时, 请再试一次',
    emailNotFound: '您提供的邮箱尚未注册',
  },
  fileUploader: {
    supportedFormat: '文件支持类型: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件数: {maxFileNum}',
  },
  transcationHistory: {
    deposit: '总入金',
    withdraw: '总出金',
    desc: {
      incomplete:
        '如果您终止或未能完成此次入金，则交易状态将显示为“未完成”。您将需要重新进行入金，因为此次交易无法被恢复。',
      success: '转账成功。您的资金会很快在您的账户中显示',
      proc: '我们正在处理您的转账，请联系: {mailTo}',
      failed: '转账失败，请联系: {mailTo} 获取更多信息',
      rejected: '您的转账申请已被拒绝，请联系: {mailTo} 获取更多信息',
    },
    withdrawDesc: {
      sub: '已提交，请等待',
      suc: '转账成功。您的资金会很快在您的账户中显示',
      proc: '我们正在处理您的转账，请联系: {mailTo}',
      incomp: '您的转账需要额外步骤才能完成，请联系: {mailTo} 获取更多信息',
      cancel: '您的转账已取消，请联系: {mailTo} 获取更多信息',
      fail: '转账失败，请联系: {mailTo} 获取更多信息',
      rej: '您的转账申请已被拒绝. 请联系: {mailTo} 获取更多信息',
    },
    cancelWithdraw: `您要取消提款请求吗？`,
    cancelError: `您的提款目前正在处理中。 因此，我们不能接受您的取消请求。 如需更多信息，您可以使用实时聊天功能联系我们。`,
    dialog: {
      appliedAmount: `申请金额`,
      applicationTime: `申请时间`,
      processedTime: `处理时间`,
      accountCurrency: `账户货币`,
      creditDeducted: `扣除的信用额度`,
      processedNote: `处理说明`,
      bankName: `银行名称`,
      bankAccountNumber: `银行账户号码`,
      abaSortCode: `ABA或Sort编码`,
      swiftCode: `Swift编码`,
      bankStatement: `银行存款证明`,
      bankAddress: `银行地址`,
      accountName: `账户名称`,
      accountNumber: `账户号码`,
      cardholderName: `持卡人姓名`,
      cardNumber: `卡号`,
      cardExpiryDate: `银行卡到期日`,
      walletAddress: `钱包地址`,
      ewalletEmailAddress: `电子钱包邮箱地址`,
    },
  },
  transferFunds: {
    default: {
      equityStop: '很遗憾，您的转账无法处理，因为转账将导致您的账户可用保证金下降到止损离场水平之100%以下',
      equityMargin: '很遗憾，我们目前无法处理您的转账，因为您的账户已完全对冲。',
      equityContinue: '我们发现您目前持有未平仓部位，在提交转账申请后，您可能会造成离场止损的问题。请确认是否要继续。',
      blackList: '此账户已被限制进行账户间转账，请联系 {supportEmail}',
    },
    pleaseNote: '请注意：',
    suc: '转账成功',
    warn1: '如果您目前有任何未结头寸，请确保您在账户中保持所需的保证金，您有责任维持不会触发追加保证金通知的账户余额',
    warn2:
      '转入与账户货币类型相同的资金会立即得到处理。如果您转入资金的货币类型与交易账户货币类型不同，请您发送电子邮件至: {mailTo}.',
    warn3: '请注意您不可以在只读账户间转移资金',
  },
  transferHistory: {
    payStatus: {
      processing: '处理中',
      paid: '已付款',
      unpaid: '未付款',
      rejected: '被拒绝',
    },
  },
  identityProof: {
    home: {
      header: '账户信息不完整',
      content: '您好，{name}，您的账户申请已接近完成，但是您仍然需要提交其他文件以验证通过您的账户。',
    },
    shufti: {
      content: `请确保填写的详细信息和语言与您提供的ID文档一致。`,
      countryOfDocument: `文档所在国家`,
    },
    popup: {
      header: '您好 {name}, 我们需要更多的账户信息以处理您的账户申请. 请提供以下所需文件:',
      addressProofSpan: `<strong>确认您的地址</strong><br />
        请提交以下文件之一：<br />
        - 房产证/居住证(有效期间内)</br>
        - 驾驶证(仅限未使用驾驶证作为身份证明时适用)</br>
        - 近三个月内的水电煤账单、银行账单</br>
        - 户口本</br>
        - 租赁合同(需加盖公章)</br>
        - 派出所或居委会开具的居住证明（需加盖公章）(最近6个月内)<br />
        - 近期的银行对账单</br>(最近6个月内)<br />
        <br />
        请注意: 您的全名及目前的地址必须在您提供的以上文件中清晰可见。`,
      idProofSpan: `<strong>确认您的身份</strong><br />
        请提交以下文件之一<br />
        - 有效的护照（完整的个人信息页）<br />
        - 有效的驾驶证<br />
        - 身份证(正面与背面)<br />
        <br />
        请注意: 您的全名必须在您提供的以上文件中清晰可见。<br />`,
    },
    reason: '我们无法审核您的文件，原因: {reason}，请重新上传',
  },
  header: {
    secPortal: 'Secure<br />Client Area',
  },
  menu: {
    home: '主页',
    accManagement: '账户管理',
    liveAcc: '真实账户',
    openAdditAcc: '附加账户',
    homeDemo: '模拟账户',
    depositFund: '入金',
    withdrawFunds: '出金',
    transferBetweenAccs: '内部转账',
    payDetails: '支付信息',
    funds: '资金',
    transactionHis: '交易历史',
    tranHis: '转账历史',
    download: '下载',
    profile: '信息',
    myProfile: '个人信息',
    cpPassword: '更改密码',
    contactUs: '联系我们',
    register: '继续申请',
    tradingTools: '专业分析工具',
    MARKET_BUZZ: '市场聚焦',
    ECON_CALENDAR: '财经日历',
    FEAT_FX: '热门产品',
    FX_IDEAS: '专家点评',
    promotion: '促销',
    noDeposit: '零存款赠金',
    referFriends: '推荐好友奖励',
    depositBonus: '50％入金奖励',
    cryptoCashback: '加密货币交易返现',
    firstTimeDeposit: `FTD 入金返现`,
    tradingTool: '交易工具',
    bankCardManage: '银行卡管理',
    capitalFlow: '资金流水',
  },
  contact: {
    desc: '{platform} 全球多语言团队随时为您提供帮助和服务, 让您拥有无与伦比的交易体验',
    followUs: '更多方式关注我们',
    tip: '由{email}登记的用户',
    liveChat: '在线客服',
  },
  depositResultDialog: {
    msg: '请问您的入金是否成功',
  },
  dialog: {
    identityProof: {
      success: '感谢您上传的文件, 我们将会尽快对文件进行审核。',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: '账户信息不完整',
      id: '身份',
      poa: '地址',
      both: '身份/地址',
      content: '您的 {doc} 证明目前正在处理中，将很快完成。 与此同时, 您可以先行入金以获得优先开户的权利.',
    },
  },
  register: {
    progressStatus: {
      title: '注册进度',
      estimatedTimeTitle: `预计时间`,
      min: '分钟',
    },
    tabs: {
      personalDetails: '个人信息',
      moreAboutYou: '详细资料',
      accountConfig: '账户配置',
      confirmYourId: '文件上传',
      fundYourAcc: '即刻入金',
    },
    btn: {
      next: '下一步',
      back: '上一步',
      fundnow: '即刻入金',
      upload: '上传',
      done: '完成',
    },
    authority: {
      dialog1: '您必须具有真实账户才能访问客户后台的此部分。',
      dialog2: `现在开设一个真实账户以解锁对您的客户后台的完全访问权限，其中包括一系列交易工具，促销活动以及更多其他内容`,
      openLiveAcc: '开设真实账户',
    },
    demo: {
      congrad: '恭喜!',
      title: '您的60天测试 MT{platform} 账户现已启用',
      title2: '您的测试账户详细信息',
      li: {
        login: '帐号',
        srv: '服务器',
        expDate: '到期日',
      },
      emailSent: '* 登录详细信息也已发送到您的注册电子邮件',
      dl: '下载您的 MT{platform} 交易平台',
      improveQ: '你想改善交易能力？',
      improveWay: '只需在几分钟内开设一个真实账户即可解锁一系列独家交易工具和促销活动',
      proTrade: '专业市场分析报告',
      customerService: '24/7 一对一专业客服',
      competitivePromotionals: '具有竞争力的活动',
      secure: '3D安全交易',
      openLiveAcc: '开设真实账户',
    },
    personalDetails: {
      page1: {
        pageTitle: '个人信息',
        userTitle: '头衔',
        firstName: '名字',
        lastName: '姓氏',
        email: '电子邮箱',
        dob: '出生日期',
        idType: '证件类型',
        referral: '是否有推荐人？(选填)',
        middleName: '中间名',
        nationality: '国籍',
        phoneNum: '电话号码',
        date: '日',
        month: '月',
        year: '年',
        placeOfBirth: '出生地',
        idNum: '证件号码',
        passportNum: '护照号码',
        driverLicenseNum: '驾照号码',
        titleDropdown: {
          mr: '先生',
          mrs: '太太',
          ms: '女士',
          miss: '小姐',
          dr: '博士',
          prof: '教授',
        },
        idTypeDropdown: {
          passport: '护照',
          idCard: '身份证',
          driverLicense: '驾照',
        },
      },
      page2: {
        pageTitle: '主要居住地址',
        mainResidency: '主要居住国家',
        streetNumber: '街道号码',
        streetName: '街道名',
        address: '详细地址',
        provinceOrState: '省份/州',
        cityOrSuburb: '城市/区',
        postcode: '邮政编码',
        usQuestion: `是否为美国公民或是在美国纳税？`,
      },
    },
    moreAboutYou: {
      pageTitle1: '工作与财务信息',
      pageTitle2: '投资经验',
      complianceExplain: '根据AML/CTF的要求，我们需要收集您的以下信息.',
    },
    accountConfig: {
      pageTitle: '账户配置',
      chooseTradingPlatform: '选择交易平台',
      chooseAccType: '选择账户类型',
      chooseAccCurrency: '选择账户币种',
    },
    confirmYourId: {
      pageTitle: '文件上传',
      description: `我们需要验证您的身份. 请上传以下所需证件或者发送文件至我们邮箱{mailTo}`,
      poidTitle: '身份证明',
      porTitle: '地址证明',
      uploadDescription: '请选择以下一种文件类型并上传',
      note: '请注意: 您的全名必须在您提供的以上文件中清晰可见。',
      pending: {
        upload: '待上传',
        verification: '待审核',
      },
      poidTypes: {
        photoId: '身份证 (正面和背面)',
        passport: '护照 (完整的个人信息页)',
        drivingLicence: '有效的驾驶证',
      },
      porTypes: `<li>房产证/居住证(有效期间内)</li>
      <li>驾驶证(仅限无使用驾驶证为身份证明者适用)</li>
      <li>近三个月内的水电煤账单、银行账单</li>
      <li>户口本</li>
      <li>租赁合同(需加盖公章)</li>
      <li>派出所或居委会开具的居住证明(需加盖公章)</li>`,
    },
    finishPage: {
      title: `{individualUserName}, 感谢您注册{platformName}真实账户!`,
      contentDefault: `我们已经收到了您的申请并成功开通了您的真实账户.  不久之后，您会在<b> {email} </ b>收到一封电子邮件，其中包含您的登录详细信息.`,
      contentWithoutId3WithAccount: `您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.  您可以立即入金来获取优先开户服务.`,
      contentWithoutId3WithoutAccount: `您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.`,
    },
  },
  autochartist: {
    1: {
      title: `市场扫描仪`,
      desc01: `全球首个市场扫描仪，可让您纵览市场行情以寻找交易机会。`,
      button01: `立刻下载`,
    },
    2: {
      title: `市场报告`,
      desc01: `Autochartist市场报告为您提供了在主要金融中心开盘之前市场交易前瞻。`,
      button01: `查看市场报告`,
    },
    3: {
      title: `Autochartist 研究门户`,
      desc01: `一个全面综合的研究门户，可让您同时查看所有的可用数据。`,
      button01: `开始`,
      button02: `用户指南`,
      button03: `动态口令`,
    },
    4: {
      title: `交易机会`,
      desc01: `根据所有Autochartist分析类型的新设置，每15分钟检查一次交易机会的更新。`,
      button01: `查看交易机会`,
    },
    5: {
      title: `Autochartist移动​​应用`,
      desc01: `根据您的交易平台中的行情表单，接收推送通知并跟踪高概率可行的交易机会。`,
      desc02: `注意：`,
      desc03: `如果用户的手机具有内置的二维码扫描仪，则可以通过单击“使用二维码登录”按钮从普通摄像头扫描二维码，也可以从自动图表移动应用程序内部扫描二维码。`,
      desc04: `二维码仅在10分钟内有效，此后必须刷新图像以获得新的二维码。`,
      desc05: `二维码仅对单次使用有效，此后必须刷新图像以获得新的二维码。`,
      button01: `下载IOS`,
      button02: `下载Andriod`,
    },
    6: {
      title: `新闻订阅`,
      desc01: `订阅我们的新闻通知，您将每天收到由Autochartist提供支持的电子邮件，以向您提供全球外汇市场的概况。与STARTRADER携手，与世界交易。`,
      button01: `订阅`,
    },
    footer: `Autochartist是目前世界上最先进的交易工具之一，可用于以图表形态，斐波那契形态和关键价位的形式自动识别交易机会。 Autochartist的独有技术可使搜索引擎每天24小时监控数万种金融工具，以发现潜在机会。 此外，Autochartist还提供了“波动率分析”功能，可让您更好地评估所交易产品的风险和波动性。`,
  },
  responseMsg: {
    //4xx warning
    410: '参数验证失败',
    411: '密码错误',
    420: '用户不存在',
    421: '密码重置被拒',
    490: '请不要重复输入卡信息',
    //5xx error
    500: '发生错误',
    501: '申请失败, 请稍后重试',
    505: '文件上传失败',
    520: '登录失败, 请重试',
    521: '您在此设备上已处于登入状态!',
    522: '请重新登录',
    523: '请重新登录',
    524: '您提供的邮箱尚未注册',
    525: '用户名或密码错误',
    527: '用户不是IB',
    528: '用户不存在',
    529: '用户名或密码错误',
    540: `系统找不到您的交易账号`,
    541: '无法找到返佣账号',
    542: '抱歉，您不允许开同名账号',
    544: '您不允许申请一个额外的账户，直到您的身份证明核实为止',
    550: '账号错误',
    551: '您的余额不足',
    553: '您好！您当前的账号尚有信用金，暂时无法提交出金/转账，如需帮助请发送邮件至{email}或联系官方客服',
    554: '请求的数额为0或者空',
    562: '无法处理您的支付请求，请重新尝试。如问题仍未解决，请通过Live Chat或者电子邮件{email}与我们取得联系',
    564: '国家未能配合该支付渠道',
    565: '银行代码错误',
    566: '无法找到支付方式',
    568: '此账户已被限制存款，请联系{email}',
    581: '您已成功选择加入促销活动。 请注意，您必须先入金才能出金。',
    582: '您正在参与活动且有持仓，无法提交出金申请。',
    584: `您没有可申请的佣金`,
    587: '余额不足请联系客服',
    590: '无法找到转出账号',
    591: '无法找到转入账号',
    593: '由于您的账户包含信贷部分，我们无法对您所选择的账户进行资金转移处理。',
    594: '不允许从这个账户转移资金',
    595: '您正在参与活动，无法提交转账申请。',
    647: '验证码错误',
    1001: '您的账号暂时无法登录，请联系您的客户经理',
    session_timeout: '登录时间超时, 正在回到登录页面',
    vload: {
      4019: '不接受代券类型或金额',
      4071: '怀疑有欺诈行为',
      4072: '已使用代券',
      4073: '代券未激活',
      4074: '代券已过期',
      4075: '没有找到代券',
      4080: '代券价值不足',
      4444: '使用代券无效。货币不匹配。',
      5000: '存储数据不可用',
    },
  },
  tradingTool: {
    mt4: {
      mt4Text1: '使用 MetaTrader 4 达成成就',
      mt4Text2: 'STARTRADER MetaTrader4 (MT4) 平台提供强大的技术分析工具，具有多种图形组合和客制化的技术指标。',
      mt4Text3: '使用此平台，体验简单的操作、稳定的交易环境和超低延迟。',
      mt4Text4: 'MetaTrader 4是满足数百万个交易者使用需求，且为最值得信赖和最受欢迎的交易平台之一。',
      mt4Text5: '<span>50</span>多个内建的技术指标',
      mt4Text6: '<span>24</span>个分析工具',
      mt4Text7: '<span>3</span>种图表类型和<span>9</span>种时间周期',
      mt4Text8: '单击交易&于图表交易',
      mt4Text9: '移动止损',
      mt4Text10: '优质体验',
      mt4Text11: '它是一个用户友善的强大平台，提供先进的技术及保障外汇，加密货币，指数，股票，贵金属和大宗商品交易。',
      mt4Text12: '支持智能交易（EAs）的算法交易',
      mt4Text13: '任何设备均可访问',
      mt4Text14: '来自顶尖供应商的交易信号',
      mt4Text15: '客制化的界面与可完全自定义的图表',
      mt4Text16: '添加自定义智能交易（EAs）和指标',
      mt4Text17: '支持多端使用',
      mt4Text18: '可在手机平板和电脑上进行交易。适用于 iOS 和 Android。',
      mt4Text19: '在线开设账户',
      mt4Text20: '填写并提交在线申请表，当审核通过后，该账户立即创建完成',
      mt4Text21: '创建账户后，您可以下载STARTRADER Professional MT4 Trading 软件，只需点击一下即可快速安装。',
      mt4Text22: '开始交易！',
      mt4Text23: '3步骤开始您的交易',
    },
    pamm: {
      pammSystem: 'PAMM系统',
      pammSystemPopular: 'PAMM系统是最流行的自动化交易管理系统，',
      mostAdvantage: '其最大优势在于所有投资者之间按照交易量的百分比分配，通常根据投资者的余额或净值进行',
      pammTitle: 'PAMM账户管理系统具备的优势：',
      pammText1: '基于全球认可交易平台MT4',
      pammText2: '按照交易量百分比进行分配',
      pammText3: '投资者账户与主账户交易结果保持一致',
      pammText4: '支持EA交易',
      pammText5: '实时风险订单管理',
      pammAccount: 'PAMM账号管理',
      pammMangeAccount: 'PAMM经理账号',
      pammInvestAccount: 'PAMM投资者账号',
      checkPammMange: '投资者可按投资目标，选择合适自己的PAMM经理',
    },
    copytrade: {
      copytrade1: 'COPY TRADE 聪明交易 轻松获利',
      copytrade2:
        'COPYTRADE是一个让您有机会可以跟随专业交易者做单的平台，加入STARTRADER跟单社区，简单几步即可进行跟单交易，从策略提供者的策略中学习以提高您的交易技巧。',
      tradeNow: '即刻交易',
      rank: '排名',
      copytrade3: '跟单步骤',
      copytrade4: '登录社区',
      copytrade5: '登录社区成为跟单者',
      copytrade6: '订阅',
      copytrade7: '参考收益排行榜，自主选择想要订阅、复制的策略提供者账号',
      copytrade8: '跟单成功',
      copytrade9: '可在MT4端或社区平台查看已复制的订单',
      copytrade10: '策略提供者',
      copytrade11: '创建个人资料',
      copytrade12: '对外公开或仅针对指定人可见',
      copytrade13: '对跟单者的初始资金进行限制',
      copytrade14: '自动注册成为策略提供者',
      copytrade15: '设置订阅者的分红',
      copytrade16: '自行设置分润',
      copytrade17: '跟单者',
      copytrade18: '多重订阅',
      copytrade19: '多样化跟单模式',
      copytrade20: '可按条件筛选策略提供者的订单',
      copytrade21: '订单安全防护',
      copytradeBtn1: '成为策略提供者',
      copytradeBtn2: '成为跟单者',
    },
  },
  activity: {
    myActivity: '我参与的活动',
    allActivity: '全部活动',
    activityContent: '活动内容',
    applyNow: '立即报名',
    activityStatus1: '待开始',
    activityStatus2: '进行中',
    activityStatus3: '已结束',
    noActivity: '暂无活动',
    selectAccount: '选择账户',
    activityReminder1: '活动期间，若发现参与者违反活动细则条款，{capitalName} 有权谢绝其参与本次活动，并取消活动资格。',
    activityReminder2: '不當交易手段包括但不限於下列操作模式：',
    activityReminder3: '1.使用两个(或者以上)帐号，进行同品种相反方向的下单。',
    activityReminder4: '2.在有信用金的情况下，进行单方向大手数的交易。',
    activityReminder5: '3.于接近新旧交易日的时间点大量下订单。',
    activityReminder6: '当确认用户出现不当交易行为时，{capitalName} 将保留清除用户不当盈利的权利，同时终止与该用户的合作关系。',
    noAccountLabel: '暂无可用账户',
    checkConfirm: '请仔细阅读活动细则条款并勾选！',
    readAndAgree: '我已阅读并同意',
    activityCancelReminder1: '若您点击确认，我们将取消您赠金活动中获取的所有赠金以及取消正在参加活动的资格',
    activityCancelReminder2: '请您再次确认，若您点击确认，我们将取消您赠金活动中获取的所有赠金以及取消正在参加活动的资格',
    applySuccess: '报名成功',
  },
}
