<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page2.mainResidency')" prop="countryCode">
          <el-select v-model="form.countryId" id="countryCode" disabled filterable data-testid="countryName">
            <el-option v-for="(item, index) in countries" :key="index" :label="item.countryName" :value="item.id"
              auto-complete="off" :data-testid="item.countryName"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24" v-if="showDiffField">
        <el-form-item :label="$t('register.personalDetails.page2.streetNumber')" prop="streetNumber" class="streetNumber">
          <el-input v-model="form.streetNumber" :disabled="disableField" id="streetNumber" maxlength="20" type="text"
            data-testid="streetNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('register.personalDetails.page2.streetName')" prop="address" class="streetNumber">
          <el-input v-model="form.address" :disabled="disableField" id="address" maxlength="256" type="text"
            data-testid="streetName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24" v-else>
        <el-form-item :label="$t('register.personalDetails.page2.streetName')" prop="address">
          <el-input v-model="form.address" :disabled="disableField" id="address" maxlength="256" type="text"
            data-testid="address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item v-if="form.countryId == 3512" :label="$t('register.personalDetails.page2.provinceOrState')"
          prop="state">
          <el-select v-model="form.state" id="state" :disabled="disableField" filterable data-testid="provinceNameEn">
            <el-option v-for="(item, index) in provinces" :key="index" :label="item.provinceName"
              :value="item.provinceNameEn" auto-complete="off" :data-testid="item.provinceName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else :label="$t('register.personalDetails.page2.provinceOrState')" prop="state">
          <el-input v-model="form.state" :disabled="disableField" id="state" maxlength="128" type="text"
            data-testid="provinceOrState"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page2.cityOrSuburb')" prop="suburb">
          <el-input v-model="form.suburb" :disabled="disableField" id="suburb" maxlength="128" type="text"
            data-testid="cityOrSuburb"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page2.postcode')" prop="postcode">
          <el-input v-model="form.postcode" :disabled="disableField" id="postcode" maxlength="64" type="text"
            data-testid="postcode"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page2.usQuestion')" prop="usCitizen">
          <el-radio-group v-model="form.usCitizen">
            <el-radio :disabled="disableField" :label="true" data-testid="yes">
              {{ this.$t('common.field.yes') }}
            </el-radio>
            <el-radio :disabled="disableField" :label="false" data-testid="no">
              {{ this.$t('common.field.no') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import userAddress from '@/mixins/register/userAddress';

export default {
  props: {
    countries: Array
  },
  mixins: [userAddress]
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/register/personalDetails.scss';
.el-form {
  max-width: 800px;
}
</style>
