const { countryCodeEnum } = require('@/constants/country')
const {
  REFER_A_FRIEND_PATHNAME,
  DEPOSIT_BONUS_PATHNAME,
  TRADING_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
} = require('@/constants/route')
const { WHITELIST } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'http://startrader.com/',
    email: 'support@ivmarkets.com',
    accountEmail: 'support@ivmarkets.com',
    blacklistEmail: 'support@ivmarkets.com',
  },
  menus: [
    {
      name: 'menu.home',
      path: '/home',
      icon: 'menu_icon_home',
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: 'menu_icon_account',
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
        },
        // {
        //   optional: true,
        //   name: 'menu.openAdditAcc',
        //   path: '/openLiveAccount',
        // },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: 'menu_icon_funds',
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
        },
        {
          name: 'menu.payDetails',
          path: '/paymentDetails',
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
        },
      ],
    },
    {
      name: 'menu.tradingTool',
      path: '/tradingTool',
      icon: 'menu_icon_tool',
    },
    {
      name: 'menu.promotion',
      path: '/activity',
      icon: 'menu_icon_promotion',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'menu_icon_contact',
    },
    {
      name: 'menu.register',
      path: '/register',
      icon: 'menu_icon_register',
    },
  ],
  // tradingPlatform: {
  //   mt4: true,
  //   mt5: true,
  // },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [],
  openLiveAccount: {
    choosePlatform: [
      {
        value: 'mt4',
        label: '4',
      },
    ],
    chooseLiveType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
    ],
    mt4RegisterChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
    ],
    mt4DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard Account',
      },
      {
        value: 'ECN',
        label: 'ECN Account',
      },
    ],
    mt5ChooseType: [],
    mt5RegisterChooseType: [],
    mt5DemoChooseType: [],
    chooseCurrency: [
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: 'USD',
        currency: 'USD',
      },
    ],
    chooseCurrencyByType: {
      standardSTP: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
      ECN: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USD',
          currency: 'USD',
        },
      ],
    },
    chooseLeverage: [100, 200, 300, 400, 500],
    chooseBalance: [
      { value: 2000, name: '$2000' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 20000, name: '$20k' },
      { value: 50000, name: '$50k' },
      { value: 100000, name: '$100k' },
    ],
    // getAgreeCondition: 'openAdditAcc.disclaimer',
  },
  metaTraderLinks(url) {
    return {
      mt4: {
        METATRADER4_windows: `https://download.mql5.com/cdn/web/startrader.international.pty/mt4/startraderinternational4setup.exe`,
        // METATRADER4_iPhone: `https://download.mql5.com/cdn/mobile/mt4/ios?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203`,
        METATRADER4_iOS: `https://download.mql5.com/cdn/mobile/mt4/ios?server=STARTRADERINTL-Demo,STARTRADERINTL-Live,STARTRADERINTL-Live2`,
        METATRADER4_Android: `https://apk-startrader-com.oss-cn-hongkong.aliyuncs.com/MetaTrader%204_400.1356_apkcombo.com.apk`,
        // METATRADER4_WebTrader: `https://webtrader.puprime.com/`,
        METATRADER4_MAC: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=support.metaquotes.net&utm_campaign=download.mt4.macos`,
      },
      // mt5: {
      //   METATRADER5_windows: `https://download.mql5.com/cdn/web/pu.prime.pty/mt5/puprime5setup.exe`,
      //   METATRADER5_iPhone: `https://download.mql5.com/cdn/mobile/mt5/ios?server=PUPrime-Demo,PUPrime-Live`,
      //   METATRADER5_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg`,
      //   METATRADER5_Android: `https://download.mql5.com/cdn/mobile/mt5/android?server=PUPrime-Demo,PUPrime-Live`,
      //   METATRADER5_WebTrader: `https://webtrader.puprime.com/`,
      // },
    }
  },
  bankInfoDetails: {
    bank_name: { show: true, value: 'DBS Bank' },
    currency: ['EUR', 'USD', 'GBP', 'USC'],
    branch_address: {
      AUD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USC: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      EUR: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      GBP: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      JPY: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      CAD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      SGD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      NZD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      HKD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
    },
    branch_name: {
      AUD: { show: false, value: '' },
      USD: { show: false, value: '' },
      USC: { show: false, value: '' },
      EUR: { show: false, value: '' },
      GBP: { show: false, value: '' },
      JPY: { show: false, value: '' },
      CAD: { show: false, value: '' },
      SGD: { show: false, value: '' },
      NZD: { show: false, value: '' },
      HKD: { show: false, value: '' },
    },
    account_name: { show: true, value: "WTSG PACIFIC UNION - CUSTOMERS'ACCOUNT" },
    account_number: {
      USD: { show: true, value: '0720381505' },
      USC: { show: true, value: '0720381505' },
      EUR: { show: true, value: '0720381505' },
      GBP: { show: true, value: '0720381505' },
      CAD: { show: true, value: '0720381505' },
      SGD: { show: true, value: '0720381505' },
    },
    beneficiary_address: { show: true, value: 'Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles' },
    bsb_number: {
      AUD: { show: true, value: '034-234' },
      USD: { show: true, value: '034-702' },
      USC: { show: true, value: '034-702' },
      EUR: { show: true, value: '034-705' },
      GBP: { show: true, value: '034-703' },
      JPY: { show: true, value: '034-702' },
      CAD: { show: true, value: '034-702' },
      SGD: { show: true, value: '034-702' },
      NZD: { show: true, value: '034-702' },
      HKD: { show: true, value: '034-702' },
    },
    swift_code: { show: true, value: 'DBSSSGSG' },
  },
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    44: 'Vload',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    65: 'Local Depositor',
    80: 'Cryptocurrency-ETH',
    81: 'Cryptocurrency-USDC(ERC20)',
  },
  internationalBankTransfer: {
    DEPOSIT: {
      ALLOW_ALL: false,
      WHITELIST: WHITELIST,
    },
  },
  restrictCreditCardCountryList: ['3589'], //Pakistan
  webtradeCode: `new MetaTraderWebTerminal( "webterminal", {
    version: 4,
    servers: ["IVMarkets-Demo","IVMarkets-Live"],
    server: "IVMarkets-Live",
    demoAllServers: true,
    utmSource: "www.ivmarkets.com",
    startMode: "",
    language: "en",
    colorScheme: "black_on_white"
} );`,
  pammLinks: {
    manager: 'https://portal.pamm.club/app/auth/manager',
    investor: 'https://portal.pamm.club/app/auth/investor',
  },
  protocolUrl: 'https://www.ivmarkets.com/pdf/Client-Agreement-FSA.pdf',
  protocol: [
    'openAdditAcc.protocol.protocol11',
    'openAdditAcc.protocol.protocol12',
    'openAdditAcc.protocol.protocol13',
    'openAdditAcc.protocol.protocol14',
    'openAdditAcc.protocol.protocol15',
    'openAdditAcc.protocol.protocol16',
    'openAdditAcc.protocol.protocol17',
    'openAdditAcc.protocol.protocol18',
    'openAdditAcc.protocol.protocol19',
  ],
  tradingToolTabs: [
    { name: 'tab4', label: 'Webtrader' },
  ],
}
