<template>
  <div class="footer_content">
    <template v-if="!$slots.footer">
      <slot name="insertFooter"></slot>
      <el-button
        v-show="showFooterCancel"
        :disabled="disabledFooterCancel"
        @click="handleCancel"
        :data-testId="cancelButtonTestId"
      >
        {{ cancelButtonText }}
      </el-button>
      <slot name="centerFooter"></slot>
      <el-button
        v-show="showFooterConfirm"
        :disabled="disabledFooterConfirm"
        type="primary"
        @click="handleConfirm"
        :data-testId="confirmButtonTestId"
      >
        {{ confirmButtonText }}
      </el-button>
      <slot name="appendFooter"></slot>
    </template>
    <slot v-else name="footer"></slot>
  </div>
</template>
<script>
export default {
  name: 'DialogFooter',
  props: {
    // 是否显示footer取消按钮
    showFooterCancel: {
      type: Boolean,
      default: true,
    },
    // 是否显示footer确定按钮
    showFooterConfirm: {
      type: Boolean,
      default: true,
    },
    // 是否禁用footer确定按钮
    disabledFooterConfirm: {
      type: Boolean,
      default: false,
    },
    // 是否禁用footer取消按钮
    disabledFooterCancel: {
      type: Boolean,
      default: false,
    },
    // confirm按钮的文案
    confirmButtonText: {
      type: String,
      default() {
        return this.$t('common.button.confirm')
      },
    },
    // cancel按钮的文案
    cancelButtonText: {
      type: String,
      default() {
        return this.$t('common.button.cancel')
      },
    },
    // confirm按钮的data-testid
    confirmButtonTestId: {
      type: String,
    },
    // cancel按钮的data-testid
    cancelButtonTestId: {
      type: String,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>
