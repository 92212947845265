// 進行異步執行
import { apiTcmktGetUrl, apiEmploymentFinanceAnswers, apiTradingAnswers, apiAutochartistToken } from '@/resource'

export default {
  async actionTradingToolUrl({ commit, state }, lang) {
    if (state.tradingToolUrl.length > 0) return
    return new Promise((resolve, reject) => {
      apiTcmktGetUrl().then(result => {
        commit('setTradingToolUrl', result.data.data)
        resolve(result.data.data)
      })
    })
  },
  async actionEmploymentFinanceAnswers({ commit, state }) {
    if (state.registerStep > 4 && state.employmentFinanceAnswers.length > 0) return state.employmentFinanceAnswers
    return new Promise((resolve, reject) => {
      apiEmploymentFinanceAnswers().then(result => {
        commit('getEmploymentFinanceAnswers', result.data)
        resolve(result.data)
      })
    })
  },
  async actionTradingAnswers({ commit, state }) {
    if (state.registerStep > 4 && state.tradingAnswers.length > 0) return state.tradingAnswers
    return new Promise((resolve, reject) => {
      apiTradingAnswers().then(result => {
        commit('getTradingAnswers', result.data)
        resolve(result.data)
      })
    })
  },
  async actionAutochartistToken({ commit, state }, userType) {
    if (state.autochartisToken.token) return state.autochartisToken
    return new Promise((resolve, reject) => {
      apiAutochartistToken(userType).then(result => {
        commit('setAutochartistToken', result.data.data)
        resolve(result.data.data)
      })
    })
  },
}
