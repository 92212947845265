<template>
  <el-empty v-bind="getBindProps">
    <img slot="image" src="@/assets/images-1/empty.png" alt="empty" />
    <template #description v-if="$slots.description">
      <slot name="description"></slot>
    </template>
    <template #default v-if="$slots.default">
      <slot name="default"></slot>
    </template>
  </el-empty>
</template>

<script>
export default {
  name: 'Empty',
  computed: {
    getBindProps() {
      return {
        ...this.$attrs,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
