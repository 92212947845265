<template>
  <div class="bank_info" v-if="isShow">
    <ul v-for="item in bankInfos" :key="item.title">
      <li v-if="item.info && item.info.show">
        <span class="title_name">{{ $t(item.title) }}</span>
        <span class="value_info">{{ item.info.value + ' ' + $t(item.desc) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import NigeriaSA from '@/bankInfo/NigeriaSA';

export default {
  name: 'InternationalDefaultBankInfo',
  props: { isShow: Boolean, currency: String, type: String },
  computed: {
    bankInfos() {
      if (this.regulator === 'fsa' && ['5796', '5696'].includes(this.countryCode)) {
        return NigeriaSA.bank_infos(this.currency);
      }
      return this.$config.bank_infos(this.currency, this.regulator, this.type);
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  }
};
</script>
