import { apiGetData } from '@/resource/register'

export default {
  data() {
    const validateYearsAtAddress = (rule, value, callback) => {
      if (value === null || value === '') callback(new Error(this.$t('common.formValidation.yearsAtAddressReq')))
      else callback()
    }
    return {
      form: {
        countryId: null,
        streetNumber: '',
        address: '',
        state: '',
        suburb: '',
        postcode: '',
        usCitizen: null,
        nationalInsuranceNumber: '',
        yearsAtAddress: null,
        taxResidencyCountryId: null,
        //address of previous
        previousCountryId: null,
        previousStreetNumber: '',
        previousAddress: '',
        previousState: '',
        previousSuburb: '',
        previousPostcode: '',
        step: 2,
      },
      rules: {
        countryId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.countryReq'),
          },
        ],
        streetNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.numberReq'),
          },
        ],
        address: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.addressReq'),
          },
        ],
        state: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.stateReq'),
          },
        ],
        suburb: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.suburbReq'),
          },
        ],
        postcode: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.postcodeReq'),
          },
        ],
        usCitizen: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.answerReq'),
          },
        ],
        nationalInsuranceNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.NIReq'),
          },
        ],
        yearsAtAddress: [
          {
            required: true,
            trigger: 'blur',
            validator: validateYearsAtAddress,
          },
        ],
        taxResidencyCountryId: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.taxResidencyReq'),
          },
        ],
        previousCountryId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.countryReq'),
          },
        ],
        previousStreetNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.numberReq'),
          },
        ],
        previousAddress: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.addressReq'),
          },
        ],
        previousState: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.stateReq'),
          },
        ],
        previousSuburb: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.suburbReq'),
          },
        ],
        previousPostcode: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.postcodeReq'),
          },
        ],
      },
      diffFieldCountryList: [6907, 4101],
      provinces: [],
      prev_provinces: [],
      showSecondAddress: false,
    }
  },
  mounted() {
    this.getUserDate()
  },
  watch: {
    'form.countryId'() {
      if (this.allProvinces.length > 0 && this.form.countryId == 3512) {
        this.getProvinces('provinces', this.form.countryId)
        this.clearState('provinces', 'state')
      }

      if (!this.showDiffField && this.form.streetNumber) {
        this.form.streetNumber = null
      }
    },
  },
  computed: {
    allProvinces() {
      return this.$store.state.register.provinces
    },
    disableField() {
      return this.$store.state.register.disableField
    },
    showDiffField() {
      return this.diffFieldCountryList.includes(parseInt(this.form.countryId))
    },
  },
  methods: {
    getProvinces(listName, countryId) {
      this.$set(
        this,
        listName,
        this.allProvinces.filter(province => province.countryID == countryId)
      )
    },
    clearState(listName, field) {
      if (!this.disableField) {
        const state = this[listName].find(province => province.provinceNameEn == this.form[field])
        if (!state) this.$set(this.form, field, '')
      }
    },
    getUserDate() {
      apiGetData(2).then(resp => {
        this.form = { ...resp.data.data }
      })
    },
  },
}
