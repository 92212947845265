import store from '@/store'

const redirect = {
  redirectToIbPortal() {
    const url = process.env.NODE_ENV == 'development' ? 'http://localhost:9083/' : store.state.common.url
    if (url) {
      const accessToken = 'accessToken=' + store.state.common.accessToken
      window.location = `${url}?${accessToken}`
    }
  },
  redirectToLogoutUrl() {
    console.log(window.location.origin)
    window.location = `${window.location.origin}/login`
  },
}

export default redirect
