import { availableLanguages } from '@/lang/availableLang'
import config from '@/config'

function getDefaultLang() {
  let defaultLang = availableLanguages.find(ele => ele == window.navigator.language.replace('-', '_'))
  if (!defaultLang) defaultLang = config.platformLang
  return defaultLang
}

export default {
  uuid: -1, //Not in use
  userName: '',
  individualUserName: '',
  userType: '',
  userTypeEnum: '',
  loginStatus: '',
  pendingQuestionnaireCompletion: false,
  openAccountEnabled: false,
  countryCode: '',
  accessToken: '',
  url: '',
  lang: getDefaultLang(),
  requireAddressProof: false,
  requireIDProof: false,
  pendingAddressProofApproval: false,
  pendingIDProofApproval: false,
  keepUploadID: false,
  keepUploadPOA: false,
  isChangedLangAtLandingPage: false,
  registerStep: 1,
  isDemo: false,
  tradingToolUrl: {},
  email: '',
  idPass: false,
  regulator: null,
  failedSecondTime: false,
  // user info
  employmentFinanceAnswers: [],
  tradingAnswers: [],
  autochartisToken: {},
  autochartisExpiryTime: '',
  autoNotifyStatus: true,
  emailMasked: '',
  isLoading: false,
}
