<template>
  <el-popover v-model="visible" popper-class="account_popover" placement="bottom-start" :width="width" trigger="click">
    <div class="account_select" ref="select" slot="reference">
      <div class="arrow">
        <i class="el-icon-arrow-down"></i>
      </div>
      <div v-if="!valueWrapper" class="placeholder">
        {{ $t('common.field.select') }}
      </div>
      <div v-else class="selected">
        <div class="account_type">{{ valueWrapper.mtAccountTypeDisplay }}</div>
        <div class="account_info">
          <div class="account_display_type">{{ valueWrapper.login }} - {{ valueWrapper.accountDisplayType }}</div>
          <div class="account_balance">{{ valueWrapper.balance | splitThousands }}{{ valueWrapper.currency }}</div>
        </div>
      </div>
    </div>
    <div class="account_list">
      <template v-if="accountList.length > 0">
        <div class="item" @click="onChange(item)" :data-testid="item.dataTestid" v-for="item in accountList">
          <div class="account_type">{{ item.mtAccountTypeDisplay }}</div>
          <div class="account_info">
            <div class="account_display_type">{{ item.login }} - {{ item.accountDisplayType }}</div>
            <div class="account_balance">{{ item.balance }}{{ item.currency }}</div>
          </div>
          <el-radio class="account_radio" :value="innerValue" :label="item.login">{{ '' }}</el-radio>
        </div>
      </template>
      <Empty v-else :image-size="80"></Empty>
    </div>
  </el-popover>
</template>

<script>
import splitThousands from '@/util/splitThousands'

export default {
  name: 'AccountSelect',
  filters: {
    splitThousands,
  },
  props: {
    /**
     * accountDisplayType
     * currency
     * mtAccountTypeDisplay
     * balance
     * login
     */
    value: {
      type: [Number, String],
    },
    accountList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    valueWrapper() {
      return this.accountList.find(item => item.login === this.innerValue)
    },
  },
  data() {
    return {
      visible: false,
      width: 392,
    }
  },
  mounted() {
    this.width = this.$refs.select.clientWidth
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onChange(value) {
      this.innerValue = value.login
      this.$emit('change', value)
      this.visible = false
    },
    onResize() {
      this.width = this.$refs.select.clientWidth
    },
  },
}
</script>

<style lang="scss" scoped>
.account_select {
  position: relative;
  height: 60px;
  border: 1px solid $border-color-base;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    border-color: $info;
  }
  &:focus {
    border-color: $primary;
  }
  // padding: 8px;
  .arrow {
    position: absolute;
    top: 14px;
    @include rtl-sass-prop(right, left, 9px);
  }

  .placeholder {
    line-height: 42px;
    padding: 8px;
    color: $text-placeholder;
  }
}

.account_list .item,
.account_select .selected {
  & + .item {
    margin-top: 10px;
  }
  padding: 8px;
  height: 60px;
  display: flex;
  border-radius: 4px;

  .account_type {
    padding: 0 8px;
    background-color: #536bec;
    color: $white;
    border-radius: 4px;
    height: 20px;
    line-height: 20px;
    @include rtl-sass-prop(margin-right, margin-left, 8px);
  }

  .account_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .account_display_type {
      line-height: 20px;
      color: $text-primary;
      font-size: 14px;
    }

    .account_balance {
      line-height: 18px;
      font-size: 12px;
      color: $text-secondary;
    }
  }

  .account_radio {
    align-self: center;
  }
}
.account_list .item {
  &:hover {
    background-color: rgba($primary, 0.2);
  }
}
</style>
<style lang="scss">
.el-form-item.is-error {
  .account_select {
    border-color: $danger;
  }
}
.account_popover {
  padding: 8px;
}
</style>
