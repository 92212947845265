import router from '@/router'
import { startLoading, endLoading } from '@/util/loading'
import helper from '@/util/signinHelper'
import store from '@/store'
import { getCookies } from '@/util/cookies'
import redirect from '@/util/redirect'

export default {
  authorize() {
    router.beforeEach((to, from, next) => {
      const registerToken = to.query.a
      const accessToken = to.query.accessToken
      const cpPath = to.query.path
      const lang = to.query.lang

      if (lang) store.commit('common/setLang', lang)

      // ib 登出回到 cp 的 logout page
      if (to.path == '/logout') helper.signOut()
      else if (accessToken) helper.refreshToken(accessToken, cpPath)
      else if (registerToken) helper.register(registerToken)
      else if (store.state.common.loginStatus) {
        //  if login but doesn't have token, logout
        if (!getCookies('token')) helper.signOut()
        if (to.name == '/' || to.name == 'login' || to.name == 'to_login') next('/home')
        if (to.meta.authority && store.state.common.registerStep < 5) next('/authority')
        else {
          if (!to.meta.noShowLoading) startLoading('router')
          next()
        }
      } else {
        to.meta.allowAnonymous ? next() : redirect.redirectToLogoutUrl()
      }
    })
    router.afterEach((to, from) => {
      endLoading()
    })
  },
}
