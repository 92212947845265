<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top" class="account_config">
    <el-row>
      <el-col :span="12" :xs="24">
        <el-form-item prop="tradingPlatform" :label="$t('register.accountConfig.chooseTradingPlatform')">
          <el-radio-group v-model="form.tradingPlatform">
            <el-radio v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)" :key="index"
              :label="item.value" :disabled="disableField" :data-testid="item.value">
              <img src="@/assets/images-1/mt4.png" v-if="item.value === 'mt4'" class="platform_img" />
              <img src="@/assets/images-1/mt5.png" v-if="item.value === 'mt5'" class="platform_img" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" :xs="24">
        <el-form-item prop="accountType" :label="$t('register.accountConfig.chooseAccType')">
          <el-select v-model="form.accountType" :disabled="disableField" filterable>
            <el-option v-for="(item, index) in accountTypeList" :key="item.value" :label="item.label" :value="item.value"
              auto-complete="off" :data-testid="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" :xs="24">
        <el-form-item prop="currency" :label="$t('register.accountConfig.chooseAccCurrency')">
          <el-select v-model="form.currency" :disabled="disableField" filterable>
            <el-option v-for="(item, index) in $config.openLiveAccount.chooseCurrency(regulator, form.accountType)"
              :key="item.currency" :label="item.value" :value="item.currency" auto-complete="off"
              :data-testid="item.currency"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" :xs="24">
        <el-form-item class="tnc_box">
          <el-checkbox id="checkbox" name="type" v-model="checkStatus" :disabled="disableField" data-testid="checkbox">
          </el-checkbox>
          <i18n path="openAdditAcc.byTicking" class="tnc">
            <template v-slot:tnc>
              <span @click="showTNC = !showTNC" data-testid="showTNC">{{ $t('openAdditAcc.tnc') }}</span>
            </template>
          </i18n>
        </el-form-item>
      </el-col>
    </el-row>
    <SDialog :visible="showTNC" mode="simple" :showFooterCancel="false" :title="$t('openAdditAcc.tnc')"
      custom-class="account_config_tnc" @cancel="showTNC = !showTNC" @confirm="showTNC = !showTNC">
      <!-- <ul v-html="
        $t($config.openLiveAccount.getAgreeCondition(regulator), {
          platform: $config.info.fullName,
          regulatorUrl: GLOBAL_DOMAIN_WEBSITE
        })
      "></ul> -->
      <ul v-for="(item, index) in getProtocol" :key="index">
        <li v-html="$t(`${item}`, {
          capitalName: $config.info.capitalName,
          protocolUrl: getProtocolUrl
        })"></li>
      </ul>
    </SDialog>
  </el-form>
</template>

<script>
import accountConfiguration from '@/mixins/register/accountConfiguration';

export default {
  mixins: [accountConfiguration],
  computed: {
    getProtocolUrl() {
      return this.$config.getProtocolUrl(this.regulator)
    },
    getProtocol() {
      return this.$config.getProtocol(this.regulator)
    },
  },
};
</script>

<style lang="scss">
// @import '@/assets/css/components/register/accountConfiguration.scss';

.el-form.account_config {
  max-width: 800px;

  .platform_img {
    width: 94px;
    height: 18px;
  }

  .tnc_box {
    .el-form-item__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-checkbox {
        width: auto;
      }

      .tnc {
        font-size: 14px;
        line-height: 22px;
        color: $text-primary;
        @include rtl-sass-prop(margin-left, margin-right, 8px);
        cursor: pointer;

        span {
          color: $text-link;
          @include rtl-sass-prop(margin-left, margin-right, 4px);
        }
      }
    }
  }
}

.account_config_tnc {
  li {
    font-size: 14px;
    line-height: 22px;
    color: $text-primary;

    a {
      color: $text-link;
    }
  }
}
</style>
