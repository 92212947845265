import { polyfill } from 'es6-promise'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import i18n from '@/lang'
import 'jquery'
import SlideVerify from 'vue-monoplasty-slide-verify'
import VueClipboard from 'vue-clipboard2'
//global redirect functions
import redirect from '@/util/redirect'
import config from './config'
import '@/assets/css/preload.scss'
import '@/assets/css/utilities/utilities.scss'
//global filters
import '@/util/filter'
import '@/util/resizeObserver'
import '@/mixins/global'
import Storage from 'vue-ls'
import '@/components/registerGlobalComponent'
import '@/icons'

const options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}

Vue.use(Storage, options)
Vue.use(SlideVerify)
Vue.use(ElementUI, {
  size: 'small',
})
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$redirect = redirect
Vue.prototype.$config = config

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
