<template>
  <div class="header_content">
    {{ title }}
    <template v-if="helpMessage">
      <el-popover placement="bottom" width="200" trigger="click" :content="helpMessage">
        <svg-icon className="help_icon" slot="reference" iconClass="help"></svg-icon>
      </el-popover>
    </template>
  </div>
</template>
<script>
export default {
  name: 'DialogHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    helpMessage: {
      type: String,
      default: '',
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.help_icon {
  color: $black;
  cursor: pointer;
  @include rtl-sass-prop(margin-left, margin-right, 8px);
  &:hover {
    color: $primary;
  }
}
</style>
