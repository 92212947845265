import router from '@/router'
import { Base64 } from 'js-base64'
import {
  apiSetlanguage,
  apiTo_logout,
  apiTo_login_by_token,
  apiTo_login_register,
  apiTo_login,
  apiTo_sms_code_login,
} from '@/resource'
import store from '@/store'
import redirect from '@/util/redirect'
import { getRegulator } from '@/util/regulator'
import { MessageBox } from 'element-ui'
import { setCookies } from '@/util/cookies'

function setUserProfile(resp) {
  const userInfo = resp.data.data
  store.commit('common/setLoginStatus', true)
  store.commit('common/setUserName', userInfo.userName)
  store.commit('common/setIndividualName', userInfo.userName.split('&')[0].trim())
  store.commit('common/setUserType', userInfo.userType)
  store.commit('common/setUserTypeEnum', userInfo.userTypeEnum)
  store.commit('common/setQuestionnaire', userInfo.pendingQuestionnaireCompletion)
  store.commit('common/setOpenAccountAction', userInfo.openAccountActionEnabled)
  store.commit('common/setCountryCode', userInfo.countryCode)
  store.commit('common/setAccessToken', Base64.encode(userInfo.accessToken))
  store.commit('common/setEmail', userInfo.email)
  setCookies('token', userInfo.accessToken, 30)
  store.commit('common/setRequireAddressProof', userInfo.identityProofStatus.requireAddressProof)
  store.commit('common/setRequireIDProof', userInfo.identityProofStatus.requireIDProof)
  store.commit('common/setPendingAddressProofApproval', userInfo.identityProofStatus.pendingAddressProofApproval)
  store.commit('common/setPendingIDProofApproval', userInfo.identityProofStatus.pendingIDProofApproval)
  store.commit('common/setRegulator', getRegulator(userInfo.regulator))
  store.commit('common/setEmailMasked', userInfo.emailMasked)

  //if user didn't change language at login page, change the language by customer's preference
  if (!store.state.common.isChangedLangAtLandingPage) {
    store.commit('common/setLang', userInfo.language)
    //if changed, save the user's preference
  } else {
    apiSetlanguage({ language: store.state.common.lang })
  }
  store.commit('common/setIbUrl', userInfo.url)

  store.commit('common/setRegisterStep', userInfo.registerStep)
  if (userInfo.demo) store.commit('common/setDemo', true)
  if (userInfo.registerStep >= 5) store.commit('register/setDisableField', true)
}

function relogin(resp) {
  MessageBox.confirm(resp.data.errmsg + ' Would you like to log out this account and then log in again?', 'Info', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
    type: 'info',
  }).then(() => {
    signOut(521)
  })
}

function signOut(method) {
  console.log('signout')
  if (store.state.common.loginStatus || method === 521) {
    apiTo_logout().then(resp => {
      store.commit('common/setLoginStatus', '')
      store.commit('register/reset')
      store.commit('regulator/reset')
      store.commit('payment/reset')
      store.commit('promotion/reset')
      store.commit('common/setAutoNotifyStatus')
      setCookies('token', '')
      redirect.redirectToLogoutUrl()
    })
  } else redirect.redirectToLogoutUrl()
}

export default {
  signIn(userName, password) {
    apiTo_login({
      userName_login: userName,
      password_login: password,
      utc: 39600000,
    }).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: { token: callbackData.token, maskedEmail: callbackData.email, callbackRegulator: callbackData.regulator },
        })
      } else if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/changePassword')
        else router.push('/home')
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      } else if (resp.data.code === 529) {
        //input wrong password more than 2 times and invoke slide verification
        store.commit('common/setFailedSecondTime', true)
      }
    })
  },
  signInMobile(countrycode, userName, password) {
    apiTo_login({
      country_code: countrycode,
      mobile_login: userName,
      password_login: password,
      utc: 39600000,
    }).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: { token: callbackData.token, maskedEmail: callbackData.email, callbackRegulator: callbackData.regulator },
        })
      } else if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/changePassword')
        else router.push('/home')
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      }
    })
  },
  signOut() {
    signOut()
  },
  refreshToken(accessToken, path) {
    apiTo_login_by_token({
      headers: {
        accessToken: Base64.decode(accessToken),
      },
    }).then(resp => {
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        store.commit('common/setAutoNotifyStatus', false)
        setUserProfile(resp)
        if (path) router.push(path)
        else router.push('/home')
      }
    })
  },
  register(registerToken) {
    apiTo_login_register({
      a: registerToken,
    }).then(resp => {
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/changePassword')
        else router.push('/home')
      } else {
        router.push('/login')
      }
    })
  },
  smsCodeLogin(token) {
    apiTo_sms_code_login({
      b: token,
    }).then(resp => {
      console.log(resp)
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.resetMobilePassword) {
          router.push({ path: 'resetPasswordMobile', query: { resetToken: resp.data.data.accessToken } })
        }
      } else {
        router.push('/loginByMobile')
      }
    })
  },
}
