<template>
  <!-- <li @click="cpsClick(payment)">
    <div class="top title">
      {{
        payment.merchant_variable.label
          ? langTranslate('deposit.default.channels.', payment.merchant_variable.label)
          : langTranslate('deposit.default.channels.', payment.merchant_variable.description_title)
      }}
    </div>
    <div class="info">
      <div class="logo" :class="paymentLogo(payment.payment_method)"></div>
      <div class="right">
        <div class="span">
          <div v-if="payment.merchant_variable.fee">
            {{ payment.merchant_variable.fee }} {{ $t('deposit.default.fee') }} <br />
          </div>
          {{ langTranslate('deposit.time.', payment.merchant_variable.processing_time) }}
        </div>
        <div class="more"><div></div></div>
      </div>
    </div>
  </li> -->
  <FundCard
    @click="cpsClick(payment)"
    :title="getTitle"
    :feeVisible="!!payment.merchant_variable.fee"
    :fee="payment.merchant_variable.fee"
    :time="langTranslate('deposit.time.', payment.merchant_variable.processing_time)"
  ></FundCard>
</template>

<script>
import cpsMixin from '@/mixins/page/deposit/cpsDeposit'
import FundCard from '@/components/FundCard.vue'

export default {
  name: 'DepositListing',
  components: {
    FundCard,
  },
  mixins: [cpsMixin],
  props: ['payment'],
  computed: {
    getTitle() {
      return this.payment.merchant_variable.label
        ? this.langTranslate('deposit.default.channels.', this.payment.merchant_variable.label)
        : this.langTranslate('deposit.default.channels.', this.payment.merchant_variable.description_title)
    },
  },
  methods: {
    cpsClick(payment) {
      payment.attach_variable = payment.attach_variable ? payment.attach_variable : ''
      payment.merchant_variable = payment.merchant_variable ? payment.merchant_variable : ''
      this.$store.dispatch('cps/actionSetPayment', payment)

      let paramsLabel = payment.merchant_variable?.label
        ? this.langTranslate('deposit.default.channels.', this.paymentDetails.merchant_variable?.label)
        : this.langTranslate('deposit.default.channels.', this.paymentDetails.merchant_variable?.description_title)
      this.$router.push({ name: 'cps', params: { name: paramsLabel } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
