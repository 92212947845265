<template>
  <el-drawer :visible.sync="innerVisible" v-bind="getBindProps" v-on="getOnEvents" :before-close="onCancel">
    <template #title v-if="$slots.title || getBindProps.title">
      <template v-if="!$slots.title">
        <DrawerHeader :helpMessage="helpMessage" :title="getBindProps.title"></DrawerHeader>
      </template>
      <template v-else>
        <slot name="title"></slot>
      </template>
    </template>

    <div class="drawer_body">
      <slot></slot>
    </div>
    <div class="drawer_footer" v-if="showFooter">
      <DrawerFooter v-bind="getBindProps" :showFooterCancel="showFooterCancel" :showFooterConfirm="showFooterConfirm"
        :disabledFooterConfirm="disabledFooterConfirm" :disabledFooterCancel="disabledFooterCancel" @cancel="onCancel"
        @confirm="onConfirm">
        <template #footer>
          <slot name="footer"></slot>
        </template>

        <template #insertFooter>
          <slot name="insertFooter"></slot>
        </template>
        <template #centerFooter>
          <slot name="centerFooter"></slot>
        </template>
        <template #appendFooter>
          <slot name="appendFooter"></slot>
        </template>
      </DrawerFooter>
    </div>
  </el-drawer>
</template>

<script>
import DrawerHeader from './components/DrawerHeader.vue'
import DrawerFooter from './components/DrawerFooter.vue'
import isMobile from '@/mixins/isMobile'

export default {
  name: 'SDrawer',
  mixins: [isMobile],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 抽屉提示文案
    helpMessage: {
      type: String,
      default: '',
    },
    // 是否显示footer
    showFooter: {
      type: Boolean,
      default: true,
    },
    // 是否显示footer确定按钮
    showFooterConfirm: {
      type: Boolean,
      default: true,
    },
    // 是否显示footer取消按钮
    showFooterCancel: {
      type: Boolean,
      default: true,
    },
    // 是否禁用footer确定按钮
    disabledFooterConfirm: {
      type: Boolean,
      default: false,
    },
    // 是否禁用footer取消按钮
    disabledFooterCancel: {
      type: Boolean,
      default: false,
    },
    // 是否关闭footer取消按钮的默认行为(关闭抽屉)
    stopFooterCancel: {
      type: Boolean,
      default: false,
    },
    // confirm按钮的文案
    confirmButtonText: {
      type: String,
      default() {
        return this.$t('common.button.confirm')
      },
    },
    // cancel按钮的文案
    cancelButtonText: {
      type: String,
      default() {
        return this.$t('common.button.cancel')
      },
    },
    // 关闭抽屉时是否需要二次确认弹窗
    closeConfirm: {
      type: Boolean,
      default: false,
    },
    // 关闭抽屉时二次确认弹窗的文案
    closeConfirmText: {
      type: String,
      default() {
        return this.$t('common.field.confirmClose')
      },
    },
  },
  components: {
    DrawerFooter,
    DrawerHeader,
  },
  computed: {
    getBindProps() {
      const opt = {
        'append-to-body': true,
        'close-on-press-escape': false,
        modal: false,
        size: '440px',
        direction: this.$store.state.common.lang === 'ar' ? 'ltr' : 'rtl',
        ...this.$attrs,
        'custom-class': this.getCustomClass,
      }
      // 移动端drawer宽度100%
      if (this.isMobile) {
        opt.size = '100%'
      }
      return opt
    },
    getOnEvents() {
      return {
        ...this.$listeners,
      }
    },
    getCustomClass() {
      let defaultClass = 'new_drawer_custom_class'
      if (this.$attrs['custom-class']) {
        defaultClass = `${defaultClass} ${this.$attrs['custom-class']}`
      }
      if (this.showFooter) {
        defaultClass = `${defaultClass} ${this.$attrs['custom-class']} has_footer`
      }
      return defaultClass
    },
    innerVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  mounted() { },
  methods: {
    async onCancel() {
      if (this.closeConfirm) {
        const res = await this.closeFunc()
        if (res) {
          this.$emit('cancel')
        }
        this.innerVisible = !res
        return
      } else {
        this.$emit('cancel')
        if (!this.stopFooterCancel) {
          this.innerVisible = false
        }
      }
    },
    onConfirm() {
      this.$emit('confirm')
    },
    closeFunc() {
      return new Promise(resolve => {
        this.$msgbox({
          title: this.$t('common.field.tips'),
          message: this.closeConfirmText,
          showCancelButton: true,
          confirmButtonText: this.$t('common.button.confirm'),
          cancelButtonText: this.$t('common.button.cancel'),
          beforeClose: (action, _, done) => {
            if (action === 'confirm') {
              done()
              resolve(true)
            } else {
              done()
              resolve(false)
            }
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.new_drawer_custom_class {
  // @include screen-mobile() {
  //   padding: 25px 30px;
  // }

  .el-drawer__header {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    color: $text-title;
    font-weight: 600;
    border-bottom: 1px solid $border-color-base;
    margin: 0;

    .header_content {
      position: relative;
      @include rtl-sass-prop(padding-left, padding-right, 10px);

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        @include rtl-sass-prop(left, right, 0);
        width: 4px;
        height: 16px;
        border-radius: 4px;
        background-color: $primary;
      }
    }

    .el-drawer__close-btn {
      color: $black;

      &:hover {
        color: $primary;
      }
    }
  }

  .drawer_body {
    padding: 16px 24px;
  }

  &.has_footer {
    .drawer_body {
      padding-bottom: 80px;
    }
  }

  .drawer_footer {
    position: absolute;
    bottom: 0;
    @include rtl-sass-prop(right, left, 0);
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid $border-color-base;
    background-color: $white;

    .footer_content {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
