<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.userTitle')" prop="title">
          <el-select v-model="form.title" :disabled="disableField" id="title" data-testid="userTitle">
            <el-option v-for="(item, index) in titleList" :key="index" :label="item.label" :value="item.value"
              auto-complete="off" :data-testid="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.firstName')" prop="firstName">
          <el-input v-model="form.firstName" :placeholder="$t('common.field.pleaseInput')" :disabled="disableField"
            id="firstName" maxlength="128" type="text" data-testid="firstName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.middleName')" prop="middleName">
          <el-input v-model="form.middleName" :placeholder="$t('common.field.pleaseInput')" :disabled="disableField"
            id="middleName" maxlength="128" type="text" data-testid="middleName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.lastName')" prop="lastName">
          <el-input v-model="form.lastName" :placeholder="$t('common.field.pleaseInput')" :disabled="disableField"
            id="lastName" maxlength="128" type="text" data-testid="lastName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.nationality')" prop="nationalityId">
          <el-select v-model="form.nationalityId" id="nationalityId" :disabled="disableField" filterable
            data-testid="nationalityId">
            <el-option v-for="(item, index) in nationalities" :key="index" :label="item.nationality" :value="item.id"
              auto-complete="new-password" :data-testid="item.nationality"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.email')" prop="email">
          <el-input v-model="form.email" :placeholder="$t('common.field.pleaseInput')" id="email"
            :readonly="emailReadOnly" type="email" data-testid="email"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-row :gutter="8">
          <p class="required-label">
            <span class="required_icon">*</span>{{ $t('register.personalDetails.page1.phoneNum') }}
          </p>
          <el-col :span="12">
            <el-form-item prop="phoneCode" class="phone" label="">
              <el-select v-model="form.phoneCode" id="phoneCode" :disabled="disableField" filterable
                data-testid="phoneCode">
                <el-option v-for="(item, index) in phoneCodes" :key="index" :label="item" :value="item"
                  :data-testid="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="mobile" class="phone">
              <el-input v-model="form.mobile" :placeholder="$t('common.field.pleaseInput')" :disabled="disableField"
                id="mobile" auto-complete="new-password" data-testid="mobile" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.dob')" prop="dob">
          <el-row :gutter="8">
            <el-col :span="8">
              <el-select v-model="dateOfBirth.date" :disabled="disableField"
                :placeholder="$t('register.personalDetails.page1.date')" filterable id="dob" class="dob"
                data-testid="dob">
                <el-option v-for="(item, index) in dates" :key="index" :label="item" :value="item"
                  :data-testid="item"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="dateOfBirth.month" :disabled="disableField"
                :placeholder="$t('register.personalDetails.page1.month')" filterable class="dob" data-testid="month">
                <el-option v-for="month in months" :key="month" :label="month" :value="month"
                  :data-testid="month"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="dateOfBirth.year" :disabled="disableField"
                :placeholder="$t('register.personalDetails.page1.year')" filterable class="dob" data-testid="year">
                <el-option v-for="item in years" :key="item" :label="item" :value="item" :data-testid="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.placeOfBirth')" prop="placeOfBirth">
          <el-select v-model="form.placeOfBirth" id="placeOfBirth" :disabled="disableField" filterable
            data-testid="placeOfBirth">
            <el-option v-for="(item, index) in birthPlace" :key="index" :label="item.countryName" :value="item.id"
              auto-complete="new-password" :data-testid="item.countryName"></el-option> </el-select></el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item :label="$t('register.personalDetails.page1.idType')" prop="idType">
          <el-select v-model="form.idType" id="idType" :disabled="disableField" filterable data-testid="idType">
            <el-option v-for="(item, index) in identificationTypes" :key="index" :label="item.label" :value="item.value"
              :data-testid="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item :label="idTypeLabel" prop="idNumber">
          <el-input v-model="form.idNumber" :placeholder="$t('common.field.pleaseInput')" id="idNumber"
            :disabled="disableField" type="text" data-testid="idNumber" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <el-form-item prop="name">
          <el-checkbox :label="$t('register.personalDetails.page1.referral')" name="type" v-model="referredCheck"
            :disabled="disableField" data-testid="checkbox"></el-checkbox>
          <el-input type="text" :disabled="disableField" v-if="referredCheck" v-model="form.referredBy"
            data-testid="referredCheck" />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import userDetail from '@/mixins/register/userDetail';

export default {
  mixins: [userDetail],
  data() {
    return {
      titleList: [
        { value: 'Mr', label: this.$t('register.personalDetails.page1.titleDropdown.mr') },
        { value: 'Mrs', label: this.$t('register.personalDetails.page1.titleDropdown.mrs') },
        { value: 'Ms', label: this.$t('register.personalDetails.page1.titleDropdown.ms') },
        { value: 'Miss', label: this.$t('register.personalDetails.page1.titleDropdown.miss') },
        { value: 'Dr', label: this.$t('register.personalDetails.page1.titleDropdown.dr') },
        { value: 'Prof', label: this.$t('register.personalDetails.page1.titleDropdown.prof') }
      ],
      identificationTypes: [
        { value: 1, label: this.$t('register.personalDetails.page1.idTypeDropdown.idCard') },
        { value: 2, label: this.$t('register.personalDetails.page1.idTypeDropdown.passport') },
        { value: 3, label: this.$t('register.personalDetails.page1.idTypeDropdown.driverLicense') }
      ]
    };
  },
  computed: {
    birthPlace() {
      return this.$store.state.register.birthPlace;
    },
    idTypeLabel() {
      switch (this.form.idType) {
        case 2:
          return this.$t('register.personalDetails.page1.passportNum');
        case 3:
          return this.$t('register.personalDetails.page1.driverLicenseNum');
        default:
          return this.$t('register.personalDetails.page1.idNum');
      }
    }
  },
  watch: {
    idTypeLabel(label) {
      this.rules.idNumber[0].message = this.$t('common.formValidation.dynamicReq', {
        dynamic: this.idTypeLabel
      });
      this.$refs.ruleForm.validateField('idNumber');
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/register/personalDetails.scss';
.el-form {
  max-width: 800px;
}

.required-label {
  font-size: 14px;
  line-height: 22px;
  color: $text-secondary;
  padding-bottom: 8px;

  span {
    color: $danger;
  }
}
</style>
