export const NO_DEPOSIT_BONUS_PATHNAME = '/noDeposit'
export const REFER_A_FRIEND_PATHNAME = '/referFriends'
export const DEPOSIT_BONUS_PATHNAME = '/depositBonus'
export const TRADING_BONUS_PATHNAME = '/tradingBonus'
export const DEPOSIT_PATHNAME = '/depositFunds'
export const CASHBACK_PATHNAME = '/cryptoCashback'
export const DEPOSIT_20_BONUS_PATHNAME = '/deposit20Bonus'
export const XMAS_MAKE_A_WISH_PATHNAME = '/xmasMakeAWish'
export const MILLION_DOLLAR_CASH_POOL_PATHNAME = '/millionDollarCashPool'
export const FIRST_TIME_DEPOSIT_PATHNAME = '/firstTimeDeposit'
