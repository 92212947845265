<template>
  <el-form-item label class="upload">
    <el-upload
      :action="action ? action : '/api/file/upload'"
      :limit="limit"
      :accept="accept"
      :before-upload="examFile"
      :before-remove="deleteFile"
      :on-success="saveUploadPath"
      :on-error="uploadError"
      data-testid="upload"
    >
      <slot></slot>
      <div class="upload-demo" v-if="!selfText">
        <svg-icon iconClass="upload"></svg-icon>
        <p class="button-upload" data-testid="button_upload">{{ $t('common.button.selectFile') }}</p>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="!selfTip">
        <slot name="warnTip"></slot>
        {{ $t('fileUploader.supportedFormat') }}<br />
        {{ $t('fileUploader.maxSize', { maxSize: 5 }) }}
        <br />
        {{ $t('fileUploader.maxFileNum', { maxFileNum: limit }) }}
      </div>
    </el-upload>
  </el-form-item>
</template>

<script>
import { apiDelete_file } from '@/resource'
export default {
  name: 'vUpload',
  props: {
    action: String,
    limit: Number,
    selfTip: Boolean,
    selfText: Boolean,
  },
  data() {
    return {
      accept: 'image/png, image/jpeg, image/jpg, image/bmp, .pdf, .doc, .docx',
      fileInfo: {
        fileList: [],
      },
      allowedFile: ['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'doc', 'docx'],
    }
  },
  methods: {
    updateFileInfo() {
      this.$emit('updateFileInfo', this.fileInfo)
    },
    updateDeletedFile(file) {
      this.$emit('updateDeletedFile', file)
    },
    examFile(file) {
      if (file.size / 1024 / 1024 > 5) {
        console.log('file size ' + file.size + ' is over size')
        return false
      }

      if (this.isFileSupported(file)) {
        return true
      } else {
        console.log('unsupported file type: ' + file.name)
        return false
      }
    },
    isFileSupported(file) {
      return this.allowedFile.some(s => {
        return file.name.toLowerCase().endsWith(s)
      })
    },
    deleteFile(file) {
      if (this.fileInfo.fileList.length == 0) {
        return true
      } else if (file.response) {
        return apiDelete_file({ fileId: file.response.data })
          .then(result => {
            if (result.data.code == 0) {
              this.fileInfo.fileList = this.fileInfo.fileList.filter(item => item != file.response.data)
              this.updateFileInfo()
              this.updateDeletedFile(file.response.data)
              return Promise.resolve(true)
            } else {
              console.log(result.data.errmsg)
              return Promise.reject()
            }
          })
          .catch(err => {
            console.log(err)
            return Promise.reject()
          })
      }
    },
    saveUploadPath(response) {
      console.log(`file: ${response}`)
      if (response.code == 0) {
        this.fileInfo.fileList.push(response.data)
        this.updateFileInfo()
      } else if (response.code == 'session_timeout') {
        this.$router.push(response.loginUrl)
      }
    },
    uploadError(response) {
      console.log(`file fail`)
      this.$message({ message: this.$t('responseMsg.500'), type: 'error' })
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/vUpload.scss';
/deep/ {
  .el-upload {
    width: 100%;
    padding: 82px 0;
    border: 1px dashed #dfe4eb;
    border-radius: 4px;
    background-color: #f8f9fc;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .upload-demo {
    .svg-icon {
      font-size: 32px;
    }

    .upload_btn {
      font-size: 12px;
      color: #7a8699;
      line-height: 18px;
      margin-top: 4px;
    }
  }
}
</style>
