// 進行異步執行
import {
  apiCountries,
  apiProvinces,
  apiNationalities,
  apiBirthPlace,
  apiPhoneCodes,
  apiEmploymentFinance,
  apiTrading,
} from '@/resource/register'

let countriesLang, provincesLang, nationalitiesLang, birthPlaceLang, EFQuestionLang, tradingQuestionLang

export default {
  async actionCountries({ commit, state }, lang) {
    if (countriesLang == lang && state.countries.length > 0) return
    countriesLang = lang
    await apiCountries(lang).then(result => {
      commit('getCountries', result.data.data)
    })
  },
  async actionProvinces({ commit, state }, lang) {
    if (provincesLang == lang && state.provinces.length > 0) return
    provincesLang = lang
    await apiProvinces(lang).then(result => {
      commit('getProvinces', result.data.data)
    })
  },
  async actionNationalities({ commit, state }, lang) {
    if (nationalitiesLang == lang && state.nationalities.length > 0) return
    nationalitiesLang = lang
    await apiNationalities(lang).then(result => {
      commit('getNationalities', result.data.data)
    })
  },
  async actionBirthPlace({ commit, state }, lang) {
    if (birthPlaceLang == lang && state.birthPlace.length > 0) return
    birthPlaceLang = lang
    await apiBirthPlace(lang).then(result => {
      commit('getBirthPlace', result.data.data)
    })
  },
  async actionPhoneCodes({ commit, state }) {
    if (state.phoneCodes.length > 0) return
    await apiPhoneCodes().then(result => {
      commit('getPhoneCodes', result.data.data)
    })
  },
  async actionEFQuestion({ commit, state }, lang) {
    if (EFQuestionLang == lang && state.EFQuestion.length > 0) return state.EFQuestion
    EFQuestionLang = lang
    return new Promise((resolve, reject) => {
      apiEmploymentFinance(lang).then(result => {
        commit('getEFQuestion', result.data.data)
        resolve(result.data.data)
      })
    })
  },
  async actionTradingQuestion({ commit, state }, lang) {
    if (tradingQuestionLang == lang && state.tradingQuestions.length > 0) return state.tradingQuestions
    tradingQuestionLang = lang
    return new Promise((resolve, reject) => {
      apiTrading(lang).then(result => {
        commit('getTradingQuestions', result.data.data)
        resolve(result.data.data)
      })
    })
  },
}
