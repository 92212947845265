import { countryCodeEnum } from './country'

export const WHITELIST = {
  [countryCodeEnum.ANTARCTICA]: true,
  [countryCodeEnum.ARGENTINA]: true,
  [countryCodeEnum.ARMENIA]: true,
  [countryCodeEnum.ARUBA]: true,
  [countryCodeEnum.AZERBAIJAN]: true,
  [countryCodeEnum.BAHAMAS]: true,
  [countryCodeEnum.BAHRAIN]: true,
  [countryCodeEnum.BANGLADESH]: true,
  [countryCodeEnum.BHUTAN]: true,
  [countryCodeEnum.BOLIVIA]: true,
  [countryCodeEnum.BOTSWANA]: true,
  [countryCodeEnum.BRAZIL]: true,
  [countryCodeEnum.BRUNEI_DARUSSALAM]: true,
  [countryCodeEnum.CAMEROON]: true,
  [countryCodeEnum.CHAD]: true,
  [countryCodeEnum.CHILE]: true,
  [countryCodeEnum.CHINA]: true,
  [countryCodeEnum.CHRISTMAS_ISLAND]: true,
  [countryCodeEnum.COLOMBIA]: true,
  [countryCodeEnum.COOK_ISLANDS]: true,
  [countryCodeEnum.CYPRUS]: true,
  [countryCodeEnum.DJIBOUTI]: true,
  [countryCodeEnum.DOMINICA]: true,
  [countryCodeEnum.DOMINICAN_REPUBLIC]: true,
  [countryCodeEnum.ECUADOR]: true,
  [countryCodeEnum.EGYPT]: true,
  [countryCodeEnum.FAROE_ISLAND]: true,
  [countryCodeEnum.FIJI]: true,
  [countryCodeEnum.GEORGIA]: true,
  [countryCodeEnum.GHANA]: true,
  [countryCodeEnum.GREENLAND]: true,
  [countryCodeEnum.INDIA]: true,
  [countryCodeEnum.INDONESIA]: true,
  [countryCodeEnum.KAZAKHSTAN]: true,
  [countryCodeEnum.KENYA]: true,
  [countryCodeEnum.KOREA]: true,
  [countryCodeEnum.KUWAIT]: true,
  [countryCodeEnum.KYRGYZSTAN]: true,
  [countryCodeEnum.LAOS]: true,
  [countryCodeEnum.MACAO]: true,
  [countryCodeEnum.MADAGASCAR]: true,
  [countryCodeEnum.MALAYSIA]: true,
  [countryCodeEnum.MALDIVES]: true,
  [countryCodeEnum.MAURITIUS]: true,
  [countryCodeEnum.MEXICO]: true,
  [countryCodeEnum.MONGOLIA]: true,
  [countryCodeEnum.MOZAMBIQUE]: true,
  [countryCodeEnum.NAMIBIA]: true,
  [countryCodeEnum.NEPAL]: true,
  [countryCodeEnum.NEW_ZEALAND]: true,
  [countryCodeEnum.NIGER]: true,
  [countryCodeEnum.NIGERIA]: true,
  [countryCodeEnum.NORFOLK_ISLAND]: true,
  [countryCodeEnum.PAPUA_NEW_GUINEA]: true,
  [countryCodeEnum.PARAGUAY]: true,
  [countryCodeEnum.PERU]: true,
  [countryCodeEnum.PUERTO_RICO]: true,
  [countryCodeEnum.SAUDI_ARABIA]: true,
  [countryCodeEnum.SOUTH_AFRICA]: true,
  [countryCodeEnum.SRI_LANKA]: true,
  [countryCodeEnum.TANZANIA]: true,
  [countryCodeEnum.THAILAND]: true,
  [countryCodeEnum.TIMOR_LESTE]: true,
  [countryCodeEnum.TOGO]: true,
  [countryCodeEnum.TUNISIA]: true,
  [countryCodeEnum.TURKMENISTAN]: true,
  [countryCodeEnum.URUGUAY]: true,
  [countryCodeEnum.UZBEKISTAN]: true,
  [countryCodeEnum.VIETNAM]: true,
  [countryCodeEnum.SOUTH_KOREA]: false,
}

export const WHITELIST2 = {
  [countryCodeEnum.ALAND_ISLANDS]: true,
  [countryCodeEnum.AMERICAN_SAMOA]: true,
  [countryCodeEnum.ANDORRA]: true,
  [countryCodeEnum.ARUBA]: true,
  [countryCodeEnum.AUSTRIA]: true,
  [countryCodeEnum.BAHRAIN]: true,
  [countryCodeEnum.BELGIUM]: true,
  [countryCodeEnum.BENIN]: true,
  [countryCodeEnum.BHUTAN]: true,
  [countryCodeEnum.BULGARIA]: true,
  [countryCodeEnum.CAMEROON]: true,
  [countryCodeEnum.CANADA]: true,
  [countryCodeEnum.CAPE_VERDE]: true,
  [countryCodeEnum.CHAD]: true,
  [countryCodeEnum.CHILE]: true,
  [countryCodeEnum.CHINA]: true,
  [countryCodeEnum.COLOMBIA]: true,
  [countryCodeEnum.COMOROS]: true,
  [countryCodeEnum.COSTA_RICA]: true,
  [countryCodeEnum.CYPRUS]: true,
  [countryCodeEnum.CZECH_REPUBLIC]: true,
  [countryCodeEnum.DENMARK]: true,
  [countryCodeEnum.DOMINICA]: true,
  [countryCodeEnum.DOMINICAN_REPUBLIC]: true,
  [countryCodeEnum.EGYPT]: true,
  [countryCodeEnum.EL_SALVADOR]: true,
  [countryCodeEnum.EQUATORIAL_GUINEA]: true,
  [countryCodeEnum.ESTONIA]: true,
  [countryCodeEnum.SWAZILAND]: true,
  [countryCodeEnum.FIJI]: true,
  [countryCodeEnum.FINLAND]: true,
  [countryCodeEnum.FRANCE]: true,
  [countryCodeEnum.FRENCH_GUIANA]: true,
  [countryCodeEnum.FRENCH_POLYNESIA]: true,
  [countryCodeEnum.GABON]: true,
  [countryCodeEnum.GAMBIA]: true,
  [countryCodeEnum.GEORGIA]: true,
  [countryCodeEnum.GERMANY]: true,
  [countryCodeEnum.GREECE]: true,
  [countryCodeEnum.GRENADA]: true,
  [countryCodeEnum.GUADELOUPE]: true,
  [countryCodeEnum.GUAM]: true,
  [countryCodeEnum.GUATEMALA]: true,
  [countryCodeEnum.HONG_KONG]: true,
  [countryCodeEnum.HUNGARY]: true,
  [countryCodeEnum.INDIA]: true,
  [countryCodeEnum.INDONESIA]: true,
  [countryCodeEnum.IRELAND]: true,
  [countryCodeEnum.ITALY]: true,
  [countryCodeEnum.JAPAN]: true,
  [countryCodeEnum.LAOS]: true,
  [countryCodeEnum.LATVIA]: true,
  [countryCodeEnum.LESOTHO]: true,
  [countryCodeEnum.LIECHTENSTEIN]: true,
  [countryCodeEnum.LITHUANIA]: true,
  [countryCodeEnum.LUXEMBOURG]: true,
  [countryCodeEnum.MACAO]: true,
  [countryCodeEnum.MADAGASCAR]: true,
  [countryCodeEnum.MALAWI]: true,
  [countryCodeEnum.MALAYSIA]: true,
  [countryCodeEnum.MALDIVES]: true,
  [countryCodeEnum.MARTINIQUE]: true,
  [countryCodeEnum.MAURITANIA]: true,
  [countryCodeEnum.MEXICO]: true,
  [countryCodeEnum.MONACO]: true,
  [countryCodeEnum.MOZAMBIQUE]: true,
  [countryCodeEnum.NAURU]: true,
  [countryCodeEnum.NETHERLANDS]: true,
  [countryCodeEnum.NEW_CALEDONIA]: true,
  [countryCodeEnum.NEW_ZEALAND]: true,
  [countryCodeEnum.NIGER]: true,
  [countryCodeEnum.NIGERIA]: false,
  [countryCodeEnum.NORWAY]: true,
  [countryCodeEnum.PALAU]: true,
  [countryCodeEnum.PERU]: true,
  [countryCodeEnum.POLAND]: true,
  [countryCodeEnum.PORTUGAL]: true,
  [countryCodeEnum.PUERTO_RICO]: true,
  [countryCodeEnum.REUNION]: true,
  [countryCodeEnum.SAINT_LUCIA]: true,
  [countryCodeEnum.SAN_MARINO]: true,
  [countryCodeEnum.SAUDI_ARABIA]: true,
  [countryCodeEnum.SINGAPORE]: true,
  [countryCodeEnum.SLOVAKIA]: true,
  [countryCodeEnum.SOLOMON_ISLANDS]: true,
  [countryCodeEnum.SOUTH_AFRICA]: true,
  [countryCodeEnum.SOUTH_KOREA]: true,
  [countryCodeEnum.SURINAME]: true,
  [countryCodeEnum.SWEDEN]: true,
  [countryCodeEnum.SWITZERLAND]: true,
  [countryCodeEnum.TAIWAN]: true,
  [countryCodeEnum.THAILAND]: true,
  [countryCodeEnum.TIMOR_LESTE]: true,
  [countryCodeEnum.TOGO]: true,
  [countryCodeEnum.TONGA]: true,
  [countryCodeEnum.UNITED_KINGDOM]: true,
  [countryCodeEnum.URUGUAY]: true,
  [countryCodeEnum.UZBEKISTAN]: true,
  [countryCodeEnum.VATICAN_CITY_STATE]: true,
  [countryCodeEnum.VIETNAM]: true,
  [countryCodeEnum.SOUTH_KOREA]: false,
}
