// 進行同步執行

export default {
  getCountries(state, value) {
    state.countries = value
  },
  getProvinces(state, value) {
    state.provinces = value
  },
  getNationalities(state, value) {
    state.nationalities = value
  },
  getBirthPlace(state, value) {
    state.birthPlace = value
  },
  getPhoneCodes(state, value) {
    state.phoneCodes = value
  },
  getEFQuestion(state, value) {
    state.EFQuestion = value
  },
  getTradingQuestions(state, value) {
    state.tradingQuestions = value
  },
  setDisableField(state, value) {
    state.disableField = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key == 'disableField') state[key] = false
      else state[key] = []
    })
  },
}
